import * as csc from 'cornerstone-core';
import * as csm from 'cornerstone-math';
import { getNormalizedOrth, getNormalizedVector } from './geometryHelper';

/**
 * Map predictions coords by first converting them in canvas coords system since the image sent to
 * back-end comes from the canvas and not the underlying image, then convert canvas coords into
 * pixel coords.
 * @param {Coords} coords
 * @param {HTMLElement} el
 */
const mapFloatCoordsToPixels = ({ x, y }, el) => ({
  x: el.image.width * x,
  y: el.image.height * y,
});

/**
 * @param {*} e   Element used to retrieve pixel aspect ratio
 * @param {*} p1
 * @param {*} p2
 * @returns Distance between 2 point which respect non square pixel
 */
const getDistanceForElement = (e, p1, p2) => {
  const { image } = csc.getEnabledElement(e);

  const columnPixelSpacing = image.columnPixelSpacing || 1;
  const rowPixelSpacing = image.rowPixelSpacing || 1;

  const ratio = columnPixelSpacing / rowPixelSpacing;
  const p1WithSpacing = { x: p1.x, y: p1.y * ratio };
  const p2WithSpacing = { x: p2.x, y: p2.y * ratio };

  return csm.point.distance(p1WithSpacing, p2WithSpacing);
};

const getCanvasDistance = (e, p1, p2) => {
  const p1c = csc.pixelToCanvas(e, p1);
  const p2c = csc.pixelToCanvas(e, p2);

  return csm.point.distance(p1c, p2c);
};

const scaleDistanceToCanvas = (e, distance) => {
  const viewport = csc.getViewport(e);
  return distance * viewport.scale;
};

const offsetPixelPointInCanvas = (elem, pixelCoords, canvasOffset) => {
  const canvasCoords = csc.pixelToCanvas(elem, pixelCoords);
  canvasCoords.x += canvasOffset.x;
  canvasCoords.y += canvasOffset.y;
  return csc.canvasToPixel(elem, canvasCoords);
};

const projectAlongAxis = (elem, refPoint, p1, p2, canvasDist) => {
  const proj = getNormalizedVector(csc.pixelToCanvas(elem, p1), csc.pixelToCanvas(elem, p2));
  return offsetPixelPointInCanvas(elem, refPoint, {
    x: proj.x * canvasDist,
    y: proj.y * canvasDist,
  });
};

const projectPerpendicularToAxis = (elem, refPoint, p1, p2, canvasDist) => {
  const proj = getNormalizedOrth(csc.pixelToCanvas(elem, p1), csc.pixelToCanvas(elem, p2));
  return offsetPixelPointInCanvas(elem, refPoint, {
    x: proj.x * canvasDist,
    y: proj.y * canvasDist,
  });
};

export {
  mapFloatCoordsToPixels,
  getCanvasDistance,
  getDistanceForElement,
  scaleDistanceToCanvas,
  offsetPixelPointInCanvas,
  projectAlongAxis,
  projectPerpendicularToAxis,
};
