import './style.css';

import React from 'react';
import { components } from 'react-select';

import PatientInfoBar from '../PatientInfoBar';

/* eslint-disable */
const OptionWithPatientData = (displayedField) =>
  class extends React.PureComponent {
    render = () => {
      if (this.props.data.__isNew__) {
        return <components.Option {...this.props} />;
      }

      const { innerProps, innerRef, data, getStyles } = this.props;
      const style = getStyles('option', this.props);
      const { file_id, name, owner_name, birth_date, specie, sex } = data.data;
      return (
        <div {...innerProps} style={style} ref={innerRef} className="patient-info-option">
          <div className="value">{data.data[displayedField]}</div>
          <PatientInfoBar
            className="info-bar"
            fileID={file_id}
            animalName={name}
            ownerName={owner_name}
            birthDate={birth_date}
            specie={specie}
            sex={sex}
            showId={false}
          />
        </div>
      );
    };
  };
/* eslint-enable */

export default OptionWithPatientData;
