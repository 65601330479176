/* eslint-disable no-underscore-dangle */
import 'app/styles/style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import ReactDatePicker from 'react-datepicker';
import produce from 'immer';

import {
  SPECIES,
  RACES,
  makeSpeciesOptions,
  makeRacesOptions,
  DEFAULT_CAT_RACE,
} from '../../utils/speciesConstants';
import { SEXES, makeSexesOptions, SEXES_WITH_OTHER } from '../../utils/sexesConstants';
import ApiCalls from '../../utils/apiCalls';

import PatientConflictWarning from '../PatientConflictWarning';
import OptionWithPatientData from '../OptionWithPatientData';
import ToolsDropdownIndicator from '../ToolsBar/ToolsDropdownIndicator';
import AgeFromDate from '../AgeFromDate';
import { DARK_INPUT_STYLE } from 'app/ReactSelectStyles';
import RequiredAsterisk from '../RequiredAsterisk';

const ON_CHANGE_ACTIONS = ['clear', 'select-option', 'create-option'];

const STATE_FIELD_WITH_SELECTED_OPTIONS = [
  'fileID',
  'chipID',
  'pedigreeID',
  'owner',
  'animal',
  'race',
];
const STATE_FIELD_WITH_VALUE = ['birthDate', 'specie', 'sex', 'attendingVeterinarian'];

const textSelectStyle = _.merge({}, _.cloneDeep(DARK_INPUT_STYLE), {
  container: (provided, ...args) => ({
    ...DARK_INPUT_STYLE.container(provided, ...args),
    minWidth: '10ch',
  }),
});

const sexStyles = _.merge({}, _.cloneDeep(DARK_INPUT_STYLE), {
  container: (provided, ...args) => ({
    ...DARK_INPUT_STYLE.container(provided, ...args),
    width: 'fit-content',
  }),
  control: (provided, ...args) => ({
    ...DARK_INPUT_STYLE.control(provided, ...args),
    width: '32px',
    paddingLeft: '0',
  }),
  option: (provided, ...args) => ({
    ...DARK_INPUT_STYLE.option(provided, ...args),
    padding: '5px',
  }),
  input: (provided) => ({ ...provided, color: 'transparent' }),
  singleValue: (provided, ...args) => ({
    ...DARK_INPUT_STYLE.singleValue(provided, ...args),
    transform: 'translateY(-50%) translateX(-50%)',
    left: '50%',
    top: '50%',
    margin: '0 !important',
  }),
});

const formatFieldFromData = (fieldKey, animalData) =>
  animalData && animalData[fieldKey]
    ? {
        label: animalData[fieldKey],
        value: animalData[fieldKey],
        data: animalData,
      }
    : null;

const formatPatientInfoData = (state) =>
  _.omitBy(
    {
      file_id: _.get(state, 'fileID.selectedOption.value'),
      chip_id: _.get(state, 'chipID.selectedOption.value'),
      pedigree_id: state.pedigreeID?.selectedOption?.value,
      animal_id: state.animalId,
      name: _.get(state, 'animal.selectedOption.value'),
      owner_name: _.get(state, 'owner.selectedOption.value'),
      owner: _.get(state, 'owner.selectedOption.data.owner'),
      attending_veterinarian: state.attendingVeterinarian?.selectedOption?.value,
      birth_date: _.get(state, 'birthDate.value'),
      specie: _.get(state, 'specie.value'),
      race: _.get(state, 'race.selectedOption.value'),
      sex: _.get(state, 'sex.value'),
    },
    _.isUndefined
  );

const isOptionDataSelected = (option, selectedOptions) =>
  _.some(selectedOptions, { data: option.data });

const hasConflicts = (state) => _.some(state, 'conflictingAnimals');

const getConflictingFields = (state, conflictingAnimalData) => {
  const formattedStatePatientData = formatPatientInfoData(state);
  const isFileIDEqual = formattedStatePatientData.file_id === conflictingAnimalData.file_id;
  const isChipIDEqual = formattedStatePatientData.chip_id === conflictingAnimalData.chip_id;
  const isAnimalEqual =
    formattedStatePatientData.name === conflictingAnimalData.name &&
    formattedStatePatientData.owner_name === conflictingAnimalData.owner_name;

  const conflictingFields = [];
  if (isFileIDEqual) conflictingFields.push('fileID');
  if (isChipIDEqual) conflictingFields.push('chipID');
  if (isAnimalEqual) conflictingFields.push('owner', 'animal');
  return conflictingFields;
};

class PatientInfoForm extends React.PureComponent {
  constructor(props = {}) {
    super(props);

    this.getAnimalOptionsFromServer = this.getOptionsFromServer.bind(
      this,
      ApiCalls.getAnimalNameCompletion,
      'animals'
    );

    this.getOwnerOptionsFromServer = this.getOptionsFromServer.bind(
      this,
      ApiCalls.getOwnerNameCompletion,
      'owners'
    );

    this.state = this.createInitialState(this.props);
    this.previousState = this.state;
    this.refreshOptions();
  }

  componentDidMount = () => {
    const { onChange } = this.props;

    onChange({ patientInfo: formatPatientInfoData(this.state), hasConflict: false });
    this.attendingVeterinarians();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { onChange } = this.props;
    const { animalId } = this.state;
    const hasFieldChanged =
      _.some(
        STATE_FIELD_WITH_SELECTED_OPTIONS,
        (stateFieldKey) =>
          // eslint-disable-next-line react/destructuring-assignment
          this.state[stateFieldKey].selectedOption !== prevState[stateFieldKey].selectedOption
      ) ||
      _.some(
        STATE_FIELD_WITH_VALUE,
        // eslint-disable-next-line react/destructuring-assignment
        (stateFieldKey) => this.state[stateFieldKey] !== prevState[stateFieldKey]
      );
    const hasConflictNow = hasConflicts(this.state);
    const hasConflictPrevious = hasConflicts(prevState);

    const isAnimalIDUpdate = animalId !== prevState.animalId;
    if ((hasFieldChanged || isAnimalIDUpdate) && !hasConflictPrevious) {
      this.previousState = produce(prevState, (draftState) => {
        _.forEach(STATE_FIELD_WITH_SELECTED_OPTIONS, (fieldKey) => {
          if (draftState[fieldKey].inputValue !== undefined) {
            draftState[fieldKey].inputValue = '';
          }
        });
      });
    }
    if (hasFieldChanged || hasConflictNow !== hasConflictPrevious || isAnimalIDUpdate) {
      onChange({ patientInfo: formatPatientInfoData(this.state), hasConflict: hasConflictNow });
    }
  };

  createInitialState = ({ initialPatientData }) => ({
    fileID: {
      backendField: 'file_id',
      selectedOption: formatFieldFromData('file_id', initialPatientData),
      inputValue: '',
      options: [],
      onInputFocus: this.onInputFocus.bind(this, 'fileID'),
      onInputChange: this.onInputChange.bind(this, 'fileID'),
      loadOptions: (value) => this.loadOptions('fileID', { file_id: value }),
      getOptionsFromServer: _.debounce(this.getAnimalOptionsFromServer, 300),
      onSelect: this.onSelectAnimal.bind(this, 'fileID'),
      components: {
        DropdownIndicator: ToolsDropdownIndicator,
        IndicatorSeparator: null,
        Option: OptionWithPatientData('file_id'),
      },
      conflictingAnimals: undefined,
    },
    chipID: {
      backendField: 'chip_id',
      selectedOption: formatFieldFromData('chip_id', initialPatientData),
      inputValue: '',
      options: [],
      onInputFocus: this.onInputFocus.bind(this, 'chipID'),
      onInputChange: this.onInputChange.bind(this, 'chipID'),
      loadOptions: (value) => this.loadOptions('chipID', { chip_id: value }),
      getOptionsFromServer: _.debounce(this.getAnimalOptionsFromServer, 300),
      onSelect: this.onSelectAnimal.bind(this, 'chipID'),
      components: {
        DropdownIndicator: ToolsDropdownIndicator,
        IndicatorSeparator: null,
        Option: OptionWithPatientData('chip_id'),
      },
      conflictingAnimals: undefined,
    },
    owner: {
      backendField: 'name',
      selectedOption: formatFieldFromData('owner_name', initialPatientData),
      inputValue: '',
      options: [],
      onInputFocus: this.onInputFocus.bind(this, 'owner'),
      onInputChange: this.onInputChange.bind(this, 'owner'),
      loadOptions: (value) => this.loadOptions('owner', this.formatOwnerSearchData(value)),
      getOptionsFromServer: _.debounce(this.getOwnerOptionsFromServer, 300),
      onSelect: this.onSelectOwner,
      conflictingAnimals: undefined,
    },
    animal: {
      backendField: 'name',
      selectedOption: formatFieldFromData('name', initialPatientData),
      inputValue: '',
      options: [],
      onInputFocus: this.onInputFocus.bind(this, 'animal'),
      onInputChange: this.onInputChange.bind(this, 'animal'),
      loadOptions: (value) => this.loadOptions('animal', this.formatAnimalSearchData(value)),
      getOptionsFromServer: _.debounce(this.getAnimalOptionsFromServer, 300),
      onSelect: this.onSelectAnimal.bind(this, 'animal'),
      components: {
        DropdownIndicator: ToolsDropdownIndicator,
        IndicatorSeparator: null,
        Option: OptionWithPatientData('name'),
      },
      conflictingAnimals: undefined,
    },
    pedigreeID: {
      backendField: 'pedigree_id',
      selectedOption: formatFieldFromData('pedigree_id', initialPatientData),
      inputValue: '',
      options: [],
      onInputFocus: this.onInputFocus.bind(this, 'pedigreeID'),
      onInputChange: this.onInputChange.bind(this, 'pedigreeID'),
      loadOptions: (value) => this.loadOptions('pedigreeID', { pedigree_id: value }),
      getOptionsFromServer: _.debounce(this.getAnimalOptionsFromServer, 300),
      onSelect: this.onSelectAnimal.bind(this, 'pedigreeID'),
      components: {
        DropdownIndicator: ToolsDropdownIndicator,
        IndicatorSeparator: null,
        Option: OptionWithPatientData('pedigree_id'),
      },
    },
    attendingVeterinarian: {
      backendField: 'attending_veterinarian',
      selectedOption: formatFieldFromData('attending_veterinarian', initialPatientData),
      inputValue: undefined,
      options: [],
      onInputFocus: this.onInputFocus.bind(this, 'attendingVeterinarian'),
      onInputChange: this.onInputChange.bind(this, 'attendingVeterinarian'),
      onSelect: this.onSimpleSelect,
      components: {
        DropdownIndicator: ToolsDropdownIndicator,
        IndicatorSeparator: null,
        Option: OptionWithPatientData('attending_veterinarian'),
      },
    },
    birthDate: {
      value: initialPatientData?.birth_date,
    },
    specie: this.makeSpecieField(_.get(initialPatientData, 'specie')),
    race: this.makeRaceField(
      _.get(initialPatientData, 'specie'),
      _.get(initialPatientData, 'race')
    ),
    sex: this.makeSexField(_.get(initialPatientData, 'sex')),

    animalId: initialPatientData._id,
    hasConflict: false,
  });

  clearConflicts = () =>
    this.setState(
      produce((draftState) => {
        _.forEach(draftState, (stateField) => {
          // eslint-disable-next-line no-param-reassign
          if (stateField?.conflictingAnimals !== undefined) {
            // eslint-disable-next-line no-param-reassign
            stateField.conflictingAnimals = undefined;
          }
        });
      })
    );

  solveConflictsForCreate = (conflictingAnimals) => {
    this.setState(
      produce((draftState) => {
        const conflictingFields = getConflictingFields(draftState, conflictingAnimals[0]);
        conflictingFields.forEach((conflictingField) => {
          draftState[conflictingField].selectedOption = null;
        });
      })
    );
    this.clearConflicts();
  };

  solveConflictsForUpdate = (conflictingAnimals) => {
    this.setState({
      animalId: conflictingAnimals[0]._id,
    });
    this.clearConflicts();
  };

  refreshOptions = () =>
    _.forEach(
      _.pick(this.state, ['fileID', 'chipID', 'pedigreeID', 'animal', 'owner']),
      (fieldState) => fieldState.loadOptions(_.get(fieldState, 'selectedOption.value', ''))
    );

  onSimpleSelect = (value) => {
    /* eslint-disable camelcase */
    this.setState(
      produce((draftState) => {
        draftState.attendingVeterinarian.selectedOption = value;
        draftState.attendingVeterinarian.inputValue = value?.value ?? '';
      })
    );
    /* eslint-enable camelcase */
  };

  onSelectAnimal = (stateFieldKey, value, { action }) => {
    /* eslint-disable camelcase */
    const refreshOptionsIfNeeded = () => {
      const shouldRefreshOwnerOptions = stateFieldKey === 'animal';
      if (shouldRefreshOwnerOptions) this.refreshOwnerOptions();
    };

    if (action === 'clear') {
      this.setState(
        produce((draftState) => {
          draftState[stateFieldKey].inputValue = '';
          draftState[stateFieldKey].selectedOption = null;
          draftState.animalId = undefined;
        }),
        () => {
          refreshOptionsIfNeeded();
          this.checkConflicts(stateFieldKey);
        }
      );
      const { [stateFieldKey]: fieldState } = this.state;
      fieldState.loadOptions('');
    } else if (action === 'create-option') {
      this.setState(
        produce((draftState) => {
          draftState[stateFieldKey].selectedOption = value;
          draftState[stateFieldKey].inputValue = value.value;
        }),
        () => this.checkConflicts(stateFieldKey)
      );
    } else if (action === 'select-option') {
      const { _id, birth_date, sex, specie, race } = value.data;
      this.setState(
        produce((draftState) => {
          draftState[stateFieldKey].inputValue = value.value;
          draftState.fileID.selectedOption = formatFieldFromData('file_id', value.data);
          if (draftState.fileID.selectedOption) {
            draftState.fileID.options = [draftState.fileID.selectedOption];
          }

          draftState.chipID.selectedOption = formatFieldFromData('chip_id', value.data);
          if (draftState.chipID.selectedOption) {
            draftState.chipID.options = [draftState.chipID.selectedOption];
          }

          draftState.owner.selectedOption = formatFieldFromData('owner_name', value.data);
          if (draftState.owner.selectedOption) {
            draftState.owner.options = [draftState.owner.selectedOption];
          }

          draftState.animal.selectedOption = formatFieldFromData('name', value.data);
          if (draftState.animal.selectedOption) {
            draftState.animal.options = [draftState.animal.selectedOption];
          }
          draftState.pedigreeID.selectedOption = formatFieldFromData('pedigree_id', value.data);
          if (draftState.pedigreeID.selectedOption) {
            draftState.pedigreeID.options = [draftState.pedigreeID.selectedOption];
          }
          draftState.attendingVeterinarian.selectedOption = formatFieldFromData(
            'attending_veterinarian',
            value.data
          );

          draftState.birthDate.value = birth_date;
          draftState.specie = this.makeSpecieField(specie);
          draftState.race = this.makeRaceField(specie, race);
          draftState.sex = this.makeSexField(sex);

          draftState.animalId = _id;
        }),
        refreshOptionsIfNeeded
      );
      this.clearConflicts();
    }
    /* eslint-enable camelcase */
  };

  onSelectOwner = (value, { action }) => {
    const setOwnerState = (newValue) => {
      this.setState(
        produce((draftState) => {
          draftState.owner.inputValue = newValue.value;
          draftState.owner.selectedOption = newValue;
        }),
        () => {
          this.refreshAnimalOptions();
          this.checkConflicts('owner');
        }
      );
    };

    if (action === 'clear') {
      this.setState(
        produce((draftState) => {
          draftState.owner.selectedOption = null;
          draftState.owner.inputValue = '';
        }),
        () => {
          this.refreshOwnerOptions();
          this.refreshAnimalOptions();
          this.checkConflicts('owner');
        }
      );
    } else if (action === 'select-option') {
      // We have to reformat option to make data similar to data coming from
      // formatFieldFromData which is used when doing cross search with both animal_name
      // and owner_name.
      const ownerFormattedOption = {
        ...value,
        data: {
          // eslint-disable-next-line no-underscore-dangle
          owner: value.data._id,
          owner_name: value.data.name,
        },
      };
      setOwnerState(ownerFormattedOption);
    } else if (action === 'create-option') {
      setOwnerState(value);
    }
  };

  setInputValue = (key, inputValue) =>
    this.setState(
      produce((draftState) => {
        draftState[key].inputValue = inputValue;
      })
    );

  onInputFocus = (key) => {
    const { [key]: fieldState } = this.state;
    if (fieldState.selectedOption) {
      this.setInputValue(key, fieldState.selectedOption.value);
    }
  };

  onInputChange = (key, value, { action }) => {
    const { [key]: fieldState } = this.state;

    if (action === 'input-change') {
      fieldState.loadOptions?.(value);
      this.setState(
        produce((draftState) => {
          draftState[key].inputValue = value;
          if (value === '') {
            draftState[key].selectedOption = null;
          }
        })
      );
      if (key === 'owner') {
        this.refreshAnimalOptions();
      } else if (key === 'animal') {
        this.refreshOwnerOptions();
      }
    } else if (action === 'input-blur') {
      this.setState(
        produce((draftState) => {
          const { inputValue, selectedOption } = draftState[key];
          const noValueChange = selectedOption?.value === inputValue;
          draftState[key].inputValue = '';
          if (!inputValue || noValueChange) return;
          draftState[key].selectedOption = { value: inputValue, label: inputValue };
          draftState[key].inputValue = '';
          this.checkConflicts(key, draftState);
        })
      );
    }
  };

  formatAnimalSearchData = (partialAnimalName) => {
    const { owner } = this.state;
    return {
      owner_id: _.get(owner, 'selectedOption.data.owner'),
      owner_name: _.get(owner, 'selectedOption.value'),
      animal_name: partialAnimalName,
    };
  };

  formatOwnerSearchData = (partialOwnerName) => {
    const { animal } = this.state;
    return {
      owner_name: partialOwnerName,
      animal_name: _.get(animal, 'selectedOption.value'),
    };
  };

  refreshOwnerOptions = () => {
    const { owner } = this.state;
    owner.loadOptions(_.get(owner, 'selectedOption.value', ''));
  };

  refreshAnimalOptions = () => {
    const { animal } = this.state;
    animal.loadOptions(_.get(animal, 'selectedOption.value', ''));
  };

  checkConflicts = (stateFieldKey, initialCheckedState = undefined) => {
    if (['pedigreeID', 'attendingVeterinarian'].includes(stateFieldKey)) return;
    const checkedState = initialCheckedState ?? this.state;
    ApiCalls.checkPatientInfoConflicts(formatPatientInfoData(checkedState)).then(({ data }) => {
      if (data.conflicting_animals)
        this.setState(
          produce((draftState) => {
            draftState[stateFieldKey].conflictingAnimals = data.conflicting_animals;
          })
        );
    });
  };

  getOptionsFromServer = (apiCall, responseKey, stateFieldKey, data) =>
    apiCall(data)
      .then((response) => {
        const {
          [stateFieldKey]: { backendField },
        } = this.state;
        const options = response.data[responseKey].map((value) => ({
          label: value[backendField],
          value: value[backendField],
          data: value,
        }));
        this.setState(
          produce((draftState) => {
            draftState[stateFieldKey].options = options;
          })
        );
      })
      .catch((error) => {
        console.log('error', error);
      });

  loadOptions = (stateOptionsKey, data) => {
    const {
      [stateOptionsKey]: { getOptionsFromServer },
    } = this.state;

    getOptionsFromServer(stateOptionsKey, data);
  };

  attendingVeterinarians = () => {
    ApiCalls.getAttendingVeterinarians().then((response) => {
      const attendingVeterinarians = _.map(response.data.attending_veterinarians, (name) => ({
        value: name,
        label: name,
      }));
      this.setState(
        produce((draftState) => {
          draftState.attendingVeterinarian.options = attendingVeterinarians;
        })
      );
    });
  };

  makeRaceField = (specie, race) => {
    const { intl } = this.props;
    const raceField = { options: makeRacesOptions(intl, specie), selectedOption: null };
    if (race) {
      raceField.selectedOption = {
        label: intl.formatMessage({
          id: `patient_info.specie.${specie}.race.${race}`,
          defaultMessage: race,
        }),
        value: race,
      };
    }
    return raceField;
  };

  makeSpecieField = (specie) => {
    const { intl } = this.props;
    return specie
      ? {
          value: specie,
          label: intl.formatMessage({
            id: `patient_info.specie.${specie}`,
            defaultMessage: specie,
          }),
        }
      : null;
  };

  makeSexField = (sex) =>
    SEXES.includes(sex)
      ? {
          value: sex,
          label: <i className={`svg-icon ${sex}`} />,
        }
      : null;

  onSelectSpecie = (value, { action }) => {
    if (!ON_CHANGE_ACTIONS.includes(action)) return;
    const defaultRace = _.get(value, 'value') === 'cat' ? DEFAULT_CAT_RACE : undefined;
    this.setState({
      specie: value,
      race: this.makeRaceField(_.get(value, 'value'), defaultRace),
    });
  };

  undoLastModification = () => this.setState(this.previousState);

  render = () => {
    const { intl, className } = this.props;
    const {
      fileID,
      owner,
      animal,
      chipID,
      pedigreeID,
      birthDate,
      specie,
      race,
      sex,
      attendingVeterinarian,
    } = this.state;
    const isStateInConflicts = hasConflicts(this.state);

    return (
      <div className={`patient-info-form ${className}`}>
        <div>
          <div>
            <FormattedMessage id="patient_info.file_id" />:
          </div>
          <Creatable
            createOptionPosition="first"
            isValidNewOption={(inputValue) => inputValue}
            styles={textSelectStyle}
            value={fileID.selectedOption}
            inputValue={fileID.inputValue}
            placeholder={null}
            onFocus={fileID.onInputFocus}
            onInputChange={fileID.onInputChange}
            onChange={fileID.onSelect}
            options={fileID.options}
            components={fileID.components}
            isOptionSelected={isOptionDataSelected}
            isDisabled={isStateInConflicts}
            isClearable={!isStateInConflicts}
          />
          {fileID.conflictingAnimals ? (
            <PatientConflictWarning
              cancel={this.undoLastModification}
              conflictingPatients={fileID.conflictingAnimals}
              selectCreate={this.solveConflictsForCreate}
              selectUpdate={this.solveConflictsForUpdate}
            />
          ) : null}
        </div>
        <div>
          <div>
            <FormattedMessage id="patient_info.owner_name" />
            <RequiredAsterisk />:
          </div>
          <Creatable
            createOptionPosition="first"
            isValidNewOption={(inputValue) => inputValue}
            styles={textSelectStyle}
            components={{ DropdownIndicator: ToolsDropdownIndicator, IndicatorSeparator: null }}
            value={owner.selectedOption}
            inputValue={owner.inputValue}
            placeholder={null}
            onFocus={owner.onInputFocus}
            onInputChange={owner.onInputChange}
            onChange={owner.onSelect}
            options={owner.options}
            isDisabled={isStateInConflicts}
            isClearable={!isStateInConflicts}
          />
          {owner.conflictingAnimals ? (
            <PatientConflictWarning
              cancel={this.undoLastModification}
              conflictingPatients={owner.conflictingAnimals}
              selectCreate={this.solveConflictsForCreate}
              selectUpdate={this.solveConflictsForUpdate}
            />
          ) : null}
        </div>

        <div>
          <div>
            <FormattedMessage id="patient_info.animal_name" />
            <RequiredAsterisk />:
          </div>
          <Creatable
            createOptionPosition="first"
            isValidNewOption={(inputValue) => inputValue}
            styles={textSelectStyle}
            value={animal.selectedOption}
            inputValue={animal.inputValue}
            placeholder={null}
            onFocus={animal.onInputFocus}
            onInputChange={animal.onInputChange}
            onChange={animal.onSelect}
            options={animal.options}
            components={animal.components}
            isOptionSelected={isOptionDataSelected}
            isDisabled={isStateInConflicts}
            isClearable={!isStateInConflicts}
          />
          {animal.conflictingAnimals ? (
            <PatientConflictWarning
              cancel={this.undoLastModification}
              conflictingPatients={animal.conflictingAnimals}
              selectCreate={this.solveConflictsForCreate}
              selectUpdate={this.solveConflictsForUpdate}
            />
          ) : null}
        </div>

        <div>
          <div>
            <FormattedMessage id="patient_info.chip_id" />:
          </div>
          <Creatable
            createOptionPosition="first"
            isValidNewOption={(inputValue) => inputValue}
            styles={textSelectStyle}
            value={chipID.selectedOption}
            inputValue={chipID.inputValue}
            placeholder={null}
            onFocus={chipID.onInputFocus}
            onInputChange={chipID.onInputChange}
            onChange={chipID.onSelect}
            options={chipID.options}
            components={chipID.components}
            isOptionSelected={isOptionDataSelected}
            isDisabled={isStateInConflicts}
            isClearable={!isStateInConflicts}
          />
          {chipID.conflictingAnimals ? (
            <PatientConflictWarning
              cancel={this.undoLastModification}
              conflictingPatients={chipID.conflictingAnimals}
              selectCreate={this.solveConflictsForCreate}
              selectUpdate={this.solveConflictsForUpdate}
            />
          ) : null}
        </div>

        <div>
          <div>
            <FormattedMessage id="patient_info.specie" />:
          </div>
          <Select
            styles={textSelectStyle}
            value={specie}
            placeholder={null}
            onChange={this.onSelectSpecie}
            getOptionValue={({ label }) => label}
            components={{ DropdownIndicator: ToolsDropdownIndicator, IndicatorSeparator: null }}
            options={makeSpeciesOptions(intl)}
            isDisabled={isStateInConflicts}
          />
        </div>
        <div>
          <div>
            <FormattedMessage id="patient_info.birth_date" />:
          </div>
          <div className="date">
            <div className={`date-picker-wrapper ${isStateInConflicts ? 'disabled' : ''}`}>
              <ReactDatePicker
                dateFormat="P"
                locale={intl.locale}
                selected={birthDate.value}
                onChange={(value) => this.setState({ birthDate: { value } })}
                showYearDropdown
                scrollableYearDropdown
              />
            </div>
            <AgeFromDate birthDate={birthDate.value} />
          </div>
        </div>
        <div>
          <div>
            <FormattedMessage id="patient_info.pedigree_id" />:
          </div>
          <Creatable
            isValidNewOption={(inputValue) => inputValue}
            styles={textSelectStyle}
            value={pedigreeID.selectedOption}
            inputValue={pedigreeID.inputValue}
            placeholder={null}
            onFocus={pedigreeID.onInputFocus}
            onInputChange={pedigreeID.onInputChange}
            onChange={pedigreeID.onSelect}
            options={pedigreeID.options}
            components={pedigreeID.components}
            isOptionSelected={isOptionDataSelected}
            isDisabled={isStateInConflicts}
            isClearable={!isStateInConflicts}
          />
        </div>
        <div>
          <div style={!specie ? { color: 'hsl(0,0%,60%)' } : {}}>
            <FormattedMessage id="patient_info.race" />:
          </div>
          <Creatable
            createOptionPosition="first"
            isValidNewOption={(inputValue) =>
              inputValue &&
              !_.some(race.options, ({ value }) => inputValue.toLowerCase() === value.toLowerCase())
            }
            styles={textSelectStyle}
            value={race.selectedOption}
            placeholder={null}
            onChange={(value, { action }) =>
              ON_CHANGE_ACTIONS.includes(action) &&
              this.setState(
                produce((draftState) => {
                  draftState.race.selectedOption = value;
                })
              )
            }
            getOptionValue={({ label }) => label}
            components={{ DropdownIndicator: ToolsDropdownIndicator, IndicatorSeparator: null }}
            options={race.options}
            isDisabled={isStateInConflicts || !specie}
            isClearable={!isStateInConflicts}
          />
        </div>

        <div style={{ display: 'fex', flexDirection: 'row', gap: '2em' }}>
          <div
            style={{
              minWidth: '0',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px',
            }}
          >
            <div>
              <FormattedMessage id="patient_info.sex" />:
            </div>
            <Select
              styles={sexStyles}
              value={sex}
              placeholder={null}
              onChange={(value, { action }) =>
                ON_CHANGE_ACTIONS.includes(action) && this.setState({ sex: value })
              }
              getOptionValue={({ label }) => label}
              options={makeSexesOptions(intl)}
              components={{ DropdownIndicator: null, IndicatorSeparator: null, input: null }}
              isDisabled={isStateInConflicts}
            />
          </div>

          <div
            style={{
              minWidth: '0',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '5px',
              flexGrow: '1',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={!specie ? { color: 'hsl(0,0%,60%)' } : {}}>
              <FormattedMessage id="patient_info.attending_veterinarian" />:
            </div>
            <Creatable
              createOptionPosition="first"
              isValidNewOption={(inputValue) =>
                inputValue &&
                !_.some(
                  attendingVeterinarian.options,
                  ({ value }) => inputValue.toLowerCase() === value.toLowerCase()
                )
              }
              styles={textSelectStyle}
              value={attendingVeterinarian.selectedOption}
              inputValue={attendingVeterinarian.inputValue}
              placeholder={null}
              onFocus={attendingVeterinarian.onInputFocus}
              onInputChange={attendingVeterinarian.onInputChange}
              onChange={attendingVeterinarian.onSelect}
              options={attendingVeterinarian.options}
              components={{ DropdownIndicator: ToolsDropdownIndicator, IndicatorSeparator: null }}
              isDisabled={isStateInConflicts}
              isClearable={!isStateInConflicts}
            />
          </div>
        </div>
      </div>
    );
  };
}

PatientInfoForm.propTypes = {
  intl: pt.shape().isRequired,
  onChange: pt.func.isRequired,
  className: pt.string,
  initialPatientData: pt.shape({
    _id: pt.string,
    file_id: pt.string,
    chip_id: pt.string,
    pedigree_id: pt.string,
    name: pt.string,
    owner: pt.string,
    owner_name: pt.string,
    birth_date: pt.instanceOf(Date),
    specie: pt.oneOf(SPECIES),
    race: pt.string,
    sex: pt.oneOf(SEXES_WITH_OTHER),
    attending_veterinarian: pt.string,
  }),
};

PatientInfoForm.defaultProps = {
  initialPatientData: null,
  className: '',
};

export default injectIntl(PatientInfoForm, { forwardRef: true });
