import { import as cornerstoneToolsImport } from 'cornerstone-tools';
import * as _ from 'lodash';
import { noop } from 'lodash';
import { computeAge } from '../components/AgeFromDate';
import {
  dicomDateToDate,
  dicomDateToDateTimeString,
  dicomSexToSexKey,
  getDicomDataValue,
} from '../utils/dicom/DicomData';

const BaseAnnotationTool = cornerstoneToolsImport('base/BaseAnnotationTool');
const getNewContext = cornerstoneToolsImport('drawing/getNewContext');
const draw = cornerstoneToolsImport('drawing/draw');

export default class DicomDataPrinterTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'DicomDataPrinter',
      configuration: {
        lineHeight: 15,
        font: '14px Lato',
        fontColor: 'white',
        getIntl: () => {},
      },
      supportedInteractionTypes: [],
    };

    super(props, defaultProps);

    this.preventNewMeasurement = true;

    this.dicomTextArea = { topLeft: { y: 0 } };
  }

  /**
   * @brief Disable since we do not want click to create a new tool data.
   */
  // eslint-disable-next-line class-methods-use-this
  createNewMeasurement = () => {};

  // eslint-disable-next-line class-methods-use-this
  pointNearTool = () => false;

  /* eslint-disable no-param-reassign */
  // eslint-disable-next-line class-methods-use-this
  updateCachedStats = () => {};

  renderToolData = (evt) => {
    const eventData = evt.detail;
    const { dicomData } = evt.detail.image;
    const { getIntl } = this.configuration;
    const { formatMessage = noop, formatNumber = noop } = getIntl() ?? {};

    if (!dicomData) return;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    draw(context, (ctx) => {
      const PatientName = getDicomDataValue(dicomData, 'PatientName');
      const PatientSex = getDicomDataValue(dicomData, 'PatientSex');
      const PatientSexNeutered = getDicomDataValue(dicomData, 'PatientSexNeutered');
      const PatientBirthDate = getDicomDataValue(dicomData, 'PatientBirthDate');
      const AcquisitionDate = getDicomDataValue(dicomData, 'AcquisitionDate');
      const AcquisitionTime = getDicomDataValue(dicomData, 'AcquisitionTime');
      const PixelSpacing = getDicomDataValue(dicomData, 'PixelSpacing');
      const ResponsiblePerson = getDicomDataValue(dicomData, 'ResponsiblePerson');
      const ImagerPixelSpacing = getDicomDataValue(dicomData, 'ImagerPixelSpacing');
      const PatientSpeciesDescription = getDicomDataValue(dicomData, 'PatientSpeciesDescription');
      const ReferringPhysicianName = getDicomDataValue(dicomData, 'ReferringPhysicianName');
      const InstitutionAddress = getDicomDataValue(dicomData, 'InstitutionAddress');
      const InstitutionName = getDicomDataValue(dicomData, 'InstitutionName');
      const WindowWidth = getDicomDataValue(dicomData, 'WindowWidth');
      const WindowCenter = getDicomDataValue(dicomData, 'WindowCenter');
      const StudyDescription = getDicomDataValue(dicomData, 'StudyDescription');
      const SeriesDescription = getDicomDataValue(dicomData, 'SeriesDescription');
      const KVP = getDicomDataValue(dicomData, 'KVP');
      const XRayTubeCurrentInuA = getDicomDataValue(dicomData, 'XRayTubeCurrentInuA');
      const ExposureInuAs = getDicomDataValue(dicomData, 'ExposureInuAs');

      const { width, height } = eventData.canvasContext.canvas;
      const { lineHeight, fontColor, font } = this.configuration;
      ctx.fillStyle = fontColor;
      ctx.font = font;
      ctx.textAlign = 'start';
      ctx.textBaseline = 'top';
      let strokeX = 5;
      let strokeY = 5;
      let ownerName;
      // top left
      if (PatientName) {
        let patientName;
        [patientName, ownerName] = PatientName.split('^').reverse();
        ctx.fillText(patientName, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (ResponsiblePerson || ownerName) {
        ctx.fillText(ResponsiblePerson || ownerName, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (PatientSpeciesDescription) {
        ctx.fillText(PatientSpeciesDescription, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (PatientSex) {
        const sexKey = dicomSexToSexKey(PatientSex, PatientSexNeutered);
        // PatientSexNeutered
        if (sexKey) {
          const sexText = formatMessage({ id: `patient_info.sex.${sexKey}` });
          ctx.fillText(sexText, strokeX, strokeY);
          strokeY += lineHeight;
        }
      }
      if (PatientBirthDate) {
        const patientAge = computeAge(dicomDateToDate(PatientBirthDate));
        let patientAgeString = '';
        if (patientAge.years > 0) {
          patientAgeString += formatNumber(patientAge.years, {
            style: 'unit',
            unit: 'year',
            unitDisplay: 'narrow',
          });
        }
        if (patientAge.months > 0) {
          patientAgeString += `${patientAgeString && ' '}${formatNumber(patientAge.months, {
            style: 'unit',
            unit: 'month',
            unitDisplay: 'narrow',
          })}`;
        }
        if (patientAge.weeks > 0) {
          patientAgeString += `${patientAgeString && ' '}${formatNumber(patientAge.weeks, {
            style: 'unit',
            unit: 'week',
            unitDisplay: 'narrow',
          })}`;
        }
        ctx.fillText(patientAgeString, strokeX, strokeY);
        strokeY += lineHeight;
      }
      this.dicomTextArea.topLeft.y = strokeY;

      // bottom left
      ctx.textAlign = 'start';
      ctx.textBaseline = 'bottom';
      strokeX = 5;
      strokeY = height - 5;
      if (ReferringPhysicianName) {
        ctx.fillText(ReferringPhysicianName, strokeX, strokeY);
        strokeY -= lineHeight;
      }

      // top right
      strokeX = width - 5;
      strokeY = 5;
      ctx.textAlign = 'end';
      ctx.textBaseline = 'top';
      if (AcquisitionDate) {
        ctx.fillText(dicomDateToDateTimeString(AcquisitionDate, AcquisitionTime), strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (StudyDescription) {
        ctx.fillText(StudyDescription, strokeX, strokeY);
        strokeY += lineHeight;
      }
      if (SeriesDescription) {
        ctx.fillText(SeriesDescription, strokeX, strokeY);
        strokeY += lineHeight;
      }

      // bottom right
      ctx.textBaseline = 'bottom';
      ctx.textAlign = 'end';
      strokeX = width - 5;
      strokeY = height - 5;
      if (InstitutionName) {
        ctx.fillText(InstitutionName, strokeX, strokeY);
        strokeY -= lineHeight;
      }
      if (InstitutionAddress) {
        ctx.fillText(InstitutionAddress, strokeX, strokeY);
        strokeY -= lineHeight;
      }
      if (PixelSpacing ?? ImagerPixelSpacing) {
        const pixelSpacing = _.map(PixelSpacing ?? ImagerPixelSpacing, (value) =>
          parseFloat(value).toFixed(3)
        );
        ctx.fillText(formatMessage({ id: 'image_info.pixel_spacing' }), strokeX, strokeY);
        strokeY -= lineHeight;
        ctx.fillText(
          `${pixelSpacing} ${formatMessage({ id: 'image_info.pixel_spacing.unit' })}`,
          strokeX,
          strokeY
        );
        strokeY -= lineHeight;
      }
      if (KVP || XRayTubeCurrentInuA || ExposureInuAs) {
        const acquisitionConstantText = _.filter([
          formatMessage({ id: 'image_info.KVP.unit' }, { KVP }),
          formatMessage(
            { id: 'image_info.Exposure.unit' },
            { Exposure: +(ExposureInuAs / 1000).toFixed(2) }
          ),
          formatMessage(
            { id: 'image_info.XRayTubeCurrent.unit' },
            { XRayTubeCurrent: Math.floor(XRayTubeCurrentInuA / 1000) }
          ),
        ]).join(' ');
        ctx.fillText(acquisitionConstantText, strokeX, strokeY);
        strokeY -= lineHeight;
      }

      // if (WindowWidth && WindowCenter) {
      //   ctx.fillText(`WW/WC : ${WindowWidth}/${WindowCenter}`, strokeX, strokeY);
      //   strokeY -= lineHeight;
      // }
    });
  };
}
