import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import * as PropTypes from 'prop-types';

import {FormattedMessage} from 'react-intl';


export class MaintenancePage extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
  };

  render() {
    return (
      <div style={{marginTop: "10%", textAlign: "center"}}>
        <h1><FormattedMessage id="maintenance.title"/></h1>
        <span style={{fontSize: "2em"}}><FormattedMessage id="maintenance.body"/></span>
      </div>
    )
  }
}

MaintenancePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};


const mapStateToProps = createStructuredSelector({});


function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePage);
