import _ from 'lodash';
import { SyncStatusEnum } from 'app/components/SyncStatus';
import formatImageForUpload, {
  FormatImageForUploadOptions,
} from 'app/containers/Teleradiology/formatImageForUpload';
import { DicomData, DicomTransferSyntax } from 'app/interfaces/Dicom';
import { DisplayableImageData } from 'app/interfaces/Image';
import { Transformation } from 'app/interfaces/Image/Transformation';
import {
  ImageUploadPromise,
  ImageUploadPromisesByImageId,
  ImagesToUpload,
  TeleradiologyAPI,
} from 'app/interfaces/TeleradiologyAPI';
import { DropzoneImage } from 'app/interfaces/Study';

export type ImageForUpload = DropzoneImage & {
  status: SyncStatusEnum;
  image: DisplayableImageData;
  transformation: Transformation;
  dicomData?: DicomData;
};

type ResumedImageUploads = ImageUploadPromisesByImageId;

export async function uploadTeleradiologyImages(
  teleradiologyAPI: TeleradiologyAPI,
  formatter: FormatImageForUploadOptions,
  studyId: string,
  images: ImageForUpload[]
): Promise<ResumedImageUploads> {
  const imagesToUpload: ImagesToUpload = _.fromPairs(
    await Promise.all(
      // Avoid sending already ongoing images
      _.reject(images, (image) => image.status === SyncStatusEnum.ONGOING).map(async (image) => [
        image.backendId,
        {
          imageContent: await formatImageForUpload(
            formatter,
            image.image,
            image.transformation,
            image.dicomData,
            DicomTransferSyntax.JPEGLSLossless
          ),
        },
      ])
    )
  );

  try {
    const resumedUploads = teleradiologyAPI.addImagesToAnalyze(studyId, imagesToUpload);
    // Here we can type convert because we would get aan thrown error if the objet is not a Generic Error
    return resumedUploads as Promise<{ [imageId: string]: ImageUploadPromise }>;
  } catch (error) {
    throw error;
  }
}

export async function resumeTeleradiologyUpload(
  teleradiologyAPI: TeleradiologyAPI,
  formatter: FormatImageForUploadOptions,
  studyId: string,
  images: ImageForUpload[]
): Promise<ResumedImageUploads> {
  return uploadTeleradiologyImages(
    teleradiologyAPI,
    formatter,
    studyId,
    images.filter((image) => image.status in [SyncStatusEnum.NOT_SYNC, SyncStatusEnum.ERROR_SYNC])
  );
}
