// Loads an image id and given a url to an image
function loadImage(imageId, options) {
  // extract the attributes we need
  const {
    height,
    width,
    minPixelValue = 0,
    maxPixelValue = 65535,
    columnPixelSpacing,
    rowPixelSpacing,
    windowWidth,
    windowCenter,
  } = options;

  const getPixelData = () => options.data;

  // Extract the various attributes we need
  const image = {
    imageId,
    minPixelValue,
    maxPixelValue,
    slope: 1,
    intercept: 0,
    windowCenter: windowCenter ?? (maxPixelValue + minPixelValue) / 2,
    windowWidth: windowWidth ?? maxPixelValue - minPixelValue,
    getPixelData,
    rows: height,
    columns: width,
    height,
    width,
    color: false,
    columnPixelSpacing,
    rowPixelSpacing,
    invert: false,
    sizeInBytes: width * height * 2,
  };

  return {
    promise: Promise.resolve(image),
    cancelFn: () => {},
  };
}

function registerImageLoader(cornerstone) {
  cornerstone.registerImageLoader('rawUInt16', loadImage);
}

export { registerImageLoader, loadImage };
