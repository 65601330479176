import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { SET_PACS_CONFIGURATION, SET_PACS_ENABLED } from 'app/redux/PACSConfiguration/actions';
import observeStore from 'app/utils/redux/observeStore';
import { fromJS, Iterable } from 'immutable';
import _ from 'lodash';

export const PACS_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.PACSConfiguration';

const pacsConfigurationInitialState = fromJS(
  restoreFromLocalStorage(PACS_CONFIGURATION_LOCAL_STORAGE_KEY, {})
);

const PACSConfigurationReducer = (state = pacsConfigurationInitialState, action) => {
  switch (action.type) {
    case SET_PACS_CONFIGURATION:
      return state.clear().merge(action.pacsConfiguration);
    case SET_PACS_ENABLED:
      return state.set('enabled', action.enabled);
    default:
      return state;
  }
};

export const selectPACSConfiguration = (state) => state.get('PACSConfiguration');

export const isPACSConfigurationDisabled = (PACSConfiguration) => {
  const PACSConfigurationJS = Iterable.isIterable(PACSConfiguration)
    ? PACSConfiguration.toJS()
    : PACSConfiguration;

  return (
    PACSConfigurationJS === undefined ||
    _.isEmpty(PACSConfigurationJS) ||
    !PACSConfigurationJS.enabled
  );
};

export const attachPACSConfigurationWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectPACSConfiguration,
    makeSyncReduxWithLocalStorageObserver(PACS_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default PACSConfigurationReducer;
