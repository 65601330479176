/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { createSelector } from 'reselect';

import languageProviderReducer from 'app/containers/LanguageProvider/reducer';
import globalReducer from 'app/redux/global/reducer';
import pmsReducer from 'app/pms/reducer';
import viewerConfigurationReducer, {
  VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY,
} from 'app/redux/viewerConfiguration/reducer';
import { makeSyncReduxWithLocalStorageObserver } from 'app/dataManagement/syncReduxStateWithLocalStorage';
import observeStore from 'app/utils/redux/observeStore';
import xrayConfigurationReducer from 'app/redux/xrayGeneratorConfiguration/reducer';
import flatPanelConfigurationReducer from 'app/redux/flatPanelConfiguration/reducer';
import versionUpdateReducer from 'app/redux/versionUpdate/reducer';
import userInputMappingReducer from 'app/redux/userInputMapping/reducer';
import aiOnlyConfigurationReducer from 'app/redux/aiOnlyConfiguration/reducer';
import deviceParametersReducer from 'app/redux/deviceParameters/reducer';
import permissionsReducer from 'app/redux/permissions/reducer';
import PACSConfigurationReducer from 'app/redux/PACSConfiguration/reducer';
import teleradiologyReducer from 'app/redux/teleradiology/reducer';
import announcementsReducer from 'app/redux/announcements/reducer';
import currentStudyInfosReducer from 'app/redux/currentStudyInfos/reducer';
import { makeSelectWatchedDirectory } from 'app/pms/selector';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
});

export const makeSelectRoute = () =>
  createSelector(
    (state) => state.get('route'),
    (routeState) => routeState.toJS()
  );

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      });
    default:
      return state;
  }
}

export const selectPMSReducer = (state) => state.get('pms');
export const selectPMSWatchedDirectory = makeSelectWatchedDirectory();
export const selectPMSType = (state) => selectPMSReducer(state).get('type');
export const selectPMSExportConfiguration = (state) =>
  selectPMSReducer(state).get('exportConfiguration');

export const attachSyncPMSStateWithLocalStorageObserver = (store) => {
  observeStore(store, selectPMSReducer, makeSyncReduxWithLocalStorageObserver('redux.pms'));
};

export const selectViewerConfiguration = (state) => state.get('viewerConfiguration');

export const attachViewerConfigurationWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectViewerConfiguration,
    makeSyncReduxWithLocalStorageObserver(VIEWER_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};
/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    route: routeReducer,
    global: globalReducer,
    language: languageProviderReducer,
    pms: pmsReducer,
    userInputMapping: userInputMappingReducer,
    viewerConfiguration: viewerConfigurationReducer,
    xRayConfiguration: xrayConfigurationReducer,
    PACSConfiguration: PACSConfigurationReducer,
    flatPanelConfiguration: flatPanelConfigurationReducer,
    versionUpdate: versionUpdateReducer,
    aiOnlyConfiguration: aiOnlyConfigurationReducer,
    deviceParameters: deviceParametersReducer,
    permissions: permissionsReducer,
    teleradiology: teleradiologyReducer,
    announcements: announcementsReducer,
    currentStudyInfos: currentStudyInfosReducer,
    ...asyncReducers,
  });
}
