import { SET_AI_ONLY_ENABLED } from './constants';

/**
 *
 * @param {boolean} enabled
 * @returns {object}
 */
// eslint-disable-next-line import/prefer-default-export
export const setAiOnlyEnabled = (enabled) => ({
  type: SET_AI_ONLY_ENABLED,
  payload: { enabled },
});
