/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import 'semantic-ui-css/semantic.min.css';

import 'app/styles/style.scss';
import 'app/components/Dropzone/style.css';

import {
  Dimmer,
  Icon,
  Loader,
  Popup,
  Button,
  Menu,
  MenuItem,
  Placeholder,
  Divider,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import React from 'react';
import * as pt from 'prop-types';
import produce from 'immer';
import * as _ from 'lodash';
import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import { FormattedMessage, injectIntl } from 'react-intl';
import { browserHistory, withRouter } from 'react-router';
import queryString from 'query-string';

import dropzoneReducer from 'app/components/Dropzone/reducer';
import { ReactComponent as FlatPanelIcon } from 'static/svg_icons/flat_panel_detector_square.svg';

import { getBrowser } from 'app/utils/windowUtils';
import ApiCalls from 'app/utils/apiCalls';
import HelpMessage from 'app/components/HelpMessage';
import InferenceErrorMessage from 'app/components/InferenceErrorMessage';
import PatternStructuredList from 'app/components/PatternStructuredList';
import RadioImage from 'app/components/RadioImage';
import WhatImagesToSend from 'app/components/WhatImagesToSend';
import DysplasiaPatternStructuredList from 'app/components/DysplasiaPatternStructuredList';
import EditableReport from 'app/components/EditableReport';
import { getReportFromStudy, splitReport } from 'app/utils/reports/reportsGenerator';
import RadioImageData, {
  EVENTS as RadioImageDataEvents,
  produceImageId,
  RESTORATION_STATUS,
} from 'app/utils/RadioImageData';
import ToolsBar, { PRECOMPUTED_TOOLS } from 'app/components/ToolsBar';
import {
  createCommonToolsList,
  createImageToolsList,
  createThumbnailToolsList,
} from 'app/components/Dropzone/utils';
import PatientInfoBar from 'app/components/PatientInfoBar';
import PatientInfoModal from 'app/components/Dropzone/PatientInfoModal';
import { linkCornerstoneElements } from 'app/CornerstoneTools/linkElements';
import {
  VALID_PREDICTION_TYPES,
  ABDOMEN_PREDICTION_TYPES,
  THORAX_PREDICTION_TYPES,
} from 'app/utils/predictions/constants';
import cornerstoneElementToBlob, {
  VISIBLE_TOOLS,
  convertImageAndElementToBlob,
} from 'app/CornerstoneTools/cornerstoneElementToBlob';
import {
  THORAX_GROUPS,
  THORAX_PATTERNS,
  ABDOMEN_GROUPS,
  ABDOMEN_PATTERNS,
} from 'app/components/PatternStructuredList/patternsGroups';
import VHSReferencesDropdown from 'app/components/VHSReferencesDropdown';
import ExamIcon from 'app/components/ExamIcon';
import XRayAreaView from 'app/components/XRayAreaView';
import { loadStudy } from 'app/utils/studyUtils';
import isWorkListImage from 'app/utils/isWorkListImage';
import FlatPanelList from 'app/components/FlatPanelList';
import FoldableAIPanel from 'app/components/FoldableAIPanel';
import { FlatPanelStateContext, getCurrentDetector } from 'app/components/FlatPanelStateProvider';
import isXrayLibEnabled from 'app/utils/xray/isXrayLibEnabled';
import {
  getDefaultAnatomicRegion,
  getProcessingFromAnatomicRegion,
  isSameAnatomicThickness,
} from 'app/utils/xrayRegions';
import loadImage, { isRawImage } from 'app/utils/cornerstone/loadImage';
import { ReactComponent as Picoxia } from 'static/svg_icons/picoxia.svg';
import {
  convertIRayImageToDicomData,
  getPatientInfoFromDicomData,
  updateDicomDataPatient,
  updateDicomDataID,
  updateDicomDataStudyInfo,
  getDicomDataValue,
  getXRayDetectorID,
  dicomDateTimeToDate,
} from 'app/utils/dicom/DicomData';
import withContext from 'app/utils/withContext';
import { convertDicomParserDataToDicomData } from 'app/utils/dicom/convertDicomParserDataToDicomData';
import { isAnyDicomImage } from 'app/utils/DicomHelpers';
import {
  LocalDicomStoreContext,
  LocalDicomStoreContextShape,
} from 'app/providers/LocalDicomStoreProvider';
import EditAnatomicRegionModal from 'app/components/Dropzone/EditAnatomicRegionModal';
import { isAcquisitionStudy } from 'app/utils/isAcquisitionStudy';
import { memoizeDebounce } from 'app/utils/lodashMixins';
import { FRONT_END_PATH } from 'app/redux/global/constants';
import { isAcquisitionImage } from 'app/utils/isAcquisitionImage';
import { getMinMaxValues, getViewportToSave } from 'app/utils/cornerstone/imageUtils';
import { DEFAULT_SPECIE } from 'app/utils/speciesConstants';
import { DETECTOR_IMPLEMENTATION } from 'app/types/xray';
import FullSizeValidationButton from 'app/components/Dropzone/FullSizeValidationButton';
import {
  cancelFullSizeConfiguration,
  confirmFullSizeConfiguration,
  onFullSizeConfigurationToggle,
  rescaleDisplayedImageToConfiguredFullSize,
} from 'app/components/Dropzone/effects';
import FullSizeConfigurationButton from 'app/components/ToolsBar/FullSizeConfigurationButton';
import { selectViewerConfiguration } from 'app/redux/reducers';
import { selectAiOnlyConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import { toggleFullSizeConfigurationOngoing } from 'app/components/Dropzone/actions';
import CountDown from 'app/components/Dropzone/CountDown';
import XRayGeneratorStatus from 'app/components/XRayGeneratorStatus';
import { XRayGeneratorStateContext } from 'app/providers/XRayGeneratorProvider';
import { selectXRayConfiguration } from 'app/redux/xrayGeneratorConfiguration/reducer';
import { PMSExportContext } from 'app/providers/PMSIntegration/PMSExportProvider';
import ExportStudyModal from 'app/components/Dropzone/ExportStudyModal';
import { PACSCommunicationContext } from 'app/components/PACSCommunicationProvider';
import {
  isPACSConfigurationDisabled,
  selectPACSConfiguration,
} from 'app/redux/PACSConfiguration/reducer';
import ImageToPACSSyncStatus from 'app/components/ImageToPACSSyncStatus';
import UnsavedDataModal from 'app/components/Dropzone/UnsavedDataModal';
import { isDicomImage } from 'app/utils/imageFsUtil';
import isAcquisitionSoftware from 'app/utils/isAcquisitionSoftware';
import { selectDicomMapping } from 'app/redux/userInputMapping/reducer';
import ButtonStudySave from 'app/components/Dropzone/ButtonStudySave';
import TeleradiologyPanel from 'app/containers/Teleradiology/Panel';
import formatStudyForExportModal, {
  formatStudyImagesToSelectableImages,
} from 'app/components/Dropzone/formatStudyForExportModal';
import MeasurementTools from 'app/components/Dropzone/MeasurementTools';
import formatStudyImagesForTeleradiology from 'app/components/Dropzone/formatStudyImagesForTeleradiology';
import DicomBuilderContext from 'app/providers/DicomBuilderContext';
import AddXRayViewModal from 'app/components/Dropzone/AddXRayViewModal';
import PACSImagesSelector from 'app/components/Dropzone/PACSImagesSelector';
import ParallelTaskScheduler from 'app/utils/async/ParallelTaskScheduler';
import { setImageId, setStudyId } from 'app/redux/currentStudyInfos/actions';
import { selectCurrentImageId, selectCurrentStudyId } from 'app/redux/currentStudyInfos/reducer';

const AUTOMATIC_IMPORT_TIMEOUT_DELAY_MS = 10 * 60 * 1000;

const ACQUISITION_COUNTDOWN_DURATION_S = 5;

const DEFAULT_ANIMAL = { specie: DEFAULT_SPECIE };
Object.freeze(DEFAULT_ANIMAL);

const DEFAULT_ACQUISITION_CONSTANTS = {
  thickness: 0,
  kV: 0,
  mA: 0,
  s: 0,
};
Object.freeze(DEFAULT_ACQUISITION_CONSTANTS);

const UPPER_TOOLS_BAR_TOOLS_LISTS = [
  ['LeftMarker', 'RightMarker', 'TextMarker', 'ArrowAnnotate'],
  ['ClockwiseRotation', 'CounterClockwiseRotation'],
  ['VerticalFlip', 'HorizontalFlip'],
  ['Magnify', 'ZoomMouseWheel', 'FullSizeConfiguration', 'Crop'],
  ['NegativeColor', 'WWWC', 'Processing'],
  ['Eraser', 'Pan', 'FullScreen', 'PicoxiaAnalysis'],
];
Object.freeze(UPPER_TOOLS_BAR_TOOLS_LISTS);

const createStudy = ({
  images = [],
  comment = '',
  compteRendu = '',
  saveOngoing = false,
  isCommentDirty = false,
  clearOngoing = false,
  commentSaved = true,
  studyInstanceUID = undefined,
} = {}) => ({
  /** @type {[RadioImageData]} */
  images,
  comment,
  saveOngoing,
  isCommentDirty,
  clearOngoing,
  commentSaved,
  studyInstanceUID,
});

const formatStudyToPatient = (study) => ({ ...study.animal });

const formatPatientToStudyAnimal = (patientInfo) => ({
  animal: patientInfo,
  animalName: patientInfo.name,
  ownerName: patientInfo.owner_name,
});

const linkImageCornerstoneElements = (image) => {
  if (!image.cornerstoneRef || !image.thumbnailCornerstoneRef) return;
  linkCornerstoneElements(image.cornerstoneRef, image.thumbnailCornerstoneRef);
};

const getStatusAccordingToPrediction = (image) => {
  const noPredictions = _.isEmpty(image.predictions);
  if (image.isInferenceOngoing && noPredictions) return 'ongoing';
  if (!noPredictions) return 'sync';
  return 'not_sync';
};

class Dropzone extends React.Component {
  constructor(props) {
    super(props);
    const queryStringParsed = queryString.parse(window.location.search);
    if ('hideHelpers' in queryStringParsed && queryStringParsed.hideHelpers === '1') {
      this.hideHelpers = true;
    }
    this.state = {
      currentStudy: createStudy(),
      isAcquisitionMode: false,
      acquisitionCountDown: undefined,
      commonToolsList: createCommonToolsList(() => this.props.intl),
      errorMessage: null,
      selectedImageIndex: 0,
      lastStudyUpdate: Date.now(),
      snipper: null,
      fileWatcher: null,
      showExportSetupHelp: false,
      showAnnotations: true,
      mode: 'normal',
      isLinkAnimalToStudyNeeded: false,
      toolButtonsState: {
        FullScreen: {
          switchFullScreen: this.switchFullScreen,
          isFullScreen: false,
        },
      },
      isFullScreenConfigurationOngoing: false,
      retrySaveData: undefined,
      leavePageOnError: undefined,
      unsavedPageLeaveAllowed: false,
    };
    this.lastId = 0;
    this.saveImageAnnotationsDebounced = memoizeDebounce(this.saveImageAnnotations, 2000, {
      resolver: (image) => image.id,
    });
    this.editAcquisitionConstantsDebounced = memoizeDebounce(
      ApiCalls.editAcquisitionConstants,
      1000,
      { resolver: (imageId) => imageId }
    );
    this.saveCommentDebounced = _.debounce(this.saveComment, 2000);
    this.processingScheduler = new ParallelTaskScheduler(3);
  }

  componentDidMount() {
    const { isLoggedIn, router } = this.props;

    router.setRouteLeaveHook(this.getOnlinePath(), this.onPageLeave);

    if (process.env.PLATFORM !== 'website') {
      this.commandLineListener(
        null,
        queryString.parse(window.location.search, { arrayFormat: 'bracket' })
      );
      const { ipcRenderer } = window.nodeModules?.electron;
      ipcRenderer.on('newCommandLineArguments', this.commandLineListener);
    }
    if (this.redirectToLoginIfQuerySringRequests()) return;
    if (this.loadDemoIfQueryStringRequested()) return;
    this.markAcquisitionMode();
    if (this.loadStudyIfQueryStringRequested()) return;
    if (this.loadAcquisitionIfQueryStringRequested()) return;
    if (isLoggedIn) this.loadPreloadImages();
  }

  componentWillUnmount() {
    if (process.env.PLATFORM !== 'website') {
      const { ipcRenderer } = window.nodeModules?.electron;
      ipcRenderer.removeListener('newCommandLineArguments', this.commandLineListener);
    }

    const { dispatch } = this.props;
    dispatch(setStudyId(undefined));
    dispatch(setImageId(undefined));
  }

  componentDidUpdate = (_prevProps, prevState) => {
    const { isLinkAnimalToStudyNeeded, isFullScreenConfigurationOngoing, currentStudy } =
      this.state;
    if (isLinkAnimalToStudyNeeded) {
      this.linkStudyToPatient();
    }
    if (prevState.isFullScreenConfigurationOngoing !== isFullScreenConfigurationOngoing) {
      this.onFullSizeConfigurationToggle();
    }

    if (!this.isStudySaved()) {
      window.addEventListener('beforeunload', this.onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }

    const { dispatch, selectedCurrentStudyId, selectedCurrentImageId } = this.props;
    if (selectedCurrentStudyId === undefined || currentStudy.ID !== prevState.currentStudy.ID) {
      dispatch(setStudyId(currentStudy.ID));
    }

    const currentImageId = this.getDisplayedImageByState(this.state)?.backendId;
    const prevStateCurrentImageId = this.getDisplayedImageByState(prevState)?.backendId;
    if (selectedCurrentImageId === undefined || currentImageId !== prevStateCurrentImageId) {
      dispatch(setImageId(currentImageId));
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  dispatch = (action) => this.setState((state) => dropzoneReducer(state, action));

  getOnlinePath = () => {
    const { routes } = this.props;
    return routes[0].childRoutes.find((route) => route.path === 'online');
  };

  onFullSizeConfigurationToggle = () => {
    const { isFullScreenConfigurationOngoing, commonToolsList } = this.state;
    const currentImage = this.getDisplayedImage();
    if (!currentImage) return;

    onFullSizeConfigurationToggle(
      isFullScreenConfigurationOngoing,
      { ...commonToolsList, ...currentImage.toolsList },
      this.produceImageToolsListHOF(currentImage),
      this.produceCommonToolsList
    );
  };

  checkUnsavedData = () => {
    const { intl } = this.props;
    if (this.isStudySaved()) return undefined;
    return intl.formatMessage({ id: 'dropzone.get_out_without_save' });
  };

  forcePageLeave = (nextLocation) =>
    this.setState({ unsavedPageLeaveAllowed: true }, () => {
      browserHistory.push(nextLocation);
    });

  onPageLeave = (nextLocation) => {
    const { unsavedPageLeaveAllowed } = this.state;
    if (unsavedPageLeaveAllowed) return undefined;
    if (this.isStudySaved()) return undefined;

    this.clearStudy(nextLocation);

    return false;
  };

  onBeforeUnload = (evt) => {
    const unsavedMessage = this.checkUnsavedData();
    if (!unsavedMessage) {
      if (evt) delete evt.returnValue;
      return undefined;
    }

    if (process.env.PLATFORM === 'electron') {
      const dataLossOK = window.confirm(unsavedMessage);
      if (dataLossOK) {
        if (evt) delete evt.returnValue;
        return undefined;
      }
    }
    if (evt) {
      evt.preventDefault();
      // eslint-disable-next-line no-param-reassign
      evt.returnValue = unsavedMessage;
    }
    return unsavedMessage;
  };

  addImportImagesInputRef = (ref) => {
    this.importImagesInput = ref;
  };

  loadPreloadImages = () => {
    ApiCalls.getPreloadImages().then((response) => {
      if ('images' in response.data && response.data.images.length > 0) {
        const imageMetadata = {
          ownerName: response.data.owner_name,
          animalName: response.data.animal_name,
        };
        this.addImagesToStudy(response.data.images, response.data.origin, imageMetadata);
      }
    });
  };

  produceCommonToolsList = (fn) => {
    this.setState((s) => ({ commonToolsList: produce(s.commonToolsList, fn) }));
  };

  // Higher Order Function that return the `produceImageToolsList` function for a specific image.
  produceImageToolsListHOF = (image) => (fn) => {
    image.toolsList = produce(image.toolsList, fn);
    // Mark images as updated to trigger rerender.
    this.forceUpdate();
  };

  switchFullScreen = () => {
    const { hideHeader } = this.props;
    this.setState(
      produce((draftState) => {
        draftState.toolButtonsState.FullScreen.isFullScreen =
          !draftState.toolButtonsState.FullScreen.isFullScreen;
      }),
      // eslint-disable-next-line react/destructuring-assignment
      () => hideHeader(this.state.toolButtonsState.FullScreen.isFullScreen)
    );
  };

  switchToolStateHOF = (image, toolName) => () =>
    this.produceImageToolsListHOF(image)((draftToolsList) => {
      if (!draftToolsList[toolName]) return;
      if (['passive', 'active', 'enabled'].includes(draftToolsList[toolName].state)) {
        draftToolsList[toolName].state = 'disabled';
      } else {
        draftToolsList[toolName].state = 'passive';
      }
    });

  redirectToLoginIfQuerySringRequests = () => {
    const { isLoggedIn } = this.props;
    if (!isLoggedIn) {
      const queryStringParsed = queryString.parse(window.location.search);
      if (queryStringParsed.token) {
        browserHistory.push(`/login?token=${queryStringParsed.token}`);
        return true;
      }
    }
    return false;
  };

  markAcquisitionMode = () => {
    const queryStringParsed = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    if (queryStringParsed?.mode === 'acquisition') {
      this.setState({ isAcquisitionMode: true });
    }
  };

  loadStudyIfQueryStringRequested = () => {
    const queryStringParsed = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    if (queryStringParsed.study_ids !== undefined) {
      this.loadStudy(queryStringParsed.study_ids);
      return true;
    }
    return false;
  };

  loadAcquisitionIfQueryStringRequested = () => {
    const queryStringParsed = queryString.parse(window.location.search, { arrayFormat: 'bracket' });
    if (queryStringParsed?.mode === 'acquisition') {
      this.setState({ currentStudy: createStudy() });
      this.addNewAcquisitionImage();
      window.history.replaceState(null, null, window.location.pathname);
      return true;
    }
    return false;
  };

  onProcessingApplied = (image, isInit, isProcessingAlreadyCurrent) => {
    this.forceUpdate();
    if (!isInit && !isProcessingAlreadyCurrent) {
      this.saveImageAnnotations(image);
      this.setImage(image).catch((e) => console.warn(e));
      if (image.backendId) {
        ApiCalls.updateImageMetadata(image.backendId, { PACS: { isSync: false } });
      }
    }
  };

  loadStudy = (studyIDs) =>
    loadStudy(
      studyIDs,
      this.props.intl,
      createImageToolsList(() => this.props.intl),
      this.addListenerToImage,
      this.props.localDicomStoreContext,
      this.onProcessingApplied,
      this.refreshInference,
      () => this.forceUpdate(),
      this.processingScheduler
    ).then(
      (study) => {
        this.setState(
          { currentStudy: { ...createStudy(), ...study } },
          () => study.isCommentDirty || this.updateReport()
        );
      },
      (error) => console.warn(error)
    );

  loadDemoIfQueryStringRequested = () => {
    const queryStringParsed = queryString.parse(window.location.search);
    if (['demoClubVet', 'demo'].indexOf(queryStringParsed.mode) > -1) {
      this.loadDemo(queryStringParsed.mode);
      return true;
    }
    return false;
  };

  loadDemo = (mode) => {
    const { isLoggedIn } = this.props;
    if (!isLoggedIn && mode !== 'demoClubVet') {
      return;
    }
    /* eslint-disable global-require */
    const demoImages = [
      require('app/images/demo/1.png'),
      //       require('app/images/demo/2.png'),
      require('app/images/demo/3.png'),
      require('app/images/demo/4.png'),
      //       require('app/images/demo/5.jpg'),
      require('app/images/demo/abdo_face.jpg'),
      require('app/images/demo/abdo_profil.jpg'),
    ];
    /* eslint-enable global-require */
    const base64LoadPromises = demoImages.map(
      (imagePath) =>
        new Promise((resolve) => {
          const image = new Image();
          const canvas = document.createElement('canvas');
          image.onload = () => resolve(this.localToBase64(canvas, image));
          image.src = imagePath;
        })
    );
    Promise.all(base64LoadPromises).then((base64Images) => {
      this.addImagesToStudy(base64Images, mode, null, mode);
    });
  };

  localToBase64 = (canvas, image) => {
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
  };

  commandLineListener = (event, args) => {
    // eslint-disable-next-line global-require
    const loadImagesFromCommandLine =
      require('app/components/Dropzone/loadFromCommandLine').default;
    if (!args) {
      return;
    }
    const { registerImageArgsPromises, imageMetadata } = loadImagesFromCommandLine(args);
    if (!registerImageArgsPromises) return;
    Promise.all(registerImageArgsPromises).then((images) => {
      this.clearStudy();
      this.addImagesToStudy(images, 'commandLine', imageMetadata);
    });
  };

  onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // this.processImageList(event.dataTransfer.items.map((item) => {return item.getAsFile()}), "dragAndDrop")
    // this.processImageList([event.dataTransfer.items[0].getAsFile()], "dragAndDrop")
    const imageFiles = [];
    for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
      imageFiles.push(event.dataTransfer.items[i].getAsFile());
    }
    this.addImagesToStudy(imageFiles, 'fileDrop');
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onFileBrowserImageSelection = () => {
    const filesList = [...this.importImagesInput.files];
    this.addImagesToStudy(filesList, 'fileBrowser');
  };

  addImagesToStudy = (fileList, origin, imageMetadata, mode = 'normal') => {
    const newImages = this.processImageList(fileList, origin, imageMetadata);
    if (newImages.length === 0) return;
    this.setState((state) => {
      const { currentStudy } = state;
      const { images } = currentStudy;
      const newStudy = { ...currentStudy, images: [...images, ...newImages] };
      if (imageMetadata) {
        newStudy.animalName = imageMetadata.animalName;
        newStudy.ownerName = imageMetadata.ownerName;
      }

      return {
        currentStudy: newStudy,
        selectedImageIndex: images.length,
        lastStudyUpdate: Date.now(),
        mode,
      };
    });
  };

  processImageList = (imageList, origin) =>
    imageList.map((imageFile) => this.createImage(imageFile, origin));

  onRaceSelected = (raceKey) => {
    const { currentStudy } = this.state;
    currentStudy.images.forEach((image) => {
      image.selectedRaceKey = raceKey;
    });
    this.forceUpdate();
  };

  addListenerToImage = (image) => {
    image.thumbnailToolsList = createThumbnailToolsList();
    image.addEventListener(RadioImageDataEvents.MEASUREMENT_UPDATED, ({ detail }) => {
      if (!detail.isInitialAnnotation) {
        image.saved = false;
        this.saveImageAnnotationsDebounced(image);
        this.setState({
          lastStudyUpdate: Date.now(),
        });
      }
      this.updateReport();
    });
  };

  createImage = (imageFile, origin, initialAnnotations, initialViewport, initialImageMetadata) => {
    const { intl } = this.props;
    const image = new RadioImageData(
      imageFile,
      origin,
      createImageToolsList(() => this.props.intl),
      initialAnnotations,
      initialViewport,
      initialImageMetadata,
      intl,
      this.onProcessingApplied,
      this.refreshInference,
      () => this.forceUpdate(),
      this.processingScheduler
    );
    this.addListenerToImage(image);
    return image;
  };

  updateReport = (forceUpdate = false) => {
    this.setState((state) => {
      const { intl } = this.props;
      const { currentStudy } = state;
      if (currentStudy.isCommentDirty && !forceUpdate) return null;
      const report = getReportFromStudy(currentStudy, intl);
      const { compteRendu } = splitReport(report, intl);

      return {
        currentStudy: {
          ...currentStudy,
          comment: report,
          compteRendu,
          ...(forceUpdate ? { isCommentDirty: false } : undefined),
        },
      };
    });
  };

  isAutomaticImportImage = (image) => image.origin === 'automaticExport';

  isDicomImageFromAnotherStudy = (image) => {
    const { currentStudy } = this.state;
    const studyInstanceUID = getDicomDataValue(image.dicomData, 'StudyInstanceUID');

    return (
      !isAcquisitionStudy(currentStudy) &&
      studyInstanceUID &&
      studyInstanceUID !== currentStudy.studyInstanceUID
    );
  };

  onImageLoaded = (dataURL, image) => {
    const { intl, flatPanelStateContext, dicomMapping, aiOnlyConfiguration } = this.props;
    const isAIOnly = aiOnlyConfiguration.get('enabled');
    const { currentStudy } = this.state;

    const cscImage = csc.getImage(image.cornerstoneRef);

    if (!image.dicomData) {
      if (isRawImage(image.imageFile)) {
        image.dicomData = convertIRayImageToDicomData(
          flatPanelStateContext,
          currentStudy,
          image,
          intl
        );
      } else if (isAnyDicomImage(image.imageFile)) {
        image.dicomData = convertDicomParserDataToDicomData(cscImage.data);
        if (!image.rawImageDicomData) {
          image.rawImageDicomData = image.dicomData;
        }
      }
    }

    const shouldCreateNewStudyOnDicomDiff =
      this.isDicomImageFromAnotherStudy(image) && (this.isAutomaticImportImage(image) || isAIOnly);

    if (shouldCreateNewStudyOnDicomDiff) {
      const studyInstanceUID = getDicomDataValue(image.dicomData, 'StudyInstanceUID');
      this.clearStudyForAutomaticExport(studyInstanceUID);
    }

    if (image.dicomData) {
      // Import patient information
      const studyHasNoAnimal = currentStudy.animal === undefined;

      if (studyHasNoAnimal || isAIOnly) {
        const patientInfo = getPatientInfoFromDicomData(image.dicomData, dicomMapping?.species);
        const hasPatientName = patientInfo?.name;
        if (hasPatientName || isAIOnly) {
          this.linkPatientFromDicom(patientInfo, { force: isAIOnly });
        }
      }

      // Retrieve Image characteristics
      cscImage.dicomData = image.dicomData;
      const pixelSpacing =
        getDicomDataValue(image.dicomData, 'PixelSpacing') ??
        getDicomDataValue(image.dicomData, 'ImagerPixelSpacing');
      cscImage.rowPixelSpacing = parseFloat(pixelSpacing?.[0]);
      cscImage.colPixelSpacing = parseFloat(pixelSpacing?.[1]);
      cscImage.columnPixelSpacing = parseFloat(pixelSpacing?.[1]);
      csc.updateImage(image.cornerstoneRef);
    }
    if (image.origin === 'automaticExport' && (cscImage.height < 200 || cscImage.width < 200)) {
      _.remove(currentStudy.images, image);
      this.forceUpdate();
      return;
    }

    if (image.loadInitialMetadata()) {
      this.forceUpdate();
    }
    image.isLoaded = true;
    if (!_.isEmpty(image.predictions)) {
      if (!currentStudy.comment) {
        this.updateReport();
      } else {
        this.forceUpdate();
      }
      return;
    }
    this.getPredictions(dataURL, image).then(() => {
      image.base64 = dataURL;
      this.updateReport();
    });
  };

  getPredictions = (dataUrl, image) => {
    if (Object.keys(image.predictions).length === 0) {
      return this.inferOnImage(dataUrl, image);
    }
    return Promise.resolve(image.predictions);
  };

  setImage = async (image) => {
    if (!image.backendId) {
      return Promise.reject(new Error('missing backendId for image'));
    }
    const { cornerstoneImage } = await loadImage({ imageFile: image.processedImage });
    const { blob } = await convertImageAndElementToBlob(image.cornerstoneRef, cornerstoneImage, {
      syncWWWC: true,
      toolsFiltered: [],
    });
    return ApiCalls.putImage(image.backendId, blob);
  };

  refreshInference = async (image) => {
    const cropHandles = cst.getToolState(image.cornerstoneRef, 'Crop')?.data?.[0]?.handles;

    const imageBlob = await cornerstoneElementToBlob(image.cornerstoneRef, {
      syncWWWC: true,
      toolsFiltered: ['Crop'],
    });

    // We delete old automatic annotations than were not modified by user.
    // This is done by directly deleting toolState?.data to differentiate
    // a deleted tool (data is empty) and a tool to refresh (data does not exist).
    PRECOMPUTED_TOOLS.forEach((toolName) => {
      const toolState = cst.getToolState(image.cornerstoneRef, toolName);
      if (toolState?.data?.[0] && !toolState?.data?.[0]?.userModified) {
        toolState.data[0].mustRefresh = true;
      }
    });

    image.predictions = {};
    this.forceUpdate();

    let cropRect;
    if (cropHandles) {
      const { topLeft, end } = cropHandles;
      cropRect = [topLeft.x, topLeft.y, end.x, end.y];
    }

    const response = await ApiCalls.refreshInference(
      image.backendId,
      imageBlob.blob,
      csc.getImage(image.cornerstoneRef),
      cropRect
    );

    image.predictions = response.data.predictions;
    this.forceUpdate();
  };

  inferOnImage = (dataURL, image) => {
    const data = new FormData();
    data.append('filename', image.imageFile.name);
    data.append('image', dataURL);
    data.append('origin', image.origin);
    const { currentStudy: study } = this.state;
    if (study.ID) data.append('study_id', study.ID);
    if (image.backendId) data.append('image_id', image.backendId);
    if (image.acquisitionConstants) {
      data.append('acquisitionConstants', JSON.stringify(image.acquisitionConstants));
    }
    if (image.anatomicRegion) {
      data.append('anatomicRegion', image.anatomicRegion);
    }
    image.isInferenceOngoing = true;
    this.forceUpdate();

    const alreadyHasBackendId = image.backendId;

    return ApiCalls.infer(data)
      .then(
        (response) => {
          const createdStudyId = response.data.study_id;
          image.predictions = response.data.predictions;
          image.backendId = response.data.image_id;

          const { currentStudy } = this.state;
          if (!currentStudy.ID) {
            currentStudy.ID = createdStudyId;
          } else if (currentStudy.ID !== createdStudyId) {
            ApiCalls.changeStudy(currentStudy.ID, createdStudyId, [image.backendId]);
          }
          if (image.dicomData) {
            this.updateAllDicomID(image.dicomData, currentStudy, image);
          }
          // For a x-ray image create using the `x-ray` button we do not have an ID at the time of
          // acquisition this require annotations to be saved here.
          if (image.processedImage && !alreadyHasBackendId) {
            this.saveImageAnnotations(image);
            this.setImage(image);
          }
          this.setState({
            // Here we change study reference to notify the sub-components that the study has changed.
            // For example ComplementaryInfoForm require this to refresh comment.
            currentStudy: { ...currentStudy },
            errorMessage: null,
            showExportSetupHelp: false,
          });
        },
        (error) => {
          this.setState({ errorMessage: error });
        }
      )
      .finally(() => {
        image.isInferenceOngoing = false;
        this.forceUpdate();
      });
  };

  openFileBrowser = () => {
    this.importImagesInput.value = null;
    this.importImagesInput.click();
  };

  getMainImageCanvasMaxWidth = () => document.getElementById('radioPicture').clientWidth - 40;

  hiddenIfImage = () =>
    this.getDisplayedImage() &&
    (this.getDisplayedImage().imageFile || isWorkListImage(this.getDisplayedImage()))
      ? ' hidden'
      : '';

  dragAndDropText = () => {
    if (getBrowser().localeCompare('IE') === 0 || getBrowser().localeCompare('Edge') === 0) {
      return (
        <b>
          <FormattedMessage id="dropzone.dragAndDropText.ie" />
        </b>
      );
    }
    return (
      <span>
        <FormattedMessage id="dropzone.dragAndDropText.notIE" />
      </span>
    );
  };

  getDisplayedImageByState = (state) => {
    const { currentStudy, selectedImageIndex } = state;
    const anyDisplayableImage =
      _.some(currentStudy.images, { isLoaded: true }) ||
      _.some(currentStudy.images, isWorkListImage);
    if (!anyDisplayableImage) return null;
    return currentStudy.images[selectedImageIndex];
  };

  getDisplayedImage = () => this.getDisplayedImageByState(this.state);

  renderLeftPane = () => {
    const { currentStudy, isAcquisitionMode } = this.state;
    const isAcquisition = isAcquisitionStudy(currentStudy);
    const isInterpretationButtonsDisabled =
      isAcquisitionSoftware() && (isAcquisitionMode || isAcquisition);
    const addImageButton = isInterpretationButtonsDisabled || (
      <Popup
        position="right center"
        trigger={
          <button
            type="button"
            className="ui button picoxia add-image"
            onClick={this.openFileBrowser}
          >
            <Icon name="image outline" size="large" />
            <span>
              <FormattedMessage id="dropzone.add_file" />
            </span>
          </button>
        }
        content={<FormattedMessage id="dropzone.add_file.tooltip" />}
      />
    );
    const screenShotButton = isInterpretationButtonsDisabled || this.getSnipper();
    const automaticImportButton = isInterpretationButtonsDisabled || this.getFileWatcher();
    return (
      <div>
        {this.renderImagesAsThumbnails()}
        <div className="add-image-tools">
          <Button.Group vertical>
            <>
              {this.renderNewAcquisitionButton()}
              {addImageButton}
              {screenShotButton}
              {automaticImportButton}
            </>
          </Button.Group>
        </div>
        <div className="left-pane-divider" />
        {this.renderSavePanel()}
      </div>
    );
  };

  renderImagesAsThumbnails = () => (
    <div className="thumbnails">
      <div>{this.imagesThumbnails()}</div>
    </div>
  );

  isStudySaved = () => {
    const { currentStudy } = this.state;
    const imagesSaved = _(currentStudy.images)
      .filter((image) => image instanceof RadioImageData)
      .every('saved');
    return imagesSaved && currentStudy.commentSaved;
  };

  isPACSSyncOngoing = () => {
    const {
      currentStudy: { images },
    } = this.state;
    return _.some(images, (image) => image?.PACS?.isSyncOngoing);
  };

  saveImageAnnotations = (image) => {
    if (!image.backendId) return undefined;
    const annotations = _.mapValues(image.annotations, (toolMap) => Object.fromEntries(toolMap));

    const imageMetadata = {
      ...getMinMaxValues(image.cornerstoneRef),
      processingType: image.processedImage?.processingType,
      manufacturer: image.detectorKind,
      needLogRescale: image.needLogRescale,
    };
    if (image?.PACS?.isSync) {
      imageMetadata.PACS = { isSync: image?.PACS?.isSync };
    }

    return ApiCalls.saveImageAnnotations(
      image.backendId,
      annotations,
      getViewportToSave(image.cornerstoneRef) ?? {},
      imageMetadata
    ).then(() => {
      image.saved = true;
      this.forceUpdate();
    });
  };

  saveComment = () => {
    const { currentStudy } = this.state;
    return ApiCalls.sendComplementaryInfo({
      study_id: currentStudy.ID,
      comment: currentStudy.comment,
      isCommentDirty: currentStudy.isCommentDirty,
    }).then(() =>
      this.setState((state) => ({ currentStudy: { ...state.currentStudy, commentSaved: true } }))
    );
  };

  saveStudy = () => {
    const { exportDataToPMS } = this.props;
    const { currentStudy } = this.state;

    const studySavePromises = _(currentStudy.images)
      .reject(isWorkListImage)
      .map(this.saveImageAnnotations);
    studySavePromises.push(this.saveComment);

    if (exportDataToPMS) {
      const imagesForPMS =
        currentStudy.images
          ?.filter(isAcquisitionImage)
          .map(({ cornerstoneRef, annotations, dicomData, toolsList }) => {
            const imageData = csc.getImage(cornerstoneRef);
            const acquisitionDate = dicomDateTimeToDate(
              getDicomDataValue(dicomData, 'AcquisitionDate'),
              getDicomDataValue(dicomData, 'AcquisitionTime')
            );
            const visibleAnnotations = _.pickBy(annotations, (value, toolName) =>
              VISIBLE_TOOLS.includes(toolsList[toolName]?.state)
            );
            const viewport = _.pick(csc.getViewport(cornerstoneRef), ['voi']);

            return {
              imageData,
              acquisitionDate,
              annotations: visibleAnnotations,
              viewport,
            };
          }) ?? [];

      studySavePromises.push(exportDataToPMS(currentStudy, currentStudy.animal, imagesForPMS));
    }

    return Promise.all(studySavePromises).then(
      () =>
        this.setState({
          currentStudy: { ...currentStudy, commentSaved: true, saveOngoing: false },
        }),
      () =>
        this.setState({
          currentStudy: { ...currentStudy, saveOngoing: false },
        })
    );
  };

  validateStudy = async () => {
    const { currentStudy } = this.state;
    const workListImageIndexes = _(currentStudy.images)
      .pickBy(isWorkListImage)
      .keys()
      .reject(_.isUndefined)
      .map(Number)
      .value();

    this.setState((state) => ({ currentStudy: { ...state.currentStudy, saveOngoing: true } }));

    this.deleteImages(workListImageIndexes);
    // We wait for state to be updated before
    await new Promise((r) => this.forceUpdate(r));

    const saveStudyPromise = this.saveStudy();
    const isPACSActivated = this.isPACSActivated();

    const nextLocation =
      isAcquisitionStudy(currentStudy) && isAcquisitionSoftware() ? '/acquisition' : '/online';
    try {
      if (isPACSActivated) {
        await Promise.allSettled([...this.syncWithPACS(), saveStudyPromise]);
      } else {
        await saveStudyPromise;
      }
    } catch {
      this.setState({
        retrySaveData: () => this.validateStudy(),
        leavePageOnError: () => this.forcePageLeave(nextLocation),
      });
      return;
    } finally {
      this.setState((state) => ({ currentStudy: { ...state.currentStudy, saveOngoing: false } }));
    }
    this.clearRetrySave();

    browserHistory.push(nextLocation);
  };

  sendSelectedImagesToPACS = (selectedImages) => {
    const { currentStudy } = this.state;
    const { pacsCommunication } = this.props;
    const { dicomBuilder } = this.props;
    const studyId = currentStudy.ID;

    /// DICOM images should be exported one after the other to avoid overloading PACS system
    let pacsSyncChain = Promise.resolve();

    const pacsSyncRequests = selectedImages
      .map((image, index) => {
        const imageId = image.backendId;

        const isPACSExportable = pacsCommunication && image.dicomData;
        if (!isPACSExportable) return undefined;

        image.PACS.syncError = undefined;
        image.PACS.isSyncOngoing = true;
        this.forceUpdate();

        const syncWithPACSOncePreviousComplete = () =>
          image
            // eslint-disable-next-line react/destructuring-assignment
            .syncWithPACS(this.props.pacsCommunication, dicomBuilder, studyId, index)
            ?.then(() => ApiCalls.updateImageMetadata(imageId, { PACS: { isSync: true } }))
            .catch((error) => console.log('Failed to sync image with PACS', error))
            .finally(() => this.forceUpdate());

        pacsSyncChain = pacsSyncChain.then(
          syncWithPACSOncePreviousComplete,
          syncWithPACSOncePreviousComplete
        );
        return pacsSyncChain;
      })
      .filter((promise) => promise !== undefined);
    this.forceUpdate();

    return pacsSyncRequests;
  };

  syncWithPACS = () => {
    const { viewerConfiguration } = this.props;

    const { syncToPACSOnValidate } = viewerConfiguration.toJS();
    if (!syncToPACSOnValidate) return [];

    const { currentStudy } = this.state;
    const { images } = currentStudy ?? {};

    const pacsSyncRequests = this.sendSelectedImagesToPACS(
      images.filter(RadioImageData.isCompatibleWithPACS).filter((image) => !image?.PACS?.isSync)
    );

    this.forceUpdate();

    return pacsSyncRequests;
  };

  // eslint-disable-next-line react/destructuring-assignment
  isPACSActivated = () => !isPACSConfigurationDisabled(this.props.pacsConfiguration);

  renderSavePanel = () => {
    const {
      currentStudy: { saveOngoing, clearOngoing },
      currentStudy,
    } = this.state;
    const saved = this.isStudySaved();

    const studyForExport = formatStudyForExportModal(currentStudy);
    const isPACSActivated = this.isPACSActivated();
    const imagesForPACS = formatStudyImagesToSelectableImages(
      currentStudy?.images?.filter(RadioImageData.isCompatibleWithPACS)
    );
    const initialSelectedImages = imagesForPACS.filter((image) => !image.PACS?.isSync);

    return (
      <div className="study-actions">
        <div className={`icons-list ${isPACSActivated ? 'with-pacs' : ''}`}>
          <div>
            <Dimmer.Dimmable dimmed={saveOngoing}>
              <Dimmer active={saveOngoing}>
                <Loader />
              </Dimmer>
              <ButtonStudySave saved={saved} validateStudy={this.validateStudy} />
            </Dimmer.Dimmable>
          </div>

          {isPACSActivated && (
            <div>
              <Dimmer.Dimmable>
                <Dimmer active={!saveOngoing && this.isPACSSyncOngoing()}>
                  <Loader />
                </Dimmer>
                <PACSImagesSelector
                  images={imagesForPACS}
                  initialSelectedImages={initialSelectedImages}
                  onConfirm={this.sendSelectedImagesToPACS}
                />
              </Dimmer.Dimmable>
            </div>
          )}

          <div>
            <Dimmer.Dimmable>
              <ExportStudyModal currentStudy={studyForExport} />
            </Dimmer.Dimmable>
          </div>
          <div>
            <Dimmer.Dimmable dimmed={clearOngoing}>
              <Dimmer active={clearOngoing}>
                <Loader />
              </Dimmer>
              <Popup
                trigger={
                  <button className="picoxia" type="button" onClick={() => this.clearStudy()}>
                    <Icon name="close" size="big" />
                  </button>
                }
                content={<FormattedMessage id="dropzone.get_out.tooltip" />}
              />
            </Dimmer.Dimmable>
          </div>
        </div>
      </div>
    );
  };

  imagesThumbnails = () => {
    const { currentStudy, selectedImageIndex } = this.state;
    return currentStudy.images.map((image, index) => {
      const { isLoaded, PACS } = image;
      const { syncError, isSync, isSyncOngoing } = PACS ?? {};

      const onClick = this.getThumbnailOnClick(image, index);
      const isSelected = selectedImageIndex === index;

      const shouldDisplayPACSStatus =
        this.isPACSActivated() && RadioImageData.isCompatibleWithPACS(image);

      let renderedImage;
      let isImageLoading;
      const status = getStatusAccordingToPrediction(image);
      if (isWorkListImage(image)) {
        renderedImage = (
          <ExamIcon
            exam={image.anatomicRegion ?? getDefaultAnatomicRegion(currentStudy.animal?.specie)}
          />
        );
      } else {
        isImageLoading = !isLoaded;

        renderedImage = (
          <div className="radio">
            <Dimmer active={!isLoaded}>
              <Loader />
            </Dimmer>
            <RadioImage
              className="radio"
              cornerstoneDivId={`cornerstoneImageThumbnail${image.id}`}
              thumbnailMode
              toolsList={image.thumbnailToolsList}
              toolsEnabled
              image={image}
              cornerstoneRef={(e) => {
                image.thumbnailCornerstoneRef = e;
                linkImageCornerstoneElements(image);
              }}
            />
          </div>
        );
      }

      return (
        <div
          className={`image ${isSelected ? 'selected' : ''}`}
          key={image.id}
          onClick={onClick}
          role="presentation"
        >
          {renderedImage}
          <div className="thumbnail-status">
            {shouldDisplayPACSStatus && (
              <ImageToPACSSyncStatus
                syncError={syncError}
                isSync={isSync}
                isSyncOngoing={isSyncOngoing}
              />
            )}
          </div>
          {!isImageLoading && (
            <div className="thumbnail-actions flex">
              {this.renderEditAnatomicRegion(image)}
              {this.renderRedoAcquisition(image)}
              {this.renderDeleteIcon(image, index)}
            </div>
          )}
          {this.renderAnalyzeButton(image, index)}
        </div>
      );
    });
  };

  onAnatomicRegionEditHOF = (image) => (anatomicRegion) => {
    const { intl } = this.props;
    const { currentStudy } = this.state;

    image.anatomicRegion = anatomicRegion;

    // Retrieve acquisition constants from same anatomicRegion image
    if (isWorkListImage(image)) {
      const lastAnatomicallyMatchingImage = _(currentStudy.images)
        .filter('anatomicRegion')
        .filter('acquisitionConstants')
        .filter((studyImage) => isSameAnatomicThickness(anatomicRegion, studyImage.anatomicRegion))
        .last();

      image.anatomicRegion = anatomicRegion;
      if (lastAnatomicallyMatchingImage) {
        image.acquisitionConstants = _.cloneDeep(
          lastAnatomicallyMatchingImage.acquisitionConstants
        );
        if (image.backendId) {
          ApiCalls.editAcquisitionConstants(image.backendId, image.acquisitionConstants);
        }
      }
    }

    if (image.backendId) {
      ApiCalls.editAnatomicRegion(image.backendId, image.anatomicRegion);
      ApiCalls.updateImageMetadata(image.backendId, { PACS: { isSync: false } });
    }
    if (image.PACS) image.PACS.isSync = false;

    if (image.dicomData) {
      image.dicomData = updateDicomDataStudyInfo(image.dicomData, intl, currentStudy, image);
    }
    if (image.rawImageDicomData) {
      image.rawImageDicomData = updateDicomDataStudyInfo(
        image.rawImageDicomData,
        intl,
        currentStudy,
        image
      );
      this.saveRawDicomImageToLocal(image);
    }
    this.refreshDicomDataForCornerstone();
    this.forceUpdate();
  };

  renderEditAnatomicRegion = (image) => {
    const isAnatomicRegionEditable = isWorkListImage(image) || isAcquisitionImage(image);
    if (!isAnatomicRegionEditable) return undefined;
    const { currentStudy } = this.state;
    return (
      <EditAnatomicRegionModal
        study={currentStudy}
        image={image}
        onNewAnatomicRegion={this.onAnatomicRegionEditHOF(image)}
      />
    );
  };

  renderRedoAcquisition = (image) =>
    isAcquisitionImage(image) && (
      <Popup
        content={<FormattedMessage id="dropzone.redo_acquisition" />}
        trigger={
          <Icon
            onClick={() => this.redoAcquisition(image)}
            fitted
            className="flex center thumbnail-icon redo-image"
            name="redo alternate"
            size="large"
          />
        }
      />
    );

  renderDeleteIcon = (_image, index) => (
    <Icon
      onClick={(event) => this.deleteSelectedImage(event, index)}
      fitted
      className="thumbnail-icon delete-image"
      name="times"
      size="large"
    />
  );

  redoAcquisition = (image) => {
    this.setState((state) => {
      const { currentStudy } = state;
      const { animal } = currentStudy;

      const newImage = {
        id: produceImageId(),
        origin: 'worklist',
        anatomicRegion: image.anatomicRegion ?? getDefaultAnatomicRegion(animal?.specie),
        acquisitionConstants: image.acquisitionConstants ?? DEFAULT_ACQUISITION_CONSTANTS,
      };
      return {
        currentStudy: {
          ...currentStudy,
          images: [...currentStudy.images, newImage],
        },
        selectedImageIndex: currentStudy.images.length,
      };
    });
  };

  deleteSelectedImage = (event, index) => {
    event.stopPropagation();

    this.deleteImages([index]);
  };

  /** @param {[Number]} deletedIndexes */
  deleteImages = (deletedIndexes) => {
    const { currentStudy, selectedImageIndex } = this.state;
    const backendIds = _(deletedIndexes)
      .map((index) => currentStudy.images[index].backendId)
      .filter()
      .value();

    ApiCalls.removeImagesFromStudy(currentStudy.ID, backendIds).catch((error) =>
      console.log('Failed to delete image from study', error)
    );

    const newImagesList = _.reject(currentStudy.images, (_image, index) =>
      deletedIndexes.includes(index)
    );
    const newIndex = Math.max(
      0,
      selectedImageIndex - _.filter(deletedIndexes, (index) => index <= selectedImageIndex).length
    );

    this.setState((state) => ({
      currentStudy: { ...state.currentStudy, images: newImagesList },
      selectedImageIndex: newIndex,
    }));
    this.updateReport();
  };

  clearRetrySave = () => this.setState({ retrySaveData: undefined, leavePageOnError: undefined });

  clearStudy = async (nextLocation) => {
    const { currentStudy } = this.state;

    let nextLocationOnceSaved;
    if (nextLocation) nextLocationOnceSaved = nextLocation;
    else if (isAcquisitionStudy(currentStudy) && isAcquisitionSoftware()) {
      nextLocationOnceSaved = '/acquisition';
    } else {
      nextLocationOnceSaved = '/online';
    }

    if (!this.isStudySaved()) {
      this.setState((state) => ({ currentStudy: { ...state.currentStudy, clearOngoing: true } }));

      try {
        await this.saveStudy();
      } catch {
        this.setState({
          retrySaveData: () => this.clearStudy(nextLocationOnceSaved),
          leavePageOnError: () => this.forcePageLeave(nextLocationOnceSaved),
        });
        return;
      } finally {
        this.setState((state) => ({
          currentStudy: { ...state.currentStudy, clearOngoing: false },
        }));
      }
      this.clearRetrySave();
    }

    browserHistory.push(nextLocationOnceSaved);
    this.setState({
      currentStudy: createStudy(),
      selectedImageIndex: null,
    });
  };

  clearStudyForAutomaticExport = (studyInstanceUID) => {
    this.setState(({ currentStudy }) => {
      const newStudy = createStudy({ studyInstanceUID });
      newStudy.images = currentStudy.images.filter(
        ({ dicomData, isLoaded }) =>
          getDicomDataValue(dicomData, 'StudyInstanceUID') === studyInstanceUID || !isLoaded
      );
      return { currentStudy: newStudy, selectedImageIndex: 0 };
    });
  };

  analyzeImage = (index) => {
    const { currentStudy } = this.state;
    currentStudy.images[index].analyzed = true;
    this.setState({ selectedImageIndex: index });
  };

  renderAnalyzeButton = (image, index) => {
    if (!image.analyzed && !isWorkListImage(image)) {
      return (
        <button type="button" className="ui green button" onClick={() => this.analyzeImage(index)}>
          <FormattedMessage id="dropzone.analyze" />
        </button>
      );
    }
    return null;
  };

  getThumbnailOnClick = (image, index) => {
    if (image.analyzed || isWorkListImage(image)) {
      return () => {
        this.selectImage(index);
      };
    }
    return () => {};
  };

  selectImage = (index) => {
    this.setState({ selectedImageIndex: index });
  };

  getDisplayedImageInfo = (infoName) =>
    this.anyPrediction() ? this.getDisplayedImage()[infoName] : {};

  getDisplayedPredictions = () => this.getDisplayedImageInfo('predictions');

  anyPrediction = () => {
    const { currentStudy } = this.state;
    return currentStudy.images.some((image) => !_.isEmpty(image.predictions));
  };

  helpMessage = () => {
    const { showExportSetupHelp } = this.state;
    if (showExportSetupHelp) {
      return (
        <HelpMessage
          style={{ margin: '10px 10px 5px 10px' }}
          headerText={this.formatMessage({ id: 'dropzone.helpMessages.automaticExport.header' })}
          paragraphText={
            <p>
              <FormattedMessage id="dropzone.helpMessages.automaticExport.paragraph" />
            </p>
          }
          iconClass="paper plane icon"
          closable
        />
      );
    }
    if (!this.anyPrediction() && !this.hideHelpers) {
      return <WhatImagesToSend loadDemo={this.loadDemo} />;
    }
    return null;
  };

  refreshDicomDataForCornerstone = () => {
    const { currentStudy } = this.state;
    currentStudy.images.forEach((image) => {
      if (!image.dicomData) return;
      try {
        const cscImage = csc.getImage(image.cornerstoneRef);
        cscImage.dicomData = image.dicomData;
        csc.updateImage(image.cornerstoneRef);
      } catch {
        // Avoid error on loading image
      }
    });
    this.forceUpdate();
  };

  updateAllDicomPatientInfo = () => {
    const { intl } = this.props;
    const { currentStudy } = this.state;
    currentStudy.images.forEach((image) => {
      if (!image.dicomData) return;
      image.dicomData = updateDicomDataPatient(image.dicomData, currentStudy.animal, intl);
      if (image.rawImageDicomData) {
        image.rawImageDicomData = updateDicomDataPatient(
          image.rawImageDicomData,
          currentStudy.animal,
          intl
        );
        this.saveRawDicomImageToLocal(image);
      }
    });
    this.refreshDicomDataForCornerstone();
  };

  updateAllDicomID = () => {
    const { currentStudy } = this.state;
    currentStudy.images.forEach((image) => {
      if (!image.dicomData) return;
      if (!currentStudy.ID) return;
      // By updating ID if StudyID does not match, we include external dicom image into our save
      // cycle.
      if (!image.dicomData.StudyID || image.dicomData.StudyID !== currentStudy.ID) {
        image.dicomData = updateDicomDataID(image.dicomData, currentStudy, image);
      }
      if (image.rawImageDicomData) {
        image.rawImageDicomData = updateDicomDataID(image.rawImageDicomData, currentStudy, image);
        if (image.rawImageDicomData.StudyID) {
          this.saveRawDicomImageToLocal(image);
        }
      }
    });
    this.refreshDicomDataForCornerstone();
  };

  /**
   * Save raw dicom image into filesystem if studyID and backendID are set
   * @param {*} image Image to save
   */
  saveRawDicomImageToLocal = (image) => {
    const { localDicomStoreContext } = this.props;
    const { currentStudy } = this.state;
    if (!image.rawImageDicomData.StudyID) return;
    if (!currentStudy.ID || !image.backendId) return;
    localDicomStoreContext.storeDicomImage(
      currentStudy.ID,
      image.backendId,
      image.rawImageDicomData
    );
  };

  triggerLinkPatientToStudy = (patientData) => {
    this.setState(
      ({ currentStudy }) => ({
        currentStudy: {
          ...currentStudy,
          ..._.cloneDeep(formatPatientToStudyAnimal(patientData.animal)),
        },
        isLinkAnimalToStudyNeeded: true,
      }),
      this.updateAllDicomPatientInfo
    );
  };

  setPatientInfo = (patientInfo) => {
    this.setState(
      ({ currentStudy }) => ({
        currentStudy: {
          ...currentStudy,
          ..._.cloneDeep(formatPatientToStudyAnimal(patientInfo)),
        },
      }),
      this.updateAllDicomPatientInfo
    );
  };

  linkPatientFromDicom = (patientInfo, { force = false } = {}) => {
    ApiCalls.savePatientInfo(patientInfo).then(
      ({ data }) => {
        const { currentStudy } = this.state;
        const studyHasNoAnimal = currentStudy.animal === undefined;
        if (!studyHasNoAnimal && !force) return;
        this.triggerLinkPatientToStudy(data);
      },
      (error) => {
        console.error('request error, savePatientInfo', error);
        if (force) this.setPatientInfo(patientInfo);
      }
    );
  };

  linkStudyToPatient = () => {
    const { currentStudy, isLinkAnimalToStudyNeeded } = this.state;
    if (!isLinkAnimalToStudyNeeded || currentStudy?.ID === undefined) return;
    this.setState({ isLinkAnimalToStudyNeeded: false });
    // eslint-disable-next-line no-underscore-dangle
    ApiCalls.linkStudyToAnimal(currentStudy.ID, currentStudy.animal._id).catch((error) => {
      console.error('linkStudyToAnimal failed', error);
      this.setState({ isLinkAnimalToStudyNeeded: true });
    });
  };

  rescaleDisplayedImageToConfiguredFullSize = () => {
    const { viewerConfiguration } = this.props;
    const currentImage = this.getDisplayedImage();
    const fullSizeScaleConfigurations = viewerConfiguration.toJS().fullSize;
    const detectorId = getXRayDetectorID(currentImage?.dicomData);

    return rescaleDisplayedImageToConfiguredFullSize(
      currentImage.cornerstoneRef,
      fullSizeScaleConfigurations,
      detectorId
    );
  };

  toggleFullSizeConfiguration = (isOngoing) =>
    this.dispatch(toggleFullSizeConfigurationOngoing(isOngoing));

  renderFullSizeConfigurationButton = () => {
    const { isFullScreenConfigurationOngoing } = this.state;
    const currentImage = this.getDisplayedImage();
    const isImageAssociatedWithDetectorId = getXRayDetectorID(currentImage?.dicomData);

    return (
      <FullSizeConfigurationButton
        applyFullSize={this.rescaleDisplayedImageToConfiguredFullSize}
        isFullSizeConfigurationOngoing={isFullScreenConfigurationOngoing}
        toggleFullSizeConfiguration={this.toggleFullSizeConfiguration}
        hidden={!isImageAssociatedWithDetectorId}
      />
    );
  };

  renderMiddlePane = () => {
    const { intl } = this.props;
    const { commonToolsList, currentStudy, toolButtonsState, isFullScreenConfigurationOngoing } =
      this.state;
    const patient = formatStudyToPatient(currentStudy);
    const { name, owner_name, birth_date, sex, specie, file_id } = patient;
    const currentImage = this.getDisplayedImage();
    const isDisplayableImage = currentImage && !isWorkListImage(currentImage);
    const { toolsList, anatomicRegion } = currentImage || {};

    return (
      <div className={`viewer-pane icons-${intl.locale}`}>
        {currentImage && (
          <div className="patient-info-bar-wrapper">
            <PatientInfoBar
              animalName={name}
              ownerName={owner_name}
              sex={sex}
              birthDate={birth_date}
              fileID={file_id}
              specie={specie}
            />
            <PatientInfoModal
              onPatientSave={this.triggerLinkPatientToStudy}
              initialPatientData={patient}
            />
          </div>
        )}
        {currentImage && isDisplayableImage && (
          <ToolsBar
            study={currentStudy}
            predictions={currentImage.predictions}
            toolsList={{ ...commonToolsList, ...toolsList }}
            toolsProps={{
              ...toolButtonsState,
              ...currentImage.toolsProps,
              FullSizeConfiguration: { content: this.renderFullSizeConfigurationButton() },
            }}
            focusedElement={currentImage.cornerstoneRef}
            produceCommonToolsList={this.produceCommonToolsList}
            produceImageToolsList={this.produceImageToolsListHOF(currentImage)}
            displayedToolsLists={UPPER_TOOLS_BAR_TOOLS_LISTS}
            anatomicRegion={anatomicRegion}
            disabled={isFullScreenConfigurationOngoing}
          />
        )}
        {this.renderDropzone()}
        {this.renderAcquisitionCountdown()}
      </div>
    );
  };

  onNewSnipImage = (image) => {
    this.addImagesToStudy(image, 'snip');
  };

  onNewAutoExportedImages = (images) => {
    const { currentStudy } = this.state;
    const { lastStudyUpdate } = this.state;
    if (lastStudyUpdate > Date.now() + AUTOMATIC_IMPORT_TIMEOUT_DELAY_MS) {
      this.clearStudy();
    }
    let addedImages = images;
    if (currentStudy.studyInstanceUID) {
      addedImages = images.filter(({ path }) => isDicomImage(path));
    }
    this.addImagesToStudy(addedImages, 'automaticExport');
  };

  getSnipper = () => {
    if (process.env.PLATFORM === 'website') return null;
    const { snipper } = this.state;
    if (!snipper) {
      import('app/components/Snipper').then((snip) => {
        this.setState({ snipper: snip });
      });
      return null;
    }
    return <snipper.default onImageReady={(image) => this.onNewSnipImage(image)} />;
  };

  getFileWatcher = () => {
    if (process.env.PLATFORM === 'website') return null;
    const { fileWatcher } = this.state;
    if (!fileWatcher) {
      import('app/components/FileWatcher').then((fileWatcherComp) => {
        this.setState({ fileWatcher: fileWatcherComp });
      });
      return null;
    }
    return <fileWatcher.default onImageReady={(images) => this.onNewAutoExportedImages(images)} />;
  };

  onAcquisitionConstantsChange = (changedImage, value) => {
    const { currentStudy } = this.state;
    const { anatomicRegion } = changedImage;
    _(currentStudy.images)
      .filter(isWorkListImage)
      .filter((image) => isSameAnatomicThickness(anatomicRegion, image.anatomicRegion))
      .forEach((image) => {
        image.acquisitionConstants = value;
        if (image.backendId) {
          this.editAcquisitionConstantsDebounced(image.backendId, value);
        }
      });
    this.forceUpdate();
  };

  renderDropzone = () => {
    const { dispatch } = this.props;
    const { currentStudy, selectedImageIndex, isFullScreenConfigurationOngoing } = this.state;
    const currentImage = this.getDisplayedImage();

    const FullSizeValidationButtonsIfOnGoing = () => {
      if (!isFullScreenConfigurationOngoing || !currentImage) return null;
      const detectorId = getXRayDetectorID(currentImage?.dicomData);
      if (!detectorId) return null;

      return (
        <FullSizeValidationButton
          onConfirm={() =>
            confirmFullSizeConfiguration(
              currentImage.cornerstoneRef,
              detectorId,
              dispatch,
              this.dispatch
            )
          }
          onCancel={() => cancelFullSizeConfiguration(this.dispatch)}
        />
      );
    };

    return (
      <picture id="radioPicture">
        <div>
          <input
            ref={this.addImportImagesInputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={this.onFileBrowserImageSelection}
            accept=".jpg,.JPG,.png,.PNG,.dcm,.DCM,.dicom,.DICOM,.JPEG,.jpeg"
            multiple
          />
          <div className={`placeholderForImagesWrapper ${this.hiddenIfImage()}`}>
            {this.helpMessage()}
            <div className="placeholderForImagesButtonWrapper">
              <button
                className={`ui placeholder placeholderForImage${this.hiddenIfImage()}`}
                onClick={this.openFileBrowser}
                type="button"
              >
                <span className="placeholderText">{this.dragAndDropText()}</span>
              </button>
            </div>
          </div>
          {currentStudy.images.map((image, index) => {
            const isVisible = selectedImageIndex !== null && index === selectedImageIndex;
            const isVisibleClass = isVisible ? 'visible' : 'not-visible';
            if (isWorkListImage(image)) {
              return (
                <XRayAreaView
                  key={image.id}
                  image={image}
                  animal={currentStudy.animal ?? DEFAULT_ANIMAL}
                  isFocused={isVisible}
                  className={`main-radio-image ${isVisibleClass}`}
                  onChange={(value) => this.onAcquisitionConstantsChange(image, value)}
                />
              );
            }
            const { commonToolsList } = this.state;
            const { toolsList } = image;
            const { isLoaded } = image;
            const notYetLoadedClass = isLoaded ? '' : 'not-yet-loaded';
            return (
              <div key={image.id}>
                {!isLoaded && (
                  <Dimmer active>
                    <Loader size="huge" />
                  </Dimmer>
                )}
                <RadioImage
                  className={`main-radio-image ${notYetLoadedClass} ${isVisibleClass}`}
                  cornerstoneDivId={`cornerstoneImage${image.id}`}
                  processCornerstoneImage={image.restoreDicomFromFilesystem}
                  onImageLoaded={this.onImageLoaded}
                  thumbnailMode={false}
                  toolsList={{ ...commonToolsList, ...toolsList }}
                  toolsEnabled
                  onMeasurementUpdate={image.onMeasurementUpdate}
                  cornerstoneRef={(e) => {
                    image.cornerstoneRef = e;
                    linkImageCornerstoneElements(image);
                    image.attachListeners(e);
                    this.forceUpdate();
                  }}
                  image={image}
                />
              </div>
            );
          })}
          <FullSizeValidationButtonsIfOnGoing />
        </div>
      </picture>
    );
  };

  onCommentChange = (commentValue) => {
    this.setState((state) => ({
      currentStudy: {
        ...state.currentStudy,
        comment: commentValue,
        isCommentDirty: true,
        commentSaved: false,
      },
    }));
    this.saveCommentDebounced();
  };

  onSwitchAnnotationState = () => {
    this.setState(({ currentStudy, showAnnotations: oldShowAnnotations }) => {
      const showAnnotations = !oldShowAnnotations;

      currentStudy.images.forEach((image) => {
        image.toolsList = produce(image.toolsList, (draftToolsList) => {
          _.forEach(draftToolsList, (draftTool) => {
            if (draftTool.options.name === 'Crop') return;
            if (draftTool.options.name === 'ScaleOverlay') {
              draftTool.state = showAnnotations ? 'active' : 'disabled';
            } else {
              draftTool.state = showAnnotations ? 'passive' : 'disabled';
            }
          });
        });
      });

      return { showAnnotations };
    });
  };

  selectActivePatternsRegion = (_e, { name }) =>
    this.setState(() => {
      const currentImage = this.getDisplayedImage();
      if (!currentImage) return undefined;
      currentImage.activePatternsRegion = name;
      return {};
    });

  renderBadImage = () => {
    const currentImage = this.getDisplayedImage();
    if (!currentImage || !currentImage.predictions?.type) return undefined;
    const isBadImage = !VALID_PREDICTION_TYPES.includes(currentImage.predictions.type);
    if (isBadImage) {
      return (
        <div className="container">
          <div className="ui icon message">
            <i className="exclamation triangle icon" />
            <div className="content">
              <div className="header">
                <FormattedMessage id="patternStructuredList.badImage.header" />
              </div>
              <p>
                <FormattedMessage id="patternStructuredList.badImage.pleaseVerify" />
              </p>
              <ul>
                <li>
                  <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.thorax" />
                </li>
                <li>
                  <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.catOrDog" />
                </li>
                <li>
                  <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.centered" />
                </li>
                <li>
                  <FormattedMessage id="patternStructuredList.badImage.pleaseVerify.machineConstants" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  addNewAcquisitionImage = (anatomicRegion) =>
    this.setState((state) => {
      const { currentStudy } = state;
      const newImage = {
        id: produceImageId(),
        origin: 'worklist',
        anatomicRegion,
        acquisitionConstants: DEFAULT_ACQUISITION_CONSTANTS,
      };
      return {
        currentStudy: {
          ...currentStudy,
          images: [...currentStudy.images, newImage],
          ...(currentStudy.animal ? null : { animal: { specie: DEFAULT_SPECIE } }),
        },
        selectedImageIndex: currentStudy.images.length,
      };
    });

  renderNewAcquisitionButton = () => {
    if (!isXrayLibEnabled() || !isAcquisitionSoftware()) return null;
    const { currentStudy } = this.state;
    return (
      <AddXRayViewModal
        specie={currentStudy.animal?.specie ?? DEFAULT_SPECIE}
        onNewAnatomicRegion={this.addNewAcquisitionImage}
      />
    );
  };

  getNextWorklistImageIndex = () => {
    const { currentStudy, selectedImageIndex } = this.state;
    const { images } = currentStudy;
    for (let i = 1; i < images.length; i += 1) {
      const currentIndex = (selectedImageIndex + i) % images.length;
      if (isWorkListImage(images[currentIndex])) {
        return currentIndex;
      }
    }
    return -1;
  };

  triggerNextAcquisition = () => {
    const nextWorklistImageIndex = this.getNextWorklistImageIndex();
    if (nextWorklistImageIndex !== -1) {
      this.setState({ selectedImageIndex: nextWorklistImageIndex });
      this.cleanupAutomaticNextAcquisition();
    }
  };

  cleanupAutomaticNextAcquisition = () => {
    this.ref.removeEventListener('mousemove', this.cleanupAutomaticNextAcquisition);

    clearTimeout(this.triggerNextAcquisitionTimeout);
    delete this.triggerNextAcquisitionTimeout;

    clearInterval(this.showNextAcquisitionCountDown);
    delete this.showNextAcquisitionCountDown;
    this.setState({ acquisitionCountDown: undefined });
  };

  startNextAutomaticAcquisition = () => {
    if (this.getNextWorklistImageIndex() === -1) return;

    this.setState({ acquisitionCountDown: ACQUISITION_COUNTDOWN_DURATION_S });
    this.triggerNextAcquisitionTimeout = setTimeout(
      this.triggerNextAcquisition,
      ACQUISITION_COUNTDOWN_DURATION_S * 1000
    );
    this.showNextAcquisitionCountDown = setInterval(
      () => this.setState((state) => ({ acquisitionCountDown: state.acquisitionCountDown - 1 })),
      1000
    );
    this.ref.addEventListener('mousemove', this.cleanupAutomaticNextAcquisition);
  };

  onAcquisitionImage = async (acquiredImage) => {
    const { flatPanelStateContext, intl } = this.props;
    const { currentStudy, selectedImageIndex, animal } = this.state;
    const currentImage = this.getDisplayedImage();

    let acquiredImageIndex;

    if (isWorkListImage(currentImage)) {
      acquiredImageIndex = selectedImageIndex;
    } else {
      const nextWorkListImageIndex = this.getNextWorklistImageIndex();
      const hasAnotherWorklistImageInStudy = nextWorkListImageIndex > -1;
      if (hasAnotherWorklistImageInStudy) {
        acquiredImageIndex = nextWorkListImageIndex;
      }
    }
    const willCreateImageAfterProcessing = acquiredImageIndex === undefined;
    const currentWorkListImage =
      acquiredImageIndex !== undefined ? currentStudy.images[acquiredImageIndex] : undefined;

    const acquisitionImage = this.createImage(
      {
        ...acquiredImage,
        minPixelValue: acquiredImage.min_pixel_value,
        maxPixelValue: acquiredImage.max_pixel_value,
      },
      'acquisition'
    );

    acquisitionImage.restorationStatus = RESTORATION_STATUS.done;
    acquisitionImage.acquisitionTime = new Date();
    acquisitionImage.rawImage = acquisitionImage.imageFile;
    acquisitionImage.origin = 'acquisition';
    acquisitionImage.photometricInterpretation = 'MONOCHROME';
    acquisitionImage.manufacturer = DETECTOR_IMPLEMENTATION.toString(
      getCurrentDetector(flatPanelStateContext).getImplementation()
    );
    acquisitionImage.needLogRescale = true;

    if (currentWorkListImage) {
      _.merge(
        acquisitionImage,
        _.pick(currentWorkListImage, ['backendId', 'anatomicRegion', 'acquisitionConstants'])
      );
    } else {
      // Add default parameters if we were not focussing a workList image.
      acquisitionImage.anatomicRegion = getDefaultAnatomicRegion(animal?.specie);
      acquisitionImage.acquisitionConstants = DEFAULT_ACQUISITION_CONSTANTS;
    }

    // Focus the acquired image if it was not already focused
    if (acquiredImageIndex) {
      currentStudy.images[acquiredImageIndex].isProcessingOnGoing = true;
      this.setState({ selectedImageIndex: acquiredImageIndex });
    }

    acquisitionImage.rawImageDicomData = convertIRayImageToDicomData(
      flatPanelStateContext,
      currentStudy,
      acquisitionImage,
      intl,
      false
    );
    this.saveRawDicomImageToLocal(acquisitionImage);

    const processingType = getProcessingFromAnatomicRegion(acquisitionImage.anatomicRegion);
    await acquisitionImage.applyProcessing(processingType);

    if (willCreateImageAfterProcessing) {
      currentStudy.images.push(acquisitionImage);
      this.setState({ selectedImageIndex: currentStudy.images.length - 1 });
    } else {
      currentStudy.images[acquiredImageIndex] = acquisitionImage;
      this.setState({ selectedImageIndex: acquiredImageIndex });
    }

    this.startNextAutomaticAcquisition();
    this.forceUpdate();
  };

  renderAcquisitionRightPane = () => {
    const { xRayConfiguration } = this.props;
    const { devicePath } = xRayConfiguration?.toJS() ?? {};

    return (
      <div className="acquisition-pane">
        {devicePath && (
          <>
            <XRayGeneratorStatus />
            <Divider style={{ width: '80%' }} />
          </>
        )}
        <FlatPanelList onNewImage={this.onAcquisitionImage} />
      </div>
    );
  };

  renderImageRightPane = () => {
    const {
      errorMessage,
      currentStudy,
      commonToolsList,
      showAnnotations,
      isFullScreenConfigurationOngoing,
    } = this.state;
    const currentImage = this.getDisplayedImage();
    const isNonDisplayableImage = !currentImage || isWorkListImage(currentImage);

    const renderToolsBar = () => {
      if (isNonDisplayableImage) return null;
      const { toolsList, anatomicRegion } = currentImage;
      return (
        <div>
          <MeasurementTools
            predictions={currentImage.predictions}
            toolsList={{ ...commonToolsList, ...toolsList }}
            focusedElement={currentImage.cornerstoneRef}
            produceCommonToolsList={this.produceCommonToolsList}
            produceImageToolsList={this.produceImageToolsListHOF(currentImage)}
            showAnnotations={showAnnotations}
            onSwitchAnnotationState={this.onSwitchAnnotationState}
            anatomicRegion={anatomicRegion}
            disabled={isFullScreenConfigurationOngoing}
          />
        </div>
      );
    };

    const renderPatterns = () => {
      if (isNonDisplayableImage) return null;
      if (_.isEmpty(currentImage?.predictions)) {
        return (
          <div className="patterns picoxia-animation">
            <Picoxia />
          </div>
        );
      }
      if (currentImage?.predictions?.no_credit_left) {
        return undefined;
      }
      const patternsComponent = [];
      const { type } = currentImage.predictions;
      if (currentImage.predictions.type === 'pelvis_face') {
        patternsComponent.push({
          label: 'pelvis',
          component: (
            <DysplasiaPatternStructuredList
              predictions={currentImage.predictions}
              annotations={currentImage.annotations}
            />
          ),
        });
      }
      if (THORAX_PREDICTION_TYPES.includes(type)) {
        patternsComponent.push({
          label: 'thorax',
          component: (
            <PatternStructuredList
              groups={THORAX_GROUPS}
              patterns={THORAX_PATTERNS}
              image={currentImage}
              updateParentImage={() => this.updateReport()}
              groupSpecificChildrenBefore={{
                'Système Cardio-Vasculaire': (
                  <div className="ui divided selection list" key="vhs">
                    <div>
                      <VHSReferencesDropdown
                        onVHSClick={this.switchToolStateHOF(currentImage, 'VHS')}
                        predictions={currentImage.predictions}
                        annotations={currentImage.annotations}
                        selectedRaceKey={currentImage.selectedRaceKey}
                        onRaceSelected={this.onRaceSelected}
                        patientInfo={currentStudy.animal}
                      />
                    </div>
                  </div>
                ),
              }}
            />
          ),
        });
      }
      if (ABDOMEN_PREDICTION_TYPES.includes(type)) {
        patternsComponent.push({
          label: 'abdomen',
          component: (
            <PatternStructuredList
              groups={ABDOMEN_GROUPS}
              patterns={ABDOMEN_PATTERNS}
              image={currentImage}
              updateParentImage={() => this.updateReport()}
            />
          ),
        });
      }

      if (patternsComponent.length === 0) return null;
      const { activePatternsRegion = patternsComponent[0].label } = currentImage;
      const multiRegion = patternsComponent.length > 1;
      const selectedComponent = (
        multiRegion
          ? _.find(patternsComponent, { label: activePatternsRegion }) ?? patternsComponent[0]
          : patternsComponent[0]
      ).component;

      return (
        <>
          {patternsComponent.length > 1 && (
            <Menu tabular attached="top" className="patterns-menu">
              {patternsComponent.map(({ label }) => (
                <MenuItem
                  key={label}
                  name={label}
                  active={activePatternsRegion === label}
                  onClick={this.selectActivePatternsRegion}
                >
                  <FormattedMessage id={label} />
                </MenuItem>
              ))}
            </Menu>
          )}
          <div className="patterns">{selectedComponent}</div>
        </>
      );
    };

    const renderCommentZone = () => {
      const anyImageLoaded = _.some(currentStudy.images, { isLoaded: true });
      if (anyImageLoaded) {
        const isBadImage = !VALID_PREDICTION_TYPES.includes(currentImage?.predictions?.type);
        const isCommentDisabled = isBadImage && !isAcquisitionSoftware();
        if (isCommentDisabled) return null;
        return (
          <div>
            <EditableReport
              study={currentStudy}
              onChange={this.onCommentChange}
              minHeight="95px"
              maxHeight="225px"
              isDirty={currentStudy.isCommentDirty}
            />
          </div>
        );
      }
      return null;
    };

    const renderPicoxiaInterpretationNeeded = () => {
      if (currentImage?.predictions?.no_credit) {
        return (
          <button
            className="picoxia purchase-link button picoxiaLightGreen"
            type="button"
            onClick={() => window.open(`${FRONT_END_PATH}/purchase`)}
          >
            <Picoxia />
            <FormattedMessage id="buy_interpretation.link" />
          </button>
        );
      }
      return undefined;
    };

    const isAcquisitionImageInAcquisitionSoftware =
      isAcquisitionSoftware() && isAcquisitionImage(currentImage);

    const inferenceError = errorMessage ? (
      <InferenceErrorMessage errorMessage={errorMessage} />
    ) : null;

    const badImageDisabled = isAcquisitionImageInAcquisitionSoftware;

    const { compteRendu } = currentStudy;
    const hiddenPanelStyle = isNonDisplayableImage ? { display: 'none' } : {};
    const isBadImage =
      currentImage &&
      currentImage.predictions?.type &&
      !VALID_PREDICTION_TYPES.includes(currentImage?.predictions.type);
    const badImage = !badImageDisabled && this.renderBadImage();
    const commentZone = (!isBadImage || isAcquisitionSoftware()) && renderCommentZone();
    const toolsBar = renderToolsBar();
    const purchaseLink = renderPicoxiaInterpretationNeeded();
    const patterns = !isBadImage && renderPatterns();

    return (
      <div style={hiddenPanelStyle}>
        <FoldableAIPanel>
          {commentZone}
          {toolsBar}
          <TeleradiologyPanel
            studyId={currentStudy.ID}
            animal={currentStudy.animal}
            studyDate={currentStudy.creationDate}
            images={formatStudyImagesForTeleradiology(currentStudy.images)}
            aiReport={compteRendu}
          />
          {purchaseLink}
          {badImage}
          {inferenceError}
          {patterns}
        </FoldableAIPanel>
      </div>
    );
  };

  renderRightPane = () => {
    const currentImage = this.getDisplayedImage();

    return (
      <>
        {this.renderImageRightPane()}
        {isWorkListImage(currentImage) && this.renderAcquisitionRightPane()}
      </>
    );
  };

  renderAcquisitionCountdown = () => {
    const { acquisitionCountDown } = this.state;
    if (acquisitionCountDown === undefined) return null;
    return (
      <div className="acquisition-countdown">
        <CountDown duration={ACQUISITION_COUNTDOWN_DURATION_S} count={acquisitionCountDown} />
      </div>
    );
  };

  render() {
    const { toolButtonsState, retrySaveData, leavePageOnError } = this.state;
    return (
      <div
        className={`dropzone viewer-background ${
          toolButtonsState.FullScreen.isFullScreen ? 'fullscreen' : ''
        }`}
        ref={(e) => {
          this.ref = e;
        }}
        onDrop={this.onFileDrop}
        onDragOver={this.onDragOver}
      >
        <div className="viewer-grid">
          <div className="left-pane">{this.renderLeftPane()}</div>
          {this.renderMiddlePane()}
          <div className="tools-pane">{this.renderRightPane()}</div>
        </div>
        <UnsavedDataModal
          retrySaveData={retrySaveData}
          leavePage={leavePageOnError}
          cancel={this.clearRetrySave}
        />
      </div>
    );
  }
}

Dropzone.propTypes = {
  isLoggedIn: pt.bool.isRequired,
  intl: pt.shape().isRequired,
  hideHeader: pt.func.isRequired,
  router: pt.shape().isRequired,
  routes: pt.arrayOf(pt.shape()).isRequired,
  flatPanelStateContext: pt.shape().isRequired,
  localDicomStoreContext: LocalDicomStoreContextShape.isRequired,
  exportDataToPMS: pt.func,
  pacsCommunication: pt.shape(),
  pacsConfiguration: pt.shape(),
};

Dropzone.defaultProps = {
  exportDataToPMS: undefined,
  pacsCommunication: undefined,
  pacsConfiguration: undefined,
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.get('global').get('loggedIn'),
    viewerConfiguration: selectViewerConfiguration(state),
    xRayConfiguration: selectXRayConfiguration(state),
    pacsConfiguration: selectPACSConfiguration(state),
    aiOnlyConfiguration: selectAiOnlyConfiguration(state),
    dicomMapping: selectDicomMapping(state),
    selectedCurrentStudyId: selectCurrentStudyId(state),
    selectedCurrentImageId: selectCurrentImageId(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default withContext(
  withContext(
    withContext(
      withContext(
        withContext(
          withContext(
            connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Dropzone))),
            FlatPanelStateContext,
            'flatPanelStateContext'
          ),
          LocalDicomStoreContext,
          'localDicomStoreContext'
        ),
        XRayGeneratorStateContext,
        'xRayGeneratorStateContext'
      ),
      PACSCommunicationContext,
      'pacsCommunication'
    ),
    DicomBuilderContext,
    'dicomBuilder'
  ),
  PMSExportContext,
  'exportDataToPMS'
);
