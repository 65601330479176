import { translate } from '../languageUtil';
import { MARQUE, PRESENT, PROBABLE, SUSPICION, ABSENT } from './constants';

const inSingle = (listOfPatterns, item) => listOfPatterns.indexOf(item) > -1;

const isIn = (listOfLists, item) => listOfLists.some((list) => inSingle(list, item));

const formatStr = (str, ...args) =>
  str.replace(/{(\d+)}/g, (match, number) =>
    typeof args[number] !== 'undefined' ? args[number] : match
  );

const severityToInt = (severity) => {
  switch (severity) {
    case 'marque':
      return MARQUE;
    case 'present':
      return PRESENT;
    case 'probable':
      return PROBABLE;
    case 'suspicion':
      return SUSPICION;
    case 'absent':
      return ABSENT;
    default:
      console.log('Unknown severity:', severity);
      return null;
  }
};

const getSeverite = (
  pattern,
  markedPatterns,
  presentPatterns,
  likelyPatterns,
  unlikelyPatterns
) => {
  if (isIn([markedPatterns], pattern)) {
    return 'marque';
  }
  if (isIn([presentPatterns], pattern)) {
    return 'present';
  }
  if (isIn([likelyPatterns], pattern)) {
    return 'probable';
  }
  if (isIn([unlikelyPatterns], pattern)) {
    return 'suspicion';
  }
  return 'absent';
};

const getSeveriteInt = (
  pattern,
  markedPatterns,
  presentPatterns,
  likelyPatterns,
  unlikelyPatterns
) =>
  severityToInt(
    getSeverite(pattern, markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns)
  );

const haveSameSeverity = (patterns, listOfLists) =>
  // listOfLists is supposed to be ordered by decreasing severity
  listOfLists.some((list) => patterns.every((pattern) => inSingle(list, pattern)));

const addToMaxSeverity = (patternToAdd, patterns, listOfLists) => {
  // listOfLists is supposed to be ordered by decreasing severity
  const listToAppendTo = listOfLists.find((list) =>
    patterns.some((pattern) => inSingle(list, pattern))
  );
  if (listToAppendTo) {
    listToAppendTo.unshift(patternToAdd);
  }
};

const isLeftSeverityWorst = (leftSeverity, rightSeverity) =>
  severityToInt(leftSeverity) > severityToInt(rightSeverity);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function lowerCaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
function removeTrailingDot(string) {
  return string.endsWith('.') ? string.slice(0, -1) : string;
}
function addTrailingDot(string) {
  return string.endsWith('.') ? string : `${string}.`;
}

export {
  inSingle,
  isIn,
  formatStr,
  severityToInt,
  getSeverite,
  getSeveriteInt,
  haveSameSeverity,
  addToMaxSeverity,
  isLeftSeverityWorst,
  capitalizeFirstLetter,
  lowerCaseFirstLetter,
  removeTrailingDot,
  addTrailingDot,
};
