import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import * as cst from 'cornerstone-tools';
import { injectIntl } from 'react-intl';
import ActivatableToolButton from 'app/components/ToolsBar/ActivatableToolButton';

import ToolType from '../../types/ToolType';
import PreComputedToolButton from './PreComputedToolButton';

const NORBERG_OLSSON_REGIONS = ['pelvis_face'];
Object.freeze(NORBERG_OLSSON_REGIONS);

const drawNorbergOlsson = (name, element, predictions, force = false) => {
  if (!('norberg_olsson' in predictions) && !force) return false;

  return cst.getToolForElement(element, name).addToElement(element, predictions?.norberg_olsson);
};

const NorbergOlssonButton = (props) => {
  const { toolsList, name, produceImageToolsList, focusedElement, predictions, anatomicRegion } =
    props;

  const isNorbergOlssonPredictionsTypes = NORBERG_OLSSON_REGIONS.includes(predictions.type);
  const isNorbergOlssonAnatomicRegion = [
    'dog abdomen Bassin face',
    'dog entire_body',
    undefined,
  ].includes(anatomicRegion);

  if (!isNorbergOlssonAnatomicRegion && !isNorbergOlssonPredictionsTypes) return null;

  const disableDistractionIndexOnActivation = (newState) => {
    if (!['active', 'passive'].includes(newState)) return;
    produceImageToolsList((draftToolsList) => {
      if (draftToolsList.DistractionIndex === undefined) return;
      draftToolsList.DistractionIndex.state = 'disabled';
    });
  };
  if (isNorbergOlssonPredictionsTypes) {
    return (
      <PreComputedToolButton
        toolsList={toolsList}
        name={name}
        tooltipId="tools.norberg_olsson.tooltip"
        content={<i className="svg-icon norberg_olsson" />}
        produceImageToolsList={produceImageToolsList}
        drawToolDataFn={drawNorbergOlsson}
        focusedElement={focusedElement}
        predictions={predictions}
        setToolStateFn={disableDistractionIndexOnActivation}
      />
    );
  }

  return (
    <ActivatableToolButton
      icon="norberg_olsson"
      tooltipId="tools.norberg_olsson.tooltip"
      disabledState="disabled"
      setToolStateFn={disableDistractionIndexOnActivation}
      {...props}
    />
  );
};

NorbergOlssonButton.propTypes = {
  toolsList: pt.objectOf(ToolType).isRequired,
  name: pt.string.isRequired,
  produceImageToolsList: pt.func.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
  predictions: pt.shape({
    type: pt.string,
    norberg_olsson: pt.shape(),
  }),
};

NorbergOlssonButton.defaultProps = {
  focusedElement: null,
  predictions: {},
};

export default injectIntl(NorbergOlssonButton);
