import React from 'react';
import ReactDOM from 'react-dom';
import * as pt from 'prop-types';
import { Form, Input } from 'semantic-ui-react';

import { forceImageUpdate } from '../../CornerstoneTools/forceImageUpdate';

class FloatingInputCallback extends React.PureComponent {
  constructor(props) {
    super(props);
    const { defaultValue } = props;
    this.state = {
      value: defaultValue || '',
    };
    this.inputRef = undefined;
    this.submitted = false;
  }

  componentDidMount = () => {
    this.inputRef.focus();
  };

  setInputRef = (e) => {
    this.inputRef = e;
  };

  onSubmit = () => {
    this.submitted = true;
    const { getTextCallback } = this.props;
    const { value } = this.state;
    getTextCallback(value);
  };

  onBlur = () => {
    if (this.submitted) return;
    const { defaultValue, getTextCallback } = this.props;
    getTextCallback(defaultValue);
  };

  render = () => {
    const { value } = this.state;
    const { top, left } = this.props;
    return (
      <div
        style={{
          position: 'fixed',
          top,
          left,
        }}
      >
        <Form onSubmit={this.onSubmit}>
          <Input
            value={value}
            ref={this.setInputRef}
            onBlur={this.onBlur}
            onChange={(e, { value: inputValue }) => this.setState({ value: inputValue })}
            type="text"
          />
        </Form>
      </div>
    );
  };
}

FloatingInputCallback.propTypes = {
  getTextCallback: pt.func.isRequired,
  top: pt.number.isRequired,
  left: pt.number.isRequired,
  defaultValue: pt.string,
};

FloatingInputCallback.defaultProps = {
  defaultValue: null,
};

export default FloatingInputCallback;

const addInputBox = (doneChangingTextCallback, eventData, defaultValue) => {
  const inputBoxContainer = document.createElement('div');
  document.body.appendChild(inputBoxContainer);

  const getTextCallback = (text) => {
    doneChangingTextCallback(text);
    document.body.removeChild(inputBoxContainer);
    forceImageUpdate(eventData.element);
  };

  const { clientX, clientY } = eventData.event;

  ReactDOM.render(
    <FloatingInputCallback
      defaultValue={defaultValue}
      left={clientX}
      top={clientY}
      getTextCallback={getTextCallback}
    />,
    inputBoxContainer
  );
};

export { addInputBox };
