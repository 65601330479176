import * as _ from 'lodash';
import { translationMessages } from '../i18n';
import { IntlShape } from 'react-intl';

const SPECIES = ['cat', 'dog', 'rabbit', 'ferret', 'rodent', 'reptile', 'bird', 'other'] as const;
export type SpeciesType = typeof SPECIES[number];

const DEFAULT_SPECIE = 'dog';

const SPECIES_ALIASES: Partial<Record<SpeciesType, string[]>> = {
  cat: ['chat', 'ct', 'feline', 'cht'],
  dog: ['cn', 'chien', 'canine', 'golden', 'epagneul', 'chizn', 'canin', 'chie'],
  rabbit: ['lapin', 'nac', 'lp'],
  ferret: ['furet'],
  rodent: ['chinchilla', 'cobaye', 'rat'],
  reptile: [],
  bird: [],
};

_.forEach(SPECIES_ALIASES, (aliases, specie) => {
  const specieTranslations = _.map(
    translationMessages,
    (translationDict) => translationDict[`patient_info.specie.${specie}`]
  );
  aliases.push(...specieTranslations);
});
Object.freeze(SPECIES_ALIASES);

const makeSpeciesOptions = (intl: IntlShape) =>
  SPECIES.map((specie) => ({
    label: intl.formatMessage({ id: `patient_info.specie.${specie}` }),
    value: specie,
  }));

const RACES: Partial<Record<SpeciesType, string[]>> = {
  dog: [
    'american_akita',
    'akita_inu',
    'american_staffordshire_terrier',
    'borzoi',
    'basenji',
    'basset',
    'beagle',
    'beauceron',
    'german_shepherd',
    'australian_shepherd',
    'belgian_shepherd',
    'swiss_white_shepherd',
    'bichon',
    'bobtail',
    'border_collie',
    'boston_terrier',
    'american_bulldog',
    'french_bulldog',
    'australian_cattle_dog',
    'bernese_mountain_dog',
    'boxer',
    'german_shorthaired_pointer',
    'weimaraner',
    'bull_terrier',
    'english_bulldog',
    'bullmastiff',
    'cairn_terrier',
    'cane_corso',
    'poodle',
    'pug',
    'cavalier_king_charles',
    'irish_water_dog',
    'portuguese_water_dog',
    'chihuahua',
    'chow_chow',
    'american_cocker_spaniel',
    'cocker_spaniel',
    'collie',
    'cotton_tulear',
    'dalmatian',
    'doberman_pinscher',
    'german_mastiff',
    'dogo_argentino',
    'dogue_de_bordeaux',
    'spaniel',
    'fox_terrier',
    'golden_retriever',
    'greyhound',
    'griffin',
    'hovawart',
    'husky',
    'jack_russell_terrier',
    'labrador',
    'leonberg',
    'lhasa_apso',
    'mastiff',
    'pinscher',
    'rottweiler',
    'saint_bernard',
    'samoyed',
    'schnauzer',
    'setter',
    'shar-pei',
    'shiba-inu',
    'shih_tzu',
    'spitz',
    'springer',
    'staffordshire_bull_terrier',
    'dachshund',
    'newfoundland',
    'welsh_corgi',
    'west_highland_white_terrier',
    'whippet',
    'yorkshire_terrier',
  ],
  cat: [
    'abyssinian',
    'american_bobtail',
    'american_curl',
    'american_shorthair',
    'turkish_angora',
    'bengal',
    'bombay',
    'british_longhair',
    'british_shorthair',
    'burmese',
    'chartreux',
    'devon_rex',
    'european',
    'exotic_shorthair',
    'maine_coon',
    'egyptian_mau',
    'munchkin',
    'norwegian',
    'persian',
    'ragdoll',
    'sacred_of_burma',
    'savannah',
    'scottish_fold',
    'siamese',
    'siberian',
    'somali',
    'sphynx',
  ],
  rabbit: [],
  ferret: [],
  rodent: [],
  reptile: [],
  bird: [],
} as const;
const DEFAULT_CAT_RACE = 'european';

const CAT_VHS_REFERENCES = [
  {
    key: 'cat.other',
    lower: 7.2,
    upper: 7.8,
    bibli: 'Litster et Buchanan, 2000',
  },
];
Object.freeze(CAT_VHS_REFERENCES);

const DOG_VHS_REFERENCES = [
  {
    key: 'dog.other',
    lower: 9.2,
    upper: 10.2,
    bibli: 'Buchanan et Bücheler, 1995',
  },
  {
    key: 'dog.beagle',
    lower: 10.1,
    upper: 10.9,
    bibli: 'Kraetschmer et al., 2008',
  },
  {
    key: 'dog.german_shepherd',
    lower: 8.9,
    upper: 10.5,
    bibli: 'Lamb et al., 2001',
  },
  {
    key: 'dog.boston_terrier',
    lower: 10.3,
    upper: 13.1,
    bibli: 'Jepsen-Grant, Pollard et Johnson, 2012',
  },
  {
    key: 'dog.boxer',
    lower: 10.8,
    upper: 12.4,
    bibli: 'Lamb et al., 2001',
  },
  {
    key: 'dog.english_bulldog',
    lower: 11,
    upper: 14.4,
    bibli: 'Jepsen-Grant, Pollard et Johnson, 2012',
  },
  {
    key: 'dog.pug',
    lower: 9.8,
    upper: 11.6,
    bibli: 'Jepsen-Grant, Pollard et Johnson, 2012',
  },
  {
    key: 'dog.cavalier_king_charles',
    lower: 10.1,
    upper: 11.1,
    bibli: 'Lamb et al., 2001',
  },
  {
    key: 'dog.greyhound',
    lower: 10.4,
    upper: 10.6,
    bibli: 'Marin et al., 2007',
  },
  {
    key: 'dog.labrador',
    lower: 10.3,
    upper: 10.5,
    bibli: 'Jepsen-Grant, Pollard et Johnson, 2012',
  },
  {
    key: 'dog.lhasa_apso',
    lower: 8.8,
    upper: 10.4,
    bibli: 'Jepsen-Grant, Pollard et Johnson, 2012',
  },
  {
    key: 'dog.rottweiler',
    lower: 9.7,
    upper: 9.9,
    bibli: 'Marin et al., 2007',
  },
  {
    key: 'dog.shih_tzu',
    lower: 8.9,
    upper: 10.1,
    bibli: 'Jepsen-Grant, Pollard et Johnson, 2012',
  },
] as const;
Object.freeze(DOG_VHS_REFERENCES);

const makeRacesOptions = (intl: IntlShape, specie: SpeciesType) =>
  specie
    ? _.map(RACES[specie], (race) => ({
        label: intl.formatMessage({ id: `patient_info.specie.${specie}.race.${race}` }),
        value: race,
      }))
    : [];

export {
  SPECIES,
  DEFAULT_SPECIE,
  SPECIES_ALIASES,
  RACES,
  DEFAULT_CAT_RACE,
  CAT_VHS_REFERENCES,
  DOG_VHS_REFERENCES,
  makeSpeciesOptions,
  makeRacesOptions,
};
