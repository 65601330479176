import * as _ from 'lodash';
import {
  AngleTool,
  LengthTool,
  EraserTool,
  WwwcTool,
  ArrowAnnotateTool,
  ScaleOverlayTool,
} from 'cornerstone-tools';

import DistractionIndexTool from 'app/CornerstoneTools/DistractionIndexTool';
import { PanTool, PanMultiTouchTool, ZoomTouchPinchTool } from 'app/CornerstoneTools';
import PicoxiaPanTool from 'app/CornerstoneTools/PanTool';
import injectSwitchableTool from '../../CornerstoneTools/injectSwitchableTool';
import ZoomMouseWheelTool from '../../CornerstoneTools/ZoomWheelTool';
import NorbergOlssonTool from '../../CornerstoneTools/NorbergOlssonTool';
import VHSTool from '../../CornerstoneTools/VHSTool';
import TextMarkerTool from '../../CornerstoneTools/TextMarkerTool';
import ImageMarkerTool from '../../CornerstoneTools/ImageMarkerTool';

import leftMarkerImageEN from '../../../static/svg_icons/left_marker_cornerstone_en.svg?url';
import leftMarkerImageFR from '../../../static/svg_icons/left_marker_cornerstone_fr.svg?url';
import rightMarkerImageEN from '../../../static/svg_icons/right_marker_cornerstone_en.svg?url';
import rightMarkerImageFR from '../../../static/svg_icons/right_marker_cornerstone_fr.svg?url';
import {
  leftMarkerENCursor,
  leftMarkerFRCursor,
  rightMarkerENCursor,
  rightMarkerFRCursor,
} from '../../CornerstoneTools/Cursors';
import { addInputBox } from '../FloatingInputCallback';
import CropTool from '../../CornerstoneTools/CropTool';
import MagnifyTool from '../../CornerstoneTools/MagnifyTool';
import CircleRadiusTool from '../../CornerstoneTools/CircleRadiusTool';
import DicomDataPrinterTool from '../../CornerstoneTools/DicomDataPrinterTool';
import TPLOTool from '../../CornerstoneTools/TPLOTool';

const getTextCallback = (doneChangingTextCallback, eventData) => {
  addInputBox(doneChangingTextCallback, eventData);
};

function changeTextCallbackForTextMarker(data, eventData, doneChangingTextCallback) {
  addInputBox((text) => doneChangingTextCallback(data, text), eventData, data.text);
}

function changeTextCallbackForArrowAnnotate(data, eventData, doneChangingTextCallback) {
  addInputBox((text) => doneChangingTextCallback(text), eventData, data.text);
}

export const INTL_MARKERS = {
  en: {
    leftMarker: {
      cursor: leftMarkerENCursor,
      source: leftMarkerImageEN,
    },
    rightMarker: {
      cursor: rightMarkerENCursor,
      source: rightMarkerImageEN,
    },
  },
  fr: {
    leftMarker: {
      cursor: leftMarkerFRCursor,
      source: leftMarkerImageFR,
    },
    rightMarker: {
      cursor: rightMarkerFRCursor,
      source: rightMarkerImageFR,
    },
  },
};

const INITIAL_COMMON_TOOLS_LIST = {
  ZoomMouseWheel: {
    tool: ZoomMouseWheelTool,
    state: 'active',
    options: {
      name: 'ZoomMouseWheel',
      // This button mask does not exist, it is here to denote
      // that multiple tools with zoomwheel share the same control method.
      mouseButtonMask: 100,
      configuration: {
        cropToolName: 'Crop',
      },
    },
  },
  FineGrainZoomMouseWheel: {
    tool: ZoomMouseWheelTool,
    state: 'disabled',
    options: {
      name: 'FineGrainZoomMouseWheel',
      mouseButtonMask: 100,
      configuration: {
        ticksMultiplier: 1 / 30,
      },
    },
  },
  ZoomTouchPinch: {
    tool: ZoomTouchPinchTool,
    state: 'active',
    options: {
      name: 'ZoomTouchPinch',
      mouseButtonMask: 0,
    },
  },
  Pan: {
    tool: PicoxiaPanTool,
    state: 'active',
    options: {
      name: 'Pan',
      mouseButtonMask: 1,
    },
  },
  PanMultiTouch: {
    tool: PanMultiTouchTool,
    state: 'active',
    options: {
      name: 'PanMultiTouch',
      mouseButtonMask: 102,
    },
  },
  PanWheel: {
    tool: PanTool,
    state: 'active',
    options: {
      name: 'PanWheel',
      mouseButtonMask: 4,
    },
  },
  Eraser: {
    tool: EraserTool,
    state: 'disabled',
    options: {
      name: 'Eraser',
      mouseButtonMask: 1,
    },
  },
  // Specific tool for enabling only Mouse right click without interfering on touch
  WWWCRightClick: {
    tool: WwwcTool,
    state: 'active',
    options: {
      name: 'WWWCRightClick',
      mouseButtonMask: 2,
      supportedInteractionTypes: ['Mouse'],
    },
  },
  WWWC: {
    tool: WwwcTool,
    state: 'disabled',
    options: {
      name: 'WWWC',
      mouseButtonMask: 1,
      isTouchActive: true,
    },
  },
};

const INITIAL_IMAGE_TOOLS_LIST = {
  ArrowAnnotate: {
    tool: ArrowAnnotateTool,
    state: 'passive',
    options: {
      name: 'ArrowAnnotate',
      mouseButtonMask: 1,
      deleteIfHandleOutsideImage: false,
      preventHandleOutsideImage: false,
      configuration: {
        allowEmptyLabel: true,
        drawHandlesOnHover: true,
        getTextCallback,
        changeTextCallback: changeTextCallbackForArrowAnnotate,
      },
    },
  },
  TextMarker: {
    tool: TextMarkerTool,
    state: 'passive',
    options: {
      name: 'TextMarker',
      mouseButtonMask: 1,
      configuration: {
        getTextCallback,
        changeTextCallback: changeTextCallbackForTextMarker,
      },
    },
  },
  Length: {
    tool: LengthTool,
    state: 'passive',
    options: {
      name: 'Length',
      mouseButtonMask: 1,
    },
  },
  CircleRadius: {
    tool: CircleRadiusTool,
    state: 'passive',
    options: {
      name: 'CircleRadius',
      mouseButtonMask: 1,
    },
  },
  Angle: {
    tool: AngleTool,
    state: 'passive',
    options: {
      name: 'Angle',
      mouseButtonMask: 1,
    },
  },
  Magnify: {
    tool: MagnifyTool,
    state: 'disabled',
    options: {
      name: 'Magnify',
      mouseButtonMask: 1,
    },
  },
  VHS: {
    tool: injectSwitchableTool(VHSTool),
    state: 'disabled',
    options: {
      name: 'VHS',
      mouseButtonMask: 0,
    },
  },
  // Putting this tool last will make it mask all the other tools.
  Crop: {
    tool: CropTool,
    state: 'passive',
    options: {
      name: 'Crop',
      mouseButtonMask: 1,
    },
  },
  ScaleOverlay: {
    tool: ScaleOverlayTool,
    state: 'active',
    options: {
      name: 'ScaleOverlay',
    },
  },
};

const THUMBNAIL_TOOLS_LIST = {
  Crop: {
    tool: CropTool,
    state: 'passive',
    options: {
      name: 'Crop',
      mouseButtonMask: 1,
    },
  },
};

// Freeze initialization data to avoid unwanted mutation.
Object.freeze(INITIAL_COMMON_TOOLS_LIST);
Object.freeze(INITIAL_IMAGE_TOOLS_LIST);
Object.freeze(THUMBNAIL_TOOLS_LIST);

const createCommonToolsList = (getIntl) => {
  const intlMarkers = getIntl().locale === 'fr' ? INTL_MARKERS.fr : INTL_MARKERS.en;

  return _.merge({}, INITIAL_COMMON_TOOLS_LIST, {
    LeftMarker: {
      tool: ImageMarkerTool,
      state: 'passive',
      options: {
        name: 'LeftMarker',
        mouseButtonMask: 1,
        configuration: {
          markerSource: intlMarkers.leftMarker.source,
        },
        svgCursor: intlMarkers.leftMarker.cursor,
      },
    },
    RightMarker: {
      tool: ImageMarkerTool,
      state: 'passive',
      options: {
        name: 'RightMarker',
        mouseButtonMask: 1,
        configuration: {
          markerSource: intlMarkers.rightMarker.source,
        },
        svgCursor: intlMarkers.rightMarker.cursor,
      },
    },
  });
};

const createImageToolsList = (getIntl) =>
  _.merge(_.cloneDeep(INITIAL_IMAGE_TOOLS_LIST), {
    TPLO: {
      tool: TPLOTool,
      state: 'passive',
      options: {
        name: 'TPLO',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    },

    DistractionIndex: {
      tool: DistractionIndexTool,
      state: 'passive',
      options: {
        name: 'DistractionIndex',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    },
    NorbergOlsson: {
      tool: injectSwitchableTool(NorbergOlssonTool),
      state: 'passive',
      options: {
        name: 'NorbergOlsson',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    },
    DicomDataPrinter: {
      tool: DicomDataPrinterTool,
      state: 'passive',
      options: {
        name: 'DicomDataPrinter',
        mouseButtonMask: 1,
        configuration: { getIntl },
      },
    },
  });

const createThumbnailToolsList = () => _.cloneDeep(THUMBNAIL_TOOLS_LIST);

export { createCommonToolsList, createImageToolsList, createThumbnailToolsList };
