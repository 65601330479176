import produce from 'immer';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import {
  SET_DETECTOR_IP_CONFIGURATION,
  SET_DETECTOR_KIND,
  DetectorKind,
  setDetectorKind,
  setDetectorIPConfiguration,
} from 'app/redux/flatPanelConfiguration/actions';
import observeStore from 'app/utils/redux/observeStore';

export const FLAT_PANEL_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.flatPanelConfiguration';

export type FlatPanelConfiguration = {
  detectorKind: DetectorKind;
  detectorIP?: string;
  hostIP?: string;
};

export type FlatPanelConfigurationAction =
  | ReturnType<typeof setDetectorKind>
  | ReturnType<typeof setDetectorIPConfiguration>;

export const selectFlatPanelConfiguration = (
  state: Immutable.Map<string, any>
): FlatPanelConfiguration => state.get('flatPanelConfiguration');

export const selectFlatPanelKind = (state: Immutable.Map<string, any>) =>
  selectFlatPanelConfiguration(state).detectorKind;

export const attachFlatPanelConfigurationWithLocalStorageSynchronizationObserver = (store: any) => {
  observeStore(
    store,
    selectFlatPanelConfiguration,
    makeSyncReduxWithLocalStorageObserver(FLAT_PANEL_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

const getInitialState = (): FlatPanelConfiguration =>
  Object.freeze(restoreFromLocalStorage(FLAT_PANEL_CONFIGURATION_LOCAL_STORAGE_KEY, {}));

const flatPanelConfigurationReducer = (
  state: FlatPanelConfiguration = getInitialState(),
  { type, payload }: FlatPanelConfigurationAction
) =>
  produce(state, (draft) => {
    switch (type) {
      case SET_DETECTOR_KIND:
        draft.detectorKind = payload.detectorKind;
        break;
      case SET_DETECTOR_IP_CONFIGURATION:
        draft.detectorIP = payload.detectorIP;
        draft.hostIP = payload.hostIP;
        break;
      default:
    }
  });

export default flatPanelConfigurationReducer;
