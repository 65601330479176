/* eslint-disable jsx-a11y/label-has-associated-control */
import AdminStoreContext from 'app/injectors/AdminStore/context';
import toastifyPromise from 'app/utils/toastifyPromise';
import React, { useCallback, useState } from 'react';
import { useContext } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, FormInput, Icon, Modal, Popup } from 'semantic-ui-react';

/**
 *
 * @param {Object} props
 * @param {AdminStore} props.adminStore
 * @param {Team} props.team
 * @param {() => void} props.onNewLicense
 * @returns
 */
export default function AddIALicenseModal({ team, onNewLicense }) {
  // Component state to set name, start date, end date and total amount.
  const intl = useIntl();
  const adminStore = useContext(AdminStoreContext);
  const [name, setName] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const promise = adminStore.addIALicensePack(team.name, name, startDate, endDate, totalAmount);
      toastifyPromise('addIALicensePack', promise);
      promise.then(() => {
        onNewLicense();
        setOpen(false);
      });
    },
    [name, startDate, endDate, totalAmount, onNewLicense, setOpen]
  );

  return (
    <Modal
      trigger={
        <Button floated="right" size="tiny" color="green">
          <Icon name="plus" />
          <FormattedMessage id="adminPage.addIALicense.button" />
        </Button>
      }
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Modal.Header>
        <FormattedMessage id="adminPage.addIALicense.title" />
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>
              <FormattedMessage id="adminPage.addIALicense.input.name" />
            </label>
            <Form.Input value={name} onChange={(e, { value }) => setName(value)} />
          </Form.Field>
          <Form.Field required>
            <label>
              <FormattedMessage id="adminPage.addIALicense.input.count" />
            </label>
            <Form.Input value={totalAmount} onChange={(e, { value }) => setTotalAmount(value)} />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>
                <FormattedMessage id="adminPage.addIALicense.input.start" />
              </label>
              <ReactDatePicker
                dateFormat="P"
                locale={intl.locale}
                selected={startDate}
                onChange={setStartDate}
                showYearDropdown
                scrollableYearDropdown
              />
            </Form.Field>
            <Form.Field required>
              <label>
                <FormattedMessage id="adminPage.addIALicense.input.end" />
              </label>
              <ReactDatePicker
                dateFormat="P"
                locale={intl.locale}
                selected={endDate}
                onChange={setEndDate}
                showYearDropdown
                scrollableYearDropdown
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} basic color="red">
          <Icon name="times" />
          <FormattedMessage id="general.cancel" />
        </Button>
        <Button type="submit" positive onClick={handleSubmit}>
          <Icon name="plus" />
          <FormattedMessage id="general.add" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
