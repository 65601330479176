import React from 'react';
import * as pt from 'prop-types';
import * as cst from 'cornerstone-tools';
import * as csc from 'cornerstone-core';
import { injectIntl } from 'react-intl';

import ToolType from '../../types/ToolType';

const VISIBLE_STATES = ['passive', 'active'];

/**
 * Generic class that display button for tools which can use precomputed annotations
 * provided by IA predictions.
 * An example is the NorbergOlsson tool.
 * Tool customization is done by specifying `content`, `title` and `drawToolDataFn` props.
 */
class PreComputedToolButton extends React.Component {
  componentDidMount() {
    this.attachListenerIfNeeded();
    if (this.canAddToolData() && !this.toolAlreadyDeleted()) {
      this.addToolData(null, false);
    }
  }

  componentDidUpdate() {
    this.attachListenerIfNeeded();
    if (this.canAddToolData() && !this.toolAlreadyDeleted()) {
      this.addToolData(null, false);
    }
  }

  attachListenerIfNeeded = () => {
    const { focusedElement } = this.props;
    if (!focusedElement) return;

    if (focusedElement.PreComputedToolButtonListener) return;

    focusedElement.PreComputedToolButtonListener = (evt) => {
      const { produceImageToolsList, name } = this.props;
      if (evt?.detail?.toolName !== name) return;
      if (cst.getToolState(focusedElement, name)?.data?.length > 0) return;
      produceImageToolsList((draftToolsList) => {
        if (VISIBLE_STATES.includes(draftToolsList[name].state)) {
          draftToolsList[name].state = 'disabled';
        }
      });
    };

    focusedElement.addEventListener(
      cst.EVENTS.MEASUREMENT_REMOVED,
      focusedElement.PreComputedToolButtonListener
    );
  };

  /**
   * Add tool data to the element using the `drawToolData` given by higher level component.
   * If the 'predictions' do not allow automatic tool draw the tool data might not be generated.
   * @param {*} newState  New state the tool will be in. Use null if no state change is required
   * @param {*} force     Force tool draw even if predictions are insufficient.
   */
  addToolData = (newState, force) => {
    const { name, focusedElement, predictions, drawToolDataFn } = this.props;

    if (newState) this.setToolState(newState);
    if (!this.canAddToolData()) return;
    // Draw tool from provided `drawToolData` function
    const drawnTool = drawToolDataFn(name, focusedElement, predictions, force);
    if (drawnTool) {
      // We remove possibly leftover data
      const toolData = cst.getToolState(focusedElement, name)?.data;
      for (let i = toolData?.length ?? -1; i >= 0; i -= 1) {
        const measurementData = toolData[i];
        if (measurementData !== drawnTool) {
          cst.removeToolState(focusedElement, name, measurementData);
        }
      }
      // Hacky forceUpdate put here to force rerender else eye icon is not displayed.
      // This is because tool measurements are not directly visible from props.
      this.forceUpdate();
    }
  };

  setToolState = (newState) => {
    const { toolsList, name, produceImageToolsList } = this.props;
    const { state } = toolsList[name];

    if (state !== newState) {
      produceImageToolsList((draftToolsList) => {
        if (draftToolsList[name].state !== newState) {
          draftToolsList[name].state = newState;
        }
      });
    }
  };

  toolAlreadyDeleted = () => {
    const { name, focusedElement } = this.props;

    if (!focusedElement) return false;
    try {
      if (csc.getEnabledElement(focusedElement).image === undefined) return false;
    } catch {
      return false;
    }
    const toolData = cst.getToolState(focusedElement, name);
    return toolData && toolData.data.length === 0;
  };

  canAddToolData = () => {
    const { name, focusedElement } = this.props;

    if (!focusedElement) return false;
    try {
      if (csc.getEnabledElement(focusedElement).image === undefined) return false;
    } catch {
      return false;
    }
    const toolData = cst.getToolState(focusedElement, name);

    // No need to add data is there is already one or if it is marked as needing refresh.
    if (toolData?.data?.length > 0 && !toolData?.data?.[0]?.mustRefresh) return false;
    return true;
  };

  switchToolState = () => {
    const { name, produceImageToolsList, setToolStateFn } = this.props;
    const newState = this.isActive() ? 'disabled' : 'passive';

    setToolStateFn?.(newState);

    produceImageToolsList((draftToolsList) => {
      if (VISIBLE_STATES.includes(draftToolsList[name].state)) {
        draftToolsList[name].state = 'disabled';
      } else {
        draftToolsList[name].state = 'passive';
      }
    });
  };

  addDataOrSwitchState = () => {
    if (this.canAddToolData()) {
      this.addToolData('passive', true);
      return;
    }
    this.switchToolState();
  };

  isActive = () => {
    const { focusedElement, name, toolsList } = this.props;
    if (!focusedElement) return null;
    const toolData = cst.getToolState(focusedElement, name);
    if (!toolData || toolData.data === 0) return null;

    return VISIBLE_STATES.includes(toolsList[name].state);
  };

  render = () => {
    const { content, intl, tooltipId } = this.props;
    const activeClass = this.isActive() ? 'active' : '';
    return (
      <button
        type="button"
        className={`picoxia tool-button ${activeClass}`}
        onPointerDown={this.addDataOrSwitchState}
        title={intl.formatMessage({ id: tooltipId })}
      >
        {content}
      </button>
    );
  };
}

PreComputedToolButton.propTypes = {
  toolsList: pt.objectOf(ToolType).isRequired,
  name: pt.string.isRequired,
  content: pt.oneOfType([pt.string, pt.shape()]).isRequired,
  intl: pt.shape().isRequired,
  tooltipId: pt.string.isRequired,
  produceImageToolsList: pt.func.isRequired,
  drawToolDataFn: pt.func.isRequired,
  setToolStateFn: pt.func,
  focusedElement: pt.instanceOf(HTMLElement),
  predictions: pt.shape({
    type: pt.string,
    norberg_olsson: pt.shape(),
  }),
};

PreComputedToolButton.defaultProps = {
  focusedElement: null,
  predictions: {},
  setToolStateFn: undefined,
};

export default injectIntl(PreComputedToolButton);
