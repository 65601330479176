// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business
import { getAsyncInjectors, checkAuth } from 'utils/asyncInjectors';
import React from 'react';

// Imports of the components
import App from './containers/App';
import MaintenancePage from './containers/MaintenancePage';
import DownloadPage from './containers/DownloadPage';
import PartnerLinkPage from './containers/PartnerLinkPage';
import ManagementPage from './containers/ManagementPage';
import HomePage from './containers/HomePage';
import OnlinePage from './containers/OnlinePage';
import CheatSheetPage from './containers/CheatSheetPage';
import AccountPage from './containers/AccountPage';
import MonitoringPage from './containers/MonitoringPage';
import ChangePasswordPage from './containers/ChangePasswordPage';
import UnsubscribePage from './containers/UnsubscribePage';
import ConfirmMailPage from './containers/ConfirmMailPage';
import LoginPage from './containers/LoginPage';
import BillingPage from './containers/BillingPage';
import PaymentSuccessPage from './containers/PaymentSuccessPage';
import PaymentCanceledPage from './containers/PaymentCanceledPage';
import AcquisitionPage from './containers/AcquisitionPage';
import TutorialsPage from './containers/TutorialsPage';
import TestDysplasiaPatternStructuredList from './components/DysplasiaPatternStructuredList/TestDysplasiaPatternStructuredList';
import TestDysplasiaPattern from './components/DysplasiaPatternStructuredList/TestDysplasiaPattern';
import TestPatientInfoForm from './components/PatientInfoForm/TestPatientInfoForm';
import RequestDemoPage from './containers/RequestDemoPage';
import PicoxIAIntelligencePage from './containers/PicoxIAIntelligencePage';
import PicoxIAAcquisitionPage from './containers/PicoxIAAcquisitionPage';
import isAcquisitionSoftware from './utils/isAcquisitionSoftware';
import AdminPage from './containers/AdminPage';
import PrivacyPolicyPage from './containers/PrivacyPolicyPage';
import LegalNoticesPage from './containers/LegalNoticesPage';
import { useState } from 'react';
import FakeAdminStore from 'app/adapters/FakeAdminStore';
import TermsOfSalesPage from './containers/TermsOfSalesPage';
import TermsOfUsePage from './containers/TermsOfUsePage';

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

const DEFAULT_PATH_FOR_DESKTOP = isAcquisitionSoftware() ? 'acquisition' : 'online';

export default function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  // const maintenance = [
  //   {
  //     path: '*',
  //     name: 'maintenancePage',
  //     component: MaintenancePage,
  //   },
  // ];
  const electronSpecific = [
    {
      path: '*',
      onEnter: (nextState, replace) =>
        replace({
          ...nextState.location,
          pathname: `/${nextState.params.lang}/${DEFAULT_PATH_FOR_DESKTOP}`,
        }),
    },
  ];

  const websiteSpecific = [
    {
      path: 'download',
      name: 'downloadPage',
      component: DownloadPage,
    },
    {
      path: 'picoxia-intelligence',
      name: 'picoxIAIntelligence',
      component: PicoxIAIntelligencePage,
    },
    {
      path: 'picoxia-acquisition',
      name: 'picoxIAAcquisition',
      component: PicoxIAAcquisitionPage,
    },
    {
      path: 'tutorials',
      name: 'tutorialsPage',
      component: TutorialsPage,
    },
    {
      path: 'request-demo',
      name: 'requestDemoPage',
      component: RequestDemoPage,
    },
    {
      path: 'link',
      name: 'partnerLinkPage',
      component: PartnerLinkPage,
    },
    {
      path: 'management',
      name: 'ManagementPage',
      component: ManagementPage,
    },
    {
      path: 'admin',
      name: 'AdminPage',
      component: AdminPage,
    },
    {
      name: 'homePage',
      // onEnter: (nextState, replace) => checkAuth(store, nextState, replace),
      component: HomePage,
    },
    {
      path: '*',
      onEnter: (nextState, replace) =>
        replace({ ...nextState.location, pathname: `/${nextState.params.lang}/` }),
    },
  ];

  const common = [
    {
      path: 'online',
      name: 'onlinePage',
      component: OnlinePage,
    },
    {
      path: 'cheat_sheet',
      name: 'CheatSheetPage',
      component: CheatSheetPage,
    },
    {
      path: 'account',
      name: 'accountPage',
      onEnter: (nextState, replace) => checkAuth(store, nextState, replace),
      component: AccountPage,
    },
    {
      path: 'monitoring',
      name: 'monitoringPage',
      onEnter: (nextState, replace) => checkAuth(store, nextState, replace),
      component: MonitoringPage,
    },
    {
      path: 'change_password',
      name: 'changePasswordPage',
      component: ChangePasswordPage,
    },
    {
      path: 'unsubscribe_mail',
      name: 'UnsubscribePage',
      component: UnsubscribePage,
    },
    {
      path: 'confirm_email',
      name: 'confirmMailPage',
      component: ConfirmMailPage,
    },
    {
      path: 'login',
      name: 'loginPage',
      component: LoginPage,
    },
    {
      path: 'purchase',
      name: 'billingPage',
      component: BillingPage,
    },
    {
      path: 'purchase_success',
      name: 'paymentSuccess',
      component: PaymentSuccessPage,
    },
    {
      path: 'purchase_canceled',
      name: 'paymentCanceledPage',
      component: PaymentCanceledPage,
    },
    {
      path: 'register',
      name: 'registerPage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import('containers/RegisterPage/reducer'),
          import('containers/RegisterPage'),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer('registerPage', reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: 'acquisition',
      name: 'AcquisitionPage',
      component: AcquisitionPage,
    },
    {
      path: 'privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: 'legal-notices',
      name: 'LegalNoticesPage',
      component: LegalNoticesPage,
    },
    {
      path: 'terms-of-sales',
      name: 'TermsOfSalesPage',
      component: TermsOfSalesPage,
    },
    {
      path: 'terms-of-use',
      name: 'TermsOfUsePage',
      component: TermsOfUsePage,
    },
  ];

  const testRoutes = [
    {
      path: 'test_dysplasia_pattern_structured_list',
      name: 'TestDysplasiaPatternStructuredList',
      component: TestDysplasiaPatternStructuredList,
    },
    {
      path: 'test_dysplasia_pattern',
      name: 'TestDysplasiaPattern',
      component: TestDysplasiaPattern,
    },
    {
      path: 'test_patient_info_form',
      name: 'TestPatientInfoForm',
      component: TestPatientInfoForm,
    },
  ];

  let childRoutes = common;
  if (process.env.NODE_ENV === 'development') {
    childRoutes = childRoutes.concat(testRoutes);
  }
  if (process.env.PLATFORM === 'electron') {
    childRoutes = childRoutes.concat(electronSpecific);
  } else {
    childRoutes = childRoutes.concat(websiteSpecific);
  }

  let componentToFirstLoad;
  if (process.env.PLATFORM === 'electron') {
    if (isAcquisitionSoftware()) {
      componentToFirstLoad = AcquisitionPage;
    } else {
      componentToFirstLoad = OnlinePage;
    }
  } else {
    componentToFirstLoad = HomePage;
  }

  // Wrapp all Routes in the App component
  const rootRoute = {
    component: App,
    indexRoute: { component: componentToFirstLoad },
    path: '/:lang',
    childRoutes,
  };

  return rootRoute;
}
