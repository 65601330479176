/* eslint-disable no-underscore-dangle */
import 'semantic-ui-css/semantic.min.css';

import 'app/styles/style.scss';
import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import produce from 'immer';
import { pull, reduce, join } from 'lodash';

import NorbergOlssonButton from './NorbergOlssonButton';
import ToolType from '../../types/ToolType';
import VHSButton from './VHSButton';
import { makeActivatableToolButton } from './ActivatableToolButton';
import VerticalFlipButton from './VerticalFlipButton';
import HorizontalFlipButton from './HorizontalFlipButton';
import { makeFixedAngleRotateButton } from './FixedAngleRotateButton';
import NegativeColorButton from './NegativeColorButton';
import FullScreenButton from './FullScreenButton';
import ZoomWithPercentButton from './ZoomWithPercentButton';
import WWWCGroup from './WWWCGroup';
import CropToolButton from './CropToolButton';
import { checkOverflowX } from '../../utils/windowUtils';
import ToolsOverflowDropdown from './ToolsOverflowDropdown';
import ProcessingListButton from './ProcessingListButton';
import PicoxiaAnalysisButton from './PicoxiaAnalysisButton';
import DIButton from 'app/components/ToolsBar/DIButton';
import { getDefaultAnatomicRegion } from 'app/utils/xrayRegions';
import TPLOButton from 'app/components/ToolsBar/TPLOButton';
import FullSizeConfigurationButton from 'app/components/ToolsBar/FullSizeConfigurationButton';

const PRECOMPUTED_TOOLS = ['VHS', 'NorbergOlsson'];
Object.freeze(PRECOMPUTED_TOOLS);

const ContentForwarder = ({ content }) => content;

const STATELESS_TOOLS_BUTTON_MAP = {
  // Below are buttons that apply their behavior on click and do not activate a specific tool.
  NegativeColor: NegativeColorButton,
  FullScreen: FullScreenButton,
  FullSizeConfiguration: ContentForwarder,
  Processing: ProcessingListButton,
  PicoxiaAnalysis: PicoxiaAnalysisButton,
  VerticalFlip: VerticalFlipButton,
  HorizontalFlip: HorizontalFlipButton,
  ClockwiseRotation: makeFixedAngleRotateButton(
    90,
    <i className="svg-icon clockwise-rotation" />,
    'tools.rotate_right.tooltip'
  ),
  CounterClockwiseRotation: makeFixedAngleRotateButton(
    -90,
    <i className="svg-icon counter-clockwise-rotation" />,
    'tools.rotate_left.tooltip'
  ),
};
Object.freeze(STATELESS_TOOLS_BUTTON_MAP);

const STATEFUL_TOOLS_BUTTON_MAP = {
  // Image manipulation tool
  Pan: makeActivatableToolButton(<i className="svg-icon pan" />, 'tools.pan.tooltip'),
  Crop: CropToolButton,
  Magnify: makeActivatableToolButton(<i className="svg-icon magnify" />, 'tools.magnify.tooltip'),
  ZoomMouseWheel: ZoomWithPercentButton,
  WWWC: WWWCGroup,
  Eraser: makeActivatableToolButton(<i className="svg-icon eraser" />, 'tools.erase.tooltip'),

  // Annotations tools
  LeftMarker: makeActivatableToolButton(
    <i className="svg-icon left-marker" />,
    'tools.left_marker.tooltip'
  ),
  RightMarker: makeActivatableToolButton(
    <i className="svg-icon right-marker" />,
    'tools.right_marker.tooltip'
  ),
  TextMarker: makeActivatableToolButton(
    <i className="svg-icon text-marker" />,
    'tools.text_annotate.tooltip'
  ),
  ArrowAnnotate: makeActivatableToolButton(
    <i className="svg-icon arrow-annotate" />,
    'tools.arrow_annotate.tooltip'
  ),

  // Measurement tools
  Length: makeActivatableToolButton(<i className="svg-icon length" />, 'tools.length.tooltip'),
  CircleRadius: makeActivatableToolButton(
    <i className="svg-icon circle-radius" />,
    'tools.circle_radius.tooltip'
  ),
  Angle: makeActivatableToolButton(<i className="svg-icon angle" />, 'tools.angle.tooltip'),
  TPLO: TPLOButton,
  DistractionIndex: DIButton,
  NorbergOlsson: NorbergOlssonButton,
  VHS: VHSButton,
};
Object.freeze(STATEFUL_TOOLS_BUTTON_MAP);

const insertDividerBetweenTools = (toolsListsButtons) =>
  reduce(
    toolsListsButtons,
    (result, nextToolsList) => {
      const newResult = [...(result || [])];
      if (result) {
        const dividerKey = `${result[result.length - 1].key}_divider`;
        newResult.push(<div key={dividerKey} className="tools-divider" />);
      }
      newResult.push(nextToolsList);
      return newResult;
    },
    null
  );

class ToolsBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapseToolsBar: false,
      overflowingComponents: [],
    };
    // eslint-disable-next-line no-underscore-dangle
    this.componentsRefs = new Map();
    this.lastToolsBarWidth = undefined;
  }

  componentDidMount() {
    this.checkOverflowAndAdjustTools();
  }

  componentDidUpdate(prevProps) {
    const { focusedElement } = this.props;
    if (prevProps.focusedElement !== focusedElement) {
      this.resetOverFlowingMap();
      return;
    }

    this.checkOverflowAndAdjustTools();
  }

  setToolsBarRef = (el) => {
    if (!el) return;
    this.toolsBarRef = el;
    if (!el.ToolsBarResizeObserver) {
      // eslint-disable-next-line no-param-reassign
      el.ToolsBarResizeObserver = new ResizeObserver(this.handleResize);
      el.ToolsBarResizeObserver.observe(el);
    }
  };

  setComponentRef = (toolName, el) => {
    // This is a pain but we use Map here to preserve insertion order
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
    this.componentsRefs.set(toolName, el);
  };

  handleResize = () => this.checkOverflowAndAdjustTools();

  checkOverflowAndAdjustTools = () => {
    if (!this.toolsBarRef) return;

    const { x: toolsBarOffsetX, width: toolsBarWidth } = this.toolsBarRef.getBoundingClientRect();
    const isToolsBarGrowing = Math.round(toolsBarWidth) > Math.round(this.lastToolsBarWidth);
    this.lastToolsBarWidth = Math.round(toolsBarWidth);

    if (isToolsBarGrowing) {
      this.resetOverFlowingMap();
      return;
    }

    // From here toolsbar is either smaller than before or still has the same size
    const toolsBarEndX = Math.round(toolsBarOffsetX) + Math.round(toolsBarWidth);

    const isOverflow = checkOverflowX(this.toolsBarRef);
    if (isOverflow) {
      this.setState(
        produce((draftState) => {
          const { overflowingComponents } = draftState;

          let lastTool;
          this.componentsRefs.forEach((componentRef, toolName) => {
            if (!componentRef) return;
            const { x: toolOffsetX, width } = componentRef.getBoundingClientRect();
            const toolEndX = toolOffsetX + width;
            if (toolEndX >= toolsBarEndX) {
              overflowingComponents.push(toolName);
            }
            lastTool = toolName;
          });

          if (!overflowingComponents.includes(lastTool)) overflowingComponents.push(lastTool);
        })
      );
    }
  };

  resetOverFlowingMap = () => this.setState({ overflowingComponents: [] });

  collapseTools = () => this.setState((state) => ({ collapseToolsBar: !state.collapseToolsBar }));

  getOverflowingTools = () => {
    const { overflowingComponents } = this.state;
    return pull(
      overflowingComponents.map((toolName) => this.getToolButton(toolName)),
      undefined
    );
  };

  getToolButton = (toolName) => {
    const {
      study,
      toolsList,
      focusedElement,
      predictions,
      produceCommonToolsList,
      produceImageToolsList,
      toolsProps,
      anatomicRegion,
    } = this.props;

    let ToolButton = STATEFUL_TOOLS_BUTTON_MAP[toolName];
    if (ToolButton) {
      const isInToolsList = toolsList[toolName] !== undefined;
      if (!isInToolsList) ToolButton = undefined;
    } else {
      ToolButton = STATELESS_TOOLS_BUTTON_MAP[toolName];
    }
    if (!ToolButton) return undefined;
    return (
      <ToolButton
        name={toolName}
        toolsList={toolsList}
        focusedElement={focusedElement}
        predictions={predictions}
        produceCommonToolsList={produceCommonToolsList}
        produceImageToolsList={produceImageToolsList}
        study={study}
        anatomicRegion={anatomicRegion}
        {...toolsProps[toolName]}
      />
    );
  };

  getFilteredToolsList = (displayedToolsList) => {
    const { overflowingComponents } = this.state;

    return reduce(
      displayedToolsList,
      (result, toolName) => {
        if (overflowingComponents.includes(toolName)) return result;

        const toolButton = this.getToolButton(toolName) || {};
        if (!toolButton) return result;

        return [
          ...(result || []),
          <div ref={(el) => this.setComponentRef(toolName, el)} key={toolName}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {toolButton}
          </div>,
        ];
      },
      null
    );
  };

  // eslint-disable-next-line no-unused-vars
  getComponentsToolsList = () => {
    const { displayedToolsLists } = this.props;
    return reduce(
      displayedToolsLists,
      (result, displayedToolsList) => {
        const buttonsList = this.getFilteredToolsList(displayedToolsList);
        if (!buttonsList) return result;

        const buttonsListKey = join(displayedToolsList, '_');
        return [
          ...(result || []),
          <div key={buttonsListKey} className="tools-group">
            {buttonsList}
          </div>,
        ];
      },
      null
    );
  };

  render() {
    const { className, toolsList, focusedElement, disabled } = this.props;
    const toolsListsButtons = this.getComponentsToolsList(toolsList, focusedElement);

    if (!toolsListsButtons) return null;
    const dividedToolsListsButtons = insertDividerBetweenTools(toolsListsButtons);
    const overflowingTools = this.getOverflowingTools();
    let toolBarClassName = `tools-bar ${className}`;
    if (disabled) toolBarClassName += 'tools-bar__disabled';
    return (
      <div className={toolBarClassName} ref={this.setToolsBarRef}>
        {dividedToolsListsButtons}
        <div
          style={{ display: overflowingTools.length > 0 ? undefined : 'none', maxHeight: '10px' }}
        >
          <ToolsOverflowDropdown tools={overflowingTools} />
        </div>
      </div>
    );
  }
}

ToolsBar.propTypes = {
  study: pt.shape({}),
  toolsList: pt.objectOf(ToolType).isRequired,
  produceCommonToolsList: pt.func.isRequired,
  produceImageToolsList: pt.func.isRequired,
  displayedToolsLists: pt.arrayOf(pt.arrayOf(pt.string)).isRequired,
  toolsProps: pt.objectOf(pt.objectOf(pt.any)),
  focusedElement: pt.instanceOf(HTMLElement),
  predictions: pt.shape(),
  anatomicRegion: pt.string,
  className: pt.string,
  disabled: pt.bool,
};

ToolsBar.defaultProps = {
  study: {},
  focusedElement: null,
  predictions: {},
  toolsProps: {},
  className: '',
  disabled: false,
  anatomicRegion: getDefaultAnatomicRegion(),
};

export default ToolsBar;

export { STATELESS_TOOLS_BUTTON_MAP, STATEFUL_TOOLS_BUTTON_MAP, PRECOMPUTED_TOOLS };
