/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const withContext = (Element, Context, contextKey = 'context') =>
  React.forwardRef((props, ref) => (
    <Context.Consumer>
      {(context) => <Element {...{ [contextKey]: context }} {...props} ref={ref} />}
    </Context.Consumer>
  ));

export default withContext;
