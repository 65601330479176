import React from 'react';
import ActivatableToolButton from 'app/components/ToolsBar/ActivatableToolButton';

export default (props) => {
  const { anatomicRegion, produceImageToolsList } = props;
  if (!['dog abdomen Bassin face', 'dog entire_body', undefined].includes(anatomicRegion)) {
    return null;
  }

  const disableNorbergOlssonOnActivation = (newState) => {
    if (newState !== 'active') return;
    produceImageToolsList((draftToolsList) => {
      if (draftToolsList.NorbergOlsson === undefined) return;
      draftToolsList.NorbergOlsson.state = 'disabled';
    });
  };

  return (
    <ActivatableToolButton
      icon="distraction-index"
      tooltipId="tools.distraction_index.tooltip"
      disabledState="disabled"
      setToolStateFn={disableNorbergOlssonOnActivation}
      {...props}
    />
  );
};
