import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button, Popup } from 'semantic-ui-react';

import FlatPanelShortStatus from '../FlatPanelShortStatus';
import { FlatPanelStateContext } from '../FlatPanelStateProvider';
import { DETECTOR_ACQUISITION_STATE, DETECTOR_EVENTS, DETECTOR_STATE } from '../../types/xray';
import withContext from '../../utils/withContext';
import { isDev, isStaging } from '../../utils/envUtil';

class FlatPanelList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.registerNewImageListener();
  }

  componentDidMount() {
    const { context } = this.props;
    const { selectedDetectorIndex, wakeUpDetector } = context;

    wakeUpDetector(selectedDetectorIndex);
  }

  componentDidUpdate() {
    const { context } = this.props;
    const { selectedDetectorIndex, wakeUpDetector } = context;
    const { detector } = this.getCurrentDetector();

    wakeUpDetector(selectedDetectorIndex);
    if (detector !== this.listenedDetector) this.registerNewImageListener();
  }

  componentWillUnmount = () => {
    this.listenedDetector?.removeListener(DETECTOR_EVENTS.NEW_IMAGE, this.onNewImage);
    this.listenedDetector?.removeListener(DETECTOR_EVENTS.RETRANSFER_IMAGE, this.onNewImage);
  };

  registerNewImageListener = () => {
    const { detector } = this.getCurrentDetector();
    try {
      this.listenedDetector?.removeListener(DETECTOR_EVENTS.NEW_IMAGE, this.onNewImage);
      this.listenedDetector?.removeListener(DETECTOR_EVENTS.RETRANSFER_IMAGE, this.onNewImage);
    } catch (error) {
      console.warn('remove listener failed with', error);
    }
    this.listenedDetector = undefined;
    if (!detector) return;

    this.listenedDetector = detector;
    detector.on(DETECTOR_EVENTS.NEW_IMAGE, this.onNewImage);
    detector.on(DETECTOR_EVENTS.RETRANSFER_IMAGE, this.onNewImage);
  };

  onNewImage = (evt) =>
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onNewImage({ detectorID: this.props.context.selectedDetectorIndex, ...evt });

  getCurrentDetector = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const { detectors, detectorsStates, selectedDetectorIndex } = this.props.context;
    return {
      detector: detectors?.[selectedDetectorIndex],
      detectorState: detectorsStates?.[selectedDetectorIndex],
    };
  };

  forceAcquisition = () => this.getCurrentDetector().detector?.forceAcquire();

  getLastImageData = () => this.getCurrentDetector()?.detector?.getLastImageData();

  hasLastImageData = () => this.getCurrentDetector()?.detector?.hasLastImageData();

  render() {
    const { context } = this.props;
    const { detectors, detectorManager } = context;

    if (!detectorManager) return null;

    const { detectorState } = this.getCurrentDetector();
    const acquisitionStateString = DETECTOR_ACQUISITION_STATE.toString(
      detectorState?.acquisitionState
    );
    return (
      <div className="flat-panel-list">
        <h2>
          <FormattedMessage id="flat_panel" />
        </h2>
        <div className="detector-status">
          <div className={`acquisition-state ${acquisitionStateString}`}>
            <FormattedMessage id={`flat_panel.acquisition_state.${acquisitionStateString}`} />
          </div>
          {window?.nodeModules?.electron?.remote?.process?.env?.ENABLE_ACQUISITION_BUTTON ||
          isDev() ||
          isStaging() ? (
            <Button
              className="picoxia button"
              type="button"
              disabled={detectorState?.state !== DETECTOR_STATE.Ready}
              onClick={this.forceAcquisition}
            >
              Force Acquisition
            </Button>
          ) : null}
        </div>

        <div className="detectors-list">
          {_.map(detectors, (_detector, detectorId) => {
            console.log(detectorId, _detector);
            return <FlatPanelShortStatus key={detectorId} detectorId={detectorId} />;
          })}
        </div>
        {this.hasLastImageData() ? (
          <Popup
            trigger={
              <Button
                color="grey"
                basic
                size="mini"
                className="picoxia button last-image-data"
                icon="code"
                type="button"
                disabled={detectorState?.state === DETECTOR_STATE.Unknown}
                onClick={this.getLastImageData}
              />
            }
            content={<FormattedMessage id="flat_panel.save_last_image_debug.tooltip" />}
          />
        ) : null}
      </div>
    );
  }
}

FlatPanelList.propTypes = {
  context: pt.shape().isRequired,
  onNewImage: pt.func.isRequired,
};

export default withContext(FlatPanelList, FlatPanelStateContext);
