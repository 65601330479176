import _ from 'lodash';
import { DEFAULT_SPECIE } from './speciesConstants';

const XRAY_REGIONS = {
  thorax: [],
  abdomen: ['abdomen', 'Bassin'],
  crane: [],
  rachis: ['Cervical', 'Thoraco-Lombaire'],
  membre_pelvien_gauche: ['Hanche-Fémur', 'Grasset', 'Tibia', 'Tarse-Doigts'],
  membre_pelvien_droit: ['Hanche-Fémur', 'Grasset', 'Tibia', 'Tarse-Doigts'],
  membre_thoracique_gauche: ['Epaule', 'Coude', 'Radius-Ulna', 'Carpe-Doigts'],
  membre_thoracique_droit: ['Epaule', 'Coude', 'Radius-Ulna', 'Carpe-Doigts'],
  entire_body: [],
};
Object.freeze(XRAY_REGIONS);

const VIEWS = ['face', 'profil'];

const DEFAULT_ANATOMIC_REGION = 'entire_body';

export const SAME_THICKNESS_AREAS = [
  ...['Hanche-Fémur', 'Grasset', 'Tibia', 'Tarse-Doigts'].map((subArea) => [
    ['membre_pelvien_gauche', subArea],
    ['membre_pelvien_droit', subArea],
  ]),
  ...['Epaule', 'Coude', 'Radius-Ulna', 'Carpe-Doigts'].map((subArea) => [
    ['membre_thoracique_gauche', subArea],
    ['membre_thoracique_droit', subArea],
  ]),
];

const THORAX_REGIONS = ['thorax'];
const ABDOMEN_REGIONS = ['abdomen'];

const BONES_REGIONS = [
  'crane',
  'rachis',
  'membre_pelvien_gauche',
  'membre_pelvien_droit',
  'membre_thoracique_gauche',
  'membre_thoracique_droit',
];

const LEFT_ORIENTATION_REGION = ['membre_pelvien_gauche', 'membre_thoracique_gauche'];
const RIGHT_ORIENTATION_REGION = ['membre_pelvien_droit', 'membre_thoracique_droit'];

/**
 * Decompose the anatomic region string and return it into object format.
 * @param {string} anatomicRegionString A string that represent the anatomical region being considered, it can have 2 forms:
 * - "{specie} {mainRegion} {subRegion} {view}"
 * - "{specie} {mainRegion} {view}"
 * @returns {{ specie:string, mainRegion:string, subRegion:string, view:string }} AnatomicRegion as an object
 */
const getAnatomicRegionFromString = (anatomicRegionString) => {
  if (!anatomicRegionString) {
    return { specie: DEFAULT_SPECIE, mainRegion: DEFAULT_ANATOMIC_REGION };
  }
  const [specie, mainRegion, ...anatomicRegionDetails] = anatomicRegionString.split(' ');
  const [view, subRegion] = anatomicRegionDetails.reverse();
  let orientation;
  if (LEFT_ORIENTATION_REGION.includes(mainRegion)) orientation = 'left';
  if (RIGHT_ORIENTATION_REGION.includes(mainRegion)) orientation = 'right';

  return { specie, mainRegion, subRegion, view, orientation };
};

const getProcessingFromAnatomicRegion = (anatomicRegion) => {
  const { specie, mainRegion, subRegion } = getAnatomicRegionFromString(anatomicRegion);
  const speciePrefix = specie === 'dog' ? 'dog' : 'cat';
  if (subRegion === 'Bassin') return 'bones';
  if (subRegion === 'abdomen') return `${speciePrefix}_abdomen`;
  if (mainRegion === 'thorax') return `${speciePrefix}_thorax`;
  if (mainRegion === 'crane' && specie === 'dog') return `dog_skull`;
  if (BONES_REGIONS.includes(mainRegion)) return `bones`;

  return 'default';
};

const getDefaultAnatomicRegion = (specie = DEFAULT_SPECIE) =>
  `${specie} ${DEFAULT_ANATOMIC_REGION}`;

export const isSameAnatomicThickness = (anatomicRegionString1, anatomicRegionString2) => {
  const anatomicRegion1 = _.omit(getAnatomicRegionFromString(anatomicRegionString1), [
    'orientation',
  ]);
  const anatomicRegion2 = _.omit(getAnatomicRegionFromString(anatomicRegionString2), [
    'orientation',
  ]);

  SAME_THICKNESS_AREAS.forEach((areas) => {
    const mainArea = areas[0];
    areas.forEach(([mainRegion, subRegion]) => {
      [anatomicRegion1, anatomicRegion2].forEach((anatomicRegion) => {
        if (anatomicRegion.mainRegion === mainRegion && anatomicRegion.subRegion === subRegion) {
          anatomicRegion.mainRegion = mainArea[0];
          anatomicRegion.subRegion = mainArea[1];
        }
      });
    });
  });

  return _.isEqual(anatomicRegion1, anatomicRegion2);
};

export {
  XRAY_REGIONS,
  VIEWS,
  DEFAULT_ANATOMIC_REGION,
  THORAX_REGIONS,
  BONES_REGIONS,
  ABDOMEN_REGIONS,
  getProcessingFromAnatomicRegion,
  getAnatomicRegionFromString,
  getDefaultAnatomicRegion,
};
