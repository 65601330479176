import React, { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPMSExportConfiguration, selectPMSType } from 'app/redux/reducers';
import PmsExporter, { PMSExportFn } from 'app/pms/exporter/PmsExporter';

export const PMSExportContext = React.createContext<PMSExportFn>(undefined);

type PMSExportProviderProps = {
  pmsExportFunctions: { [key: string]: PmsExporter['exportDataToPMS'] };
};

const PMSExportProvider = ({
  children,
  pmsExportFunctions,
}: PropsWithChildren<PMSExportProviderProps>) => {
  const pmsType = useSelector(selectPMSType);
  const pmsExportConfiguration = useSelector(selectPMSExportConfiguration);
  const pmsExportConfigurationJS = useMemo(
    () => pmsExportConfiguration?.toJS?.(),
    [pmsExportConfiguration]
  );
  const isPMSEnabled = !!(pmsType && pmsExportConfigurationJS);

  const exportToPMSFn = useMemo(() => {
    if (!isPMSEnabled) return undefined;

    const executeExport: PMSExportFn = async (studyInfo, patientInfo, images) =>
      pmsExportFunctions[pmsType]?.(pmsExportConfigurationJS, studyInfo, patientInfo, images);
    return executeExport;
  }, [pmsExportConfigurationJS, isPMSEnabled]);

  return <PMSExportContext.Provider value={exportToPMSFn}>{children}</PMSExportContext.Provider>;
};

export default PMSExportProvider;
