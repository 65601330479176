import React from 'react';
import * as pt from 'prop-types';
import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import { forceImageUpdate } from '../../CornerstoneTools/forceImageUpdate';
import OnClickToolButton from './OnClickToolButton';
import { focusHandles } from '../../CornerstoneTools/CropTool';

class HorizontalFlipButton extends React.PureComponent {
  doHorizontalFlip = () => {
    const { focusedElement } = this.props;
    if (!focusedElement) return;
    try {
      const { viewport } = csc.getEnabledElement(focusedElement);
      const isRotated = viewport.rotation % 180 === 90;
      if (!isRotated) {
        viewport.hflip = !viewport.hflip;
      } else {
        viewport.vflip = !viewport.vflip;
      }

      const cropToolData = cst.getToolState(focusedElement, 'Crop');
      if (cropToolData && cropToolData.data.length > 0) {
        focusHandles(focusedElement, cropToolData.data[0].handles);
      }
      forceImageUpdate(focusedElement);
    } catch (error) {
      console.log('Error in HorizontalFlipButton', error);
    }
  };

  render = () => (
    <OnClickToolButton
      onClick={this.doHorizontalFlip}
      tooltipId="tools.horizontal_flip.tooltip"
      content={<i className="svg-icon horizontal-flip" />}
    />
  );
}

HorizontalFlipButton.propTypes = {
  focusedElement: pt.instanceOf(HTMLElement),
};

HorizontalFlipButton.defaultProps = {
  focusedElement: null,
};

export default HorizontalFlipButton;
