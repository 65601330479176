import * as _ from 'lodash';

/**
 * Combine memoize and debounce to debounce based on input parameters.
 * This replace the need to create a debounce function for each hyper parameter.
 *
 * @param {*} func Function to debounce.
 * @param {*} wait Timeout before debounced function is called
 * @param {*} options _.debounce and _.memoize options combined (use resolver to have fine
 * grained resolution)
 * @returns Debounced function.
 */
const memoizeDebounce = (func, wait = 0, options = {}) => {
  const mem = _.memoize(() => _.debounce(func, wait, options), options.resolver);
  return (...args) => mem(...args)(...args);
};

_.mixin({ memoizeDebounce });

export { memoizeDebounce };
