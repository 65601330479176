import React from 'react';
import { useSelector } from 'react-redux';
import { makeSelectLocale } from '../LanguageProvider/selectors';
import './styles.scss';
import AppHelmet from 'app/components/AppHelmet';
import { Container, Header, List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function RenderFrenchText() {
  return (
    <>
      <section id="introduction">
        <p>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin
          2004 pour La Confiance dans l'Économie Numérique, dite L.C.E.N., il est porté à la
          connaissance des Utilisateurs du site&nbsp;
          <b>
            <a href="https://picoxia.com/fr/">picoxia.com</a>
          </b>
          &nbsp; les présentes mentions légales.
        </p>
      </section>

      <section id="publisher">
        <h2>Éditeur</h2>
        <p>
          L'édition du site est assurée par la Société <b>Picoxia SAS</b> au capital de 5&nbsp;000
          euros, immatriculée au RCS d'Evry sous le numéro 850833047 et dont le siège social est
          situé au 37 rue du Bois Chaland, 91090 Lisses.
        </p>
        <List>
          <List.Item>N° de TVA intracommunautaire&nbsp;: FR25 850833047</List.Item>
          <List.Item>
            N° de téléphone&nbsp;:&nbsp;
            <a href="tel:0033160861717" target="_blank">
              +33 1 60 86 17 17
            </a>
          </List.Item>
          <List.Item>
            Email&nbsp;:&nbsp;
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </List.Item>
          <List.Item>Directeur de la publication&nbsp;: Robin IMBACH</List.Item>
        </List>
      </section>

      <section id="hosting">
        <h2>Hébergement</h2>
        <List>
          <List.Item>Google Ireland Limited</List.Item>
          <List.Item>Gordon House, Barrow Street, Dublin 4</List.Item>
          <List.Item>Irlande</List.Item>
          <List.Item>
            N° de téléphone&nbsp;:&nbsp;
            <a href="tel:0035314361000" target="_blank">
              +353 1 436 1000
            </a>
          </List.Item>
        </List>
      </section>

      <section id="site-access">
        <h2>Accès au site</h2>
        <p>
          Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure,
          interruption programmée ou non et pouvant découler d'une nécessité de maintenance.
          <br />
          En cas de modification, interruption ou suspension des services le site&nbsp;
          <b>
            <a href="https://picoxia.com/fr/">picoxia.com</a>
          </b>
          &nbsp; ne saurait être tenu responsable
        </p>
      </section>
      <section id="data-collection">
        <h2>Collecte des données</h2>
        <p>
          Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles
          dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative
          à l'informatique, aux fichiers et aux libertés. Les données personnelles (nom, adresse,
          téléphone...) communiquées par l'Utilisateur sur le site sont ainsi destinées au seul
          usage de Picoxia et de son partenaire FAS. Ces données ne seront pas communiquées à des
          tiers.
          <br />
          En vertu de cette loi vous disposez d'un droit d'accès, de modification et de suppression
          des données qui vous concernent.
          <br />
          Vous pouvez exercer ce droit à tout moment par mail à l'adresse suivante&nbsp;:&nbsp;
          <b>
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </b>
        </p>
      </section>

      <section id="intellectual-property">
        <h2>Propriété intellectuelle</h2>
        <p>
          Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou
          partie du site&nbsp;
          <b>
            <a href="https://picoxia.com/fr/">picoxia.com</a>
          </b>
          &nbsp; sans autorisation de l'Editeur est prohibée et pourra entrainer des actions et
          poursuites judiciaires telles que prévues par le Code de la propriété intellectuelle et le
          Code civil.
        </p>
      </section>
    </>
  );
}

function RenderEnglishText() {
  return (
    <>
      <section id="introduction">
        <p>
          In accordance with the provisions of Articles 6-III and 19 of Law No. 2004-575 of 21 June
          2004 on <i>La Confiance dans l'Économie Numérique</i>, known as the L.C.E.N., Users of
          the&nbsp;
          <b>
            <a href="https://picoxia.com/en/">picoxia.com</a>
          </b>
          &nbsp; website are hereby informed of the present legal notice.
        </p>
      </section>

      <section id="publisher">
        <h2>Publisher</h2>
        <p>
          The Site is published by <b>Picoxia SAS</b>, a company with a share capital of 5,000
          euros, registered with the Evry Trade and Companies Register under the number 850833047,
          and whose registered office is located at&nbsp;
          <i>37 rue du Bois Chaland, 91090 Lisses</i>.
        </p>
        <List>
          <List.Item>Intra-Community VAT number: FR25 850833047</List.Item>
          <List.Item>
            Phone number:&nbsp;
            <a href="tel:0033160861717" target="_blank">
              +33 1 60 86 17 17
            </a>
          </List.Item>
          <List.Item>
            E-mail&nbsp;:&nbsp;
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </List.Item>
          <List.Item>Publications Director: Robin Imbach</List.Item>
        </List>
      </section>

      <section id="hosting">
        <h2>Hosting</h2>
        <List>
          <List.Item>Google Ireland Limited</List.Item>
          <List.Item>Gordon House, Barrow Street, Dublin 4</List.Item>
          <List.Item>Ireland</List.Item>
          <List.Item>
            Phone Number:&nbsp;
            <a href="tel:0035314361000" target="_blank">
              +353 1 436 1000
            </a>
          </List.Item>
        </List>
      </section>

      <section id="site-access">
        <h2>Access to the Site</h2>
        <p>
          The Site is accessible from any location, 7 days a week, 24 hours a day, except in cases
          of force majeure, planned or unplanned interruptions that may result from maintenance
          requirements. In case of modification, interruption or suspension of the services,&nbsp;
          <b>
            <a href="https://picoxia.com/en/">picoxia.com</a>
          </b>
          &nbsp; cannot be held responsible.
        </p>
      </section>
      <section id="data-collection">
        <h2>Data collection</h2>
        <p>
          The Site guarantees that the User's personal information is collected and processed in
          compliance with the French Data Protection Act no. 78-17 of 6 January 1978. The personal
          data (name, address, telephone number, etc.) provided by the User on the Site are intended
          for the exclusive use of Picoxia and its partner FAS. These data will not be communicated
          to third parties. By virtue of this law, you have the right to access, modify and delete
          data concerning you. You can exercise this right at any time by sending an e-mail to the
          following address:&nbsp;
          <b>
            <a href="mailto:contact@picoxia.com" target="_blank">
              contact@picoxia.com
            </a>
          </b>
        </p>
      </section>

      <section id="intellectual-property">
        <h2>Intellectual property rights</h2>
        <p>
          Any use, reproduction, distribution, marketing or modification of all or part of the&nbsp;
          <b>
            <a href="https://picoxia.com/en/">picoxia.com</a>
          </b>
          &nbsp; website without the publisher's authorisation is prohibited and may result in legal
          action as provided for by the French Intellectual Property Code and the French Civil Code.
        </p>
      </section>
    </>
  );
}

function LegalNotices() {
  const locale = useSelector(makeSelectLocale());

  if (locale === 'fr') {
    return <RenderFrenchText />;
  }

  return <RenderEnglishText />;
}
/**
 * LegalNoticesPage du site
 * @returns {JSX.Element}
 */
function LegalNoticesPage() {
  return (
    <div className="legal-notices-page">
      <AppHelmet
        titleIntlID="legal-notices.title"
        descriptionIntlID="legal-notices.page-description"
      />
      <Container textAlign="justified">
        <Header as="h1">
          <FormattedMessage id="legal-notices.title" />
          <Header.Subheader>
            <FormattedMessage id="legal-notices.sub-title" />
          </Header.Subheader>
        </Header>
        <LegalNotices />
      </Container>
    </div>
  );
}

export default LegalNoticesPage;
