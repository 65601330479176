/* eslint-disable max-len */
/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { attachXRayConfigurationWithLocalStorageSynchronizationObserver } from 'app/redux/xrayGeneratorConfiguration/reducer';
import { attachPACSConfigurationWithLocalStorageSynchronizationObserver } from 'app/redux/PACSConfiguration/reducer';
import { attachFlatPanelConfigurationWithLocalStorageSynchronizationObserver } from 'app/redux/flatPanelConfiguration/reducer';
import { attachGlobalSubscriptionsConfigurationWithLocalStorageSynchronizationObserver } from 'app/redux/global/reducer';
import { attachUserInputMappingWithLocalStorageSynchronizationObserver } from 'app/redux/userInputMapping/reducer';
import { attachAiOnlyConfigurationWithLocalStorageSynchronizationObserver } from 'app/redux/aiOnlyConfiguration/reducer';
import { attachDeviceParametersWithLocalStorageSynchronizationObserver } from 'app/redux/deviceParameters/reducer';
import languageMiddleware from './containers/LanguageProvider/languageMiddleware';
import attachURLRedirectionManagerObserver from './containers/LanguageProvider/urlRedirectionManager';
import { attachSubscriptionManagerObserver } from './redux/subscriptionsManager';
import { getAsyncInjectors } from './utils/asyncInjectors';
import loginSagas from './redux/loginSagas';
import createReducer, {
  attachSyncPMSStateWithLocalStorageObserver,
  attachViewerConfigurationWithLocalStorageSynchronizationObserver,
} from './redux/reducers';
import { attachPermissionsWithLocalStorageSynchronizationObserver } from './redux/permissions/reducer';
import { attachTeleradiologyWithLocalStorageSynchronizationObserver } from './redux/teleradiology/reducer';
import { attachAnnouncementsWithLocalStorageSynchronizationObserver } from 'app/redux/announcements/reducer';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history), languageMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

  // Observers
  attachSubscriptionManagerObserver(store);
  attachSyncPMSStateWithLocalStorageObserver(store);
  attachURLRedirectionManagerObserver(store);
  attachViewerConfigurationWithLocalStorageSynchronizationObserver(store);
  attachXRayConfigurationWithLocalStorageSynchronizationObserver(store);
  attachPACSConfigurationWithLocalStorageSynchronizationObserver(store);
  attachFlatPanelConfigurationWithLocalStorageSynchronizationObserver(store);
  attachGlobalSubscriptionsConfigurationWithLocalStorageSynchronizationObserver(store);
  attachUserInputMappingWithLocalStorageSynchronizationObserver(store);
  attachAiOnlyConfigurationWithLocalStorageSynchronizationObserver(store);
  attachDeviceParametersWithLocalStorageSynchronizationObserver(store);
  attachPermissionsWithLocalStorageSynchronizationObserver(store);
  attachTeleradiologyWithLocalStorageSynchronizationObserver(store);
  attachAnnouncementsWithLocalStorageSynchronizationObserver(store);

  // Extensions
  store.runSaga = sagaMiddleware.run;

  store.asyncReducers = {}; // Async reducer registry

  const { injectSagas } = getAsyncInjectors(store);

  // Global sagas
  injectSagas([loginSagas]);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./redux/reducers', () => {
      import('./redux/reducers').then((reducerModule) => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
}
