/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {
  CONNECTION_STATE,
  DETECTOR_ACQUISITION_STATE,
  DETECTOR_EVENTS,
  DETECTOR_IMPLEMENTATION,
  DETECTOR_STATE,
} from 'app/types/xray';
import TimeoutPromise from 'app/utils/TimeoutPromise';
import EventEmitter from 'events';
import _ from 'lodash';

export default class InMemoryFlatPanelDetector extends EventEmitter {
  /**
   *
   * @param {Object} detectorProps
   * @param {number} detectorProps.detectorID
   * @param {number} detectorProps.implementation
   * @param {string} detectorProps.serialNumber
   * @param {string} detectorProps.productModel
   * @param {[number, number]} detectorProps.pixelSpacing
   * @param {number} detectorProps.initialState
   * @param {number} detectorProps.initialAcquisitionState
   * @param {number} detectorProps.initialConnectionState
   * @param {DataImage[]} detectorProps.images
   */
  constructor({
    detectorID = 1,
    implementation = DETECTOR_IMPLEMENTATION.IRAY,
    serialNumber = 'abcefg',
    productModel = 'test_model',
    pixelSpacing = [50, 50],
    initialState = DETECTOR_STATE.Unknown,
    initialAcquisitionState = DETECTOR_ACQUISITION_STATE.Unknown,
    initialConnectionState = CONNECTION_STATE.Unknown,
    imagesPromise = undefined,
  } = {}) {
    super();

    this.detectorID = detectorID;
    this.implementation = implementation;
    this.serialNumber = serialNumber;
    this.productModel = productModel;
    this.pixelSpacing = pixelSpacing;
    this.state = initialState;
    this.acquisitionState = initialAcquisitionState;
    this.connectionState = initialConnectionState;

    this.images = [];
    imagesPromise?.then((images) => {
      this.images = images;
    });

    this.imagesIndex = 0;
  }

  getDetectorID = () => this.detectorID;

  getImplementation = () => this.implementation;

  getSerialNumber = () => this.serialNumber;

  getProductModel = () => this.productModel;

  getPixelSpacing = () => this.pixelSpacing;

  getState = () => this.state;

  getAcquisitionState = () => this.acquisitionState;

  getConnectionState = () => this.connectionState;

  connect = () => {
    this._updateState(DETECTOR_STATE.Ready);
    this._updateConnectionState(CONNECTION_STATE.OK);

    this.emit(DETECTOR_EVENTS.CONNECTED);
  };

  disconnect = () => {
    this._updateState(DETECTOR_STATE.Unknown);
    this._updateConnectionState(CONNECTION_STATE.Unknown);
    this.emit(DETECTOR_EVENTS.DISCONNECTED);
  };

  wakeUp = () => {
    if (this.state !== DETECTOR_STATE.Sleeping) return;
    this._updateState(DETECTOR_STATE.Ready);
  };

  sleep = () => {
    if (this.state === DETECTOR_STATE.Sleeping) return;
    this._updateState(DETECTOR_STATE.Sleeping);
  };

  startAutoAcq = () => {
    // Here we simulate a busy state chain with acquisition state change in the middle
    this._updateState(DETECTOR_STATE.Busy);
    this._updateAcquisitionState(DETECTOR_ACQUISITION_STATE.AutoTriggerPending);

    setTimeout(() => {
      this._updateAcquisitionState(DETECTOR_ACQUISITION_STATE.AutoTrigger);
      this._updateState(DETECTOR_STATE.Ready);
    }, 1000);
  };

  stopAutoAcq = () => {
    // Here we simulate a busy state chain with acquisition state change in the middle
    this._updateState(DETECTOR_STATE.Busy);

    setTimeout(() => {
      this._updateAcquisitionState(DETECTOR_ACQUISITION_STATE.Unknown);
      this._updateState(DETECTOR_STATE.Ready);
    }, 1000);
  };

  forceAcquire = () => {
    this._updateState(DETECTOR_STATE.Busy);

    (async () => {
      await TimeoutPromise(1000);
      this._updateAcquisitionState(DETECTOR_ACQUISITION_STATE.Manual);

      await this.acquireVirtualImage();

      this.startAutoAcq();
    })();
  };

  hasLastImageData = () => false;

  /* Demo/Test only methods */

  acquireVirtualImage = async () => {
    this._updateState(DETECTOR_STATE.Busy);
    this.emit(DETECTOR_EVENTS.ACQUISITION_START);

    await TimeoutPromise(1000);

    this._emitNextImageInQueue();

    await TimeoutPromise(1000);

    this.emit(DETECTOR_EVENTS.ACQUISITION_END);

    this._updateState(DETECTOR_STATE.Ready);
  };

  retransmitVirtualImage = async () => {
    this._updateState(DETECTOR_STATE.Busy);

    await TimeoutPromise(1000);

    this._emitNextImageInQueue();

    await TimeoutPromise(500);

    this._updateState(DETECTOR_STATE.Ready);
  };

  /* Private methods */

  emit = async (...args) => {
    await undefined;
    super.emit(...args);
  };

  _updateState = (newState) => {
    const previousState = this.state;
    this.state = newState;

    this.emit(DETECTOR_EVENTS.STATUS_CHANGE, {
      previous_status: previousState,
      new_status: this.state,
    });
  };

  _updateConnectionState = (newState) => {
    const previousConnectionState = this.connectionState;
    this.connectionState = newState;

    this.emit(DETECTOR_EVENTS.CONNECTION_STATUS_CHANGE, {
      previous_status: previousConnectionState,
      new_status: this.connectionState,
    });
  };

  _updateAcquisitionState = (newState) => {
    const previousAcquisitionState = this.acquisitionState;
    this.acquisitionState = newState;

    this.emit(DETECTOR_EVENTS.ACQUISITION_STATUS_CHANGE, {
      previous_status: previousAcquisitionState,
      new_status: this.acquisitionState,
    });
  };

  _emitNextImageInQueue = () => {
    try {
      const imageToSend = this.images[this.imagesIndex % this.images.length];

      if (!imageToSend) return;

      this.imagesIndex += 1;

      const imageEvent = _.cloneDeep({
        data: imageToSend.data,
        width: imageToSend.width,
        height: imageToSend.height,
        bytes_per_pixel: imageToSend.bytes_per_pixel,
        max_pixel_value: imageToSend.maxPixelValue,
        min_pixel_value: imageToSend.minPixelValue,
      });

      this.emit(DETECTOR_EVENTS.NEW_IMAGE, imageEvent);
    } catch {
      //
    }
  };
}
