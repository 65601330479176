import './style.scss';

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as pt from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Divider, Form, Icon, Input, Label, Message } from 'semantic-ui-react';
import { useContext } from 'react';
import { PACSCommunicationContext } from 'app/components/PACSCommunicationProvider';
import RequiredAsterisk from 'app/components/RequiredAsterisk';
import _ from 'lodash';
import { useEffect } from 'react';
import ErrorMessage from 'app/components/PACSErrorMessage';
import MinDurationPromise from 'app/utils/MinDurationPromise';
import useCancellablePromises from 'app/hooks/useCancelablePromises';

const useIntState = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const setIntValue = (newValue) => setValue(parseInt(newValue, 10));
  return [value, setIntValue];
};

const PACSConfiguration = ({
  onAssociationSuccess,
  initialValue,
  minimumAssociationDuration = 1000,
}) => {
  /** @type {import('app/native/node-addons/picoxia-dicom').PACSCommunication} */
  const pacsCommunication = useContext(PACSCommunicationContext);
  if (!pacsCommunication) return null;

  const [hostIP, sethostIP] = useState(initialValue?.hostIP ?? '');
  const [port, setPort] = useIntState(initialValue?.port ?? '');
  const [hostAETitle, setHostAETitle] = useState(initialValue?.hostAETitle ?? '');
  const [username, setUsername] = useState(initialValue?.username ?? '');
  const [password, setPassword] = useState(initialValue?.password ?? '');
  const [timeout, setTimeout] = useIntState(initialValue?.timeout ?? '');
  const [associateOngoing, setAssociateOngoing] = useState(false);
  const [error, setError] = useState(undefined);
  const cancellablePromises = useCancellablePromises();

  const areRequiredFieldsSet = () => hostIP && port && hostAETitle;

  const associate = async () => {
    setAssociateOngoing(true);
    const associationParameters = _.pickBy({
      hostIP,
      port,
      hostAETitle,
      username,
      password,
      timeout,
    });
    try {
      await cancellablePromises(
        MinDurationPromise(
          pacsCommunication.associate(associationParameters),
          minimumAssociationDuration
        )
      );
      onAssociationSuccess(associationParameters);
    } catch (associationError) {
      if (associationError.isCanceled) return;
      setError(associationError);
    }
    setAssociateOngoing(false);
  };

  useEffect(() => {
    setError(undefined);
  }, [hostIP, port, hostAETitle, username, password, timeout]);

  useEffect(() => {
    if (associateOngoing) setError(undefined);
  }, [associateOngoing]);

  return (
    <Form className="pacs-configuration">
      <Form.Group widths="equal">
        <Form.Field as="label">
          <Input
            label={
              <Label>
                <FormattedMessage id="pacs.configuration.host_ip" />
                <RequiredAsterisk />
              </Label>
            }
            id="pacs.configuration.host_ip"
            value={hostIP}
            onChange={(_target, { value }) => sethostIP(value)}
          />
        </Form.Field>
        <Form.Field as="label">
          <Input
            label={
              <Label>
                <FormattedMessage id="pacs.configuration.username" />
              </Label>
            }
            id="pacs.configuration.username"
            value={username}
            onChange={(_target, { value }) => setUsername(value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field as="label">
          <Input
            label={
              <Label>
                <FormattedMessage id="pacs.configuration.port" />
                <RequiredAsterisk />
              </Label>
            }
            id="pacs.configuration.port"
            value={port}
            onChange={(_target, { value }) => setPort(value)}
            type="number"
            max={65535}
            min={0}
          />
        </Form.Field>
        <Form.Field as="label">
          <Input
            label={
              <Label>
                <FormattedMessage id="pacs.configuration.password" />
              </Label>
            }
            id="pacs.configuration.password"
            value={password}
            onChange={(_target, { value }) => setPassword(value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field as="label">
          <Input
            label={
              <Label>
                <FormattedMessage id="pacs.configuration.host_ae_title" />
                <RequiredAsterisk />
              </Label>
            }
            id="pacs.configuration.host_ae_title"
            value={hostAETitle}
            onChange={(_target, { value }) => setHostAETitle(value)}
            maxLength={16}
          />
        </Form.Field>
        <Form.Field as="label">
          <Input
            label={
              <Label>
                <FormattedMessage id="pacs.configuration.timeout" />
              </Label>
            }
            id="pacs.configuration.timeout"
            value={timeout}
            onChange={(_target, { value }) => setTimeout(value)}
            type="number"
            min={0}
          />
        </Form.Field>
      </Form.Group>
      <Divider clearing />
      <ErrorMessage error={error} titleID="pacs.configuration.association_error.title" />
      <div className="pacs-configuration__buttons">
        <Button
          primary
          type="submit"
          disabled={!areRequiredFieldsSet() || associateOngoing}
          onClick={associate}
        >
          {associateOngoing ? (
            <>
              <Icon name="sync" loading />
              <FormattedMessage id="pacs.configuration.connecting" />
            </>
          ) : (
            <FormattedMessage id="pacs.configuration.connect" />
          )}
        </Button>
      </div>
    </Form>
  );
};

PACSConfiguration.propTypes = {
  onAssociationSuccess: pt.func,
  initialValue: pt.shape({
    hostIP: pt.string,
    port: pt.number,
    hostAETitle: pt.string,
    selfAETitle: pt.string,
    username: pt.string,
    password: pt.string,
    timeout: pt.number,
  }),
  minimumAssociationDuration: pt.number,
};

PACSConfiguration.defaultProps = {
  onAssociationSuccess: () => {},
  initialValue: undefined,
  minimumAssociationDuration: 1000,
};

export default PACSConfiguration;
