/* eslint-disable no-underscore-dangle */
import { add, format } from 'date-fns';
import MissingFileIDError from 'app/pms/exporter/MissingFileIDError';
import _ from 'lodash';
import path from 'app/native/node/path';
/* eslint-disable camelcase */

export const VETO_PARTNER_EXPORT_DEFAULT_JPEG_PARAMS = {
  maxWidth: 720,
  maxHeight: 720,
  quality: 0.95,
};

const formatDateForVetoPartner = (date) => format(date, 'yyyyMMdd_HHmm');

export default class VetoPartnerExporter {
  /**
   *
   * @param {import('app/interfaces/ImageEncoder').default} imageEncoder
   * @param {import('fs').promises} fileSystemPromises
   */
  constructor(imageEncoder, fileSystemPromises) {
    this.imageEncoder = imageEncoder;
    this.fileSystemPromises = fileSystemPromises;
  }

  /**
   *
   * @param {{exportDirectory:string}} exportConfiguration
   * @param {*} _studyInfo
   * @param {{file_id:string, name:string}} patientInfo
   * @param {[{
   *  imageData:cornerstone.Image,
   *  acquisitionDate:Date,
   *  annotations:Map<string, Object>,
   *  viewport:cornerstone.Viewport}]} images
   * @returns {Promise<[{status:"fulfilled"|"rejected", value:undefined, reason:Error}]>}
   */
  exportDataToPMS = async (
    { exportDirectory } = {},
    _studyInfo,
    { file_id, name } = {},
    images = []
  ) => {
    // PMS not configured, don't bother user with this
    if (!exportDirectory) return undefined;

    if (!file_id) {
      throw new MissingFileIDError('patient file_id is required to export images to PMS');
    }

    const exportDirectoryFiles = await this.fileSystemPromises.readdir(exportDirectory);

    const imagesWithNoDuplicatedDate = images.reduce((dedupImages, image) => {
      let { acquisitionDate = new Date() } = image;
      let filename = `${formatDateForVetoPartner(acquisitionDate)}_${file_id}_${name}.JPEG`;

      while (_.find(dedupImages, { filename }) || exportDirectoryFiles.includes(filename)) {
        acquisitionDate = add(acquisitionDate, { minutes: 1 });
        filename = `${formatDateForVetoPartner(acquisitionDate)}_${file_id}_${name}.JPEG`;
      }
      return [...dedupImages, { ...image, filename }];
    }, []);

    return Promise.allSettled(
      imagesWithNoDuplicatedDate.map(({ imageData, filename, annotations, viewport }) =>
        this.imageEncoder
          .toJPEG(imageData, viewport, annotations, VETO_PARTNER_EXPORT_DEFAULT_JPEG_PARAMS)
          .then((jpegBuffer) =>
            this.fileSystemPromises.writeFile(path().join(exportDirectory, filename), jpegBuffer)
          )
          .catch((error) => {
            console.warn(error);
            throw error;
          })
      )
    );
  };
}
