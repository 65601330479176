import './style.scss';

import _, { random } from 'lodash';
import * as pt from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox, Dimmer, Icon, Loader, Popup } from 'semantic-ui-react';

import { SPECIES } from '../../utils/speciesConstants';

import { ReactComponent as Bird } from './animal_parts/bird.svg';
import { ReactComponent as Cat } from './animal_parts/cat.svg';
import { ReactComponent as Dog } from './animal_parts/dog.svg';
import { ReactComponent as Ferret } from './animal_parts/ferret.svg';
import { ReactComponent as Rabbit } from './animal_parts/rabbit.svg';
import { ReactComponent as Reptile } from './animal_parts/reptile.svg';
import { ReactComponent as Rodent } from './animal_parts/rodent.svg';

import { ReactComponent as BirdEntireBody } from './animal_parts/bird_entire_body.svg';
import { ReactComponent as FerretEntireBody } from './animal_parts/ferret_entire_body.svg';
import { ReactComponent as RabbitEntireBody } from './animal_parts/rabbit_entire_body.svg';
import { ReactComponent as ReptileEntireBody } from './animal_parts/reptile_entire_body.svg';
import { ReactComponent as RodentEntireBody } from './animal_parts/rodent_entire_body.svg';

import { ReactComponent as DogAbdomen } from './animal_parts/dog_abdomen.svg';
import { ReactComponent as DogEntireBody } from './animal_parts/dog_entire_body.svg';
import { ReactComponent as DogMembrePelvienGauche } from './animal_parts/dog_pelvic_limb_left.svg';
import { ReactComponent as DogMembrePelvienDroit } from './animal_parts/dog_pelvic_limb_right.svg';
import { ReactComponent as DogCrane } from './animal_parts/dog_skull.svg';
import { ReactComponent as DogRachis } from './animal_parts/dog_spine.svg';
import { ReactComponent as DogMembreThoraciqueGauche } from './animal_parts/dog_thoracic_limb_left.svg';
import { ReactComponent as DogMembreThoraciqueDroit } from './animal_parts/dog_thoracic_limb_right.svg';
import { ReactComponent as DogThorax } from './animal_parts/dog_thorax.svg';

import FocusIcon from 'app/components/XRayAreaView/FocusIcon';
import {
  XRayGeneratorControllerContext,
  XRayGeneratorStateContext,
} from 'app/providers/XRayGeneratorProvider';
import { DEFAULT_SPECIE } from 'app/utils/speciesConstants';
import { getAnatomicRegionFromString } from 'app/utils/xrayRegions';
import {
  EVENT_GENERATOR_RAD_PARAMETERS_CHANGE,
  EVENT_GENERATOR_THICKNESS,
  READY_STATUS,
  UNIT_STATUS,
} from 'app/xray/generator/constants';
import { DETECTOR_EVENTS } from '../../types/xray';
import ACQUISITION_CONSTANTS, {
  MAX_KV,
  MAX_THICKNESS,
  MIN_KV,
  MIN_THICKNESS,
} from '../../utils/acquisitionConstants';
import { FlatPanelStateContext, getCurrentDetector } from '../FlatPanelStateProvider';
import { ReactComponent as CatAbdomen } from './animal_parts/cat_abdomen.svg';
import { ReactComponent as CatEntireBody } from './animal_parts/cat_entire_body.svg';
import { ReactComponent as CatMembrePelvienGauche } from './animal_parts/cat_pelvic_limb_left.svg';
import { ReactComponent as CatMembrePelvienDroit } from './animal_parts/cat_pelvic_limb_right.svg';
import { ReactComponent as CatCrane } from './animal_parts/cat_skull.svg';
import { ReactComponent as CatRachis } from './animal_parts/cat_spine.svg';
import { ReactComponent as CatMembreThoraciqueGauche } from './animal_parts/cat_thoracic_limb_left.svg';
import { ReactComponent as CatMembreThoraciqueDroit } from './animal_parts/cat_thoracic_limb_right.svg';
import { ReactComponent as CatThorax } from './animal_parts/cat_thorax.svg';
import ConstantSelector from './ConstantSelector';
import { SET_RAD_RETRY_DELAY } from 'app/xray/generator/constants';
import useDidUpdateEffect from 'app/utils/hooks/useDidUpdateEffect';
import { useSelector } from 'react-redux';
import { selectXRayDevicePath } from 'app/redux/xrayGeneratorConfiguration/reducer';

const SET_RAD_DEBOUNCE_MS = 1000;

const SPECIES_BODY_SVG = {
  cat: Cat,
  dog: Dog,
  rabbit: Rabbit,
  ferret: Ferret,
  rodent: Rodent,
  bird: Bird,
  reptile: Reptile,
  animal: Dog,
};

const SPECIES_BODY_BODY_PARTS_SVG = {
  dog: {
    thorax: DogThorax,
    abdomen: DogAbdomen,
    crane: DogCrane,
    rachis: DogRachis,
    membre_pelvien_gauche: DogMembrePelvienGauche,
    membre_pelvien_droit: DogMembrePelvienDroit,
    membre_thoracique_gauche: DogMembreThoraciqueGauche,
    membre_thoracique_droit: DogMembreThoraciqueDroit,
    entire_body: DogEntireBody,
  },
  cat: {
    thorax: CatThorax,
    abdomen: CatAbdomen,
    crane: CatCrane,
    rachis: CatRachis,
    membre_pelvien_gauche: CatMembrePelvienGauche,
    membre_pelvien_droit: CatMembrePelvienDroit,
    membre_thoracique_gauche: CatMembreThoraciqueGauche,
    membre_thoracique_droit: CatMembreThoraciqueDroit,
    entire_body: CatEntireBody,
  },
  rabbit: {
    thorax: RabbitEntireBody,
    abdomen: RabbitEntireBody,
    crane: RabbitEntireBody,
    rachis: RabbitEntireBody,
    membre_pelvien_gauche: RabbitEntireBody,
    membre_pelvien_droit: RabbitEntireBody,
    membre_thoracique_gauche: RabbitEntireBody,
    membre_thoracique_droit: RabbitEntireBody,
    entire_body: RabbitEntireBody,
  },
  ferret: {
    thorax: FerretEntireBody,
    abdomen: FerretEntireBody,
    crane: FerretEntireBody,
    rachis: FerretEntireBody,
    membre_pelvien_gauche: FerretEntireBody,
    membre_pelvien_droit: FerretEntireBody,
    membre_thoracique_gauche: FerretEntireBody,
    membre_thoracique_droit: FerretEntireBody,
    entire_body: FerretEntireBody,
  },
  rodent: {
    thorax: RodentEntireBody,
    abdomen: RodentEntireBody,
    crane: RodentEntireBody,
    rachis: RodentEntireBody,
    membre_pelvien_gauche: RodentEntireBody,
    membre_pelvien_droit: RodentEntireBody,
    membre_thoracique_gauche: RodentEntireBody,
    membre_thoracique_droit: RodentEntireBody,
    entire_body: RodentEntireBody,
  },
  bird: {
    thorax: BirdEntireBody,
    abdomen: BirdEntireBody,
    crane: BirdEntireBody,
    rachis: BirdEntireBody,
    membre_pelvien_gauche: BirdEntireBody,
    membre_pelvien_droit: BirdEntireBody,
    membre_thoracique_gauche: BirdEntireBody,
    membre_thoracique_droit: BirdEntireBody,
    entire_body: BirdEntireBody,
  },
  reptile: {
    thorax: ReptileEntireBody,
    abdomen: ReptileEntireBody,
    crane: ReptileEntireBody,
    rachis: ReptileEntireBody,
    membre_pelvien_gauche: ReptileEntireBody,
    membre_pelvien_droit: ReptileEntireBody,
    membre_thoracique_gauche: ReptileEntireBody,
    membre_thoracique_droit: ReptileEntireBody,
    entire_body: ReptileEntireBody,
  },
};

const getAcquisitionConstantsMapForRegion = (anatomicRegion) => {
  const { mainRegion, subRegion } = getAnatomicRegionFromString(anatomicRegion);
  return (
    ACQUISITION_CONSTANTS[subRegion] ??
    ACQUISITION_CONSTANTS[mainRegion] ??
    ACQUISITION_CONSTANTS.default
  );
};

function RadSyncDisplay({ radSyncState }) {
  if (!radSyncState) return null;

  const isSyncOngoing = radSyncState === 'rad_syncing';
  const isSync = radSyncState === 'rad_sync';

  const testId = `xray_generator.${radSyncState}`;
  let statusIndicator;
  let popupContent;
  if (isSyncOngoing) {
    statusIndicator = (
      <div data-testid={testId} className="rad-sync-state">
        <Icon name="sync" size="small" loading />
      </div>
    );
    popupContent = <FormattedMessage id="xray_generator.rad.syncing" />;
  } else if (isSync) {
    statusIndicator = (
      <div data-testid={testId} className="rad-sync-state rad-sync-state--sync">
        ●
      </div>
    );
    popupContent = <FormattedMessage id="xray_generator.rad.sync" />;
  } else {
    statusIndicator = (
      <div data-testid={testId} className="rad-sync-state rad-sync-state--not-sync">
        ●
      </div>
    );
    popupContent = <FormattedMessage id="xray_generator.rad.not_sync" />;
  }

  return (
    <Popup
      hoverable
      flowing
      trigger={statusIndicator}
      content={popupContent}
      position="right center"
    />
  );
}

const XRayAreaView = ({ image, animal, className, style, onChange, isFocused }) => {
  const [automaticConstants, setAutomaticConstants] = useState(true);
  const [acquisitionStarted, setAcquisitionStarted] = useState(false);
  const flatPanelStateContext = useContext(FlatPanelStateContext);
  const xRayGeneratorController = useContext(XRayGeneratorControllerContext);
  const xRayGeneratorState = useContext(XRayGeneratorStateContext);
  const thicknessRequestInterval = useRef();
  const xRayGeneratorControllerRef = useRef();
  const displayUpdatedRadParameters = useRef();
  const radChangeHandler = useRef();
  xRayGeneratorControllerRef.current = xRayGeneratorController;
  const [radSyncState, setRadSyncState] = useState(undefined);
  const [isManualFieldFocussed, setIsManualFieldFocussed] = useState(false);
  const devicePath = useSelector(selectXRayDevicePath);

  const setRadSyncIfPossible = (radSyncStateToSet) => {
    if (!xRayGeneratorControllerRef.current) {
      setRadSyncState(undefined);
    } else {
      setRadSyncState(radSyncStateToSet);
    }
  };
  const onManualFieldFocus = () => setIsManualFieldFocussed(true);
  const onManualFieldBlur = () => setIsManualFieldFocussed(false);

  const isGeneratorControlAllowed = !acquisitionStarted && isFocused;
  const isGeneratorControlActive = !!devicePath;

  const imageAcquisitionConstants = image?.acquisitionConstants;
  const requestedRadParameters = useMemo(
    () => ({
      ms: Math.round(imageAcquisitionConstants?.s * 1000),
      mA: imageAcquisitionConstants?.mA,
      kV: imageAcquisitionConstants?.kV,
    }),
    [imageAcquisitionConstants]
  );

  const validateRad = useCallback(
    (...args) => {
      if (!xRayGeneratorControllerRef.current) return;

      xRayGeneratorControllerRef.current?.removeEventListener(
        EVENT_GENERATOR_RAD_PARAMETERS_CHANGE,
        radChangeHandler.current
      );
      radChangeHandler.current = (event) => {
        displayUpdatedRadParameters.current(event);
        xRayGeneratorControllerRef.current?.lock(false);
      };
      xRayGeneratorControllerRef.current?.addEventListener(
        EVENT_GENERATOR_RAD_PARAMETERS_CHANGE,
        radChangeHandler.current,
        { once: true }
      );

      setRadSyncIfPossible('rad_syncing');
      xRayGeneratorControllerRef.current?.setRadParameters(...args);
    },
    [setRadSyncIfPossible]
  );

  const [setRadParametersDebounced] = useState(() => _.debounce(validateRad, SET_RAD_DEBOUNCE_MS));

  const triggerParameterChange = useCallback(
    (...args) => {
      if (radSyncState === 'rad_syncing') return;
      onChange(...args);
    },
    [onChange, radSyncState]
  );

  const onAutomaticConstantSwitch = useCallback(
    (_event, { checked }) => setAutomaticConstants(checked),
    [setAutomaticConstants]
  );

  // TODO Remove
  const [listenForThickness, setListenForThickness] = useState(false);
  const onAcquisitionStart = useCallback(() => setAcquisitionStarted(true), []);
  const onAcquisitionEnd = useCallback(() => setAcquisitionStarted(false), []);

  const removeListeners = (detector) => {
    detector?.removeListener(DETECTOR_EVENTS.ACQUISITION_START, onAcquisitionStart);
    detector?.removeListener(DETECTOR_EVENTS.ACQUISITION_END, onAcquisitionEnd);
  };

  const appliedParameters = useMemo(
    () => _.pick(xRayGeneratorState?.radParameters, ['kV', 'ms', 'mA']),
    [xRayGeneratorState?.radParameters]
  );

  displayUpdatedRadParameters.current = (radParameters) => {
    const secondes = radParameters.ms / 1000.0;
    onChange({
      ...imageAcquisitionConstants,
      ..._.pick(radParameters, ['kV', 'mAs', 'mA', 'focus']),
      s: secondes,
    });
  };

  const planRadParametersSetOnceChangeSettle = () => {
    if (!xRayGeneratorController) return;
    if (requestedRadParameters.kV === 0) return;
    if (!isGeneratorControlAllowed) return;

    if (xRayGeneratorState?.unitStatus !== UNIT_STATUS.STANDBY_MODE) return;

    const isStatusReady = xRayGeneratorState?.readyStatus === READY_STATUS.READY;

    const isRequestedParametersApplied = _.isEqual(appliedParameters, requestedRadParameters);

    setRadParametersDebounced.cancel();
    if (isRequestedParametersApplied && isStatusReady) {
      setRadSyncIfPossible('rad_sync');
      return;
    }

    if (radSyncState === 'rad_syncing') return;
    if (isStatusReady) xRayGeneratorController?.lock(true);
    setRadSyncIfPossible('rad_not_sync');
    if (isManualFieldFocussed) return;

    setRadParametersDebounced(requestedRadParameters);
  };

  useEffect(planRadParametersSetOnceChangeSettle, [
    isGeneratorControlAllowed,
    radSyncState,
    isManualFieldFocussed,
    requestedRadParameters,
    xRayGeneratorController,
    xRayGeneratorState?.unitStatus,
    xRayGeneratorState?.readyStatus,
  ]);

  const cancelRadOperationsWhenNotActive = () => {
    if (isGeneratorControlAllowed) return;

    setRadParametersDebounced.cancel();
    if (!xRayGeneratorController) {
      setRadSyncIfPossible(undefined);
      return;
    }

    setRadSyncIfPossible('rad_not_sync');
    xRayGeneratorController?.removeEventListener(
      EVENT_GENERATOR_RAD_PARAMETERS_CHANGE,
      radChangeHandler.current
    );
  };
  useEffect(cancelRadOperationsWhenNotActive, [isGeneratorControlAllowed, xRayGeneratorController]);

  const retrySettingRadIfSyncTooLong = () => {
    if (radSyncState !== 'rad_syncing') return undefined;

    const radRetryInterval = setInterval(
      () => validateRad(requestedRadParameters),
      SET_RAD_RETRY_DELAY
    );
    return () => clearInterval(radRetryInterval);
  };
  useEffect(retrySettingRadIfSyncTooLong, [requestedRadParameters, radSyncState]);

  const unsetRadSyncStatusOnDisconnection = () => {
    setRadSyncIfPossible(undefined);
  };
  useEffect(unsetRadSyncStatusOnDisconnection, [xRayGeneratorController]);

  const onValueChange = useCallback(
    (constantName, value) => {
      let parameters;
      if (constantName === 'mAs') {
        const { mA, s } = image.acquisitionConstants;
        const previousMS = s * 1000;
        const requiredMS = (value / mA) * 1000;
        let roundedMS = Math.round(requiredMS);
        if (roundedMS === previousMS) {
          roundedMS += requiredMS > previousMS ? 1 : -1;
        }
        triggerParameterChange({ ...image.acquisitionConstants, mAs: value, s: roundedMS / 1000 });
        return;
      }
      if (constantName === 'mA') {
        const { mA, s } = image.acquisitionConstants;
        const targetMAS = mA * s;
        const newSeconds = Math.round((targetMAS / value) * 1000) / 1000;
        parameters = { ...image.acquisitionConstants, [constantName]: value, s: newSeconds };
      } else {
        parameters = { ...image.acquisitionConstants, [constantName]: value };
      }
      triggerParameterChange(_.omit(parameters, ['mAs']));
    },
    [triggerParameterChange, image]
  );

  const onThicknessChange = useCallback(
    (_constantName, thickness) => {
      const roundedThickness = Math.round(thickness);
      const boundedThickness = Math.min(MAX_THICKNESS, Math.max(MIN_THICKNESS, roundedThickness));
      if (!automaticConstants) {
        triggerParameterChange({
          ...imageAcquisitionConstants,
          thickness: roundedThickness,
        });
        return;
      }

      const acquisitionConstantsMap = getAcquisitionConstantsMapForRegion(image?.anatomicRegion);
      const acquisitionConstants = _(acquisitionConstantsMap).find({ thickness: boundedThickness });
      if (acquisitionConstants) {
        triggerParameterChange({
          ...imageAcquisitionConstants,
          ...acquisitionConstants,
          thickness: roundedThickness,
        });
        return;
      }
      const max = _(acquisitionConstantsMap).maxBy('thickness');
      const min = _(acquisitionConstantsMap).minBy('thickness');

      triggerParameterChange({
        ...imageAcquisitionConstants,
        ...(boundedThickness > max.thickness ? max : min),
        thickness: roundedThickness,
      });
    },
    [triggerParameterChange, image, automaticConstants, imageAcquisitionConstants]
  );

  const currentDetector = getCurrentDetector(flatPanelStateContext);
  const registerDetectorAcquisitionListener = () => {
    currentDetector?.on(DETECTOR_EVENTS.ACQUISITION_START, onAcquisitionStart);
    currentDetector?.on(DETECTOR_EVENTS.ACQUISITION_END, onAcquisitionEnd);

    return () => {
      removeListeners(currentDetector);
      setAcquisitionStarted(false);
    };
  };
  useEffect(registerDetectorAcquisitionListener, [currentDetector]);

  const initializeNullAcquisitionConstants = () => {
    const { thickness } = image.acquisitionConstants;
    if (thickness === 0) onThicknessChange(undefined, 10);
  };
  useEffect(initializeNullAcquisitionConstants, []);

  const listenForThicknessEvents = () => {
    if (!isGeneratorControlAllowed) return undefined;
    const callback = ({ thickness }) => {
      if (thickness === 0) return;
      onThicknessChange(undefined, thickness);
    };
    xRayGeneratorController?.on(EVENT_GENERATOR_THICKNESS, callback);
    return () => xRayGeneratorController?.removeEventListener(EVENT_GENERATOR_THICKNESS, callback);
  };
  useEffect(listenForThicknessEvents, [
    isGeneratorControlAllowed,
    xRayGeneratorController,
    onThicknessChange,
    automaticConstants,
    imageAcquisitionConstants,
  ]);

  const startThicknessRequestsLoop = () => {
    if (!isGeneratorControlAllowed) return undefined;
    if (!listenForThickness) return undefined;
    thicknessRequestInterval.current = setInterval(
      () => xRayGeneratorController?.requestThickness(),
      500
    );
    return () => clearInterval(thicknessRequestInterval.current);
  };
  useEffect(startThicknessRequestsLoop, [
    isGeneratorControlAllowed,
    listenForThickness,
    xRayGeneratorController,
  ]);

  const restoreAutomaticConstants = () => {
    if (!automaticConstants) return;

    onThicknessChange(undefined, imageAcquisitionConstants?.thickness);
  };
  useDidUpdateEffect(restoreAutomaticConstants, [automaticConstants]);

  const { specie } = animal;
  const { anatomicRegion, isProcessingOnGoing } = image;
  const { thickness, kV, mA, s, focus } = image.acquisitionConstants;
  const mAs = image.acquisitionConstants.mAs ?? +(mA * s).toFixed(1);

  const { mainRegion, subRegion, view } = getAnatomicRegionFromString(anatomicRegion);

  const BodySVG = SPECIES_BODY_SVG[specie] ?? SPECIES_BODY_SVG.dog;
  const TargetedBodyPartSVG = (SPECIES_BODY_BODY_PARTS_SVG[specie] ??
    SPECIES_BODY_BODY_PARTS_SVG.dog)[mainRegion];
  const isRadSync = radSyncState === 'rad_sync';
  const isPrepState =
    xRayGeneratorState?.unitStatus === UNIT_STATUS.FIRST_TIME || xRayGeneratorState?.prepStarted;

  if (!isFocused) return null;

  return (
    <div className={`xray-area-view ${className}`} style={style}>
      <Dimmer active={acquisitionStarted || isProcessingOnGoing}>
        <Loader />
      </Dimmer>
      <div className="body-part-title">
        <div className="main-part">
          <FormattedMessage id={`exam.body_part.${mainRegion}`} />
        </div>
        <div className="secondary-part">
          {subRegion && <FormattedMessage id={`exam.body_part.${subRegion}`} />}
          {subRegion && view && ' '}
          {view && <FormattedMessage id={`exam.view.${view}`} />}
        </div>
      </div>
      <div className="animal-xray">
        <div>
          <BodySVG className="body-full" />
          <TargetedBodyPartSVG className="body-part" />
        </div>
      </div>
      <form
        className="constants-area"
        onSubmit={(e) => {
          validateRad(requestedRadParameters);
          e.preventDefault();
        }}
      >
        <div className="constants-container">
          <RadSyncDisplay radSyncState={radSyncState} />
          <ConstantSelector
            className="thickness"
            onChange={onThicknessChange}
            value={thickness}
            constantName="thickness"
            data-testid="thickness-selector"
            step="1"
            type="dropdown"
            min={MIN_THICKNESS}
            max={MAX_THICKNESS}
            disabled={isPrepState}
          />
          <div className="constants-right-list">
            {!automaticConstants ? (
              <ConstantSelector
                className="electrical-constant"
                onChange={onValueChange}
                data-testid="kV-selector"
                value={kV}
                type="dropdown"
                constantName="kV"
                step="1"
                min={MIN_KV}
                max={MAX_KV}
                style={{ width: '7em' }}
                disabled={automaticConstants || isPrepState}
              />
            ) : (
              <ConstantSelector
                className="electrical-constant"
                onChange={onValueChange}
                value={kV}
                constantName="kV"
                style={{ width: '7em' }}
                disabled
              />
            )}

            <ConstantSelector
              className="electrical-constant"
              value={mAs}
              data-testid="mAs-selector"
              constantName="mAs"
              onChange={onValueChange}
              onFocus={onManualFieldFocus}
              onBlur={onManualFieldBlur}
              step="0.1"
              style={{ width: '7em' }}
              disabled={automaticConstants || isPrepState}
            />

            {!automaticConstants && (
              <ConstantSelector
                className="electrical-constant"
                value={mA}
                data-testid="mA-selector"
                onChange={onValueChange}
                type="dropdown"
                constantName="mA"
                step="25"
                min={25}
                max={300}
                style={{ width: '7em' }}
                disabled={isPrepState}
              />
            )}
            {!automaticConstants && (
              <ConstantSelector
                className="electrical-constant"
                value={s}
                data-testid="seconds-selector"
                onChange={onValueChange}
                onFocus={onManualFieldFocus}
                onBlur={onManualFieldBlur}
                constantName="s"
                step="0.001"
                style={{ width: '9em' }}
                disabled={isPrepState}
              />
            )}

            <FocusIcon focus={focus} />
          </div>
        </div>
        <div className="xray-area-view__sub-area">
          {isGeneratorControlActive && (
            <Checkbox
              as="label"
              className="xray-area-view__sub-area__input-mode"
              checked={automaticConstants}
              toggle
              onChange={onAutomaticConstantSwitch}
              label={
                <label>
                  <FormattedMessage
                    id={
                      automaticConstants
                        ? 'xray_generator.rad.automatic'
                        : 'xray_generator.rad.manual'
                    }
                  />
                </label>
              }
            />
          )}

          {!automaticConstants &&
            xRayGeneratorState?.connectionState === 'connected' &&
            !isRadSync &&
            isManualFieldFocussed && (
              <Button primary={!isRadSync} className="validate-rad" type="submit">
                <FormattedMessage id="xray_generator.validate_rad" />
              </Button>
            )}
          <div />
          {/* <Checkbox
            className="xray-area-view__sub-area__input-mode"
            checked={listenForThickness}
            toggle
            onChange={(_event, { checked }) => setListenForThickness(checked)}
            id="xray-area-view__thickness_listen-select"
            label={
              <label htmlFor="xray-area-view__thickness_listen-select">
                {listenForThickness ? 'Épaisseur automatique' : 'Épaisseur manuelle'}
              </label>
            }
          /> */}
        </div>
      </form>
    </div>
  );
};

XRayAreaView.propTypes = {
  image: pt.shape({
    anatomicRegion: pt.string.isRequired,
    acquisitionConstants: pt.shape({
      thickness: pt.number.isRequired,
      kV: pt.number.isRequired,
      mA: pt.number.isRequired,
      s: pt.number.isRequired,
      focus: pt.oneOfType([pt.bool, pt.number]),
    }),
    isProcessingOnGoing: pt.bool,
  }).isRequired,
  animal: pt.shape({ specie: pt.oneOf(SPECIES) }).isRequired,
  onChange: pt.func.isRequired,
  className: pt.string,
  style: pt.shape({}),
  isFocused: pt.bool,
};

XRayAreaView.defaultProps = {
  className: '',
  style: null,
  isFocused: true,
};

export default XRayAreaView;
