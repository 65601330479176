import React from 'react';
import * as PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { generateDocx } from '../../utils/docxUtil';
import { getImageAttribute } from '../../utils/studyInfo';
import ApiCalls from '../../utils/apiCalls';
import { getReport } from '../../utils/reports/reportsGenerator';

class WordButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reportInfo: null };
  }

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  getReportInfo = () => {
    const { reportInfo } = this.state;
    if (reportInfo) {
      return Promise.resolve(reportInfo);
    }
    return ApiCalls.getReportInfo(true).then((response) => {
      this.setState({ reportInfo: response.data.reportInfo });
      return response.data.reportInfo;
    });
  };

  getMetadata = () => {
    const { study } = this.props;
    const imagesWithMetadata = study.images.filter((image) => image.metadata);
    if (imagesWithMetadata.length > 0) {
      return imagesWithMetadata[0].metadata;
    }
    return { animalSex: '', animalWeight: '', animalSize: '', animalAge: '' };
  };

  getSpecie = () => {
    const { study } = this.props;
    const catConfidence = getImageAttribute(study.images, 'predictions').chat;
    if (catConfidence > 0.9) {
      return this.formatMessage({ id: 'complementaryInfoForm.specie.cat' });
    }
    if (catConfidence < 0.1) {
      return this.formatMessage({ id: 'complementaryInfoForm.specie.dog' });
    }
    return this.formatMessage({ id: 'complementaryInfoForm.specie.other' });
  };

  renderWordButton = () => {
    const onClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.getReportInfo().then((reportInfo) => {
        const { intl, study } = this.props;
        const { animalName, ownerName, creationDate } = study;
        const { animalSex, animalWeight, animalSize, animalAge } = this.getMetadata();
        const specie = this.getSpecie();
        const { clinicInfo } = reportInfo;
        const clinicName = reportInfo.workplace_name;
        const vetName =
          reportInfo.first_name !== 'null'
            ? `Dr. ${reportInfo.first_name} ${reportInfo.last_name}`
            : '';
        const userMail = reportInfo.mail;
        const clinicLogo = reportInfo.clinic_logo === 'NO_LOGO' ? null : reportInfo.clinic_logo;
        let { report } = this.props;
        if (!report) {
          report = getReport(animalName, ownerName, study.images, intl, creationDate);
        }
        generateDocx(
          report,
          animalName,
          ownerName,
          animalSex,
          animalWeight,
          animalSize,
          animalAge,
          specie,
          clinicName,
          vetName,
          userMail,
          clinicInfo,
          study.images,
          clinicLogo,
          intl
        );
      });
    };
    return (
      <Button
        type="button"
        size="large"
        style={{ float: 'right', backgroundColor: '#365695', color: 'white' }}
        onClick={onClick}
        icon
      >
        <Icon name="file word" />
      </Button>
    );
  };

  render() {
    // {this.state.patternName.replace(/_/g, " ")}
    return (
      <Popup
        content={this.formatMessage({ id: 'complementaryInfoForm.openWord' })}
        trigger={this.renderWordButton()}
      />
    );
  }
}

WordButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  study: PropTypes.shape({
    ownerName: PropTypes.string,
    animalName: PropTypes.string,
    creationDate: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  intl: PropTypes.shape().isRequired,
};

WordButton.defaultProps = {};

function mapStateToProps(state) {
  return {
    route: state.get('route'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WordButton));
