/* eslint-disable react/prop-types */
import { selectAiOnlyConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';

/**
 * Button Save study with Popup
 * @param {boolean} saved
 * @param {Promise<void>} validateStudy
 * @returns {JSX.Element}
 */
function ButtonStudySave({ saved, validateStudy }) {
  /** @type {Immutable.Map} */
  const aiOnlyConfiguration = useSelector(selectAiOnlyConfiguration);
  /** @type {boolean} */
  const aiOnlyEnabled = aiOnlyConfiguration.get('enabled');
  const allTooltipContentIds = ['save_all_img', 'sync_img_with_pacs', 'remove_non_acquired_views'];
  const authorizedTooltipContentIds = allTooltipContentIds.filter(
    (tooltipContentId) => !(aiOnlyEnabled === true && tooltipContentId === 'sync_img_with_pacs')
  );
  const tooltipContentList = authorizedTooltipContentIds.map((id) => <LiRow key={id} id={id} />);

  return (
    <Popup
      trigger={
        <button className={`picoxia ${saved ? 'saved' : ''}`} type="button" onClick={validateStudy}>
          <Icon name="check" size="big" />
        </button>
      }
    >
      <Popup.Header>
        <FormattedMessage id="dropzone.save.tooltip.header" />
      </Popup.Header>
      <Popup.Content>
        <ul>{tooltipContentList}</ul>
      </Popup.Content>
    </Popup>
  );
}

/**
 * LiRow Loop
 * @param {string} id
 * @returns {JSX.Element}
 */
function LiRow({ id }) {
  const fullId = `dropzone.save.tooltip.content.li.${id}`;
  return (
    <li>
      <FormattedMessage id={fullId} />
    </li>
  );
}

export default ButtonStudySave;
