import React from 'react';
import './styles.scss';
import AppHelmet from 'app/components/AppHelmet';
import { Container, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import TermsOfSalesContent from './content';

/**
 * TermsOfSalesPage du site
 * @returns {JSX.Element}
 */
function TermsOfSalesPage() {
  return (
    <div className="terms-of-sales-page">
      <AppHelmet
        titleIntlID="terms-of-sales.title"
        descriptionIntlID="terms-of-sales.page-description"
      />
      <Container textAlign="justified">
        <Header as="h1">
          <FormattedMessage id="terms-of-sales.title" />
        </Header>
        <TermsOfSalesContent />
      </Container>
    </div>
  );
}

export default TermsOfSalesPage;
