export const XRAY_CONFIGURATION_SET_DEVICE_PATH = 'XRAY_CONFIGURATION.SET_DEVICE_PATH';
export const XRAY_CONFIGURATION_ADD_OPERATOR = 'XRAY_CONFIGURATION.ADD_OPERATOR';
export const XRAY_CONFIGURATION_SET_LAST_OPERATORS = 'XRAY_CONFIGURATION.SET_SELECTED_OPERATORS';
export const XRAY_CONFIGURATION_DELETE_OPERATOR = 'XRAY_CONFIGURATION.DELETE_OPERATOR';

export const setDevicePath = (devicePath) => ({
  type: XRAY_CONFIGURATION_SET_DEVICE_PATH,
  payload: { devicePath },
});
export const addOperator = (operator) => ({
  type: XRAY_CONFIGURATION_ADD_OPERATOR,
  payload: { operator },
});
export const setSelectedOperators = (operators) => ({
  type: XRAY_CONFIGURATION_SET_LAST_OPERATORS,
  payload: { operators },
});
export const deleteOperator = (operator) => ({
  type: XRAY_CONFIGURATION_DELETE_OPERATOR,
  payload: { operator },
});
