export const SET_PACS_CONFIGURATION = 'SET_PACS_CONFIGURATION';
export const SET_PACS_ENABLED = 'SET_PACS_ENABLED';

export const setPACSConfiguration = (pacsConfiguration) => ({
  type: SET_PACS_CONFIGURATION,
  pacsConfiguration,
});

export const setPACSEnabled = (enabled) => ({
  type: SET_PACS_ENABLED,
  enabled,
});
