// import 'semantic-ui-css/semantic.min.css'
import 'app/styles/style.scss';
import './style.scss';

import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isNotLargeScreen } from 'app/utils/windowUtils';
import { Modal, Button } from 'semantic-ui-react';
import queryString from 'query-string';
import ApiCalls from '../../utils/apiCalls';
import { CHANGE_USERNAME } from '../../redux/global/constants';
import { logout } from '../../redux/global/actions';
import FormattedServerError from '../FormattedServerError';
import NavbarFullSize from './NavbarFullSize';
import NavbarBurger from './NavbarBurger';
import IntlCrawlableLink from '../IntlCrawlableLink';
import { UNAUTHORIZED } from 'app/utils/APICodes';
import isAcquisitionSoftware from 'app/utils/isAcquisitionSoftware';
import { selectAiOnlyConfiguration } from 'app/redux/aiOnlyConfiguration/reducer';
import Immutable from 'immutable';
import { selectPermissionAcquisition, selectPermissions } from 'app/redux/permissions/reducer';

const { electron } = window.nodeModules ?? {};

const FULL_SCREEN_EVENTS = [
  'enter-full-screen',
  'leave-full-screen',
  'enter-html-full-screen',
  'leave-html-full-screen',
  'maximize',
  'unmaximize',
  'minimize',
];
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
    this.electronItems = [
      {
        routes: ['/acquisition'],
        messageId: 'header.acquisition',
        titleMessageId: 'header.acquisition.title',
      },
      {
        routes: ['/online'],
        messageId: 'header.interpretation',
        titleMessageId: 'header.interpretation.title',
      },
      { routes: ['/monitoring'], messageId: 'header.pacs', titleMessageId: 'header.pacs.title' },
    ];

    this.websiteItems = [
      {
        routes: ['/'],
        icon: 'home',
        titleMessageId: 'header.home',
      },
      {
        routes: ['/acquisition'],
        messageId: 'header.acquisition',
        titleMessageId: 'header.acquisition.title',
      },
      {
        routes: ['/online'],
        messageId: 'header.onLine',
        titleMessageId: 'header.online.title',
      },
      {
        routes: ['/monitoring'],
        messageId: 'header.pacs',
        titleMessageId: 'header.pacs.title',
        isLoggedInNeeded: true,
      },
      {
        routes: ['/management'],
        messageId: 'header.management',
        titleMessageId: 'header.management.title',
        isLoggedInNeeded: true,
      },
      {
        routes: ['/admin'],
        messageId: 'header.admin',
        titleMessageId: 'header.admin.title',
        isLoggedInNeeded: true,
      },
    ];

    this.websiteMiddleItems = [
      {
        routes: ['/purchase'],
        messageId: 'header.purchase',
        titleMessageId: 'header.purchase.title',
        hasNotSubscribedNeeded: true,
      },
      {
        menuName: 'header.discover',
        subMenu: [
          {
            routes: ['/picoxia-intelligence'],
            messageId: 'header.picoxIAIntelligence',
            titleMessageId: 'header.picoxIAIntelligence.title',
          },
          {
            routes: ['/picoxia-acquisition'],
            messageId: 'header.picoxIAAcquisition',
            titleMessageId: 'header.picoxia-acquisition.title',
          },
          {
            routes: ['/request-demo'],
            messageId: 'header.requestDemo',
            titleMessageId: 'header.requestDemo.title',
          },
        ],
      },
      {
        menuName: 'header.resources',
        subMenu: [
          {
            routes: ['/download'],
            messageId: 'header.download',
            titleMessageId: 'header.download.title',
          },
          {
            routes: ['/tutorials'],
            messageId: 'header.tutorials',
            titleMessageId: 'header.tutorials.title',
          },
          {
            routes: ['/#scientific-validation'],
            messageId: 'header.scientificValidation',
            titleMessageId: 'header.scientificValidation.title',
          },
          {
            routes: ['/cheat_sheet'],
            messageId: 'header.cheatSheets',
            titleMessageId: 'header.cheatSheets.title',
          },
        ],
      },
    ];
  }

  componentDidMount = () => {
    this.listenOnResize();
    if (this.props.isLoggedIn) {
      this.getUserName();
    }
  };

  componentWillUnmount = () => {
    if (electron) {
      const win = electron.remote.getCurrentWindow();
      FULL_SCREEN_EVENTS.forEach((eventName) => win.removeListener(eventName, this.onResize));
    }
  };

  getWebSiteItems = () => {
    const { hasAcquisitionPermissions, immutablePermissions } = this.props;
    let websiteItems = _.cloneDeep(this.websiteItems);
    const permissions = immutablePermissions.toJS();

    if (!hasAcquisitionPermissions && !isAcquisitionSoftware()) {
      websiteItems = _.reject(websiteItems, { messageId: 'header.acquisition' });
    }
    const isTeamMember = !_.isEmpty(permissions.teams);
    const isManager = permissions.managerPermissions.manager_name;
    const isAdmin = permissions.admin;
    if (!isTeamMember && !isAdmin) {
      websiteItems = _.reject(websiteItems, { messageId: 'header.admin' });
    }
    if (!isTeamMember && !isAdmin && !isManager) {
      websiteItems = _.reject(websiteItems, { messageId: 'header.management' });
    }

    return websiteItems;
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  listenOnResize = () => {
    if (!electron) return;
    const win = electron.remote.getCurrentWindow();
    // We forceUpdate to force refresh of maximize icon.
    FULL_SCREEN_EVENTS.forEach((eventName) => win.on(eventName, this.onResize));
  };

  onResize = () => this.forceUpdate();

  switchFullScreenMode = () => {
    if (!electron) return;
    const win = electron.remote.getCurrentWindow();
    win.setFullScreen(!win.isFullScreen());
    this.forceUpdate();
  };

  getUserName = () => {
    ApiCalls.getMail()
      .then((response) => {
        this.props.dispatch({ type: CHANGE_USERNAME, username: response.data.mail });
        // this.setState({ username: response.data.mail });
      })
      .catch((error) => {
        this.setState({ errorMessage: error });
      });
  };

  getUserNameOnLinkClick = () => {
    if (this.props.isLoggedIn) {
      this.getUserName();
    }
  };

  redirectToLogin = () => {
    this.setState({ errorMessage: null });
    this.props.dispatch(logout({ redirectRoute: '/login' }));
  };

  renderDisconnectedModal = () => {
    const { errorMessage } = this.state;
    const statusCode = errorMessage?.response?.status;
    const isUserDisconnected = statusCode === UNAUTHORIZED;

    if (errorMessage) {
      console.log(errorMessage);
    }

    if (isUserDisconnected) {
      return (
        <Modal onClose={this.redirectToLogin} open={isUserDisconnected}>
          <Modal.Header>
            <FormattedMessage id="header.disconnectedModal.header" />
          </Modal.Header>
          <Modal.Content>
            <FormattedServerError error={errorMessage} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.redirectToLogin}
              content={this.formatMessage({ id: 'header.disconnectedModal.ok' })}
              positive
            />
          </Modal.Actions>
        </Modal>
      );
    }
  };

  linkIsActive = (linkPathName) => {
    const { locale } = this.props;
    if (window.location.pathname.replace('C:/', '') === `/${locale}/online`) {
      const queryStringParsed = queryString.parse(window.location.search, {
        arrayFormat: 'bracket',
      });
      if (queryStringParsed.mode === 'acquisition') {
        const isAcquisitionCheck = linkPathName === `/${locale}/acquisition`;

        if (isAcquisitionCheck) return 'active ';
        return '';
      }
    }
    const pathname = window.location.pathname.replace('C:/', '');
    return `/${locale}${linkPathName}` === pathname ? 'active ' : '';
  };

  getNavigationItemClassName = (linkPathNames, classNameBase) =>
    `${this.linkIsActive(linkPathNames)}${classNameBase}`;

  renderNavigationLink = (linkPathNames, content, titleId, classNameBase, onClickEvent) => {
    const title = titleId ? this.formatMessage({ id: titleId }) : '';
    return (
      <li key={content}>
        <IntlCrawlableLink
          url={linkPathNames[0]}
          key={linkPathNames[0]}
          className={this.getNavigationItemClassName(linkPathNames[0], classNameBase)}
          title={title}
          onClick={() => {
            if (onClickEvent !== undefined) {
              onClickEvent();
            }
            this.getUserNameOnLinkClick();
          }}
        >
          {content}
        </IntlCrawlableLink>
      </li>
    );
  };

  render() {
    const isASmallScreenAndWebsite = isNotLargeScreen() && process.env.PLATFORM === 'website';

    const { style, hasAcquisitionPermissions, isLoggedIn, hasSubscribed, aiOnlyConfiguration } =
      this.props;
    let leftItems =
      process.env.PLATFORM !== 'website' ? this.electronItems : this.getWebSiteItems();
    const middleItems = process.env.PLATFORM !== 'website' ? [] : this.websiteMiddleItems;
    if (!hasAcquisitionPermissions && !isAcquisitionSoftware()) {
      leftItems = _.reject(leftItems, { messageId: 'header.acquisition' });
    }

    /** AI ONLY */
    /** @type {boolean} */
    const aiOnlyEnabled = aiOnlyConfiguration.get('enabled');
    if (aiOnlyEnabled) {
      leftItems = _.reject(leftItems, { messageId: 'header.pacs' });
    }

    return (
      <header>
        {isASmallScreenAndWebsite ? (
          <NavbarBurger
            leftItems={leftItems}
            middleItems={middleItems}
            isLoggedIn={isLoggedIn}
            hasSubscribed={hasSubscribed}
            renderNavigationLink={this.renderNavigationLink}
          />
        ) : (
          <NavbarFullSize
            leftItems={leftItems}
            middleItems={middleItems}
            isLoggedIn={isLoggedIn}
            hasSubscribed={hasSubscribed}
            renderNavigationLink={this.renderNavigationLink}
            isFullScreen={this.isFullScreen}
            switchFullScreenMode={this.switchFullScreenMode}
            style={style}
          />
        )}
        {this.renderDisconnectedModal()}
      </header>
    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  hasAcquisitionPermissions: PropTypes.bool.isRequired,
  hasSubscribed: PropTypes.bool.isRequired,
  aiOnlyConfiguration: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Header.defaultProps = {
  style: {},
};

function mapStateToProps(state) {
  return {
    route: state.get('route'),
    locale: state.get('language').get('locale'),
    isLoggedIn: state.get('global').get('loggedIn'),
    hasSubscribed: state.get('global').get('subscriptions').get('subscribed'),
    hasAcquisitionPermissions: selectPermissionAcquisition(state),
    immutablePermissions: selectPermissions(state),
    aiOnlyConfiguration: selectAiOnlyConfiguration(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header));
