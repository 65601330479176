import enTranslationMessages from './translations/en.json';
import frTranslationMessages from './translations/fr.json';
import deTranslationMessages from './translations/de.json';
import srTranslationMessages from './translations/sr.json';
import elTranslationMessages from './translations/el.json';
import nlTranslationMessages from './translations/nl.json';
import ruTranslationMessages from './translations/ru.json';
import ukTranslationMessages from './translations/uk.json';
import itTranslationMessages from './translations/it.json';
import fiTranslationMessages from './translations/fi.json';
import ptTranslationMessages from './translations/pt.json';
import skTranslationMessages from './translations/sk.json';
import esTranslationMessages from './translations/es.json';
import czTranslationMessages from './translations/cs.json'; // Google use cs to identify Czech language

const translationMessages = {
  en: enTranslationMessages,
  fr: frTranslationMessages,
  de: deTranslationMessages,
  sr: srTranslationMessages,
  el: elTranslationMessages,
  nl: nlTranslationMessages,
  ru: ruTranslationMessages,
  uk: ukTranslationMessages,
  it: itTranslationMessages,
  fi: fiTranslationMessages,
  pt: ptTranslationMessages,
  sk: skTranslationMessages,
  es: esTranslationMessages,
  cz: czTranslationMessages,
};

Object.freeze(translationMessages);

export default translationMessages;
