import './style.css';

import React from 'react';
import * as pt from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Icon } from 'semantic-ui-react';
import * as _ from 'lodash';

import PatientInfoForm from '../PatientInfoForm';
import ApiCalls from '../../utils/apiCalls';

const COMPARED_PATIENT_FIELDS = [
  'file_id',
  'chip_id',
  'pedigree_id',
  'name',
  'owner',
  'owner_name',
  'birth_date',
  'specie',
  'race',
  'sex',
  'attending_veterinarian',
];
const compareInitialDataToPatientData = (initialData, patientData) => {
  const filteredData = _.pick(initialData, COMPARED_PATIENT_FIELDS);
  const filteredPatient = _.pick(patientData, COMPARED_PATIENT_FIELDS);
  return !_.isEqual(filteredData, filteredPatient);
};

class PatientInfoModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      patientInfo: undefined,
      hasConflict: false,
      // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
      patientInfoKey: 0,
    };
  }

  onChange = ({ patientInfo, hasConflict }) => this.setState({ patientInfo, hasConflict });

  savePatient = () => {
    const { onPatientSave, initialPatientData } = this.props;
    const { patientInfo } = this.state;
    if (compareInitialDataToPatientData(initialPatientData, patientInfo)) {
      ApiCalls.savePatientInfo(patientInfo)
        .then(({ data }) => {
          onPatientSave(data);
          this.setState({ open: false });
        })
        .catch((error) => {
          console.error('request error', error);
        });
    } else {
      onPatientSave({ animal: { ...patientInfo, _id: patientInfo.animal_id } });
      this.setState({ open: false });
    }
  };

  render = () => {
    const { initialPatientData } = this.props;
    const { hasConflict, patientInfoKey, open } = this.state;
    return (
      <Modal
        style={{ backgroundColor: '#222222' }}
        trigger={
          <button type="button" className="picoxia">
            <i className="svg-icon pencil" />
          </button>
        }
        open={open}
        closeOnEscape={false}
        onOpen={() => this.setState({ open: true })}
        onClose={() => {
          this.setState((state) => ({
            open: false,
            patientInfoKey: state.patientInfoKey + 1,
          }));
        }}
        size="large"
        closeIcon
      >
        <div className="patient-info-modal">
          <h2>
            <FormattedMessage id="patient_modal.info" />
          </h2>
          <PatientInfoForm
            onChange={this.onChange}
            initialPatientData={initialPatientData}
            key={patientInfoKey}
            className="patient-info"
          />
          <button
            type="button"
            className="picoxia"
            onClick={this.savePatient}
            disabled={hasConflict}
          >
            <Icon name="save" size="big" style={{ backgroundColor: 'transparent' }} />
          </button>
        </div>
      </Modal>
    );
  };
}

PatientInfoModal.propTypes = {
  onPatientSave: pt.func.isRequired,
  initialPatientData: pt.shape({}),
};

PatientInfoModal.defaultProps = {
  initialPatientData: null,
};

export default PatientInfoModal;
