import * as _ from 'lodash';

import { hasTranslation } from '../languageUtil';
import { getVHSValue } from '../../CornerstoneTools/VHSTool';
import {
  auxiliaryPredictions,
  newline,
  PRESENT,
  PROBABLE,
  ABSENT,
  DIFFERENTIAL_DIAGNOSIS,
  OPACITIES,
} from './constants';
import { postProcessPredictions } from '../predictions/postProcessPredictions';
import { isIn, getSeveriteInt, addToMaxSeverity, haveSameSeverity } from './utils';
import GenericPatternsReportGenerator from './GenericPatternsReportGenerator';
import {
  COMMON_PATTERNS_DESCRIPTION,
  THORAX_PATTERNS,
} from '../../components/PatternStructuredList/patternsGroups';

class ThoraxReportGenerator extends GenericPatternsReportGenerator {
  generateReport = (imagesInCase) => {
    const returned = this.getMaxPredictions(imagesInCase);
    const { maxPredictionsFeedback, maxPredictions } = returned;
    // maxPredictions.cardiomegalie = 0.8
    // maxPredictions.scissure_interlobaire = 0.4
    // maxPredictions.epanchement_pleural = 0.
    // maxPredictions.opacite_bronchique = 0.8
    // maxPredictions.opacite_interstitielle = 0.8
    // maxPredictions.opacite_alveolaire = 0.4
    // maxPredictions.cardiomegalie = 0.8
    // maxPredictions.dilatation_ventricule_droit = 0.4
    const numberOfPatternsDetected = this.getNumberOfPatternsToDisplay(
      maxPredictions,
      maxPredictionsFeedback,
      THORAX_PATTERNS
    );
    let report = `${this.formatMessage('thorax').toUpperCase()} :${newline}${newline}`;
    report += this.getVHSReport(imagesInCase);
    const postProcessedPredictions = postProcessPredictions(maxPredictions);
    report += this.getShortCompteRendu(postProcessedPredictions, maxPredictionsFeedback, {
      ...COMMON_PATTERNS_DESCRIPTION,
      ...THORAX_PATTERNS,
    });

    if (numberOfPatternsDetected === 0) {
      report += this.formatMessage('report.thorax.isOk');
    } else if (numberOfPatternsDetected <= 2) {
      report += this.formatMessage('report.thorax.restIsOk');
    }

    report += newline + newline;
    return report;
  };

  getVHSReport = (images) => {
    const VHSValues = images
      .filter((image) => _.get(image, 'annotations.VHS', []).size)
      .map((image) => {
        const vhsData = image.annotations.VHS.values().next().value;
        return getVHSValue(vhsData, 1);
      });

    if (VHSValues.length === 0) return '';

    let report = `${this.formatMessage('report.measure.vhs')} = `;
    VHSValues.forEach((v, i) => {
      report += `${i === 0 ? '' : '; '}${this.formatNumber(v, { style: 'decimal' })} V`;
    });

    return report + newline;
  };

  composeCardiovasculaire = (markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns) => {
    let patternsToRemove = [];
    if (isIn([markedPatterns, presentPatterns, likelyPatterns], 'cardiomegalie')) {
      if (
        isIn([markedPatterns, presentPatterns, likelyPatterns], 'dilatation_ventricule_droit') &&
        isIn([markedPatterns, presentPatterns, likelyPatterns], 'dilatation_ventricule_gauche')
      ) {
        addToMaxSeverity(
          'cardiomegalie globale',
          ['cardiomegalie', 'dilatation_ventricule_droit'],
          [markedPatterns, presentPatterns, likelyPatterns]
        );
        patternsToRemove += [
          'dilatation_ventricule_gauche',
          'dilatation_ventricule_droit',
          'cardiomegalie',
          'dilatation_atrium_gauche',
        ];
      } else if (
        isIn([markedPatterns, presentPatterns, likelyPatterns], 'dilatation_ventricule_droit')
      ) {
        addToMaxSeverity(
          'cardiomegalie droite',
          ['cardiomegalie', 'dilatation_ventricule_droit'],
          [markedPatterns, presentPatterns, likelyPatterns]
        );
        patternsToRemove += ['dilatation_ventricule_droit', 'cardiomegalie'];
      } else if (isIn([unlikelyPatterns], 'dilatation_ventricule_droit')) {
        addToMaxSeverity(
          'cardiomegalie droite suspicion',
          ['cardiomegalie', 'dilatation_ventricule_droit'],
          [markedPatterns, presentPatterns, likelyPatterns]
        );
        patternsToRemove += ['dilatation_ventricule_droit', 'cardiomegalie'];
      } else if (
        isIn([markedPatterns, presentPatterns, likelyPatterns], 'dilatation_ventricule_gauche')
      ) {
        const isDilatationAtriumGauche = isIn(
          [markedPatterns, presentPatterns],
          'dilatation_atrium_gauche'
        );
        const dilatationAtriumGauche = isDilatationAtriumGauche ? ' avec dilatation atriale' : '';
        const dilatationAtriumGaucheList = isDilatationAtriumGauche
          ? ['dilatation_atrium_gauche']
          : [];
        addToMaxSeverity(
          `cardiomegalie gauche${dilatationAtriumGauche}`,
          ['cardiomegalie', 'dilatation_ventricule_gauche'],
          [markedPatterns, presentPatterns, likelyPatterns]
        );
        patternsToRemove +=
          ['dilatation_ventricule_gauche', 'cardiomegalie'] + dilatationAtriumGaucheList;
      } else if (isIn([unlikelyPatterns], 'dilatation_ventricule_gauche')) {
        const isDilatationAtriumGauche = isIn(
          [markedPatterns, presentPatterns],
          'dilatation_atrium_gauche'
        );
        const dilatationAtriumGauche = isDilatationAtriumGauche ? ' avec dilatation atriale' : '';
        const dilatationAtriumGaucheList = isDilatationAtriumGauche
          ? ['dilatation_atrium_gauche']
          : [];
        addToMaxSeverity(
          `cardiomegalie gauche suspicion${dilatationAtriumGauche}`,
          ['cardiomegalie', 'dilatation_ventricule_gauche'],
          [markedPatterns, presentPatterns, likelyPatterns]
        );
        patternsToRemove +=
          ['dilatation_ventricule_gauche', 'cardiomegalie'] + dilatationAtriumGaucheList;
      }
    }
    return patternsToRemove;
  };

  composeOpaciteMixte = (markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns) => {
    let patternsToRemove = [];
    const severiteAlveolaire = getSeveriteInt(
      'opacite_alveolaire',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    const severiteInterstielle = getSeveriteInt(
      'opacite_interstitielle',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    const severiteBronchique = getSeveriteInt(
      'opacite_bronchique',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    if (
      severiteInterstielle > ABSENT &&
      severiteAlveolaire > ABSENT &&
      severiteBronchique > ABSENT
    ) {
      if (
        severiteAlveolaire === severiteInterstielle &&
        severiteInterstielle === severiteBronchique
      ) {
        addToMaxSeverity(
          'opacite mixte',
          ['opacite_alveolaire', 'opacite_interstitielle', 'opacite_bronchique'],
          [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle', 'opacite_bronchique'];
      } else if (severiteAlveolaire === severiteInterstielle) {
        addToMaxSeverity(
          'opacite alveolo-interstitielle',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
        );
        this.join('opacite_bronchique', 'opacite alveolo-interstitielle', [
          markedPatterns,
          presentPatterns,
          likelyPatterns,
          unlikelyPatterns,
        ]);
        patternsToRemove += [
          'opacite_alveolaire',
          'opacite_interstitielle',
          'opacite_bronchique',
          'opacite alveolo-interstitielle',
        ];
      } else if (severiteInterstielle === severiteBronchique) {
        addToMaxSeverity(
          'opacite broncho-interstitielle',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
        );
        this.join('opacite_alveolaire', 'opacite broncho-interstitielle', [
          markedPatterns,
          presentPatterns,
          likelyPatterns,
          unlikelyPatterns,
        ]);
        patternsToRemove += [
          'opacite_alveolaire',
          'opacite_interstitielle',
          'opacite_bronchique',
          'opacite broncho-interstitielle',
        ];
      } else {
        let domite;
        if (
          severiteAlveolaire === severiteBronchique &&
          severiteAlveolaire > severiteInterstielle
        ) {
          domite = 'report.domites.alveolaire et bronchique';
        } else if (severiteInterstielle > Math.max(severiteBronchique, severiteAlveolaire)) {
          domite = 'report.domites.interstitielle';
        } else if (severiteBronchique > Math.max(severiteInterstielle, severiteAlveolaire)) {
          domite = 'report.domites.bronchique';
        } else if (severiteAlveolaire > Math.max(severiteBronchique, severiteInterstielle)) {
          domite = 'report.domites.alveolaire';
        }
        const textToAdd = this.formatMessage(domite);
        addToMaxSeverity(
          textToAdd,
          ['opacite_alveolaire', 'opacite_interstitielle', 'opacite_bronchique'],
          [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
        );
        patternsToRemove += [
          'opacite_alveolaire',
          'opacite_interstitielle',
          'opacite_bronchique',
          'opacite broncho-interstitielle',
        ];
      }
    }

    return patternsToRemove;
  };

  composeBronchoInterstitiel = (
    markedPatterns,
    presentPatterns,
    likelyPatterns,
    unlikelyPatterns
  ) => {
    let patternsToRemove = [];
    if (
      haveSameSeverity(
        ['opacite_bronchique', 'opacite_interstitielle'],
        [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
      )
    ) {
      addToMaxSeverity(
        'opacite broncho-interstitielle',
        ['opacite_bronchique', 'opacite_interstitielle'],
        [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
      );
      patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
    } else if (isIn([markedPatterns], 'opacite_bronchique')) {
      if (isIn([presentPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite bronchique a interstitielle',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      } else if (isIn([likelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite bronchique a interstitielle probable',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      } else if (isIn([unlikelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite bronchique a interstitielle suspicion',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      }
    } else if (isIn([presentPatterns], 'opacite_bronchique')) {
      if (isIn([markedPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle a bronchique',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      } else if (isIn([likelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite bronchique presente et interstitielle probable',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      } else if (isIn([unlikelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite bronchique presente et interstitielle suspicion',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      }
    } else if (isIn([likelyPatterns], 'opacite_bronchique')) {
      if (isIn([markedPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle a bronchique probable',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      } else if (isIn([presentPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle presente et bronchique probable',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      }
    } else if (isIn([unlikelyPatterns], 'opacite_bronchique')) {
      if (isIn([markedPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle a bronchique suspicion',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      } else if (isIn([presentPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle presente et bronchique suspicion',
          ['opacite_bronchique', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_bronchique', 'opacite_interstitielle'];
      }
    }
    return patternsToRemove;
  };

  composeAlveoloInterstitiel = (
    markedPatterns,
    presentPatterns,
    likelyPatterns,
    unlikelyPatterns
  ) => {
    let patternsToRemove = [];
    if (
      haveSameSeverity(
        ['opacite_alveolaire', 'opacite_interstitielle'],
        [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
      )
    ) {
      addToMaxSeverity(
        'opacite alveolo-interstitielle',
        ['opacite_alveolaire', 'opacite_interstitielle'],
        [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
      );
      patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
    } else if (isIn([markedPatterns], 'opacite_alveolaire')) {
      if (isIn([presentPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite alveolaire a interstitielle',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      } else if (isIn([likelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite alveolaire a interstitielle probable',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      } else if (isIn([unlikelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite alveolaire a interstitielle suspicion',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      }
    } else if (isIn([presentPatterns], 'opacite_alveolaire')) {
      if (isIn([markedPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle a alveolaire',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      } else if (isIn([likelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite alveolaire presente et interstitielle probable',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      } else if (isIn([unlikelyPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite alveolaire presente et interstitielle suspicion',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      }
    } else if (isIn([likelyPatterns], 'opacite_alveolaire')) {
      if (isIn([markedPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle a alveolaire probable',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      } else if (isIn([presentPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle presente et alveolaire probable',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      }
    } else if (isIn([unlikelyPatterns], 'opacite_alveolaire')) {
      if (isIn([markedPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle a alveolaire suspicion',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [markedPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      } else if (isIn([presentPatterns], 'opacite_interstitielle')) {
        addToMaxSeverity(
          'opacite interstitielle presente et alveolaire suspicion',
          ['opacite_alveolaire', 'opacite_interstitielle'],
          [presentPatterns]
        );
        patternsToRemove += ['opacite_alveolaire', 'opacite_interstitielle'];
      }
    }
    return patternsToRemove;
  };

  composePulmonaire = (markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns) => {
    let patternsToRemove = [];
    patternsToRemove += this.composeOpaciteMixte(
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    if (patternsToRemove.length === 0) {
      patternsToRemove += this.composeBronchoInterstitiel(
        markedPatterns,
        presentPatterns,
        likelyPatterns,
        unlikelyPatterns
      );
      patternsToRemove += this.composeAlveoloInterstitiel(
        markedPatterns,
        presentPatterns,
        likelyPatterns,
        unlikelyPatterns
      );
    }
    return patternsToRemove;
  };

  composePleural = (markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns) => {
    let patternsToRemove = [];
    const severiteEpanchement = getSeveriteInt(
      'epanchement_pleural',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    const severiteScissures = getSeveriteInt(
      'scissure_interlobaire',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    if (severiteEpanchement >= PRESENT) {
      patternsToRemove.push('scissure_interlobaire');
    } else if (severiteEpanchement > ABSENT) {
      if (severiteScissures >= PRESENT) {
        addToMaxSeverity(
          'scissures et epanchement',
          ['scissure_interlobaire', 'epanchement_pleural'],
          [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
        );
        patternsToRemove += ['scissure_interlobaire', 'epanchement_pleural'];
      } else if (severiteScissures > ABSENT) {
        addToMaxSeverity(
          'scissures probables et epanchement',
          ['scissure_interlobaire', 'epanchement_pleural'],
          [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
        );
        patternsToRemove += ['scissure_interlobaire', 'epanchement_pleural'];
      }
    } else if (severiteEpanchement === ABSENT) {
      if (severiteScissures > ABSENT) {
        addToMaxSeverity(
          'scissures sans epanchement',
          ['scissure_interlobaire', 'epanchement_pleural'],
          [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
        );
        patternsToRemove += ['scissure_interlobaire', 'epanchement_pleural'];
      }
    }
    return patternsToRemove;
  };

  composeDeviationTrachealeCardiomegalie = (
    markedPatterns,
    presentPatterns,
    likelyPatterns,
    unlikelyPatterns
  ) => {
    const severiteDeviationTracheale = getSeveriteInt(
      'deviation_tracheale',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    let patternsToRemove = [];
    if (severiteDeviationTracheale > ABSENT) {
      // addToMaxSeverity("deviation tracheale dorsale", ["deviation_tracheale", "cardiomegalie"], [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns])
      this.associate('cardiomegalie', 'deviation_tracheale', 'deviation tracheale dorsale', [
        markedPatterns,
        presentPatterns,
        likelyPatterns,
        unlikelyPatterns,
      ]);
      patternsToRemove += ['cardiomegalie', 'deviation_tracheale'];
    }
    return patternsToRemove;
  };

  composeDeviationTrachealeDilatationOesophage = (
    markedPatterns,
    presentPatterns,
    likelyPatterns,
    unlikelyPatterns
  ) => {
    const severiteDeviationTracheale = getSeveriteInt(
      'deviation_tracheale',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    let patternsToRemove = [];
    if (severiteDeviationTracheale > ABSENT) {
      // addToMaxSeverity("deviation tracheale ventrale", ["deviation_tracheale", "dilatation_oesophagienne"], [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns])
      this.associate(
        'dilatation_oesophagienne',
        'deviation_tracheale',
        'deviation tracheale ventrale',
        [markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns]
      );
      patternsToRemove += ['dilatation_oesophagienne', 'deviation_tracheale'];
    }
    return patternsToRemove;
  };

  composeTrachee = (markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns) => {
    const severiteCardiomegalie = getSeveriteInt(
      'cardiomegalie',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    const severiteDilatationOesophage = getSeveriteInt(
      'dilatation_oesophagienne',
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    let patternsToRemove = [];
    if (severiteCardiomegalie >= severiteDilatationOesophage && severiteCardiomegalie >= PROBABLE) {
      patternsToRemove += this.composeDeviationTrachealeCardiomegalie(
        markedPatterns,
        presentPatterns,
        likelyPatterns,
        unlikelyPatterns
      );
    } else if (severiteDilatationOesophage >= PROBABLE) {
      patternsToRemove += this.composeDeviationTrachealeDilatationOesophage(
        markedPatterns,
        presentPatterns,
        likelyPatterns,
        unlikelyPatterns
      );
    }
    return patternsToRemove;
  };

  composePatterns = (markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns) => {
    let patternsToRemove = [];
    patternsToRemove += this.composeCardiovasculaire(
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    patternsToRemove += this.composePulmonaire(
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    patternsToRemove += this.composeTrachee(
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    patternsToRemove += this.composePleural(
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
    return this.removePatterns(
      patternsToRemove,
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );
  };
}

const getThoraxReport = (imagesInCase, intl) => {
  const thoraxReportGenerator = new ThoraxReportGenerator(intl);
  return thoraxReportGenerator.generateReport(imagesInCase);
};

export default getThoraxReport;
