import _ from 'lodash';
import electron from 'app/native/node/electron';
import path from 'app/native/node/path';
import fs from 'app/native/node/fs';
import loadImage from 'app/utils/cornerstone/loadImage';

export default async function loadXRayAcquisitionDemoImages() {
  if (!(electron() && fs() && path())) return [];

  const builtInDemoAppImagesDir = path().join(
    electron().remote.app.getAppPath(),
    'build_electron_main/demo_xrays'
  );
  try {
    const filenames = await fs().promises.readdir(builtInDemoAppImagesDir);

    let images = await Promise.all(
      filenames.map(async (filename) => {
        try {
          const image = await loadImage({
            imageFile: {
              path: path().join(builtInDemoAppImagesDir, filename),
              isFromFileSystem: true,
            },
          });
          const { width, height, minPixelValue, maxPixelValue } = image.cornerstoneImage;
          const data = image.cornerstoneImage.getPixelData();
          return {
            data,
            bytes_per_pixel: data.BYTES_PER_ELEMENT,
            width,
            height,
            minPixelValue,
            maxPixelValue,
          };
        } catch {
          //
        }
        return undefined;
      })
    );
    images = _.filter(images);

    return images;
  } catch {}
  return [];
}
