import 'semantic-ui-css/semantic.min.css';

import 'app/styles/style.scss';
import './style.css';

import { connect } from 'react-redux';
import React from 'react';

import * as pt from 'prop-types';
import * as cornerstone from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import { cloneDeep } from 'lodash';

import ToolType from '../../types/ToolType';

// import cornerstoneWebImageLoader from 'cornerstone-web-image-loader'
import clearElement from '../../CornerstoneTools/clearElement';
import loadImage, { loadRawUint16 } from '../../utils/cornerstone/loadImage';
import loadImageInCanvas from '../../CornerstoneTools/loadImageInCanvas';
import resetCursor from '../../CornerstoneTools/resetCursor';
import { ElementToolStateManager } from '../../CornerstoneTools/ElementToolStateManager';
import { CROP_EVENTS, isCropInProgress } from '../../CornerstoneTools/CropTool';

cst.init({
  mouseEnabled: true,
  touchEnabled: true,
  globalToolSyncEnabled: false,
  showSVGCursors: true,
});

const TOOL_STATE_FUNCTIONS_MAP = {
  active: cst.setToolActiveForElement,
  disabled: cst.setToolDisabledForElement,
  enabled: cst.setToolEnabledForElement,
  passive: cst.setToolPassiveForElement,
};

const MEASUREMENT_EVENTS = [
  cst.EVENTS.MEASUREMENT_ADDED,
  cst.EVENTS.MEASUREMENT_MODIFIED,
  cst.EVENTS.MEASUREMENT_COMPLETED,
  cst.EVENTS.MEASUREMENT_REMOVED,
];

const IMAGE_UPDATE_EVENTS = [
  cornerstone.EVENTS.NEW_IMAGE,
  cornerstone.EVENTS.IMAGE_RENDERED,
  cornerstone.EVENTS.IMAGE_LOAD_FAILED,
];

const disableContextMenu = (e) => e.preventDefault();

class RadioImage extends React.Component {
  constructor(props) {
    super(props);
    this.element = null;
    this.resizeObserver = null;
    this.isImageLoading = false;
    this.isImageLoadedTriggered = false;
    this.lastProcessedImage = undefined;
  }

  componentDidMount = () => {
    const { image } = this.props;

    if (image) {
      const isImageProcessed = !!image.processedImage;
      if (isImageProcessed) {
        this.loadProcessedImage(image.processedImage);
      } else {
        this.onNewImage(image);
      }
    }
  };

  componentWillUnmount = () => {
    // Remove all listeners
    this.resizeObserver.disconnect();
    if (this.element) {
      MEASUREMENT_EVENTS.forEach((evtType) => {
        this.element.removeEventListener(evtType, this.onMeasurementUpdate);
      });

      IMAGE_UPDATE_EVENTS.forEach((evtType) => {
        this.element.removeEventListener(evtType, this.imageStateListener);
      });
      clearElement(this.element);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { image } = this.props;
    if (image) {
      const isImageProcessed = !!image.processedImage;

      if (image.imageFile !== prevProps.image.imageFile) {
        this.onNewImage(image);
      } else if (isImageProcessed && image.processedImage !== this.lastProcessedImage) {
        this.loadProcessedImage(image.processedImage);
      } else {
        const { image: csImage } = cornerstone.getEnabledElement(this.element);
        if (!csImage && !this.isImageLoading) {
          if (!isImageProcessed) {
            // If no cornerstone did not load any image, we must render one.
            // However big dicom can take a long time to be read, we should wait for loading to
            // finish.
            this.onNewImage(image);
          } else {
            this.loadProcessedImage(image.processedImage);
          }
        }
      }
      this.setupCornerstoneTools(prevProps);
    }
  };

  onCornerstoneRef = (e) => {
    const { cornerstoneRef } = this.props;
    cornerstoneRef(e);
    this.element = e;

    // When the component unmount, the callback will be called with null.
    if (!e) return;
    cornerstone.enable(this.element);
    cst.setElementToolStateManager(this.element, new ElementToolStateManager(this.element));
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    this.resizeObserver = new ResizeObserver(this.handleResize);

    this.resizeObserver.observe(this.element);

    // Add listener for all measurement events
    MEASUREMENT_EVENTS.forEach((evtType) => {
      this.element.addEventListener(evtType, this.onMeasurementUpdate);
    });
    this.element.addEventListener(CROP_EVENTS.CROP_ACTIVATED, () => this.forceUpdate());
    IMAGE_UPDATE_EVENTS.forEach((evtType) => {
      this.element.addEventListener(evtType, this.imageStateListener);
    });
  };

  handleResize = () => {
    const { element } = this;

    try {
      const viewport = cornerstone.getViewport(element);
      // Force fit if no scale is yet set
      const shouldFitWindow = !viewport?.scale;
      cornerstone.resize(element, shouldFitWindow);
    } catch {}
  };

  triggerImageLoaded = () => {
    const { image, onImageLoaded } = this.props;

    if (!image.analyzed) return;

    const callImageLoadedWithCanvas = ({ canvas }) => {
      const dataUrl = canvas.toDataURL('image/jpeg');
      onImageLoaded(dataUrl, image);
      this.isImageLoadedTriggered = true;
    };

    const { image: csImage } = cornerstone.getEnabledElement(this.element);
    if (onImageLoaded) {
      loadImageInCanvas(csImage, callImageLoadedWithCanvas);
    } else {
      this.isImageLoadedTriggered = true;
    }
  };

  imageStateListener = (evt) => {
    if (evt.type === cornerstone.EVENTS.NEW_IMAGE) {
      this.isImageLoading = true;
      this.isImageLoadedTriggered = false;
    } else if (evt.type === cornerstone.EVENTS.IMAGE_RENDERED) {
      this.isImageLoading = false;
      if (!this.isImageLoadedTriggered) {
        this.triggerImageLoaded();
      }
    } else if (evt.type === cornerstone.EVENTS.IMAGE_LOAD_FAILED) {
      this.isImageLoading = false;
    }
  };

  onMeasurementUpdate = (evt) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onMeasurementUpdate(evt);
  };

  setupCornerstoneTools = (prevProps) => {
    const { toolsList, toolsEnabled } = this.props;
    // Early exits in case there is no changes, no image displayed or tools should not be shown.
    if (!cornerstone.getImage(this.element)) return;
    if (!toolsEnabled) return;

    let mouseButtonHasActiveTool = false;
    let noToolChange = false;
    Object.keys(toolsList).forEach((toolName) => {
      if (prevProps && prevProps.toolsList[toolName] === toolsList[toolName]) {
        return;
      }
      noToolChange = true;
      const { tool, state, options } = toolsList[toolName];
      if (state === 'active' && options.mouseButtonMask === 1) {
        mouseButtonHasActiveTool = true;
      }

      // cornerstone mutate its parameter so we pass it a clone
      const clonedOptions = cloneDeep(options);
      let currentTool = cst.getToolForElement(this.element, toolName);
      if (!currentTool) {
        cst.addToolForElement(this.element, tool, clonedOptions);
        currentTool = cst.getToolForElement(this.element, toolName);
      }
      if (currentTool.mode !== state) {
        const { supportedInteractionTypes } = clonedOptions ?? {};
        TOOL_STATE_FUNCTIONS_MAP[state](
          this.element,
          toolName,
          clonedOptions,
          supportedInteractionTypes
        );
      }
    });
    if (!mouseButtonHasActiveTool && noToolChange) resetCursor(this.element);
  };

  onNewImage = (image, attempts = 0) => {
    this.isImageLoading = true;
    this.drawImageInCanvas(image).catch((error) => {
      console.log('Load image failed', error);
      if (attempts < 5) {
        setTimeout(() => {
          this.onNewImage(image, attempts + 1);
        }, 2000);
      } else {
        console.log('Failed to load this image.');
      }
    });
  };

  drawImageInCanvas = (image) => {
    if (!image.imageFile) {
      return Promise.resolve();
    }
    return loadImage(image).then(({ cornerstoneImage, imageId, metadata }) => {
      image.imageId = imageId;
      if (metadata) {
        image.metadata = metadata;
        image.animalName = metadata.animalName;
        image.ownerName = metadata.ownerName;
      }
      const { processCornerstoneImage } = this.props;
      if (processCornerstoneImage?.(cornerstoneImage)) {
        // We do not load image image yet since a processing is ongoing
      } else {
        this.displayCornerstoneImageInElement(cornerstoneImage);
      }
    });
  };

  loadProcessedImage = (processedImage) => {
    if (processedImage === this.lastProcessedImage) return;
    loadRawUint16(processedImage).then(({ cornerstoneImage }) => {
      this.displayCornerstoneImageInElement(cornerstoneImage, false);
    });
    this.lastProcessedImage = processedImage;
  };

  displayCornerstoneImageInElement = (cornerstoneImage, reset = true) => {
    const { element } = this;
    // Disable + enable will reset the cornerstone context, remove all tools and allow the image
    // to be displayed properly
    if (reset) {
      cornerstone.disable(element);
      cornerstone.enable(element);
      cst.setElementToolStateManager(this.element, new ElementToolStateManager(this.element));
    }

    cornerstone.displayImage(element, cornerstoneImage, {
      voi: {
        windowWidth: cornerstoneImage.windowWidth,
        windowCenter: cornerstoneImage.windowCenter,
      },
      invert: cornerstoneImage.invert,
    });

    // Calling resize here is need since we saw some images being displayed with invalid aspect
    // ratio.
    if (reset) cornerstone.resize(element, true);
    // Tools are setup again since context was reset.
    this.setupCornerstoneTools();
    cornerstone.updateImage(element, true);
  };

  render() {
    const { className } = this.props;
    return (
      <div className={`${className} radio-image-container`}>
        <div
          onContextMenu={disableContextMenu}
          style={{ height: '100%', width: '100%' }}
          ref={this.onCornerstoneRef}
        >
          <canvas className="cornerstone-canvas" style={{ objectFit: 'none' }} />
        </div>
      </div>
    );
  }
}

RadioImage.propTypes = {
  image: pt.shape({
    imageFile: pt.oneOfType([pt.shape(), pt.string]),
    backendId: pt.string,
    analyzed: pt.bool,
    processedImage: pt.shape({}),
  }),
  processCornerstoneImage: pt.func,
  onImageLoaded: pt.func,
  toolsList: pt.objectOf(ToolType),
  cornerstoneRef: pt.func,
  onMeasurementUpdate: pt.func,
  toolsEnabled: pt.bool,
  className: pt.string,
};

RadioImage.defaultProps = {
  image: null,
  onImageLoaded: null,
  processCornerstoneImage: null,
  cornerstoneRef: () => {},
  onMeasurementUpdate: () => {},
  toolsList: {},
  toolsEnabled: false,
  className: '',
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RadioImage);
