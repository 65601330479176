import { isString } from 'lodash';

import {
  addToMaxSeverity,
  capitalizeFirstLetter,
  getSeverite,
  isLeftSeverityWorst,
  lowerCaseFirstLetter,
  removeTrailingDot,
} from './utils';

class BaseReportGenerator {
  constructor(intl) {
    this.intl = intl;
    this.formatMessage = (obj, ...args) => {
      if (typeof obj === 'string' || obj instanceof String) {
        return intl.formatMessage({ id: obj }, ...args);
      }
      return intl.formatMessage(obj, ...args);
    };
    this.formatNumber = intl.formatNumber;
  }

  formatMessageOrReturn = (intlKeyOrText) => {
    if (this.intl[intlKeyOrText]) return this.formatMessage(intlKeyOrText);
    return intlKeyOrText;
  };

  /**
   * Translate a given pattern into the locale.
   * Pattern can be either a [string] representation the intl key or an already translated message
   * or an array of parameters to pass to the formatMessage function.
   * @param {*} pattern String or array of the form [identifier, intl_key, args]
   * @param {*} severity Severity level use to pick alternative translation.
   * @returns Input pattern translated into text form.
   */
  formatPattern = (pattern, severity) => {
    const [patternName, ...args] = isString(pattern) ? [pattern] : pattern.slice(1);
    let possibleIntlKeys = [`report.patterns.${patternName}`, `${patternName}`];
    if (severity) {
      const severityString = `_${severity.replace(/Patterns?/i, '')}`;
      const patternNameWithSeverity = patternName + severityString;
      possibleIntlKeys = [
        `report.patterns.${patternNameWithSeverity}`,
        `${patternNameWithSeverity}`,
        ...possibleIntlKeys,
      ];
    }
    const matchingKey = possibleIntlKeys.find(
      (intlKey) => this.intl.messages[intlKey] !== undefined
    );
    if (matchingKey) return this.formatMessage(matchingKey, ...args);

    return patternName;
  };

  formatMessageIfPlural = (messageId, isPlural) => {
    const plural = isPlural ? '_plural' : '';
    if (this.intl.messages[messageId + plural]) {
      return this.formatMessage(messageId + plural);
    }
    return this.formatMessage(messageId);
  };

  getFeminin = (pattern) => {
    if (this.formatMessage(pattern)) {
      return '_feminin';
    }
    return '';
  };

  enumeratePatterns = (patternsList, finalUnion = 'report.and', severity = undefined) =>
    this.enumerate(patternsList, finalUnion, (patternKey) =>
      this.formatPattern(patternKey, severity)
    );

  enumerate = (
    intlKeys,
    finalUnion = 'report.and',
    intlKeyFormatFunction = this.formatMessageOrReturn
  ) => {
    let translatedEnumeration = '';
    intlKeys.forEach((intlKey, i) => {
      const patternString = removeTrailingDot(lowerCaseFirstLetter(intlKeyFormatFunction(intlKey)));
      if (i === 0) {
        translatedEnumeration += patternString;
      } else if (i === intlKeys.length - 1) {
        translatedEnumeration += ` ${this.formatMessage(finalUnion)} ${patternString}`;
      } else {
        translatedEnumeration += `, ${patternString}`;
      }
    });
    return translatedEnumeration;
  };

  compose = (pattern1, pattern2, listOfLists, composition) => {
    const severite1 = getSeverite(pattern1, ...listOfLists);
    const severite2 = getSeverite(pattern2, ...listOfLists);
    let pattern;
    if (isLeftSeverityWorst(severite1, severite2)) {
      pattern = `${this.formatMessage(`report.patterns.${pattern1}`)}. ${capitalizeFirstLetter(
        this.formatMessage(`report.${composition}_${severite2}${this.getFeminin(pattern2)}`, [
          this.formatMessage(`report.patterns.${pattern2}`),
        ])
      )}`;
    } else {
      pattern = `${this.formatMessage(`report.patterns.${pattern2}`)}. ${capitalizeFirstLetter(
        this.formatMessage(`report.${composition}_${severite1}${this.getFeminin(pattern1)}`, [
          this.formatMessage(`report.patterns.${pattern1}`),
        ])
      )}`;
    }
    addToMaxSeverity(pattern, [pattern1, pattern2], listOfLists);
  };

  join = (pattern1, pattern2, listOfLists) => {
    this.compose(pattern1, pattern2, listOfLists, 'aussi');
  };

  associate = (pattern1, pattern2, resultingPattern, listOfLists) => {
    const severite2 = getSeverite(pattern2, ...listOfLists);
    const pattern = `${this.formatMessage(
      `report.patterns.${pattern1}`
    )} ${this.formatMessage(`report.associe_${severite2}${this.getFeminin(pattern1)}`, [
      this.formatMessage(`report.patterns.${resultingPattern}`),
    ])}`;
    addToMaxSeverity(pattern, [pattern1, pattern2], listOfLists);
  };
}

export default BaseReportGenerator;
