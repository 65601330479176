import _ from 'lodash';
import { ImageKind, ColorModel, Image } from 'image-js';

import { DisplayableImageData } from 'app/interfaces/Image';
import { Transformation } from 'app/interfaces/Image/Transformation';

export type TransformableImage = Image;

// Operation is done in place
function fitWWWC(img: Image, voi: { windowWidth: number; windowCenter: number }): Image {
  const { windowCenter, windowWidth } = voi;
  if (!(windowCenter && windowWidth)) return img;
  const maxValue = Math.pow(2, img.bitDepth) - 1;
  const channels = img.channels;
  const components = img.components;
  const offsetFromCenter = maxValue / 2 - voi.windowCenter;
  const scale = maxValue / windowWidth;

  if (offsetFromCenter === 0 && scale === 1) return img;

  for (let i = 0; i < img.data.length; i += channels) {
    for (let c = 0; c < components; c++) {
      const value = Math.round((img.data[i + c] - windowCenter) * scale + maxValue / 2);

      img.data[i + c] = Math.max(0, Math.min(maxValue, value));
    }
  }

  return img;
}

export function convertDisplayableImageToTransformableImage(
  image: DisplayableImageData
): TransformableImage | undefined {
  if (!image?.getPixelData()) return undefined;

  const pixelData = image.getPixelData() as unknown as Uint16Array | Uint8Array;

  const kind = image.color ? 'RGBA' : 'GREY';
  const colorModel = kind !== 'GREY' ? 'RGB' : 'GREY';
  const bitDepth = colorModel === 'GREY' ? pixelData.BYTES_PER_ELEMENT * 8 : 8;

  return new Image({
    data: pixelData,
    width: image.width,
    height: image.height,
    kind: kind as ImageKind,
    bitDepth,
    colorModel: colorModel as ColorModel,
  });
}

export function transformImage(
  img: TransformableImage,
  transformations: Transformation
): Image | undefined {
  const { crop, voi, invert, rotation, hflip, vflip, resize } = transformations;

  if (crop) img = img.crop(crop);
  if (resize) {
    let { width: resizeWidth, height: resizeHeight } = resize;

    if (resizeWidth && resizeHeight) {
      // We rescale before doing other operation to make other operations faster since we are usually resizing to lower image size.
      const widthScalingFactor = resizeWidth / img.width;
      const heightScalingFactor = resizeHeight / img.height;

      // Since we use the preserve Ratio option, we only keep the dimension that would make the image fit the resize dimension will preserving aspect ratio.
      if (widthScalingFactor < heightScalingFactor) {
        resizeHeight = undefined;
      } else {
        resizeWidth = undefined;
      }
    }

    // We only apply resize if resize dimension is different from the original image dimension.
    if (resizeWidth && resizeWidth !== img.width) {
      img = img.resize({ width: resizeWidth, preserveAspectRatio: true });
    } else if (resizeHeight && resizeHeight !== img.height) {
      img = img.resize({ height: resizeHeight, preserveAspectRatio: true });
    }
  }

  if (hflip) img = img.flipX();
  if (vflip) img = img.flipY();
  if (rotation) img = img.rotate(rotation);
  // VOI act on non inverted pixel, WWWC fit should be done before inversion
  if (voi) img = fitWWWC(img, voi);
  if (invert) img = img.invert({ inPlace: true });

  return img;
}

export function toCanvasDisplayableImage(transformableImage: TransformableImage) {
  const rgbaData = transformableImage.getRGBAData({ clamped: true }) as Uint8ClampedArray;

  return {
    data: rgbaData,
    size: { width: transformableImage.width, height: transformableImage.height },
  };
}

export function toBlob(transformableImage: TransformableImage, type: string, quality?: number) {
  return transformableImage.toBlob(type, quality);
}

export function prepareImageForCanvasDisplay(
  image: DisplayableImageData,
  transformations: Transformation
) {
  const transformableImage = convertDisplayableImageToTransformableImage(image);
  if (!transformableImage) return undefined;

  const transformedImage = transformImage(transformableImage, transformations);

  return toCanvasDisplayableImage(transformedImage);
}
