import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Modal } from 'semantic-ui-react';
import MinDurationPromise from 'app/utils/MinDurationPromise';

const UnsavedDataModal = ({ retrySaveData, leavePage, cancel }) => {
  const [saveOngoing, setSaveOngoing] = useState(false);

  const retrySave = useCallback(async () => {
    setSaveOngoing(true);
    try {
      await MinDurationPromise(retrySaveData(), 1000);
    } catch {}
    setSaveOngoing(false);
  }, [retrySaveData]);

  return (
    <Modal
      open={retrySaveData !== undefined}
      closeIcon
      onClose={cancel}
      centered={false}
      dimmer={<Modal.Dimmer style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} />}
    >
      <Modal.Header>
        <FormattedMessage id="dropzone.unsaved_data_modal.header" />
      </Modal.Header>
      <Modal.Content>
        <FormattedMessage id="dropzone.unsaved_data_modal.content" />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={retrySave} style={saveOngoing ? { pointerEvent: 'none' } : {}}>
          {saveOngoing && <Icon name="sync" loading />}
          <FormattedMessage id="dropzone.unsaved_data_modal.retry" />
        </Button>
        <Button onClick={leavePage} secondary>
          <FormattedMessage id="dropzone.unsaved_data_modal.leave_page" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UnsavedDataModal;
