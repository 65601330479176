/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Helmet from 'react-helmet';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import 'app/styles/style.scss';
import './style.scss';
import { Label, Icon, Confirm, Input, Popup, Dimmer, Loader } from 'semantic-ui-react';
import * as _ from 'lodash';
import DatePicker from 'react-datepicker';
import { browserHistory } from 'react-router';
import { getDefaultAnatomicRegion } from 'app/utils/xrayRegions';
import { DEFAULT_SPECIE } from 'app/utils/speciesConstants';
import isAcquisitionSoftware from 'app/utils/isAcquisitionSoftware';
import DisplayDate from '../../components/DisplayDate';
import ApiCalls, { formatAnimalData } from '../../utils/apiCalls';
import PatientInfoForm from '../../components/PatientInfoForm';
import Exams from '../../components/Exams';
import WorkListImages from '../../components/WorkListImages';
import ProtocolExams from '../../components/ProtocolExams';
import ExamSelector from '../../components/ExamSelector';
import { isAcquisitionImage } from '../../utils/isAcquisitionImage';
import { ImageCacheProvider } from '../ImageCache';
import withContext from 'app/utils/withContext';
import { PMSParserContext } from 'app/providers/PMSIntegration/PMSParserProvider';

const InputDate = React.forwardRef(
  ({ onChange, placeholder, value, isSecure, id, onClick }, ref) => (
    <div className="inputDate">
      <Input
        inverted
        className="inputDate"
        backgroundcolor="#5e5e5e"
        icon="calendar"
        iconPosition="left"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        id={id}
        onClick={onClick}
        transparent
      />
    </div>
  )
);

const checkPatientFilled = (patient) => {
  const BASE_ORIGIN = { specie: DEFAULT_SPECIE };
  if (patient === undefined || _.isEqual(patient, BASE_ORIGIN)) return false;
  return (
    patient.file_id !== undefined ||
    (patient.owner_name !== undefined && patient.name !== undefined)
  );
};

const saveAndLinkPatientIfNeeded = async (patient, studyId) => {
  try {
    if (checkPatientFilled(patient)) {
      const { data } = await ApiCalls.savePatientInfo(patient);
      return await ApiCalls.linkStudyToAnimal(studyId, data.animal._id);
    }
  } catch (error) {
    console.warn('saveAndLinkPatientIfNeeded error', error);
    return Promise.reject(error);
  }
  return undefined;
};

export class AcquisitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workList: [],
      open: false,
      studyToDelete: {},
      hasConflict: false,
      patientInfoKey: 0,
      patient: {
        specie: DEFAULT_SPECIE,
      },
      examDate: new Date(),
      exams: [],
      problem: false,
      cause: '',
      dataLost: false,
      cancel: false,
      studyLoaded: {},
      protocols: [],
      protocolSelected: null,
      protocolsDisplay: true,
      planOngoing: false,
      acquireOngoing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pmsParser !== this.props.pmsParser) {
      this.registerParserListener(prevProps.pmsParser, this.props.pmsParser);
    }
  }

  componentDidMount = () => {
    this.getWorkList();
    this.getProtocols();
    this.registerParserListener(undefined, this.props.pmsParser);
  };

  componentWillUnmount = () => {
    const { pmsParser } = this.props;
    pmsParser?.removeEventListener('file_parsed', this.getWorkList);
  };

  registerParserListener = (prevParser, currentParser) => {
    prevParser?.removeEventListener('file_parsed', this.getWorkList);
    currentParser?.addEventListener('file_parsed', this.getWorkList);
  };

  // eslint-disable-next-line react/destructuring-assignment
  formatMessage = (...args) => this.props.intl.formatMessage(...args);

  getProtocols = () => {
    ApiCalls.getProtocols().then((response) => {
      this.setState({ protocols: response.data.protocols });
    });
  };

  getWorkList = () =>
    ApiCalls.getWorkList({}).then(({ data: { workList } }) => {
      this.setState({ workList });
    });

  renderDate = (date) => {
    const objectDate = new Date(date);
    const today = new Date();
    if (objectDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0))
      return (
        <div className="workList-column">
          <div className="info">
            <span>
              <FormattedMessage id="DisplayDate.today" />
            </span>
          </div>
          <div className="details">
            <DisplayDate date={date} format="time" />
          </div>
        </div>
      );
    return (
      <div className="workList-column">
        <div className="info">
          <Popup
            size="small"
            inverted
            trigger={
              <span>
                <DisplayDate date={date} format="short" />
              </span>
            }
            content={<DisplayDate date={date} format="short" />}
            style={{ padding: '0.5rem', marginBottom: '0.3rem' }}
          />
        </div>
        <div className="details">
          <DisplayDate date={date} format="time" />
        </div>
      </div>
    );
  };

  renderAnimal = (study) => {
    const specie = _.get(study, 'animal.specie');
    return (
      <div className="workList-column">
        <div className="info">
          <Popup
            size="small"
            inverted
            trigger={<span>{_.get(study, 'animal.name')}</span>}
            content={<span>{_.get(study, 'animal.name')}</span>}
            style={{ padding: '0.5rem', marginBottom: '0.3rem' }}
          />
        </div>
        <div className="details">
          {specie != null && (
            <FormattedMessage id={`patient_info.specie.${specie}`} defaultMessage={specie} />
          )}
        </div>
      </div>
    );
  };

  checkBeginAcquisition = (study) => study.images.some((image) => isAcquisitionImage(image));

  renderOwner = (study) => (
    <div className="workList-column">
      <div className="info">
        <Popup
          size="small"
          inverted
          trigger={<span>{_.get(study, 'animal.owner_name')}</span>}
          content={<span>{_.get(study, 'animal.owner_name')}</span>}
          style={{ padding: '0.5rem', marginBottom: '0.3rem' }}
        />
      </div>
      <div className="details">
        {this.checkBeginAcquisition(study) ? (
          <FormattedMessage id="acquisition.workList.started" />
        ) : (
          <FormattedMessage id="acquisition.workList.planned" />
        )}
      </div>
    </div>
  );

  renderWorkList = () => {
    const { workList } = this.state;
    return workList.map((element) => this.renderWorkListElement(element));
  };

  renderActions = (id) => (
    <div>
      {isAcquisitionSoftware() && (
        <Icon
          link
          className="caret square right outline icon"
          onClick={() => {
            this.openExamInViewer(id);
          }}
        />
      )}

      <Icon
        link
        className="trash alternate icon"
        onClick={() => {
          this.setState({ open: true, studyToDelete: id });
        }}
      />
    </div>
  );

  displayStudyById = (studyId) => {
    const { workList } = this.state;
    this.displayStudy(_.find(workList, { _id: studyId }));
  };

  displayStudy = (study) => {
    const patient = formatAnimalData({ ...study.animal });
    let { patientInfoKey } = this.state;
    patientInfoKey += 1;
    const exams = _.cloneDeep(study.images);

    if (patient.specie === undefined) {
      const specie = study?.images[0]?.anatomicRegion.split(' ')[0];
      patient.specie = specie ?? DEFAULT_SPECIE;
    }

    this.setState({
      patientInfoKey,
      patient,
      exams,
      examDate: new Date(study.planningDate),
      studyLoaded: study,
    });
  };

  renderWorkListElement = (study) => {
    const { studyLoaded } = this.state;
    const isSelected = studyLoaded?._id === study._id;
    return (
      <button
        className={`workList-element ${isSelected && 'selected'}`}
        onClick={() => this.displayStudy(study)}
        key={study._id}
        type="button"
      >
        <div style={{ width: '15%' }}>{this.renderOwner(study)}</div>
        <div style={{ width: '15%' }}>{this.renderAnimal(study)}</div>
        <div style={{ width: '15%' }}>{this.renderDate(study.planningDate)}</div>
        <WorkListImages study={study} />
        <div style={{ width: '15%' }}>{this.renderActions(study._id)}</div>
      </button>
    );
  };

  deleteStudy = () => {
    const { studyToDelete } = this.state;
    ApiCalls.deleteStudies([studyToDelete]).then(() => {
      this.setState({ exams: [] });
      this.getWorkList();
    });
  };

  handleConfirm = () => {
    this.setState({ open: false }, () => {
      this.deleteStudy();
    });
  };

  handleCancel = () => this.setState({ open: false, studyToDelete: {} });

  renderAlertDeleteStudy = () => {
    const { open } = this.state;
    const { intl } = this.props;
    return (
      <div>
        <Confirm
          open={open}
          header={intl.formatMessage({ id: 'acquisition.study.delete.header' })}
          content={intl.formatMessage({ id: 'acquisition.study.delete.content' })}
          onCancel={this.handleCancel}
          onConfirm={() => this.handleConfirm()}
        />
      </div>
    );
  };

  onChange = ({ patientInfo, hasConflict }) => {
    this.setState(({ patient }) => {
      const newState = { patient: patientInfo, hasConflict };
      if (patientInfo.specie !== patient.specie) {
        newState.exams = [];
      }
      return newState;
    });
  };

  handleSelectExam = ({ specie, mainRegion, subRegion, view }) => {
    const { exams } = this.state;
    const anatomicRegion = _.filter([specie, mainRegion, subRegion, view], _.identity).join(' ');
    this.setState({ exams: [...exams, { anatomicRegion }] });
  };

  handleRemoveExam = (exam) =>
    this.setState(({ exams }) => ({ exams: exams.filter((v) => v !== exam) }));

  renderProblemModal = () => {
    const { problem, cause } = this.state;
    const { intl } = this.props;
    return (
      <div>
        <Confirm
          open={problem}
          header={intl.formatMessage({ id: 'acquisition.patient.problem' })}
          content={intl.formatMessage({ id: cause })}
          onConfirm={() => {
            this.setState({ problem: false });
          }}
          onCancel={() => {
            this.setState({ problem: false });
          }}
        />
      </div>
    );
  };

  handleExamPlan = () => {
    const { exams, examDate, patient } = this.state;
    const checkPatient = this.checkPatientInfo();
    if (checkPatient !== true) {
      this.setState({ cause: checkPatient, problem: true });
    } else {
      if (exams.length === 0) {
        exams.push({ anatomicRegion: getDefaultAnatomicRegion(patient?.specie) });
      }
      this.setState({ planOngoing: true });
      ApiCalls.planExam(exams, examDate)
        .then((response) => saveAndLinkPatientIfNeeded(patient, response.data.study_id))
        .then(() => this.getWorkList())
        .then(() =>
          this.setState((state) => ({
            exams: [],
            cancel: false,
            patientInfoKey: state.patientInfoKey + 1,
            patient: { specie: DEFAULT_SPECIE },
            protocolSelected: null,
            studyLoaded: {},
          }))
        )
        .finally(() => this.setState({ planOngoing: false }));
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  checkPatientFilled = () => checkPatientFilled(this.state.patient);

  checkPatientInfo = () => {
    const { patient } = this.state;
    if (patient.name !== undefined && patient.owner_name === undefined)
      return 'acquisition.owner_missing';
    if (patient.owner_name !== undefined && patient.name === undefined)
      return 'acquisition.animal_missing';
    return true;
  };

  openExamInViewer = (studyID) =>
    browserHistory.push(`/online?mode=acquisition&study_ids[]=${studyID}`);

  startAcquisitionWithoutStudy = (patientID = undefined) =>
    browserHistory.push(`/online?mode=acquisition${patientID ? `&patient_id=${patientID}` : ''}`);

  handleExamAcquire = async () => {
    const { exams, examDate, patient, studyLoaded } = this.state;
    const checkPatient = this.checkPatientInfo();
    if (checkPatient !== true) {
      this.setState({ cause: checkPatient, problem: true });
    }
    this.setState({ acquireOngoing: true });
    let studyUpdateResponse;
    try {
      if (exams.length === 0) {
        exams.push({ anatomicRegion: getDefaultAnatomicRegion(patient?.specie) });
      }
      if (_.isEmpty(studyLoaded)) {
        studyUpdateResponse = await ApiCalls.planExam(exams, examDate);
      } else {
        studyUpdateResponse = await ApiCalls.editExam(exams, examDate, studyLoaded._id);
      }

      await saveAndLinkPatientIfNeeded(patient, studyUpdateResponse.data.study_id);
      this.openExamInViewer(studyUpdateResponse.data.study_id);
    } finally {
      this.setState({ acquireOngoing: false });
    }
  };

  renderDataLostAlert = () => {
    const { dataLost } = this.state;
    const { intl } = this.props;
    return (
      <div>
        <Confirm
          open={dataLost}
          header={intl.formatMessage({ id: 'acquisition.popup.emergency.header' })}
          content={intl.formatMessage({ id: 'acquisition.popup.dataLost.content' })}
          cancelButton={intl.formatMessage({ id: 'acquisition.popup.dataLost.cancelButton' })}
          onConfirm={() => {
            this.setState({ dataLost: false });
            this.startAcquisitionWithoutStudy();
          }}
          onCancel={() => this.setState({ dataLost: false })}
        />
      </div>
    );
  };

  renderCancelAlert = () => {
    const { cancel, patientInfoKey } = this.state;
    const { intl } = this.props;
    return (
      <div>
        <Confirm
          open={cancel}
          header={intl.formatMessage({ id: 'acquisition.popup.newStudy.header' })}
          content={intl.formatMessage({ id: 'acquisition.popup.dataLost.content' })}
          cancelButton={intl.formatMessage({ id: 'acquisition.popup.dataLost.cancelButton' })}
          onConfirm={() => {
            this.setState({
              exams: [],
              cancel: false,
              patientInfoKey: patientInfoKey + 1,
              patient: { specie: DEFAULT_SPECIE },
              protocolSelected: null,
              studyLoaded: {},
            });
          }}
          onCancel={() => {
            this.setState({ cancel: false });
          }}
        />
      </div>
    );
  };

  handleEmergency = () => {
    const { exams } = this.state;
    if (this.checkPatientFilled() || exams.length !== 0) {
      this.setState({ dataLost: true });
    } else {
      this.startAcquisitionWithoutStudy();
    }
  };

  handleCancelExam = () => {
    const { exams, studyLoaded, patientInfoKey } = this.state;
    if ((this.checkPatientFilled() || exams.length !== 0) && _.isEmpty(studyLoaded)) {
      this.setState({ cancel: true });
    } else {
      this.setState({
        exams: [],
        cancel: false,
        patientInfoKey: patientInfoKey + 1,
        patient: { specie: DEFAULT_SPECIE },
        protocolSelected: null,
        studyLoaded: {},
      });
    }
  };

  editExam = async () => {
    const { exams, examDate, patient, studyLoaded } = this.state;
    const checkPatient = this.checkPatientInfo();
    if (checkPatient !== true) {
      this.setState({ cause: checkPatient, problem: true });
    } else {
      this.setState({ planOngoing: true });
      if (exams.length === 0) {
        exams.push({ anatomicRegion: getDefaultAnatomicRegion(patient?.specie) });
      }
      try {
        const editResponse = await ApiCalls.editExam(exams, examDate, studyLoaded._id);
        const studyId = editResponse.data.study_id;
        await saveAndLinkPatientIfNeeded(patient, studyId);
        await this.getWorkList();
        this.displayStudyById(studyId);
      } finally {
        this.setState({ planOngoing: false });
      }
    }
  };

  displayProtocol = (protocol) => {
    this.setState(({ exams, patient }) => {
      const protocolExams = protocol.exams.map((exam) => ({
        anatomicRegion: exam.replace('animal', patient.specie),
      }));
      const newExams = [...exams, ...protocolExams];
      return { exams: newExams, protocolSelected: protocol._id };
    });
  };

  renderProtocols = () => {
    const { protocols, protocolSelected } = this.state;
    return protocols.map((protocol, index) => (
      <Popup
        size="tiny"
        position="left center"
        key={index}
        trigger={
          <div
            className={
              protocolSelected === protocol._id ? 'protocol-item selected' : 'protocol-item'
            }
            onClick={() => this.displayProtocol(protocol)}
          >
            {protocol.name}
          </div>
        }
        content={<ProtocolExams protocol={protocol} />}
      />
    ));
  };

  OpenORCloseProtocols = () => {
    const { protocolsDisplay } = this.state;
    this.setState({ protocolsDisplay: !protocolsDisplay });
  };

  isExamRequestOnGoing = () => {
    const { planOngoing, acquireOngoing } = this.state;
    return planOngoing || acquireOngoing;
  };

  renderPlanButton = () => {
    const { planOngoing, studyLoaded, hasConflict } = this.state;
    const patientInfoFilled = this.checkPatientFilled();
    const isStudyToPlan = _.isEmpty(studyLoaded);
    const actionType = isStudyToPlan ? 'plan' : 'edit';
    return (
      <Popup
        size="tiny"
        position="top center"
        trigger={
          <Dimmer.Dimmable
            as="button"
            dimmed={planOngoing}
            className="grey picoxia"
            onClick={isStudyToPlan ? this.handleExamPlan : this.editExam}
            disabled={hasConflict || this.isExamRequestOnGoing() || !patientInfoFilled}
          >
            <Dimmer active={planOngoing}>
              <Loader size="small" />
            </Dimmer>
            <Icon className="add to calendar outline" />
            <FormattedMessage id={`acquisition.exam.${actionType}`} />
          </Dimmer.Dimmable>
        }
        content={<FormattedMessage id={`acquisition.exam.${actionType}.title`} />}
      />
    );
  };

  renderAcquireButton = () => {
    const { acquireOngoing } = this.state;
    const patientInfoFilled = this.checkPatientFilled();
    return (
      <Popup
        size="tiny"
        position="top center"
        trigger={
          <Dimmer.Dimmable
            as="button"
            className="green picoxia"
            disabled={this.isExamRequestOnGoing() || !patientInfoFilled}
            type="button"
            onClick={this.handleExamAcquire}
            dimmed={acquireOngoing}
          >
            <Dimmer active={acquireOngoing}>
              <Loader size="small" />
            </Dimmer>
            <Icon className="caret square right outline" />
            <FormattedMessage id="acquisition.exam.acquire" />
          </Dimmer.Dimmable>
        }
        content={<FormattedMessage id="acquisition.exam.acquire.title" />}
      />
    );
  };

  renderNewStudyButton = () => (
    <Popup
      size="tiny"
      position="bottom center"
      trigger={
        <button className="grey picoxia" type="button" onClick={this.handleCancelExam}>
          <Icon className="plus square outline" />
          <FormattedMessage id="acquisition.exam.new" />
        </button>
      }
      content={<FormattedMessage id="acquisition.exam.new.title" />}
    />
  );

  renderEmergencyButton = () => (
    <Popup
      size="tiny"
      position="bottom center"
      trigger={
        <button className="picoxia emergency" type="button" onClick={this.handleEmergency}>
          <Icon className="ambulance " />
          <FormattedMessage id="acquisition.exam.emergency" />
        </button>
      }
      content={<FormattedMessage id="acquisition.exam.emergency.title" />}
    />
  );

  render() {
    const { patientInfoKey, patient, examDate, exams, cause, protocolsDisplay } = this.state;
    const { intl } = this.props;
    return (
      <ImageCacheProvider>
        <Helmet title={this.props.intl.formatMessage({ id: 'acquisition.pageTitle' })} />
        <div className="acquisition-background">
          <div className="workList">
            <Label className="section-title">
              <FormattedMessage id="acquisition.workList" />
            </Label>
            <div
              className="workList-table"
              onDoubleClick={process.env.IS_ACQUISITION_SOFTWARE && this.handleExamAcquire}
            >
              {this.renderWorkList()}
            </div>
            {this.renderAlertDeleteStudy()}
          </div>
          <div className="study-planning">
            <div className="flex center">
              <Label className="section-title">
                <FormattedMessage id="acquisition.study_creation.title" />
              </Label>
            </div>
            <div className="content">
              <div className="line add-emergency">
                {this.renderNewStudyButton()}
                {isAcquisitionSoftware() && this.renderEmergencyButton()}
              </div>
              <div className="section patient-creation">
                <div className="header">
                  <span className="exam-label">
                    <FormattedMessage id="acquisition.patient.creation" />
                  </span>
                </div>
                <PatientInfoForm
                  initialPatientData={patient}
                  key={patientInfoKey}
                  onChange={this.onChange}
                  className="patient-info patient-info-form"
                />
              </div>
              <div className="section exam-creation">
                <div className="header">
                  <span className="exam-label">
                    <FormattedMessage id="acquisition.exam" />
                  </span>
                  <div className="exam-date">
                    <Label id="date">
                      <FormattedMessage id="acquisition.exam.planned" />
                    </Label>
                    <DatePicker
                      showTimeInput
                      timeInputLabel="Time:"
                      timeFormat="p"
                      selected={examDate}
                      value={examDate}
                      dateFormat="Pp"
                      locale={intl.locale}
                      customInput={<InputDate />}
                      onChange={(date) => {
                        if (date == null) {
                          this.setState({ examDate: null });
                        }
                        this.setState({ examDate: date });
                      }}
                    />
                  </div>
                </div>
                <div className="exam-selection">
                  <ExamSelector
                    key={patientInfoKey}
                    specie={patient.specie}
                    onComplete={this.handleSelectExam}
                    onRegionSelected={() => this.setState({ protocolsDisplay: false })}
                  />
                  <div className="protocols">
                    <div className={protocolsDisplay ? 'minimize open' : 'minimize'}>
                      <FormattedMessage id="acquisition.protocols" />
                      <button type="button" onClick={this.OpenORCloseProtocols}>
                        <Icon className={protocolsDisplay ? 'window minimize' : 'add'} />
                      </button>
                    </div>
                    <div className={protocolsDisplay ? 'protocol-list' : 'protocol-min'}>
                      {this.renderProtocols()}
                    </div>
                  </div>
                </div>
              </div>
              {cause !== '' && this.renderProblemModal()}
              <div className="section exam-list">
                <span className="exam-label">
                  <FormattedMessage id="acquisition.exams.selected" />
                </span>
                <div className="exams-actions">
                  <Exams
                    key={patient.specie}
                    exams={exams}
                    onRemoveExam={this.handleRemoveExam}
                    animal={patient}
                  />
                  <div className="actions">
                    <div className="first line">
                      {isAcquisitionSoftware() && this.renderAcquireButton()}
                      {this.renderPlanButton()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.renderDataLostAlert()}
          {this.renderCancelAlert()}
        </div>
      </ImageCacheProvider>
    );
  }
}

AcquisitionPage.propTypes = {
  intl: PropTypes.shape().isRequired,
  pmsParser: PropTypes.shape(),
};

AcquisitionPage.defaultProps = {
  pmsParser: undefined,
};

export default withContext(injectIntl(AcquisitionPage), PMSParserContext, 'pmsParser');
