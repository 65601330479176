import * as csc from 'cornerstone-core';
import * as csm from 'cornerstone-math';
import {
  import as cornerstoneToolsImport,
  getToolState,
  addToolState,
  getToolForElement,
  EVENTS,
} from 'cornerstone-tools';
import * as _ from 'lodash';
import { getImageFitScale } from '../utils/cornerstone/imageUtils';
import { cropCursor } from './Cursors';
import { forceImageUpdate } from './forceImageUpdate';
import injectSwitchableTool from './injectSwitchableTool';
import { projectAlongAxis } from './pixelToCanvasUtils';

const CROP_EVENTS = {
  CROP_ACTIVATED: 'cornerstone_crop_activated',
  CROP_CONFIRMED: 'cornerstone_crop_confirmed',
  CROP_CANCELED: 'cornerstone_crop_canceled',
};

const BaseAnnotationTool = cornerstoneToolsImport('base/BaseAnnotationTool');
const getNewContext = cornerstoneToolsImport('drawing/getNewContext');
const draw = cornerstoneToolsImport('drawing/draw');
const drawLine = cornerstoneToolsImport('drawing/drawLine');
const drawLines = cornerstoneToolsImport('drawing/drawLines');
const isPointInPolygon = cornerstoneToolsImport('util/isPointInPolygon');
const triggerEvent = cornerstoneToolsImport('util/triggerEvent');

const POINTS_LINKS = {
  topLeft: { x: ['bottomLeft', 'left'], y: ['topRight', 'top'] },
  topRight: { x: ['end', 'right'], y: ['topLeft', 'top'] },
  bottomLeft: { x: ['topLeft', 'left'], y: ['end', 'bottom'] },
  end: { x: ['topRight', 'right'], y: ['bottomLeft', 'bottom'] },
  top: { x: [], y: ['topLeft', 'topRight'] },
  left: { x: ['bottomLeft', 'topLeft'], y: [] },
  right: { x: ['topRight', 'end'], y: [] },
  bottom: { x: [], y: ['end', 'bottomLeft'] },
};

const MIDDLE_POINTS_LINKS = { top: 'y', left: 'x', right: 'x', bottom: 'y' };

const ZERO_INIT_POINT = {
  x: 0,
  y: 0,
  highlight: true,
  active: false,
  allowedOutsideImage: true,
};

const isCropToolActive = (element, toolName) => {
  const tool = getToolForElement(element, toolName);
  return tool.mode === 'active';
};

const getCropRect = (element, toolName) => {
  const toolData = getToolState(element, toolName);
  if (!toolData || toolData.data.length === 0) {
    return null;
  }
  const { topLeft, end } = toolData.data[0].handles;
  return {
    x: Math.min(topLeft.x, end.x),
    y: Math.min(topLeft.y, end.y),
    width: Math.abs(topLeft.x - end.x),
    height: Math.abs(topLeft.y - end.y),
  };
};

const getCropMinScale = (element, toolName) => {
  if (isCropToolActive(element, toolName)) return null;

  const { canvas, viewport } = csc.getEnabledElement(element);

  const cropRect = getCropRect(element, toolName);
  if (cropRect) {
    return getImageFitScale(canvas, cropRect, viewport.rotation).scaleFactor;
  }
  return null;
};

const focusHandles = (element, handles) => {
  if (!handles) return;
  const { topLeft, end } = handles;
  const enabledElement = csc.getEnabledElement(element);
  const { viewport, image, canvas } = enabledElement;

  // A bit strange here, but since changing scaling will zoom the image onto the
  // center of the image we have to compute our offset from the center of the
  // image instead of from an edge.
  const cropCenterX = Math.min(end.x, topLeft.x) + Math.abs(end.x - topLeft.x) / 2;
  const cropCenterY = Math.min(end.y, topLeft.y) + Math.abs(end.y - topLeft.y) / 2;

  const imageCenterX = image.width / 2;
  const imageCenterY = image.height / 2;

  let translation = {
    x: (imageCenterX - cropCenterX) * (viewport.hflip ? -1 : 1),
    y: (imageCenterY - cropCenterY) * (viewport.vflip ? -1 : 1),
  };

  const clockwiseRotation = viewport.rotation % 360 === 90;
  const halfTurnRotation = viewport.rotation % 360 === 180;
  const counterClockwiseRotation = viewport.rotation % 360 === 270;

  if (clockwiseRotation) {
    translation = {
      x: -translation.y,
      y: translation.x,
    };
  } else if (halfTurnRotation) {
    translation = {
      x: -translation.x,
      y: -translation.y,
    };
  } else if (counterClockwiseRotation) {
    translation = {
      x: translation.y,
      y: -translation.x,
    };
  }

  const windowSize = {
    width: Math.abs(end.x - topLeft.x),
    height: Math.abs(end.y - topLeft.y),
  };
  const newImageRegion = _.merge({}, image, windowSize);
  const { scaleFactor } = getImageFitScale(canvas, newImageRegion, viewport.rotation);

  const updatedViewport = _.merge({}, viewport, {
    scale: scaleFactor,
    translation,
  });

  if (_.isEqual(updatedViewport, viewport)) return;

  csc.setViewport(element, updatedViewport);
};

const roundCoords = (coords) => ({ x: Math.round(coords.x), y: Math.round(coords.y) });

const isCropInProgress = (element, toolName = 'Crop') => {
  const cropToolData = getToolState(element, toolName);
  return cropToolData?.data?.[0]?.previousHandles !== undefined;
};

export const convertRectToCropHandles = ([x, y, width, height]) => ({
  previousHandles: null,
  handles: {
    topLeft: {
      x,
      y,
      highlight: true,
      active: true,
      allowedOutsideImage: true,
    },
    top: _.clone(ZERO_INIT_POINT),
    left: _.clone(ZERO_INIT_POINT),
    right: _.clone(ZERO_INIT_POINT),
    bottom: _.clone(ZERO_INIT_POINT),
    topRight: _.clone(ZERO_INIT_POINT),
    bottomLeft: _.clone(ZERO_INIT_POINT),
    end: {
      x: x + width,
      y: y + height,
      highlight: true,
      active: true,
      allowedOutsideImage: true,
    },
  },
});

class CropTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'Crop',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
        handleRadius: 15,
      },
      svgCursor: cropCursor,
    };

    super(props, defaultProps);

    this.preventNewMeasurement = false;

    this.zeroInitPoint = {
      ...ZERO_INIT_POINT,
      pointNearHandle: this.pointNearHandle,
    };
  }

  injectPointNearHandleToMeasurement = (measurementData) => {
    const measurementDataWithInjections = _.cloneDeep(measurementData);

    _.forEach(measurementDataWithInjections.handles, (draftHandle) => {
      if (!_.isPlainObject(draftHandle)) return;
      draftHandle.pointNearHandle = this.pointNearHandle;
    });

    return measurementDataWithInjections;
  };

  // eslint-disable-next-line class-methods-use-this
  createNewMeasurement = () => {};

  cropOnRect = (element, [x, y, width, height]) => {
    const measurementData = this.injectPointNearHandleToMeasurement(
      convertRectToCropHandles([x, y, width, height])
    );

    this.correctHandles(element, measurementData.handles);
    measurementData.handles.topLeft.active = false;
    measurementData.handles.end.active = false;

    const toolData = getToolState(element, this.name);

    if (toolData && toolData.data.length > 0) {
      _.merge(toolData.data[0], measurementData);
    } else {
      addToolState(element, this.name, measurementData);
    }

    this.validateCrop(element);
    forceImageUpdate(element);
  };

  activeCallback = (element) => {
    const enabledElement = csc.getEnabledElement(element);
    const toolData = getToolState(element, this.name);

    if (toolData && toolData.data.length > 0) {
      toolData.data[0].previousHandles = _.cloneDeep(toolData.data[0].handles ?? null);

      triggerEvent(element, CROP_EVENTS.CROP_ACTIVATED, {
        toolName: this.name,
        toolType: this.name,
        element,
        measurementData: toolData.data[0],
      });
      csc.resize(element, true);
      forceImageUpdate(element);
      return;
    }
    const { image } = enabledElement;

    const measurementData = {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      previousHandles: null,
      handles: {
        topLeft: {
          x: 0,
          y: 0,
          highlight: true,
          active: true,
          allowedOutsideImage: true,
          pointNearHandle: this.pointNearHandle,
        },
        top: _.clone(this.zeroInitPoint),
        left: _.clone(this.zeroInitPoint),
        right: _.clone(this.zeroInitPoint),
        bottom: _.clone(this.zeroInitPoint),
        topRight: _.clone(this.zeroInitPoint),
        bottomLeft: _.clone(this.zeroInitPoint),
        end: {
          x: image.width,
          y: image.height,
          highlight: true,
          active: true,
          allowedOutsideImage: true,
          pointNearHandle: this.pointNearHandle,
        },
      },
    };
    this.correctHandles(element, measurementData.handles);
    measurementData.handles.topLeft.active = false;
    measurementData.handles.end.active = false;
    addToolState(element, this.name, measurementData);
    triggerEvent(element, CROP_EVENTS.CROP_ACTIVATED, {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData,
    });
    csc.resize(element, true);
    forceImageUpdate(element);
  };

  passiveCallback = (element) => {
    this.validateCrop(element);
  };

  validateCrop = (element) => {
    const toolData = getToolState(element, this.name);

    if (!toolData || toolData.data.length === 0) return;
    const alreadyValidated = toolData.data[0].previousHandles === undefined;
    if (alreadyValidated) return;
    delete toolData.data[0].previousHandles;
    focusHandles(element, toolData.data[0].handles);
    this.fireCompleted(element, toolData.data[0]);
  };

  cancelCrop = (element) => {
    const toolData = getToolState(element, this.name);

    if (!toolData || toolData.data.length === 0) return;
    if (toolData.data[0].previousHandles) {
      toolData.data[0].handles = toolData.data[0].previousHandles;
      delete toolData.data[0].previousHandles;
      focusHandles(element, toolData.data[0].handles);
      this.fireCompleted(element, toolData.data[0]);
    } else {
      toolData.data.pop();
    }
  };

  fireCompleted = (element, measurementData) => {
    triggerEvent(element, EVENTS.MEASUREMENT_COMPLETED, {
      toolName: this.name,
      toolType: this.name,
      element,
      measurementData,
    });
  };

  pointNearHandle = (element, handle, coords) =>
    csm.point.distance(coords, csc.pixelToCanvas(element, handle)) <
    this.configuration.handleRadius;

  // eslint-disable-next-line class-methods-use-this
  updateCachedStats() {}

  // eslint-disable-next-line class-methods-use-this
  pointNearTool = (element, data, coords) => {
    const tool = getToolForElement(element, this.name);
    const isActive = tool.mode === 'active';

    if (!isActive) return false;

    const { topLeft, topRight, bottomLeft, end } = data.handles;
    const rect = [
      [topLeft.x, topLeft.y],
      [topRight.x, topRight.y],
      [end.x, end.y],
      [bottomLeft.x, bottomLeft.y],
    ];
    const pixelCoords = csc.canvasToPixel(element, coords);

    return isPointInPolygon([pixelCoords.x, pixelCoords.y], rect);
  };

  /* eslint-disable no-param-reassign */
  correctHandles = (element, handles) => {
    const { image } = csc.getEnabledElement(element);

    _.forEach(handles, (handle) => {
      handle.x = Math.min(Math.max(handle.x, 0), image.width);
      handle.y = Math.min(Math.max(handle.y, 0), image.height);
    });

    _.forEach(handles, ({ x, y, active }, key) => {
      if (!active) return;
      POINTS_LINKS[key].x.forEach((handleKey) => {
        handles[handleKey].x = x;
      });
      POINTS_LINKS[key].y.forEach((handleKey) => {
        handles[handleKey].y = y;
      });
    });

    _.forEach(handles, (handle, key) => {
      if (MIDDLE_POINTS_LINKS[key] === undefined) return;
      const edgesHandle = POINTS_LINKS[key][MIDDLE_POINTS_LINKS[key]];
      handle.x = (handles[edgesHandle[0]].x + handles[edgesHandle[1]].x) / 2;
      handle.y = (handles[edgesHandle[0]].y + handles[edgesHandle[1]].y) / 2;
    });
  };
  /* eslint-enable no-param-reassign */

  renderMaskingArea = (ctx, element, data, fillStyle) => {
    const { canvas } = ctx;
    const { topLeft: pixelTopLeft, end: pixelEnd } = data.handles;
    const topLeft = csc.pixelToCanvas(element, pixelTopLeft);
    const end = csc.pixelToCanvas(element, pixelEnd);

    const topLeftX = Math.round(Math.min(end.x, topLeft.x));
    const topLeftY = Math.round(Math.min(end.y, topLeft.y));
    const bottomRightX = Math.round(Math.max(topLeft.x, end.x));
    const bottomRightY = Math.round(Math.max(topLeft.y, end.y));

    ctx.fillStyle = fillStyle;

    ctx.fillRect(0, 0, bottomRightX, topLeftY);
    ctx.fillRect(bottomRightX, 0, canvas.width - bottomRightX, bottomRightY);
    ctx.fillRect(topLeftX, bottomRightY, canvas.width - topLeftX, canvas.height - bottomRightY);
    ctx.fillRect(0, topLeftY, topLeftX, canvas.height - topLeftY);
  };

  drawCropBorder = (ctx, element, data) => {
    const { topLeft, topRight, bottomLeft, end, top, left, right, bottom } = data.handles;
    const lineOptions = {
      color: 'rgba(0, 0, 0, 0.6)',
      lineWidth: 5,
    };
    ctx.save();

    drawLine(ctx, element, topLeft, topRight, lineOptions);
    drawLine(ctx, element, topLeft, bottomLeft, lineOptions);
    drawLine(ctx, element, end, bottomLeft, lineOptions);
    drawLine(ctx, element, end, topRight, lineOptions);

    this.drawBorderHandles(ctx, element, topLeft, top, topRight, 10);
    this.drawBorderHandles(ctx, element, topLeft, left, bottomLeft, 10);
    this.drawBorderHandles(ctx, element, topRight, right, end, 10);
    this.drawBorderHandles(ctx, element, bottomLeft, bottom, end, 10);
    this.drawBorderHandles(ctx, element, bottomLeft, topLeft, topRight, 10);
    this.drawBorderHandles(ctx, element, end, bottomLeft, topLeft, 10);
    this.drawBorderHandles(ctx, element, topRight, end, bottomLeft, 10);
    this.drawBorderHandles(ctx, element, topLeft, topRight, end, 10);

    ctx.restore();
  };

  drawBorderHandles = (ctx, element, start, middle, end, lineLength) => {
    const middlePoint = csc.pixelToCanvas(element, roundCoords(middle));

    const startPoint = csc.pixelToCanvas(
      element,
      projectAlongAxis(
        element,
        roundCoords(middle),
        roundCoords(middle),
        roundCoords(start),
        lineLength
      )
    );
    const endPoint = csc.pixelToCanvas(
      element,
      projectAlongAxis(
        element,
        roundCoords(middle),
        roundCoords(middle),
        roundCoords(end),
        lineLength
      )
    );

    const drawBorder = () => {
      ctx.beginPath();
      ctx.moveTo(Math.round(startPoint.x), Math.round(startPoint.y));
      ctx.lineTo(Math.round(middlePoint.x), Math.round(middlePoint.y));
      ctx.lineTo(Math.round(endPoint.x), Math.round(endPoint.y));
      ctx.stroke();
    };

    ctx.strokeStyle = 'white';
    ctx.lineCap = 'square';
    ctx.lineWidth = 7;
    drawBorder();

    ctx.strokeStyle = 'black';
    ctx.lineWidth = 5;
    drawBorder();
  };

  renderToolData = (evt) => {
    const eventData = evt.detail;
    const { element } = eventData;

    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const tool = getToolForElement(element, this.name);
    const isActive = tool.mode === 'active';

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    draw(context, (ctx) => {
      for (let i = 0; i < toolData.data.length; i += 1) {
        const data = toolData.data[i];

        const maskColor = isActive ? 'rgba(255,255,255,0.4)' : 'black';
        this.correctHandles(element, data.handles);
        this.renderMaskingArea(ctx, element, data, maskColor);
        // Draw the corners
        if (this.configuration.drawHandles && isActive) {
          this.drawCropBorder(ctx, element, data);
        }
      }
    });
  };
}

export default injectSwitchableTool(CropTool);

export {
  isCropToolActive,
  getCropRect,
  getCropMinScale,
  focusHandles,
  CROP_EVENTS,
  isCropInProgress,
};
