import { getImage, getViewport } from 'cornerstone-core';
import _ from 'lodash';

import { ExportableStudy } from 'app/components/Dropzone/ExportStudyModal/exportStudy';
import { DropzoneStudy } from 'app/interfaces/Study';
import isWorkListImage from 'app/utils/isWorkListImage';
import { ImageId, SelectableImage } from 'app/components/ImagesSelector';
import { Viewport } from 'app/interfaces/Image';

type ImageBaseForSelectable = {
  origin: string;
  id: ImageId;
  cornerstoneRef: HTMLElement;
};

export const formatStudyImagesToSelectableImages = <T extends ImageBaseForSelectable>(
  studyImages: T[]
): (T & SelectableImage)[] =>
  _(studyImages)
    .reject(isWorkListImage)
    .map((image) => {
      try {
        const cscImage = getImage(image.cornerstoneRef);
        if (!cscImage) return undefined;
        return {
          ...image,
          id: image.id,
          image: cscImage,
          viewport: getViewport(image.cornerstoneRef) as unknown as Viewport,
          mainRenderer: { element: image.cornerstoneRef },
        };
      } catch {
        return undefined;
      }
    })
    .filter()
    .value();

export default function (study: DropzoneStudy): ExportableStudy & { images: SelectableImage[] } {
  return {
    ...study,
    images: formatStudyImagesToSelectableImages(study.images),
  };
}
