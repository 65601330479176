/** @return {DeferredPromise} */
export default () => {
  let resolve;
  let reject;
  const promise = new Promise((promiseResolve, promiseReject) => {
    resolve = promiseResolve;
    reject = promiseReject;
  });
  promise.resolve = resolve;
  promise.reject = reject;
  return promise;
};
