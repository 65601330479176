import './style.scss';
import 'app/styles/style.scss';

import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useMemo } from 'react';

const numberMatch = /^([0-9]*\.?[0-9]*).*/;

const computeNumberPrecision = (num) => (+num).toString().split('.')[1]?.length ?? 0;

const truncateToPrecision = (num, precision) => +num.toFixed(precision);

function NumberInput({ value, onChange, step = 1, ...rest }) {
  const [displayedValue, setDisplayedValue] = useState(value);

  const stepPrecision = useMemo(() => computeNumberPrecision(step), [step]);

  // eslint-disable-next-line react/prop-types
  const onDisplayedValueChange = (event) => {
    const newValue = event.target.value.replace(',', '.').replace(numberMatch, '$1');
    if (computeNumberPrecision(newValue) > stepPrecision) return;

    if (newValue.endsWith('.') && stepPrecision === 0) return;
    setDisplayedValue(newValue);
    if (newValue.endsWith('.')) return;

    onChange?.(event, truncateToPrecision(parseFloat(newValue, 10), stepPrecision));
  };

  const stepIncrease = (event) => {
    const newValue = truncateToPrecision(
      parseFloat(displayedValue, 10) + parseFloat(step, 10),
      stepPrecision
    );
    onChange?.(event, newValue);
  };

  const stepDecrease = (event) => {
    const newValue = truncateToPrecision(
      parseFloat(displayedValue, 10) - parseFloat(step, 10),
      stepPrecision
    );
    onChange?.(event, newValue);
  };

  const onKeyDown = (event) => {
    if (event.key === 'ArrowUp') stepIncrease(event);
    if (event.key === 'ArrowDown') stepDecrease(event);
  };

  useEffect(() => {
    if (value === undefined) return;
    setDisplayedValue(value);
  }, [value]);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <div className="number-input">
      <input
        {...rest}
        type="text"
        value={displayedValue}
        onChange={onDisplayedValueChange}
        onKeyDown={onKeyDown}
      />

      <div className="number-input__buttons">
        <button
          type="button"
          className="number-input__buttons__increase flex center picoxia"
          data-testid="step-increase"
          onClick={stepIncrease}
        >
          <Icon name="caret up" className="flex center" />
        </button>
        <button
          type="button"
          className="number-input__buttons__decrease flex center picoxia"
          data-testid="step-decrease"
          onClick={stepDecrease}
        >
          <Icon name="caret down" className="flex center" />
        </button>
      </div>
    </div>
  );
}

export default NumberInput;
