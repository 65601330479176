import * as cornerstone from 'cornerstone-core';
import { cornerstoneWADOImageLoader, cornerstoneWebImageLoader } from './configureImageLoaders';
import {
  isBase64Image,
  isDicom,
  isDicomFromFileSystem,
  parseForAnimalName,
  parseForOwnerName,
} from '../DicomHelpers';
import { loadImage as loadRawImage } from './RawGreyscaleImageLoader';

const fs = process.env.PLATFORM !== 'website' ? window.nodeModules?.fs : null;

const WADOURI_SCHEMES = ['dicomweb', 'wadouri', 'dicomfile'];

let JPG_PNG_IMAGE_ID = 0;
let RAW_UINT16_IMAGE_ID = 0;
const METADATA_ELEMENTS = [
  { displayName: 'Patient Name', name: 'patientName', tag: 'x00100010' },
  { displayName: 'Patient ID', name: 'patientExternalId', tag: 'x00100020' },
  { displayName: 'Patient Birth Date', name: 'patientBirthDate', tag: 'x00100030' },
  { displayName: 'Patient Sex', name: 'patientSex', tag: 'x00100040' },
  { displayName: 'Owner Name', name: 'ownerName', tag: 'x00102297' },
  { displayName: 'Study Instance UID', name: 'studyInstanceUid', tag: 'x0020000D' },
  { displayName: 'Series Instance UID', name: 'seriesInstanceUid', tag: 'x0020000E' },
  { displayName: 'Study Date', name: 'date', tag: 'x00080020' },
  { displayName: 'Study Time', name: 'time', tag: 'x00080030' },
  { displayName: 'Accession Number', name: 'accessionNumber', tag: 'x00080050' },
  { displayName: 'Modality', name: 'modality', tag: 'x00080060' },
  { displayName: 'Study Description', name: 'studyDescription', tag: 'x00081030' },
  { displayName: 'Series Description', name: 'seriesDescription', tag: 'x0008103E' },
];

function getPixelValues(pixelData) {
  let minPixelValue = Number.MAX_VALUE;
  let maxPixelValue = Number.MIN_VALUE;
  const len = pixelData.length;
  let pixel;

  for (let i = 0; i < len; i += 1) {
    pixel = pixelData[i];
    minPixelValue = minPixelValue < pixel ? minPixelValue : pixel;
    maxPixelValue = maxPixelValue > pixel ? maxPixelValue : pixel;
  }

  return {
    minPixelValue,
    maxPixelValue,
  };
}

const loadBase64InImageElement = (image, urlImage) =>
  new Promise((resolve) => {
    const imageElement = new Image();
    imageElement.onload = () => {
      URL.revokeObjectURL(urlImage);
      resolve([imageElement, image]);
    };

    imageElement.src = urlImage;
  });

const loadJpgPngInCornerstone = (image, imageElement) => {
  const imageId = `web:${JPG_PNG_IMAGE_ID}`;
  const cornerstoneImage = cornerstoneWebImageLoader.createImage(imageElement, imageId);
  JPG_PNG_IMAGE_ID += 1;
  return { cornerstoneImage, imageId, metadata: null };
};

const loadJpgPngBase64 = async (image, urlImage) => {
  const [imageElement, resImage] = await loadBase64InImageElement(image, urlImage);
  return loadJpgPngInCornerstone(resImage, imageElement);
};

const loadJpgPngFile = (image) => {
  const { imageFile } = image;
  const urlImage = URL.createObjectURL(imageFile);
  return loadJpgPngBase64(image, urlImage);
};

const getOrCreateImageId = (image, source) => {
  const { imageFile } = image;
  let imageId;
  if (image.imageId) {
    imageId = image.imageId;
  } else if (source === 'fileSystem') {
    const buffer = fs.readFileSync(imageFile.path);
    const blob = new Blob([new Uint8Array(buffer)]);
    imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(blob);
  } else {
    imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(imageFile);
  }
  return imageId;
};

const loadDicom = (image, source) => {
  const imageId = getOrCreateImageId(image, source);
  image.imageId = imageId;

  console.log(imageId);
  return cornerstone.loadImage(imageId).then((cornerstoneImage) => {
    // We cleanup WADOImageLoader cache once image is fully loaded since it is not automatically cleared
    if (imageId) {
      const parsedImageId = cornerstoneWADOImageLoader.wadouri.parseImageId(imageId);
      if (WADOURI_SCHEMES.includes(parsedImageId.scheme)) {
        cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(parsedImageId.url);
      }
    }

    // 'generalSeriesModule', 'patientStudyModule', 'imagePlaneModule', 'imagePixelModule', 'modalityLutModule', 'voiLutModule', 'sopCommonModule', 'petIsotopeModule'
    // patientSex, patientWeight, patientSize, patientName, patientAge
    let dicomMetadata = {};
    console.log('dicom metadata');
    METADATA_ELEMENTS.forEach(({ displayName, name, tag }) => {
      dicomMetadata[name] = cornerstoneImage.data.string(tag);
    });

    const metadata = {
      animalName: parseForAnimalName(dicomMetadata.patientName),
      ownerName: parseForOwnerName(dicomMetadata.patientName),
      animalSex: dicomMetadata.patientSex,
      animalWeight: dicomMetadata.patientWeight,
      animalSize: dicomMetadata.patientSize,
      animalAge: dicomMetadata.patientAge,
    };
    const { minPixelValue, maxPixelValue } = getPixelValues(cornerstoneImage.getPixelData());
    cornerstoneImage.minPixelValue = minPixelValue;
    cornerstoneImage.maxPixelValue = maxPixelValue;
    return { cornerstoneImage, imageId, metadata };
  });
};

const loadRawUint16 = async (acquisitionData) => {
  const imageId = `rawUint16:${RAW_UINT16_IMAGE_ID}`;
  RAW_UINT16_IMAGE_ID += 1;
  const cornerstoneImage = await loadRawImage(imageId, acquisitionData).promise;
  return { cornerstoneImage, imageId, metadata: null };
};

const isRawImage = (imageFile) =>
  imageFile?.data !== undefined &&
  imageFile?.data instanceof Uint16Array &&
  imageFile?.width !== undefined &&
  imageFile?.height !== undefined;

const getMetadata = (cornerstoneImage) => {};

const loadImage = async (image) => {
  if (isRawImage(image.imageFile)) {
    return loadRawUint16(image.imageFile);
  }
  if (isBase64Image(image.imageFile)) {
    return loadJpgPngBase64(image, image.imageFile);
  }
  if (isDicom(image.imageFile)) {
    return loadDicom(image, 'web');
  }
  if (isDicomFromFileSystem(image.imageFile)) {
    return loadDicom(image, 'fileSystem');
  }
  return loadJpgPngFile(image);
};

export default loadImage;

export { isRawImage, loadRawUint16 };
