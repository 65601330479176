import {
  import as cornerstoneToolsImport,
  external,
  getToolState,
  addToolState,
  toolColors,
} from 'cornerstone-tools';
import * as _ from 'lodash';
import { offsetPixelPointInCanvas } from './pixelToCanvasUtils';

const BaseAnnotationTool = cornerstoneToolsImport('base/BaseAnnotationTool');
const getNewContext = cornerstoneToolsImport('drawing/getNewContext');
const draw = cornerstoneToolsImport('drawing/draw');
const drawHandles = cornerstoneToolsImport('drawing/drawHandles');

/**
 * @public
 * @class ImageMarkerTool
 * @memberof Tools.Annotation
 *
 * @classdesc Tool for annotating an image with image markers.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class ImageMarkerTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'ImageMarker',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        markerSource: null,
        markerImage: null,
        allowMultiple: false,
        initialImageWidth: 40,
        initialImageHeight: 40,
        drawHandlesOnHover: true,
      },
    };

    super(props, defaultProps);
    this.setMarker(this.configuration.markerSource);
  }

  setMarker = (markerSource) => {
    this.configuration.markerSource = markerSource;
    this.configuration.markerImage = null;
    if (!markerSource) return;

    const img = new Image();
    img.onload = () => {
      this.configuration.markerImage = img;
    };
    img.src = markerSource;
  };

  createNewMeasurement = (eventData) => {
    const config = this.configuration;
    const { allowMultiple, initialImageHeight, initialImageWidth } = config;

    const toolData = getToolState(eventData.element, this.name);

    if (_.get(toolData, 'data', []).length > 0 && !allowMultiple) {
      return null;
    }

    const bottomRight = {
      x: eventData.currentPoints.image.x,
      y: eventData.currentPoints.image.y,
      highlight: true,
      active: false,
      allowedOutsideImage: true,
    };
    const topLeft = offsetPixelPointInCanvas(eventData.element, bottomRight, {
      x: -initialImageWidth,
      y: -initialImageHeight,
    });
    topLeft.highlight = true;
    topLeft.active = false;
    topLeft.allowedOutsideImage = true;

    // Create the measurement data for this tool with the end handle activated
    const measurementData = {
      visible: true,
      active: false,
      color: undefined,
      handles: {
        topLeft,
        bottomRight,
      },
    };

    return measurementData;
  };

  addNewMeasurement = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const eventData = evt.detail;
    const { element } = eventData;
    const measurementData = this.createNewMeasurement(eventData);

    if (!measurementData) {
      return;
    }

    addToolState(element, this.name, measurementData);

    external.cornerstone.updateImage(element);
  };

  // eslint-disable-next-line class-methods-use-this
  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    const rect = {
      left: data.handles.topLeft.x,
      top: data.handles.topLeft.y,
      width: data.handles.bottomRight.x - data.handles.topLeft.x,
      height: data.handles.bottomRight.y - data.handles.topLeft.y,
    };
    const pixelCoords = external.cornerstone.canvasToPixel(element, coords);
    const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(rect, pixelCoords);
    const insideRect = external.cornerstoneMath.point.insideRect(pixelCoords, rect);

    return distanceToPoint < 10 || insideRect;
  }

  // eslint-disable-next-line class-methods-use-this
  updateCachedStats() {
    // Implementing to satisfy BaseAnnotationTool
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const config = this.configuration;
    const { drawHandlesOnHover, handleRadius, markerImage } = config;
    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = getToolState(eventData.element, this.name);

    if (!toolData) return;
    if (!markerImage) return;

    // We have tool data for this element - iterate over each one and draw it
    const canvasContext = getNewContext(eventData.canvasContext.canvas);

    for (let i = 0; i < toolData.data.length; i += 1) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }
      const color = toolColors.getColorIfActive(data);

      draw(canvasContext, (context) => {
        const imageStartCanvasCoords = external.cornerstone.pixelToCanvas(
          eventData.element,
          data.handles.topLeft
        );
        const imageEndCanvasCoords = external.cornerstone.pixelToCanvas(
          eventData.element,
          data.handles.bottomRight
        );
        const width = imageEndCanvasCoords.x - imageStartCanvasCoords.x;
        const height = imageEndCanvasCoords.y - imageStartCanvasCoords.y;

        context.drawImage(
          markerImage,
          imageStartCanvasCoords.x,
          imageStartCanvasCoords.y,
          width,
          height
        );

        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover && !data.active,
          hideHandlesIfMoving: false,
        };

        drawHandles(context, eventData, data.handles, handleOptions);
      });
    }
  }
}
