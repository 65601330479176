import * as csm from 'cornerstone-math';

const getNormalizedVector = (p1, p2) => {
  const norm = csm.point.distance(p1, p2);
  return {
    x: (p2.x - p1.x) / norm,
    y: (p2.y - p1.y) / norm,
  };
};

const addVector = (v1, v2) => ({ x: v1.x + v2.x, y: v1.y + v2.y });

const rotateVector = ({ x, y }, angle) => {
  const cosa = Math.cos(angle);
  const sina = Math.sin(angle);
  return { x: x * cosa - y * sina, y: x * sina + y * cosa };
};

const norm = (v) => Math.sqrt(v.x ** 2 + v.y ** 2);

const getVectorsAngle = (v1, v2) => {
  const angle = Math.atan2(v2.y, v2.x) - Math.atan2(v1.y, v1.x);
  if (angle > Math.PI) return angle - 2 * Math.PI;
  if (angle < -Math.PI) return angle + 2 * Math.PI;
  return angle;
};

const getLinesAngle = ([p1, p2], [p3, p4]) =>
  getVectorsAngle({ x: p2.x - p1.x, y: p2.y - p1.y }, { x: p4.x - p3.x, y: p4.y - p3.y });

const getNormalizedOrth = (p1, p2) => {
  const norm = csm.point.distance(p1, p2);
  return {
    x: -(p2.y - p1.y) / norm,
    y: +(p2.x - p1.x) / norm,
  };
};

const getMiddlePoint = (p1, p2) => ({
  x: (p1.x + p2.x) / 2,
  y: (p1.y + p2.y) / 2,
});

// Implementation taken from cornerstone-math
function lineDistanceToPoint(lineSegment, point) {
  const l2 = csm.point.distance(lineSegment.start, lineSegment.end);

  if (l2 === 0) {
    return csm.point.distance(point, lineSegment.start);
  }

  const t =
    ((point.x - lineSegment.start.x) * (lineSegment.end.x - lineSegment.start.x) +
      (point.y - lineSegment.start.y) * (lineSegment.end.y - lineSegment.start.y)) /
    l2;

  const pt = {
    x: lineSegment.start.x + t * (lineSegment.end.x - lineSegment.start.x),
    y: lineSegment.start.y + t * (lineSegment.end.y - lineSegment.start.y),
  };

  return csm.point.distance(point, pt);
}

// Returns intersection points of two lines
// Implementation taken from cornerstone and simplified to compute line intersection
// instead of segment intersection
const getLineIntersection = (l1, l2) => {
  const x1 = l1.start.x;
  const y1 = l1.start.y;
  const x2 = l1.end.x;
  const y2 = l1.end.y;
  const x3 = l2.start.x;
  const y3 = l2.start.y;
  const x4 = l2.end.x;
  const y4 = l2.end.y;

  // Compute a1, b1, c1, where line joining points 1 and 2 is "a1 x  +  b1 y  +  c1  =  0"
  const a1 = y2 - y1;
  const b1 = x1 - x2;
  const c1 = x2 * y1 - x1 * y2;

  // Compute a2, b2, c2
  const a2 = y4 - y3;
  const b2 = x3 - x4;
  const c2 = x4 * y3 - x3 * y4;

  /* Line segments intersect: compute intersection point.
   */
  const denom = a1 * b2 - a2 * b1;

  // Check if lines are orthogonal
  // Not equality check because or float rounding error.
  if (Math.abs(denom) < 10 ** -12) return undefined;

  /* The denom/2 is to get rounding instead of truncating.  It
   * is added or subtracted to the numerator, depending upon the
   * sign of the numerator.
   */

  let num = b1 * c2 - b2 * c1;
  const x = parseFloat(num / denom);

  num = a2 * c1 - a1 * c2;
  const y = parseFloat(num / denom);

  return { x, y };
};

export {
  norm,
  getNormalizedVector,
  getNormalizedOrth,
  getLineIntersection,
  getVectorsAngle,
  getLinesAngle,
  lineDistanceToPoint,
  addVector,
  rotateVector,
  getMiddlePoint,
};
