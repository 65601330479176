import stream from 'app/native/node/stream';
import { decode, encode, getHeader } from 'app/xray/generator/GeneratorMessageEncoder';
import {
  GeneratorPacketUnWrapper,
  GeneratorPacketWrapper,
} from 'app/xray/generator/GeneratorPackets';
import {
  DR_READY,
  ERROR,
  EXP_STATE,
  GEN_OPERATIVE_STATUS,
  GEN_THICKNESS,
  PREP_CONFIRMATION,
  PREP_STATE,
  RAD_VALUES_POST_EXP,
  RAD_VALUE_COMB_STATUS,
  REQ_CHANGE_OPERATING_MODE,
  REQ_GEN_STATUS,
  REQ_THICKNESS,
  RESET_WARNING,
  SET_RAD_PARAM_COMB,
  SET_SINGLE_RAD_PARAM,
  START_CONFIG,
  START_XRAY,
  SW_VER,
  WARNING,
} from 'app/xray/generator/MessageCode';
import {
  FOCUS,
  RAD_PARAMETER_KEY_TO_RAD_PARAMETER_CODE,
  OPERATING_MODE,
  GENERATOR_HEADER_TO_EVENT_DESCRIPTOR,
  GENERATOR_WARNING,
  EVENT_GENERATOR_WARNING,
} from 'app/xray/generator/constants';

const XRAY_SHOT_DELAY = 500;

export default class XRayGeneratorController extends EventTarget {
  /** @param {import("stream").Duplex} xRayGeneratorStream */
  constructor(xRayGeneratorStream) {
    super();
    if (!stream()) return undefined;

    this.xRayGeneratorStream = xRayGeneratorStream;
    this.writeStream = GeneratorPacketWrapper();
    this.writeStream.pipe(xRayGeneratorStream);

    // To remove
    this.listeningStream = GeneratorPacketUnWrapper();
    this.writeStream.pipe(this.listeningStream);

    this.listeningStream.on('data', (chunk) => {
      const header = getHeader(chunk);
      let headerName;
      switch (header) {
        case SET_RAD_PARAM_COMB:
          headerName = 'SET_RAD_PARAM_COMB';
          break;
        case DR_READY:
          headerName = 'DR_READY';
          break;
        case SET_SINGLE_RAD_PARAM:
          headerName = 'SET_SINGLE_RAD_PARAM';
          break;
        case RESET_WARNING:
          headerName = 'RESET_WARNING';
          break;
        case START_CONFIG:
          headerName = 'START_CONFIG';
          break;
        case PREP_STATE:
          headerName = 'PREP_STATE';
          break;
        case EXP_STATE:
          headerName = 'EXP_STATE';
          break;
        case START_XRAY:
          headerName = 'START_XRAY';
          break;
        case PREP_CONFIRMATION:
          headerName = 'PREP_CONFIRMATION';
          break;
        case REQ_THICKNESS:
          headerName = 'REQ_THICKNESS';
          break;
        case GEN_THICKNESS:
          headerName = 'GEN_THICKNESS';
          break;
        case REQ_CHANGE_OPERATING_MODE:
          headerName = 'REQ_CHANGE_OPERATING_MODE';
          break;
        case RAD_VALUE_COMB_STATUS:
          headerName = 'RAD_VALUE_COMB_STATUS';
          break;
        case REQ_GEN_STATUS:
          headerName = 'REQ_GEN_STATUS';
          break;
        case GEN_OPERATIVE_STATUS:
          headerName = 'GEN_OPERATIVE_STATUS';
          break;
        case RAD_VALUES_POST_EXP:
          headerName = 'RAD_VALUES_POST_EXP';
          break;
        case ERROR:
          headerName = 'ERROR';
          break;
        case SW_VER:
          headerName = 'SW_VER';
          break;
        case WARNING:
          headerName = 'WARNING';
          break;
        default:
          headerName = header;
      }
      console.log('Written chunk', headerName, decode(chunk));
    });

    this.readStream = GeneratorPacketUnWrapper();
    xRayGeneratorStream.pipe(this.readStream);

    this.readStream.on('data', this._onReceivePacket);
  }

  /**
   * Provide a stream close function
   */
  close = () => this.xRayGeneratorStream.close();

  /**
   * This message needs to be sent from the imaging system to the generator directly
   * after launching the imaging system.
   * It is used to synchronise the date between the imaging system and the generator.
   * @param {Date} date
   */
  init = (date) => {
    this.writeStream.write(
      encode(
        [
          START_CONFIG,
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
        ],
        [1, 2]
      )
    );
  };

  /**
   * This message is used for a keep alive mechanism. The imaging system will send
   * this message periodically at 4/5 sec interval.
   * @returns {void}
   */
  requestStatus = () => {
    this.writeStream.write(encode([REQ_GEN_STATUS]));
  };

  /**
   * This message is used to lock or unlock the generator.
   * The generator need to be locked if the imaging system is not in image acquisition mode.
   * @param {boolean} isLocked
   */
  lock = (isLocked) => {
    this.writeStream.write(encode([DR_READY, isLocked ? 0 : 1]));
  };

  /**
   * his message is used to change the state of the generator.
   * @param {OPERATING_MODE.SLEEPING|OPERATING_MODE.STANDBY|OPERATING_MODE.SHUTDOWN} mode
   */
  changeOperatingMode = (mode) => {
    this.writeStream.write(encode([REQ_CHANGE_OPERATING_MODE, mode]));
  };

  /**
   * This message is used to set a complete set of exposure values e.g. if an organ is chosen.
   * @param {RadParameters} radParameters
   * @param {Number} mAs    This property is ignored and automatically inferred from mA and mS
   * @param {Number} focus  Automatically inferred from mA
   */
  setRadParameters = ({ kV, mA, ms, focus = FOCUS.SMALL }) => {
    this.writeStream.write(
      encode(
        [SET_RAD_PARAM_COMB, kV, Math.round((ms / 1000) * mA * 10), mA, ms, focus],
        [1, 1, 2, 2, 2]
      )
    );
  };

  /**
   * This message is used to set a single radiological parameter (kV, mA, ms).
   * This can be the case if a single radiological parameter is adjusted by the user
   * after choosing an organ.
   * @param {"kV"|"mAs"|"mA"|"ms"} parameterType
   * @param {boolean} shouldIncrement
   */
  updateRadParameter = (parameterType, shouldIncrement) => {
    const parameterCode = RAD_PARAMETER_KEY_TO_RAD_PARAMETER_CODE[parameterType];
    if (parameterCode === undefined) {
      throw new Error(`updateRadParameter used with invalid key '${parameterType}'`);
    }

    this.writeStream.write(encode([SET_SINGLE_RAD_PARAM, parameterCode, shouldIncrement ? 1 : 2]));
  };

  /**
   * This message is sent by the imaging system and is used as a confirmation
   * for the generator to start the x-ray preparation.
   * @param {boolean} allowed
   */
  prepConfirmation = (allowed) => {
    this.writeStream.write(encode([PREP_CONFIRMATION, allowed ? 1 : 0]));
    setTimeout(() => this.requestStatus(), XRAY_SHOT_DELAY);
  };

  /**
   * In case the practician pressed the `prep switch` too soon the prep state can be canceled
   * @returns {void}
   */
  cancelPreparation = () => {
    this.changeOperatingMode(OPERATING_MODE.STANDBY);
  };

  /**
   * This message is sent by the imaging system and is used as a confirmation
   * for the generator to start exposure.
   * The value “Rad not allowed” will be sent periodically until imaging software is ready.
   * If the imaging software is ready the value “Rad allowed” will be sent.
   * @param {boolean} allowed
   */
  startXRay = (allowed) => {
    this.writeStream.write(encode([START_XRAY, allowed ? 1 : 0]));
    setTimeout(() => this.requestStatus(), XRAY_SHOT_DELAY);
  };

  /**
   * This message is used to determine the radio parameters according to the thickness of the
   * animal.
   * In the visual menu of X-ray parameters, the software (aqs) inquiries repeatedly as
   * often it could be done the thickness, even the case it has already received a value.
   * @returns {void}
   */
  requestThickness = () => {
    this.writeStream.write(encode([REQ_THICKNESS]));
  };

  /**
   * This message is used by the imaging system to reset the warning state of the generator.
   * @returns {void}
   */
  resetWarning = () => {
    this.writeStream.write(encode([RESET_WARNING]));
  };

  /**
   * Shutdown the generator, should be done on app termination
   */
  shutdown = () => {
    this.changeOperatingMode(OPERATING_MODE.SHUTDOWN);
  };

  /**
   * @param {EVENT_SW_VER|EVENT_GENERATOR_STATUS|EVENT_GENERATOR_RAD_PARAMETERS_CHANGE|
   * EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE|EVENT_GENERATOR_PREP_STATE|
   * EVENT_GENERATOR_EXPOSURE_STATE|EVENT_GENERATOR_THICKNESS|EVENT_GENERATOR_ERROR|
   * EVENT_GENERATOR_WARNING} eventName
   * @param {(evt:{target:XRayGeneratorController, eventName:string} & (
   * GeneratorSoftwareVersion|RadParameters|PostExposureRadParameters|GeneratorThickness|
   * GeneratorOperativeStatus|GeneratorStateChange|GeneratorError|GeneratorWarning
   * )) => void} callback
   */
  on = (eventName, callback) => this.addEventListener(eventName, callback);

  _onReceivePacket = (chunk) => {
    const header = getHeader(chunk);

    const eventDescriptor = GENERATOR_HEADER_TO_EVENT_DESCRIPTOR[header];
    if (eventDescriptor === undefined) return;

    let { eventName } = eventDescriptor;
    const { params, bytesLength } = eventDescriptor;

    const packetParts = decode(chunk, [1, ...(bytesLength ?? [])]);
    let payload = params.reduce(
      (draftPayload, param, index) => ({ ...draftPayload, [param]: packetParts[index + 1] }),
      {}
    );
    if (header === RAD_VALUE_COMB_STATUS || header === RAD_VALUES_POST_EXP) {
      payload.mAs /= 10;
    }
    if (header === GEN_THICKNESS) payload.thickness /= 10;
    if (header === ERROR) {
      const isWarningCode = Object.values(GENERATOR_WARNING).includes(payload.errorCode);
      if (isWarningCode) {
        eventName = EVENT_GENERATOR_WARNING;
        payload = { eventName, warningCode: payload.errorCode };
      }
    }

    const event = new Event(eventName);
    Object.assign(event, { eventName, ...payload });

    // To remove
    console.log('generator event', event);

    this.dispatchEvent(event);
  };
}
