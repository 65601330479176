import _ from 'lodash';
import GenericPMSJSONParser from 'app/pms/parser/GenericPMSJSONParser';
import NoParserError from 'app/pms/parser/NoParserError';
import PmsParser, { PmsConfiguration } from 'app/pms/parser/PmsParser';
import { PmsTypes } from 'app/pms/parser/types';

const ASSISTO_VET_ORIGIN = 'AssistoVet';
const ASSISTO_VET_CONFIGURATION_KEY = 'assisto_vet';
const GM_VET_ORIGIN = 'GMVet';
const GM_VET_CONFIGURATION_KEY = 'gm_vet';

export class AggregatedParser implements PmsParser {
  private currentPmsType: PmsTypes;
  constructor(
    private parsers: Record<PmsTypes, PmsParser> = {
      assisto_vet: new GenericPMSJSONParser(ASSISTO_VET_CONFIGURATION_KEY, ASSISTO_VET_ORIGIN),
      gm_vet: new GenericPMSJSONParser(GM_VET_CONFIGURATION_KEY, GM_VET_ORIGIN),
    }
  ) {
    this.currentPmsType = undefined;
  }

  parse = (fileContent: string) => {
    const parser = this.parsers[this.currentPmsType];
    if (parser === undefined) {
      throw new NoParserError(
        `No valid parser matching PMSType. Selected PMSType ${this.currentPmsType}`
      );
    }
    return parser.parse(fileContent);
  };

  updateConfiguration = (pmsConfiguration: PmsConfiguration) => {
    this.currentPmsType = pmsConfiguration.type;
    _.forEach(this.parsers, (parser) => parser.updateConfiguration(pmsConfiguration));
  };
}
