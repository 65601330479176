import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module-free';
import * as csc from 'cornerstone-core';
import * as _ from 'lodash';
import PizZipUtils from '../modified_modules/pizzip-utils';
// import JSZip from 'jszip'
// import JSZipUtils from 'jszip-utils'

import { getReport, splitReport } from './reports/reportsGenerator';
import cornerstoneElementToBlob from '../CornerstoneTools/cornerstoneElementToBlob';
import loadImage from './cornerstone/loadImage';
import loadImageInCanvas from '../CornerstoneTools/loadImageInCanvas';
import downloadFile from './downloadFile';
import ApiCalls from './apiCalls';
import { ageToString } from '../components/AgeFromDate';
import { isWorkListImage } from './isWorkListImage';
// import ImageModule from "open-docxtemplater-image-module"
// import ImageModule from "open-docxtemplater-image-module-free"
// var ImageModule = require('open-docxtemplater-image-module');
import ReportCanvas from '../../static/canevas-compte-rendu-generic.docx';

const separator = '@=@=@=@=@=@=@=@=@=@=@=@=@=';

const DOCX_TRANSLATION_KEYS = [
  'title',
  'clinic',
  'email',
  'patient',
  'owner',
  'animal_name',
  'species',
  'size',
  'age',
  'sex',
  'weight',
  'radiographic_findings',
  'conclusion',
  'recommendations',
  'x-ray_images',
];

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

function getTemplatePath() {
  return ReportCanvas;
}

function getOutputFileName(animalName, ownerName) {
  let outputFileName = 'Picoxia_';
  const date = new Date();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const dateIdentifier = date.getFullYear() + month + day + date.getHours() + date.getMinutes();
  outputFileName += dateIdentifier;
  if (ownerName) {
    outputFileName += `_${ownerName}`;
  }
  if (animalName) {
    outputFileName += `_${animalName}`;
  }
  return `${outputFileName}.docx`;
}

function base64DataURLToArrayBuffer(dataURL) {
  //  console.log(dataURL)
  const base64Regex = /^data:image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
  if (!base64Regex.test(dataURL)) {
    return false;
  }
  const stringBase64 = dataURL.replace(base64Regex, '');
  let binaryString;
  if (typeof window !== 'undefined') {
    binaryString = window.atob(stringBase64);
  } else {
    binaryString = new Buffer(stringBase64, 'base64').toString('binary');
  }
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
}

const createImageModule = (imagesStore) =>
  new ImageModule({
    getImage: (tag, tagName) => imagesStore[tagName].imageData,
    getSize: (imgBuffer, tag, tagName) => {
      const { height, width, forceHeight } = imagesStore[tagName];
      const ratio = forceHeight / height;
      const newWidth = Math.round(width * ratio);
      return [newWidth, forceHeight];
    },
  });

const preloadImage = async (image) => {
  let loadedBlob;
  try {
    if (!csc.getEnabledElement(image.cornerstoneRef)) {
      throw new Error('Require loading image from file');
    }
    loadedBlob = await cornerstoneElementToBlob(image.cornerstoneRef, true, 720, 720);
  } catch {
    const { cornerstoneImage } = await loadImage(image);
    const getBlob = async ({ canvas }) => {
      const blob = await new Promise((resolve) => canvas.toBlob(resolve));
      return { blob, width: canvas.width, height: canvas.height };
    };
    loadedBlob = await loadImageInCanvas(cornerstoneImage, getBlob, 0, 720, 720);
  }

  const { blob, width, height } = loadedBlob;
  const arrayBuffer = await blob.arrayBuffer();
  return { width, height, arrayBuffer };
};

const getSpecieString = (study, intl) => {
  let { specie } = study;
  if (!specie) {
    const imagesWithChat = _.filter(
      study.images,
      ({ predictions }) => predictions?.chat !== undefined
    );
    const catConfidence = imagesWithChat?.[0]?.predictions?.chat;
    if (catConfidence !== undefined) {
      if (catConfidence > 0.9) specie = 'cat';
      if (catConfidence < 0.1) specie = 'dog';
    }
  }
  if (!specie) return '';
  return intl.formatMessage({ id: `patient_info.specie.${specie}`, defaultMessage: specie });
};

const generateDocxBlob = async (
  report,
  animalName,
  ownerName,
  animalSex,
  animalWeight,
  animalSize,
  animalAge,
  specie,
  clinicName,
  vetName,
  userMail,
  clinicInfo,
  images,
  clinicLogo,
  intl
) => {
  // images should have keys width, height and base64
  const reportData = splitReport(report, intl);
  const data = reportData;
  // console.log(clinicInfo)
  data.animalName = animalName || '';
  data.ownerName = ownerName || '';
  data.specie = specie || '';
  data.clinicInfo = clinicInfo || '';
  data.clinicMail = userMail || '';
  data.animalSex = animalSex;
  data.animalWeight = animalWeight;
  data.animalSize = animalSize;
  data.animalAge = animalAge;
  DOCX_TRANSLATION_KEYS.forEach((key) => {
    data[`docx.${key}`] = intl.formatMessage({ id: `docx.${key}` });
  });

  const imagesStore = {};

  if (clinicLogo) {
    imagesStore.logo = {
      width: clinicLogo.width,
      height: clinicLogo.height,
      forceHeight: 150,
      imageData: base64DataURLToArrayBuffer(clinicLogo.base64),
    };
    data.logo = 'logo';
  }
  // Preload all images into promise to make them all available at once.
  const preloadPromises = images.map(preloadImage);
  const loadedImagesData = await Promise.all(preloadPromises);

  loadedImagesData.forEach((imageData, index) => {
    if (!imageData) return;
    const imageTag = `image${index + 1}`;
    imagesStore[imageTag] = {
      width: imageData.width,
      height: imageData.height,
      forceHeight: 400,
      imageData: imageData.arrayBuffer,
    };
    data[imageTag] = imageTag;
  });

  const filePath = getTemplatePath();

  return new Promise((resolve) =>
    loadFile(filePath, (error, content) => {
      if (error) {
        throw error;
      }
      const zip = new PizZip(content);
      // var zip = PizZip.getBinaryContent(content);
      // var zip = new JSZip(content);
      // var doc = new window.docxtemplater().loadZip(zip)
      const doc = new Docxtemplater().loadZip(zip);
      doc.setOptions({ linebreaks: true });
      doc.attachModule(createImageModule(imagesStore)).compile();
      doc.setData(data);
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (err) {
        const e = {
          message: err.message,
          name: err.name,
          stack: err.stack,
          properties: err.properties,
        };
        console.log(JSON.stringify({ error: e }));
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
        throw err;
      }
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      }); // Output the document using Data-URI
      resolve(out);
    })
  );
};

const generateDocx = async (
  report,
  animalName,
  ownerName,
  animalSex,
  animalWeight,
  animalSize,
  animalAge,
  specie,
  clinicName,
  vetName,
  userMail,
  clinicInfo,
  images,
  clinicLogo,
  intl
) => {
  const docxBlob = await generateDocxBlob(
    report,
    animalName,
    ownerName,
    animalSex,
    animalWeight,
    animalSize,
    animalAge,
    specie,
    clinicName,
    vetName,
    userMail,
    clinicInfo,
    images,
    clinicLogo,
    intl
  );
  downloadFile(docxBlob, getOutputFileName(animalName, ownerName));
};

const generateDocxBlobFromStudy = async (study, intl) => {
  const { reportInfo } = (await ApiCalls.getReportInfo(true))?.data;
  const { creationDate, images } = study;
  // eslint-disable-next-line camelcase
  const { name = '', owner_name = '', sex, birth_date } = study?.animal ?? {};
  const animalAgeString = ageToString(birth_date, intl);
  const specieString = getSpecieString(study, intl);

  const { comment = getReport(name, owner_name, images, intl, creationDate) } = study;
  const { clinicInfo, workplace_name: clinicName } = reportInfo;
  const vetName =
    reportInfo.first_name !== 'null' ? `Dr. ${reportInfo.first_name} ${reportInfo.last_name}` : '';
  const userMail = reportInfo.mail;
  const clinicLogo = reportInfo.clinic_logo === 'NO_LOGO' ? null : reportInfo.clinic_logo;
  return generateDocxBlob(
    comment,
    name,
    owner_name,
    sex ? intl.formatMessage({ id: `patient_info.sex.${sex}` }) : '',
    '',
    '',
    animalAgeString,
    specieString,
    clinicName,
    vetName,
    userMail,
    clinicInfo,
    _.reject(study.images, isWorkListImage),
    clinicLogo,
    intl
  );
};

const generateDocxFromStudy = async (study, intl) => {
  const docxBlob = await generateDocxBlobFromStudy(study, intl);
  // eslint-disable-next-line camelcase
  const { name, owner_name } = study?.animal ?? {};
  downloadFile(docxBlob, getOutputFileName(name, owner_name));
};

export {
  generateDocx,
  generateDocxBlob,
  generateDocxBlobFromStudy,
  generateDocxFromStudy,
  getSpecieString,
};
