export const PMS_SET_WATCHED_DIRECTORY = 'pms/SET_WATCHED_DIRECTORY';
export const PMS_SET_IS_INVALID_WATCHED_DIRECTORY = 'pms/SET_IS_INVALID_WATCHED_DIRECTORY';
export const PMS_SET_TYPE = 'pms/set_type';
export const PMS_SET_EXPORT_CONFIGURATION = 'pms/set_export_configuration';
export const PMS_SET_EXPORT_DIRECTORY = 'pms/set_export_directory';
export const PMS_SET_SPECIFIC_CONFIGURATION = 'pms/set_specific_configuration';

export const setWatchedDirectory = (watchedDirectory) => ({
  type: PMS_SET_WATCHED_DIRECTORY,
  payload: { watchedDirectory },
});

export const setInvalidWatchedDirectory = (isInvalidWatchedDirectory) => ({
  type: PMS_SET_IS_INVALID_WATCHED_DIRECTORY,
  payload: { isInvalidWatchedDirectory },
});

export const setPMSType = (type) => ({
  type: PMS_SET_TYPE,
  payload: { type },
});

export const setPMSExportConfiguration = (exportConfiguration) => ({
  type: PMS_SET_EXPORT_CONFIGURATION,
  payload: { exportConfiguration },
});

export const setPMSExportDirectory = (exportDirectory) => ({
  type: PMS_SET_EXPORT_DIRECTORY,
  payload: { exportDirectory },
});

export const setPMSSpecificConfiguration = (pmsType, path, value) => ({
  type: PMS_SET_SPECIFIC_CONFIGURATION,
  payload: { pmsType, path, value },
});
