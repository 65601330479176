import { TOGGLE_FULL_SIZE_CONFIG_ONGOING } from 'app/components/Dropzone/actions';

export default (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_FULL_SIZE_CONFIG_ONGOING:
      return { ...state, isFullScreenConfigurationOngoing: action.isOngoing };
    default:
      return state;
  }
};
