import 'app/styles/style.scss';
import './style.css';

import React, { useState } from 'react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Modal, Popup } from 'semantic-ui-react';

import ExamSelector from '../ExamSelector';
import ExamIcon from '../ExamIcon';
import { DEFAULT_SPECIE } from 'app/utils/speciesConstants';

const EditAnatomicRegionModal = React.memo(({ study, image, onNewAnatomicRegion }) => {
  const [open, setOpen] = useState(false);

  const onAnatomicRegionSelected = ({ specie, mainRegion, subRegion, view }) => {
    const anatomicRegion = _.filter([specie, mainRegion, subRegion, view], _.identity).join(' ');
    onNewAnatomicRegion(anatomicRegion);
    setOpen(false);
  };

  const specie = study.animal?.specie ?? DEFAULT_SPECIE;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      trigger={
        <Popup
          content={<FormattedMessage id="dropzone.edit_anatomic_region.tooltip" />}
          trigger={
            <button
              type="button"
              className="thumbnail-icon large icon edit-anatomic-region"
              onClick={() => setOpen(true)}
            >
              <i style={{ backgroundColor: 'white' }} className="svg-icon pencil" />
            </button>
          }
        />
      }
      className="anatomic-region-selection-modal"
    >
      <div className="anatomic-region-selection-modal__content">
        <h2>
          <FormattedMessage id="dropzone.edit_anatomic_region.title" />
        </h2>
        <div>
          <div className="flex" style={{ alignItems: 'center' }}>
            <div style={{ flexDirection: 'column' }} className="flex center">
              <h3>
                <FormattedMessage id="dropzone.edit_anatomic_region.current_region" />
              </h3>
              <ExamIcon style={{ height: '130px', width: '130px' }} exam={image.anatomicRegion} />
            </div>
            <ExamSelector specie={specie} onComplete={onAnatomicRegionSelected} />
          </div>
        </div>
      </div>
    </Modal>
  );
});

// class EditAnatomicRegionModal extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       opened,
//     };
//   }

//   render() {}
// }

EditAnatomicRegionModal.propTypes = {
  study: pt.shape({ animal: pt.shape({ specie: pt.string }) }).isRequired,
  image: pt.shape({
    anatomicRegion: pt.string,
  }).isRequired,
  onNewAnatomicRegion: pt.func,
};

EditAnatomicRegionModal.defaultProps = {
  onNewAnatomicRegion: () => {},
};

export default EditAnatomicRegionModal;
