/* eslint-disable max-len */
/* eslint-disable camelcase */
import cornerstoneElementToBlob from 'app/CornerstoneTools/cornerstoneElementToBlob';
import { getFormat } from 'app/utils/dateUtil';
import { generateDocxBlobFromStudy } from 'app/utils/docxUtil';
import JSZip from 'jszip';
import isWorkListImage from 'app/utils/isWorkListImage';
import getPicoxiaDicom from 'app/native/node/picoxiaDicom';
import * as _ from 'lodash';
import getFs from 'app/native/node/fs';
import path from 'app/native/node/path';
import getElectron from 'app/native/node/electron';
import exportZipFile from './exportZipFile';
import { format } from 'date-fns';
import { getAnatomicRegionFromString } from 'app/utils/xrayRegions';
import { IntlShape } from 'react-intl';
import { Patient } from 'app/interfaces/Patient';
import { XRayImage } from 'app/interfaces/Image';
import { DropzoneImage } from 'app/interfaces/Study';

export type JPEGFormat = {
  maxDimension: number;
  quality: number;
};

type PreparedFileData = {
  arrayBuffer: ArrayBuffer;
  filename: string;
};

type ImageId = string | number;
type SelectedFormat = { isSelected: boolean };
type FilePreparationFn = (image: DropzoneImage, index: number) => Promise<PreparedFileData>;

export type ExportableStudy = {
  animal?: Patient;
  images: DropzoneImage[];
};

const INVALID_CHAR_CODE_SELECT_REGEX = new RegExp(
  `[${String.fromCharCode(...Array(32).keys())}]`,
  'g'
);

const sanitizeFilename = (filename: string) =>
  filename
    .replaceAll(INVALID_CHAR_CODE_SELECT_REGEX, '')
    .replaceAll(/[<>:"/\\|?*]/g, '')
    .replace(/[ .]*$/, '');

const createIndividualFilename = (
  intl: IntlShape,
  image: XRayImage,
  animal: Patient | undefined,
  baseFilename: string,
  index: number
) => {
  let filename = '';
  const { anatomicRegion, acquisitionTime } = image;
  const { name, owner_name } = animal ?? {};

  if (anatomicRegion) {
    if (owner_name) filename += `${owner_name}_`;
    if (name) filename += `${name}_`;
    if (acquisitionTime) {
      const dateString = format(acquisitionTime, 'yyyy-MM-dd_kk-mm');
      filename += `${dateString}_`;
    }
    const { mainRegion, subRegion, view } = getAnatomicRegionFromString(anatomicRegion);
    if (mainRegion) filename += `${intl.formatMessage({ id: `exam.body_part.${mainRegion}` })}_`;
    if (subRegion) filename += `${intl.formatMessage({ id: `exam.body_part.${subRegion}` })}_`;
    if (view) filename += `${intl.formatMessage({ id: `exam.view.${view}` })}_`;
  } else {
    filename = `${baseFilename}_`;
  }
  filename += `${index + 1}`;
  filename = filename.replace(/_+$/, '');
  return sanitizeFilename(filename);
};

const transformBlobToArrayBuffer = async (blob: Blob) => blob.arrayBuffer();

const insertArrayBufferIntoZipFile = (
  zip: JSZip,
  completeFilename: string,
  arrayBuffer: ArrayBuffer
) => {
  zip.file(completeFilename, arrayBuffer, {
    createFolders: false,
  });
};

const createLocalFolder = (directoryPathWithFolderName: string) => {
  if (!getFs().existsSync(directoryPathWithFolderName)) {
    try {
      getFs().mkdirSync(directoryPathWithFolderName);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
  return directoryPathWithFolderName;
};

const createFileIntoFolderFromArrayBuffer = async (
  generatedPathToLocalFolder: string,
  completeFilename: string,
  dicomData: ArrayBuffer
) => {
  const pathToLocalFolderWithFileName = path().join(generatedPathToLocalFolder, completeFilename);
  try {
    getFs().appendFileSync(pathToLocalFolderWithFileName, Buffer.from(dicomData));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const imageToArrayBuffer = async (image: DropzoneImage, format: JPEGFormat) =>
  cornerstoneElementToBlob(
    image.cornerstoneRef,
    true,
    format.maxDimension,
    format.maxDimension,
    'image/jpeg',
    format.quality / 100
  ).then(
    ({ blob }) => transformBlobToArrayBuffer(blob)
    // const arrayBuffer = await transformBlobToArrayBuffer(blob);
    // const filename = createJPGFilename(baseFilename, index, formatType);
    // return { filename, arrayBuffer };
  );

const reportToArrayBuffer = (currentStudy: ExportableStudy, intl: IntlShape) =>
  generateDocxBlobFromStudy(currentStudy, intl).then(async (docxBlob) => ({
    arrayBuffer: await transformBlobToArrayBuffer(docxBlob),
    filename: `${intl.formatMessage({ id: 'report.filename' })}.docx`,
  }));

const dicomToArrayBuffer = async (image: XRayImage) => {
  if (!image.dicomData) return undefined;
  return getPicoxiaDicom()?.writeDicomAsync(image.dicomData);
};

const createBaseFilename = (intl: IntlShape, patient?: Patient) => {
  const now = Date.now();
  const dateString = format(now, 'yyyy-MM-dd_kk-mm');

  let filename = `Picoxia__${dateString}_`;

  const { name, owner_name } = patient ?? {};
  if (owner_name) filename += `_${owner_name}`;
  if (name) filename += `_${name}`;
  return sanitizeFilename(filename);
};

const exportArrayBufferPromisesIntoZipFile = async (
  filenamedArrayBufferPromises: Promise<PreparedFileData>[],
  baseFilename: string
) => {
  const zip = new JSZip();
  const savedPromises = filenamedArrayBufferPromises.map((promise) =>
    promise.then(async ({ filename, arrayBuffer }) => {
      if (!arrayBuffer) return;
      await insertArrayBufferIntoZipFile(zip, filename, arrayBuffer);
    })
  );
  await Promise.all(savedPromises);
  exportZipFile(zip, baseFilename);
};

const exportArrayBufferPromisesIntoLocalFolder = async (
  filenamedArrayBufferPromises: Promise<PreparedFileData>[],
  baseFilename: string,
  directoryPath: string
) => {
  const directoryPathWithFolderName = path().join(directoryPath, baseFilename);
  await createLocalFolder(directoryPathWithFolderName);
  const savedPromises = filenamedArrayBufferPromises.map((promise) =>
    promise.then(async ({ filename, arrayBuffer }) => {
      if (!arrayBuffer) return;
      await createFileIntoFolderFromArrayBuffer(directoryPathWithFolderName, filename, arrayBuffer);
    })
  );
  await Promise.all(savedPromises);
  getElectron().shell.openPath(directoryPathWithFolderName);
};

const exportStudy = async (
  intl: IntlShape,
  studyToExport: ExportableStudy,
  {
    selectedImages,
    isReportIncluded,
    jpgFormat,
    dicomFormat,
    thumbnailFormat,
    directoryPath,
  }: {
    selectedImages: ImageId[];
    isReportIncluded: boolean;
    jpgFormat: JPEGFormat & SelectedFormat;
    dicomFormat: SelectedFormat;
    thumbnailFormat: JPEGFormat & SelectedFormat;
    directoryPath: string;
  }
) => {
  const baseFilename = createBaseFilename(intl, studyToExport?.animal);

  const imagesToExport = studyToExport.images.filter(
    (image) => selectedImages.indexOf(image.id) !== -1
  );

  const filenamedArrayBufferPromises = [];

  const prepareJPEGImage: FilePreparationFn = async (image, index: number) => ({
    arrayBuffer: await imageToArrayBuffer(image, jpgFormat),
    filename: `${createIndividualFilename(
      intl,
      image,
      studyToExport.animal,
      baseFilename,
      index
    )}.jpg`,
  });

  const prepareJPEGThumbnail: FilePreparationFn = async (image, index: number) => ({
    arrayBuffer: await imageToArrayBuffer(image, thumbnailFormat),
    filename: `${createIndividualFilename(
      intl,
      image,
      studyToExport.animal,
      baseFilename,
      index
    )}_thumbnail.jpg`,
  });

  const prepareDicom: FilePreparationFn = async (image, index: number) => ({
    arrayBuffer: await dicomToArrayBuffer(image),
    filename: `${createIndividualFilename(
      intl,
      image,
      studyToExport.animal,
      baseFilename,
      index
    )}.dcm`,
  });

  _.reject(imagesToExport, isWorkListImage).forEach((image, index) => {
    if (jpgFormat.isSelected) {
      filenamedArrayBufferPromises.push(prepareJPEGImage(image, index));
    }
    if (thumbnailFormat.isSelected) {
      filenamedArrayBufferPromises.push(prepareJPEGThumbnail(image, index));
    }
    if (dicomFormat.isSelected) {
      filenamedArrayBufferPromises.push(prepareDicom(image, index));
    }
  });

  if (isReportIncluded) {
    filenamedArrayBufferPromises.push(reportToArrayBuffer(studyToExport, intl));
  }

  if (process.env.PLATFORM === 'website') {
    await exportArrayBufferPromisesIntoZipFile(filenamedArrayBufferPromises, baseFilename);
  }

  if (process.env.PLATFORM === 'electron') {
    await exportArrayBufferPromisesIntoLocalFolder(
      filenamedArrayBufferPromises,
      baseFilename,
      directoryPath
    );
  }
};

export default exportStudy;
