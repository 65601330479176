/** @typedef {Object} DataSet */

/**
 * @typedef {Object} AssociationParameters
 * @prop {String} hostIP        IP of the host to connect to
 * @prop {Number} port          port of the host to connect to
 * @prop {String} hostAETitle
 * @prop {String} selfAETitle
 * @prop {String} [username]
 * @prop {String} [password]
 * @prop {Number} [timeout]     Timeout in seconds
 */

/**
 * @typedef {Object} PACSError
 * @prop {("request_failure"|"send_error"|"associate_error")} error_type
 * @prop {String} status
 * @prop {String} [error_comment]
 * @prop {String[]} [attribute_identifier_list]
 * @prop {String[]} [offending_elements]
 */

/**
 * @typedef {Object} RequestSuccess
 * @prop {String} status
 * @prop {String} [error_comment]
 * @prop {String[]} [attribute_identifier_list]
 * @prop {String[]} [offending_elements]
 */

/** @typedef {("associationchange")} EventName */
/** @typedef {(boolean)} EventType */

/**
 * @typedef {Object} PACSCommunication
 * @prop {(associationParameters: AssociationParameters) => Promise<undefined|PACSError>} associate
 * @prop {(dataSet: DataSet) => Promise<RequestSuccess|PACSError>} store
 * @prop {() => Promise<RequestSuccess|PACSError>} echo
 * @prop {() => Promise<bool>} abort
 * @prop {() => Promise<bool>} release
 * @prop {() => bool} isAssociated
 * @prop {(eventName: EventName, callback:(payload: EventType) => undefined) => undefined} on
 */

/**
 * @typedef  PicoxiaDicomModule
 * @prop {new (associationParameters?: AssociationParameters) => PACSCommunication} PACSCommunication
 * @prop {(dicomBuffer: Uint8Array|String ) => DataSet} readDicom
 * @prop {(data: DataSet, header?: DataSet, transferSyntax?: String) => Uint8Array} writeDicom
 * @prop {(data: DataSet, header?: DataSet, transferSyntax?: String) => Promise<Uint8Array>} writeDicomAsync
 */

const ERROR_STATUS = {
  UNREACHABLE_HOST: 'UNREACHABLE_HOST',
  REJECTED: 'REJECTED',
  NOT_ASSOCIATED: 'NOT_ASSOCIATED',
  BROKEN_ASSOCIATION: 'BROKEN_ASSOCIATION',
  INVALID_SOP_CLASS: 'INVALID_SOP_CLASS',
  MISSING_SOP_CLASS: 'MISSING_SOP_CLASS',
  MISSING_INSTANCE_ID: 'MISSING_INSTANCE_ID',
  UNKNOWN: 'UNKNOWN',
};

/**  @returns {PicoxiaDicomModule} */
const getPicoxiaDicomModule = () => window.nodeModules?.picoxiaDicom;

export default getPicoxiaDicomModule;

export { ERROR_STATUS };
