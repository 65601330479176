// Pixel Data type is a bit special.
// For non encapsulated pixel, it can be either Uint16Array or Uint8Array.
// For encapsulated pixel data,
// it will start with a Uint32Array that specify the position of all the image frames
// then any number of Uint8Array that represent the different images fragments of each frame.
export type DicomPixelDataType = Uint8Array | Uint16Array | [Uint32Array, ...Uint8Array[]];
export type DicomBaseValue = undefined | string | Number | DicomPixelDataType;
export type DicomValue = DicomBaseValue | DicomBaseValue[];
export type DicomField = DicomValue | { data: DicomValue; VR: string };
export type DicomTag = string;
export type DicomData = { [key: string]: DicomField };

// Full list of all transfer syntaxes
// Right now we only support Implicit
export enum DicomTransferSyntax {
  ImplicitVRLittleEndian = '1.2.840.10008.1.2',
  ExplicitVRLittleEndian = '1.2.840.10008.1.2.1',
  EncapsulatedUncompressedExplicitVRLittleEndian = '1.2.840.10008.1.2.1.98',
  DeflatedExplicitVRLittleEndian = '1.2.840.10008.1.2.1.99',
  ExplicitVRBigEndian = '1.2.840.10008.1.2.2',
  ExplicitVRBigEndian_Retired = '1.2.840.10008.1.2.2',
  JPEGBaseline8Bit = '1.2.840.10008.1.2.4.50',
  JPEGBaselineProcess1 = '1.2.840.10008.1.2.4.50',
  JPEGExtended12Bit = '1.2.840.10008.1.2.4.51',
  JPEGExtendedProcess24 = '1.2.840.10008.1.2.4.51',
  JPEGExtended35 = '1.2.840.10008.1.2.4.52',
  JPEGExtendedProcess35_Retired = '1.2.840.10008.1.2.4.52',
  JPEGSpectralSelectionNonHierarchical68 = '1.2.840.10008.1.2.4.53',
  JPEGSpectralSelectionNonHierarchicalProcess68_Retired = '1.2.840.10008.1.2.4.53',
  JPEGSpectralSelectionNonHierarchical79 = '1.2.840.10008.1.2.4.54',
  JPEGSpectralSelectionNonHierarchicalProcess79_Retired = '1.2.840.10008.1.2.4.54',
  JPEGFullProgressionNonHierarchical1012 = '1.2.840.10008.1.2.4.55',
  JPEGFullProgressionNonHierarchicalProcess1012_Retired = '1.2.840.10008.1.2.4.55',
  JPEGFullProgressionNonHierarchical1113 = '1.2.840.10008.1.2.4.56',
  JPEGFullProgressionNonHierarchicalProcess1113_Retired = '1.2.840.10008.1.2.4.56',
  JPEGLossless = '1.2.840.10008.1.2.4.57',
  JPEGLosslessNonHierarchicalProcess14 = '1.2.840.10008.1.2.4.57',
  JPEGLosslessNonHierarchical15 = '1.2.840.10008.1.2.4.58',
  JPEGLosslessNonHierarchicalProcess15_Retired = '1.2.840.10008.1.2.4.58',
  JPEGExtendedHierarchical1618 = '1.2.840.10008.1.2.4.59',
  JPEGExtendedHierarchicalProcess1618_Retired = '1.2.840.10008.1.2.4.59',
  JPEGExtendedHierarchical1719 = '1.2.840.10008.1.2.4.60',
  JPEGExtendedHierarchicalProcess1719_Retired = '1.2.840.10008.1.2.4.60',
  JPEGSpectralSelectionHierarchical2022 = '1.2.840.10008.1.2.4.61',
  JPEGSpectralSelectionHierarchicalProcess2022_Retired = '1.2.840.10008.1.2.4.61',
  JPEGSpectralSelectionHierarchical2123 = '1.2.840.10008.1.2.4.62',
  JPEGSpectralSelectionHierarchicalProcess2123_Retired = '1.2.840.10008.1.2.4.62',
  JPEGFullProgressionHierarchical2426 = '1.2.840.10008.1.2.4.63',
  JPEGFullProgressionHierarchicalProcess2426_Retired = '1.2.840.10008.1.2.4.63',
  JPEGFullProgressionHierarchical2527 = '1.2.840.10008.1.2.4.64',
  JPEGFullProgressionHierarchicalProcess2527_Retired = '1.2.840.10008.1.2.4.64',
  JPEGLosslessHierarchical28 = '1.2.840.10008.1.2.4.65',
  JPEGLosslessHierarchicalProcess28_Retired = '1.2.840.10008.1.2.4.65',
  JPEGLosslessHierarchical29 = '1.2.840.10008.1.2.4.66',
  JPEGLosslessHierarchicalProcess29_Retired = '1.2.840.10008.1.2.4.66',
  JPEGLosslessSV1 = '1.2.840.10008.1.2.4.70',
  JPEGLosslessNonHierarchicalFirstOrderPredictionProcess14SelectionValue1 = '1.2.840.10008.1.2.4.70',
  JPEGLSLossless = '1.2.840.10008.1.2.4.80',
  JPEGLSLosslessImageCompression = '1.2.840.10008.1.2.4.80',
  JPEGLSNearLossless = '1.2.840.10008.1.2.4.81',
  JPEGLSLossyNearLosslessImageCompression = '1.2.840.10008.1.2.4.81',
  JPEG2000Lossless = '1.2.840.10008.1.2.4.90',
  JPEG2000ImageCompressionLosslessOnly = '1.2.840.10008.1.2.4.90',
  JPEG2000 = '1.2.840.10008.1.2.4.91',
  JPEG2000ImageCompression = '1.2.840.10008.1.2.4.91',
  JPEG2000MCLossless = '1.2.840.10008.1.2.4.92',
  JPEG2000Part2MulticomponentImageCompressionLosslessOnly = '1.2.840.10008.1.2.4.92',
  JPEG2000MC = '1.2.840.10008.1.2.4.93',
  JPEG2000Part2MulticomponentImageCompression = '1.2.840.10008.1.2.4.93',
  JPIPReferenced = '1.2.840.10008.1.2.4.94',
  JPIPReferencedDeflate = '1.2.840.10008.1.2.4.95',
  MPEG2MPML = '1.2.840.10008.1.2.4.100',
  MPEG2MainProfileMainLevel = '1.2.840.10008.1.2.4.100',
  MPEG2MPHL = '1.2.840.10008.1.2.4.101',
  MPEG2MainProfileHighLevel = '1.2.840.10008.1.2.4.101',
  MPEG4HP41 = '1.2.840.10008.1.2.4.102',
  MPEG4AVCH264HighProfileLevel41 = '1.2.840.10008.1.2.4.102',
  MPEG4HP41BD = '1.2.840.10008.1.2.4.103',
  MPEG4AVCH264BDcompatibleHighProfileLevel41 = '1.2.840.10008.1.2.4.103',
  MPEG4HP422D = '1.2.840.10008.1.2.4.104',
  MPEG4AVCH264HighProfileLevel42For2DVideo = '1.2.840.10008.1.2.4.104',
  MPEG4HP423D = '1.2.840.10008.1.2.4.105',
  MPEG4AVCH264HighProfileLevel42For3DVideo = '1.2.840.10008.1.2.4.105',
  MPEG4HP42STEREO = '1.2.840.10008.1.2.4.106',
  MPEG4AVCH264StereoHighProfileLevel42 = '1.2.840.10008.1.2.4.106',
  HEVCMP51 = '1.2.840.10008.1.2.4.107',
  HEVCH265MainProfileLevel51 = '1.2.840.10008.1.2.4.107',
  HEVCM10P51 = '1.2.840.10008.1.2.4.108',
  HEVCH265Main10ProfileLevel51 = '1.2.840.10008.1.2.4.108',
  RLELossless = '1.2.840.10008.1.2.5',
  RFC2557MIMEEncapsulation = '1.2.840.10008.1.2.6.1',
  RFC2557MIMEencapsulation_Retired = '1.2.840.10008.1.2.6.1',
  XMLEncoding = '1.2.840.10008.1.2.6.2',
  XMLEncoding_Retired = '1.2.840.10008.1.2.6.2',
  SMPTEST211020UncompressedProgressiveActiveVideo = '1.2.840.10008.1.2.7.1',
  SMPTEST211020UncompressedInterlacedActiveVideo = '1.2.840.10008.1.2.7.2',
  SMPTEST211030PCMDigitalAudio = '1.2.840.10008.1.2.7.3',
}

export enum SupportedEncodingTransferSyntax {
  ImplicitVRLittleEndian = DicomTransferSyntax.ImplicitVRLittleEndian,
  ExplicitVRLittleEndian = DicomTransferSyntax.ExplicitVRLittleEndian,
  JPEGLSLossless = DicomTransferSyntax.JPEGLSLossless,
}
