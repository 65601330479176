import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import * as _ from 'lodash';
import { focusHandles } from './CropTool';

const VIEWPORT_PROPERTIES = ['hflip', 'vflip', 'rotation', 'invert'];

export function syncViewportAdaptCrop(elementToSync, viewport) {
  if (!csc.getImage(elementToSync)) return;
  try {
    const newViewport = _.pick(viewport, VIEWPORT_PROPERTIES);
    const currentViewport = _.pick(csc.getViewport(elementToSync), VIEWPORT_PROPERTIES);
    if (_.isEqual(newViewport, currentViewport)) return;

    csc.setViewport(elementToSync, newViewport);
    const cropToolData = cst.getToolState(elementToSync, 'Crop');
    if (cropToolData && cropToolData.data.length > 0) {
      focusHandles(elementToSync, cropToolData.data[0].handles);
    }
  } catch {
    // Pass
  }
}

export function syncCrop(elementToSync, cropMeasurementData) {
  if (!csc.getImage(elementToSync)) return;

  const previousCropData = cst.getToolState(elementToSync, 'Crop')?.data?.[0];
  const mainElementCrop = _.omit(cropMeasurementData, ['uuid']);

  if (previousCropData) _.merge(previousCropData, mainElementCrop);
  else cst.addToolState(elementToSync, 'Crop', mainElementCrop);

  focusHandles(elementToSync, cropMeasurementData.handles);
}

function syncViewportOnMainRender(targetElement, evt) {
  syncViewportAdaptCrop(targetElement, evt.detail.viewport);
}

function cropCompletionListener(targetElement, evt) {
  const { toolName, measurementData } = evt.detail;
  if (toolName !== 'Crop') return;
  syncCrop(targetElement, measurementData);
}

const linkCornerstoneElements = (sourceElement, targetElement) => {
  const syncViewportCallback = _.debounce(
    (evt) => syncViewportOnMainRender(targetElement, evt),
    100
  );
  const syncCropCallback = (evt) => cropCompletionListener(targetElement, evt);

  sourceElement.addEventListener(csc.EVENTS.IMAGE_RENDERED, syncViewportCallback);
  sourceElement.addEventListener(cst.EVENTS.MEASUREMENT_COMPLETED, syncCropCallback);
  sourceElement.addEventListener(cst.EVENTS.MEASUREMENT_ADDED, syncCropCallback);

  return () => {
    sourceElement.removeEventListener(csc.EVENTS.IMAGE_RENDERED, syncViewportCallback);
    sourceElement.removeEventListener(cst.EVENTS.MEASUREMENT_COMPLETED, syncCropCallback);
    sourceElement.removeEventListener(cst.EVENTS.MEASUREMENT_ADDED, syncCropCallback);
  };
};

export default linkCornerstoneElements;
export { linkCornerstoneElements };
