import { format, parse } from 'date-fns';
import _ from 'lodash';
import React, { memo, useCallback, useEffect, useRef, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useImmerReducer } from 'use-immer';

import fs from 'app/native/node/fs';
import path from 'app/native/node/path';
import {
  selectXRayConfiguration,
  selectXRaySelectedOperators,
} from 'app/redux/xrayGeneratorConfiguration/reducer';
import {
  CONNECTION_KEEP_ALIVE_TIMEOUT_MS,
  CONNECTION_RETRY_INTERVAL_MS,
  GENERATOR_WAKE_UP_INTERVAL_MS,
} from 'app/xray/generator/constants';
import XRayGeneratorManagerContext from 'app/providers/XRayGeneratorManagerProvider/context';
import {
  EVENT_GENERATOR_RAD_PARAMETERS_CHANGE,
  EVENT_GENERATOR_STATUS,
  EVENT_SW_VER,
  EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE,
  EVENT_GENERATOR_PREP_STATE,
  EVENT_GENERATOR_EXPOSURE_STATE,
  EVENT_GENERATOR_THICKNESS,
  EVENT_GENERATOR_ERROR,
  EVENT_GENERATOR_WARNING,
  STATE_CHANGE,
  IGNORED_WARNINGS,
  GENERATOR_WARNING,
  UNIT_STATUS,
  IGNORED_WARNINGS_IN_HISTORY,
} from 'app/xray/generator/constants';
import { Message, Modal } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import logger from 'app/utils/debug/logger';
import convertXRayFileToShotObjects from './convertXRayFileToShotObjects';

const CONNECTION_STATE = ['not_connected', 'connection_failed', 'connecting', 'connected'];

/**
 * @typedef {Object} XRayGeneratorState
 * @prop {String} version
 * @prop {import("app/adapters/XRayGenerator/XRayGeneratorController").RadParameters} radParameters
 * @prop {import("app/adapters/XRayGenerator/XRayGeneratorController").UnitStatus} unitStatus
 * @prop {import("app/adapters/XRayGenerator/XRayGeneratorController").ReadyStatus} readyStatus
 * @prop {import("app/adapters/XRayGenerator/XRayGeneratorController").GeneratorError | undefined} error
 * @prop {import("app/adapters/XRayGenerator/XRayGeneratorController").GeneratorWarning | undefined} warning
 */

/**
 * @typedef {Object} XRayGeneratorHistory
 * @prop {(operator : String|undefined) => String} getHistoryAsCsv
 * @prop {() => [Object]} getAllHistory
 */
/**
 * @typedef {Object} XRayGeneratorFaultsHistory
 * @prop {() => [Object]} getFaults
 */

/** @type {React.Context<XRayGeneratorController>} */
export const XRayGeneratorControllerContext = React.createContext();
/** @type {React.Context<XRayGeneratorState>} */
export const XRayGeneratorDispatchContext = React.createContext();
/** @type {React.Context<XRayGeneratorState>} */
export const XRayGeneratorStateContext = React.createContext();
/** @type {React.Context<XRayGeneratorHistory>} */
export const XRayGeneratorHistoryContext = React.createContext();
/** @type {React.Context<XRayGeneratorFaultsHistory>} */
export const XRayGeneratorFaultsHistoryContext = React.createContext();

export const setConnectionState = (connectionState) => ({
  type: 'setConnectionState',
  payload: connectionState,
});
export const setSoftwareVersion = (softwareVersion) => ({
  type: 'setSoftwareVersion',
  payload: softwareVersion,
});
export const updateGeneratorStatus = (generatorStatus) => ({
  type: 'updateGeneratorStatus',
  payload: generatorStatus,
});
export const setErrorStatus = (errorCode) => ({
  type: 'setErrorStatus',
  payload: errorCode,
});
export const setWarningStatus = (warningCode) => ({
  type: 'setWarningStatus',
  payload: warningCode,
});
export const clearWarningStatus = () => ({
  type: 'clearWarningStatus',
});
export const setRadParameters = (radParameters) => ({
  type: 'setRadParameters',
  payload: radParameters,
});
export const setXRayOngoing = (isOngoing) => ({
  type: 'setXRayOngoing',
  payload: isOngoing,
});
export const startPreparation = () => ({
  type: 'startPreparation',
});
export const clearPreparation = () => ({
  type: 'clearPreparation',
});

const xRayGeneratorStateReducer = (draft, { type, payload }) => {
  if (type === 'setConnectionState') {
    draft.connectionState = payload;
    if (draft.connectionState !== 'connected') {
      draft.softwareVersion = undefined;
      draft.unitStatus = undefined;
      draft.readyStatus = undefined;
      draft.error = undefined;
      draft.warning = undefined;
      draft.radParameters = undefined;
      draft.xRayOngoing = false;
      draft.prepStarted = false;
    }
  } else if (type === 'setSoftwareVersion') draft.softwareVersion = payload;
  else if (type === 'setErrorStatus') draft.error = payload;
  else if (type === 'setWarningStatus') draft.warning = payload;
  else if (type === 'clearWarningStatus') draft.warning = undefined;
  else if (type === 'setRadParameters') {
    draft.radParameters = _.pick(payload, ['kV', 'mAs', 'mA', 'ms', 'focus']);
  } else if (type === 'updateGeneratorStatus') {
    draft.unitStatus = payload.unitStatus;
    draft.readyStatus = payload.readyStatus;
    if (
      draft.unitStatus === UNIT_STATUS.FIRST_TIME ||
      draft.unitStatus === UNIT_STATUS.SYSTEM_ERROR
    ) {
      draft.prepStarted = false;
    }
    if (draft.unitStatus !== UNIT_STATUS.SYSTEM_ERROR) {
      draft.warning = undefined;
    }
  } else if (type === 'setXRayOngoing') {
    draft.xRayOngoing = payload;
  } else if (type === 'startPreparation') {
    draft.prepStarted = true;
  } else if (type === 'clearPreparation') {
    draft.prepStarted = false;
  }
};

const saveShotToHistory = async (xRayHistoryFile, { kV, mAs, ms, mA }, operators = []) => {
  const nodeFs = fs();
  if (!nodeFs) return;
  const date = format(new Date(), 'yyyy-MM-dd_kk:mm:ss');
  const operatorsString = operators.length > 0 ? `"${operators.join(',')}"` : '';
  const lineContent = [date, kV, mAs, mA, ms, operatorsString].join(',');
  await xRayHistoryFile?.appendFile(`${lineContent}\r\n`, {
    encoding: 'utf8',
  });
};

const saveErrorToHistory = async (xRayErrorLogFile, code, isError) => {
  const nodeFs = fs();
  if (!nodeFs) return;
  if (!xRayErrorLogFile) return;
  const formattedDate = format(new Date(), 'yyyy-MM-dd_kk:mm:ss');
  const type = isError ? 'E' : 'W';
  const hexCode = `0x${code.toString(16)}`;
  const lineContent = `${formattedDate} ${type} ${hexCode}`;
  await xRayErrorLogFile?.appendFile(`${lineContent}\r\n`, {
    encoding: 'utf8',
  });
};

const getOperatorHistoryAsCsv = (fileContent, operator) => {
  const header = 'date,kV,mAs,mA,ms,operators\r\n';
  const matchingLines = !operator
    ? fileContent
    : fileContent
        .split(/\r?\n/)
        .filter((line) => line.match(new RegExp(`(,|")${operator}(,|")`)))
        .join('\r\n');
  return header + matchingLines;
};

export const X_RAY_TUBE_NOT_READY_TIMER = 3000;
/**
 * @param {Object} param
 */
const XRayGeneratorProvider = ({ children, xRayHistoryPath, xRayErrorLogPath }) => {
  const xRayGeneratorManager = useContext(XRayGeneratorManagerContext);
  if (!xRayGeneratorManager) return children;

  const xRayConfigurationImmutable = useSelector(selectXRayConfiguration);
  const [xRayGeneratorController, setXRayGeneratorController] = useState();
  const [xrayHandle, setXRayHandle] = useState();
  const xRayGeneratorControllerRef = useRef();
  xRayGeneratorControllerRef.current = xRayGeneratorController;
  const [xRayGeneratorState, dispatchConnectionState] = useImmerReducer(xRayGeneratorStateReducer, {
    connectionState: 'not_connected',
    softwareVersion: undefined,
    unitStatus: undefined,
    readyStatus: undefined,
    error: undefined,
    warning: undefined,
    radParameters: undefined,
    xRayOngoing: false,
    prepStarted: false,
  });
  const selectedOperators = useSelector(selectXRaySelectedOperators);
  const selectedOperatorsRef = useRef();
  const connectionWakeUpTimeout = useRef();
  const requestStatusTimeout = useRef();
  const xRayHistoryFile = useRef();
  const xRayErrorLogFile = useRef();
  const connectionRetryTimeout = useRef();
  const setupGeneratorHistoryFunctions = () => ({
    getHistoryAsCsv: (operator) =>
      fs()
        ?.promises.readFile(xRayHistoryPath, { encoding: 'utf8' })
        .then((fileContent) => getOperatorHistoryAsCsv(fileContent, operator)),
    getAllHistory: () =>
      fs()
        ?.promises.readFile(xRayHistoryPath, { encoding: 'utf8' })
        .then(convertXRayFileToShotObjects),
  });

  const [xRayGeneratorHistory, setXRayGeneratorHistory] = useState(
    setupGeneratorHistoryFunctions()
  );
  const xRayGeneratorStateRef = useRef();
  xRayGeneratorStateRef.current = xRayGeneratorState;
  const [isBadQualityWarningShown, setIsBadQualityWarningShown] = useState(false);

  const xRayGeneratorDefaultHistory = useRef();
  xRayGeneratorDefaultHistory.current = {
    getFaults: async () =>
      fs()
        ?.promises.readFile(xRayErrorLogPath, { encoding: 'utf8' })
        .then((fileContent) =>
          fileContent
            .split(/\r?\n/)
            .filter(_.identity)
            .map((errorLine) => {
              const [datetime, type, code] = errorLine.split(' ');
              return {
                timestamp: parse(datetime, 'yyyy-MM-dd_kk:mm:ss', new Date()),
                type: type === 'E' ? 'error' : 'warning',
                code: parseInt(code, 16),
              };
            })
        ),
  };

  selectedOperatorsRef.current = selectedOperators;
  const { devicePath } = xRayConfigurationImmutable.toJS();

  useEffect(() => {
    setXRayGeneratorHistory(setupGeneratorHistoryFunctions());
  }, [xRayHistoryPath]);

  useEffect(() => {
    if (!xRayHistoryPath) return undefined;

    (async () => {
      await fs().promises.mkdir(path().dirname(xRayHistoryPath), { recursive: true });

      xRayHistoryFile.current = await fs().promises.open(xRayHistoryPath, 'a+');
    })();
    return () => xRayHistoryFile.current?.close();
  }, [xRayHistoryPath]);

  useEffect(() => {
    if (!xRayErrorLogPath) return undefined;

    (async () => {
      await fs().promises.mkdir(path().dirname(xRayErrorLogPath), { recursive: true });

      xRayErrorLogFile.current = await fs().promises.open(xRayErrorLogPath, 'a+');
    })();

    return () => xRayErrorLogFile.current?.close();
  }, [xRayErrorLogPath]);

  const refreshKeepAlive = () => {
    const planRequestStatus = (lastRequest) => {
      clearTimeout(requestStatusTimeout.current);
      const timeoutId = setTimeout(() => {
        clearTimeout(requestStatusTimeout.current);
        if (!xRayGeneratorControllerRef.current) return;

        xRayGeneratorControllerRef.current.requestStatus();

        if (!lastRequest) {
          planRequestStatus(true);
          return;
        }

        requestStatusTimeout.current = setTimeout(
          () => dispatchConnectionState(setConnectionState('connecting')),
          CONNECTION_KEEP_ALIVE_TIMEOUT_MS
        );
      }, CONNECTION_KEEP_ALIVE_TIMEOUT_MS);

      requestStatusTimeout.current = timeoutId;
    };
    planRequestStatus(false);
  };

  const connectToGenerator = useCallback(async () => {
    setXRayGeneratorController(undefined);
    if (!devicePath) {
      dispatchConnectionState(setConnectionState('not_connected'));
      return;
    }

    let newXRayGeneratorController;
    try {
      const xRayGeneratorHandle = await xRayGeneratorManager.get(devicePath);
      setXRayHandle(xRayGeneratorHandle);
      dispatchConnectionState(setConnectionState('connecting'));

      xRayGeneratorHandle.on('disconnected', () => {
        setXRayHandle(undefined);
      });

      newXRayGeneratorController = await xRayGeneratorHandle.connect();
    } catch (e) {
      setXRayHandle(undefined);
      logger.error(e);
      dispatchConnectionState(setConnectionState('connection_failed'));
      return;
    }

    setXRayGeneratorController(newXRayGeneratorController);

    newXRayGeneratorController.on(EVENT_SW_VER, ({ version, major, minor }) => {
      dispatchConnectionState(setSoftwareVersion({ version, major, minor }));
      dispatchConnectionState(setConnectionState('connected'));
      clearTimeout(connectionWakeUpTimeout.current);
      connectionWakeUpTimeout.current = undefined;
    });

    newXRayGeneratorController?.on(EVENT_GENERATOR_STATUS, (event) => {
      refreshKeepAlive();
      dispatchConnectionState(updateGeneratorStatus(event));
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_RAD_PARAMETERS_CHANGE, (event) => {
      dispatchConnectionState(setRadParameters(event));
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_RAD_PARAMETERS_EXPOSURE, (exposureEvent) => {
      newXRayGeneratorController.requestStatus();
      saveShotToHistory(
        xRayHistoryFile.current,
        exposureEvent,
        selectedOperatorsRef.current?.toJS()
      );
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_WARNING, ({ warningCode }) => {
      if (
        IGNORED_WARNINGS_IN_HISTORY.includes(warningCode) ||
        xRayGeneratorStateRef.current.warning === warningCode
      )
        return;
      saveErrorToHistory(xRayErrorLogFile.current, warningCode, false);
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_ERROR, ({ errorCode }) => {
      if (xRayGeneratorStateRef.current.error === errorCode) return;
      saveErrorToHistory(xRayErrorLogFile.current, errorCode, true);
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_PREP_STATE, ({ status }) => {
      newXRayGeneratorController.prepConfirmation(status === STATE_CHANGE.ACTIVE);
      if (status === STATE_CHANGE.ACTIVE) {
        dispatchConnectionState(startPreparation());
      }
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_EXPOSURE_STATE, ({ status }) => {
      newXRayGeneratorController.startXRay(status === STATE_CHANGE.ACTIVE);
      dispatchConnectionState(setXRayOngoing(status === STATE_CHANGE.ACTIVE));
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_THICKNESS, () => {});
    newXRayGeneratorController.on(EVENT_GENERATOR_ERROR, (event) => {
      dispatchConnectionState(setErrorStatus(event.errorCode));
    });
    newXRayGeneratorController.on(EVENT_GENERATOR_WARNING, (event) => {
      if (event.warningCode === GENERATOR_WARNING.XRAY_CANCEL_BY_OPERATOR) {
        setIsBadQualityWarningShown(true);
        setTimeout(() => setIsBadQualityWarningShown(false), 5000);
        newXRayGeneratorController?.resetWarning();
      } else if (IGNORED_WARNINGS.includes(event.warningCode)) {
        newXRayGeneratorController?.resetWarning();
      } else if (event.warningCode === GENERATOR_WARNING.X_RAY_TUBE_NOT_READY) {
        setTimeout(() => {
          if (xRayGeneratorStateRef.current?.warning === GENERATOR_WARNING.X_RAY_TUBE_NOT_READY) {
            newXRayGeneratorController?.resetWarning();
          }
        }, X_RAY_TUBE_NOT_READY_TIMER);
        dispatchConnectionState(setWarningStatus(event.warningCode));
      } else {
        dispatchConnectionState(setWarningStatus(event.warningCode));
      }
    });
  }, [devicePath, xRayGeneratorManager]);

  const autoClearPrepStarted = () => {
    if (!xRayGeneratorState?.prepStarted) return undefined;
    const clearPrepTimeout = setTimeout(() => dispatchConnectionState(clearPreparation()), 5000);
    return () => clearTimeout(clearPrepTimeout);
  };
  useEffect(autoClearPrepStarted, [
    xRayGeneratorState?.prepStarted,
    xRayGeneratorState?.unitStatus,
  ]);
  // Reconnect each time function change, ie each time devicePath, change
  useEffect(() => connectToGenerator(), [connectToGenerator, devicePath]);

  useEffect(() => {
    if (xrayHandle === undefined) {
      setXRayGeneratorController(undefined);
      if (devicePath) {
        connectionRetryTimeout.current = setTimeout(
          () => connectToGenerator(),
          CONNECTION_RETRY_INTERVAL_MS
        );
      }
    }
    return () => clearTimeout(connectionRetryTimeout.current);
  }, [connectToGenerator, devicePath, xrayHandle]);

  // Clear all timers on unmount
  useEffect(
    () => () => {
      clearTimeout(connectionWakeUpTimeout.current);
      clearTimeout(requestStatusTimeout.current);
      clearTimeout(connectionRetryTimeout.current);
    },
    []
  );

  // Close xRayGeneratorController each time is it recreated
  useEffect(() => {
    if (!xRayGeneratorController) return undefined;

    const shutdownBeforeUnload = () => {
      xRayGeneratorController?.shutdown();
      xRayGeneratorController?.close();
    };
    window.addEventListener('beforeunload', shutdownBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', shutdownBeforeUnload);
      xRayGeneratorController?.shutdown();
      xRayGeneratorController?.close();
    };
  }, [xRayGeneratorController]);

  useEffect(() => {
    if (!xRayGeneratorController) return undefined;
    if (xRayGeneratorState.connectionState === 'connecting') {
      xRayGeneratorController.init(new Date());
      connectionWakeUpTimeout.current = setInterval(() => {
        xRayGeneratorController.init(new Date());
      }, GENERATOR_WAKE_UP_INTERVAL_MS);
      return () => clearTimeout(connectionWakeUpTimeout.current);
    }
    if (xRayGeneratorState.connectionState !== 'connected') {
      return undefined;
    }
    xRayGeneratorController.requestStatus();
    refreshKeepAlive();

    return () => {
      clearTimeout(requestStatusTimeout.current);
    };
  }, [xRayGeneratorController, xRayGeneratorState.connectionState]);

  return (
    <XRayGeneratorControllerContext.Provider value={xRayGeneratorController}>
      <XRayGeneratorDispatchContext.Provider value={dispatchConnectionState}>
        <XRayGeneratorStateContext.Provider value={xRayGeneratorState}>
          <XRayGeneratorHistoryContext.Provider value={xRayGeneratorHistory}>
            <XRayGeneratorFaultsHistoryContext.Provider value={xRayGeneratorDefaultHistory.current}>
              {children}
              <Modal
                open={isBadQualityWarningShown}
                onClose={() => setIsBadQualityWarningShown(false)}
                closeOnDimmerClick
              >
                <Message negative>
                  <Message.Header
                    style={{
                      fontSize: '2em',
                      lineHeight: '1.2em',
                      padding: '1rem',
                    }}
                  >
                    <FormattedMessage id="xray_generator.warning.early_cancel.bad_xray_quality" />
                  </Message.Header>
                </Message>
              </Modal>
              <Modal open={xRayGeneratorState.warning === GENERATOR_WARNING.X_RAY_TUBE_NOT_READY}>
                <Message negative>
                  <Message.Header
                    style={{
                      fontSize: '2em',
                      lineHeight: '1.2em',
                      padding: '1rem',
                    }}
                  >
                    <FormattedMessage id="xray_generator.status.warning.X_RAY_TUBE_NOT_READY" />
                  </Message.Header>
                </Message>
              </Modal>
            </XRayGeneratorFaultsHistoryContext.Provider>
          </XRayGeneratorHistoryContext.Provider>
        </XRayGeneratorStateContext.Provider>
      </XRayGeneratorDispatchContext.Provider>
    </XRayGeneratorControllerContext.Provider>
  );
};

export default memo(XRayGeneratorProvider);
