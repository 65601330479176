import React from 'react';
import * as PropTypes from 'prop-types';
import AppHelmet from 'app/components/AppHelmet';
import Dropzone from '../../components/Dropzone/index';
import withContext from 'app/utils/withContext';
import HideHeaderFnProvider from 'app/providers/HideHeaderFnProvider/context';

export class OnlinePage extends React.Component {
  preventDragHandler = (e) => {
    e.preventDefault();
  };

  render() {
    const { hideHeader } = this.props;
    return (
      <div onDrop={this.preventDragHandler}>
        <AppHelmet titleIntlID="demo.pageTitle" descriptionIntlID="demo.pageDescription" />
        <Dropzone hideHeader={hideHeader} />
      </div>
    );
  }
}

OnlinePage.propTypes = {
  hideHeader: PropTypes.func.isRequired,
};

export default withContext(OnlinePage, HideHeaderFnProvider, 'hideHeader');
