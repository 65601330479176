/* eslint-disable class-methods-use-this */
import * as Comlink from 'comlink';
import _ from 'lodash';

import makeBasicFunctionalWorkerPool from 'app/encoders/jpegls/BasicFunctionalWorkerPool.js';
import EncodeWorker from './encode.worker';
import DecodeWorker from './decode.worker';

class JPEGLSModule {
  constructor() {
    this.encodeWithWorker = makeBasicFunctionalWorkerPool(
      _.times(3, () => Comlink.wrap(new EncodeWorker()))
    );
    this.decodeWithWorker = makeBasicFunctionalWorkerPool(
      _.times(3, () => Comlink.wrap(new DecodeWorker()))
    );
  }

  /**
   *
   * @param {Uint16Array|Uint8Array} imageBuffer
   * @param {FrameInfo} frameInfo
   * @param {number} nearlossless
   */
  async encode(data, frameInfo, nearlossless = 0) {
    const encodedBuffer = await this.encodeWithWorker({ data, frameInfo, nearlossless });

    return encodedBuffer;
  }

  async decode(data) {
    const decodedImage = await this.decodeWithWorker({ buffer: data });

    return decodedImage;
  }
}

export default function createJPEGLSModule() {
  if (!window.Worker) return undefined;
  if (typeof WebAssembly === 'undefined') return undefined;
  return new JPEGLSModule();
}
