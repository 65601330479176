/* eslint-disable camelcase */

import { convertImageAndElementToCanvas } from 'app/CornerstoneTools/cornerstoneElementToBlob';
import ImageTransformer from 'app/adapters/ImageTransformer';
import { DataImage } from 'app/interfaces/DataImage';
import { DicomData, DicomTransferSyntax, DicomPixelDataType } from 'app/interfaces/Dicom';
import { DisplayableImageData, Size } from 'app/interfaces/Image';
import { Transformation, VOI } from 'app/interfaces/Image/Transformation';
import { IJPEGLSEncoder } from 'app/interfaces/ImageEncoder';
import { IImageTransformer, IImageTransformerFactory } from 'app/interfaces/ImageTransformer';
import logger from 'app/utils/debug/logger';

type ImageEncoders = {
  [DicomTransferSyntax.JPEGLSLossless]: IJPEGLSEncoder;
};

export type EncodedTransferSyntaxes = keyof ImageEncoders;

const computeBitsStored = (bitDepth: number) => {
  if (bitDepth > 12) return 16;
  if (bitDepth > 10) return 12;
  if (bitDepth > 8) return 10;
  return 8;
};

let imageIdCounter = 0;
const insertAnnotationsInImage = async (
  imageTransformer: IImageTransformer,
  element: HTMLElement,
  originalImageSize: Size
) => {
  const { width, height } = originalImageSize;
  const emptyImagePixelData = new Uint8Array(width * height);

  const emptyImage = {
    // eslint-disable-next-line no-plusplus
    imageId: `rawUInt16:${imageIdCounter++}`,
    minPixelValue: 0,
    maxPixelValue: 255,
    slope: 1,
    intercept: 0,
    windowCenter: 127,
    windowWidth: 255,
    getPixelData: () => emptyImagePixelData,
    rows: height,
    columns: width,
    height,
    width,
    color: false,
    columnPixelSpacing: 1,
    rowPixelSpacing: 1,
    invert: false,
    sizeInBytes: width * height,
  };

  /** @param {HTMLCanvasElement} canvas */
  const onCanvasLoaded = async (canvas: HTMLCanvasElement) =>
    imageTransformer.insertCanvasMask(canvas);

  return convertImageAndElementToCanvas(element, emptyImage, onCanvasLoaded, true, null, null);
};

export default class DicomBuilder {
  constructor(
    private imageTransformerFactory: IImageTransformerFactory,
    private imageEncoders?: ImageEncoders
  ) {}

  private async encodePixelData(
    img: IImageTransformer,
    transferSyntax?: EncodedTransferSyntaxes
  ): Promise<DicomPixelDataType | undefined> {
    const encoder = this.imageEncoders[transferSyntax];
    if (!encoder) {
      logger.warn(`No encoder found for transferSyntax : ${transferSyntax}`);
      return undefined;
    }

    if (transferSyntax === DicomTransferSyntax.JPEGLSLossless) {
      const encodeOptions = {
        frameInfo: {
          width: img.info.width,
          height: img.info.height,
          bitsPerSample: img.info.bitDepth,
          componentCount: img.info.components,
        },
      };

      try {
        const pixelData = await encoder.encode(img.toBuffer(), encodeOptions);
        return [new Uint32Array(0), pixelData];
      } catch (e) {
        console.error(`Encode error for ${transferSyntax}`, e);
      }
    }

    return undefined;
  }

  private async injectPixelData(
    dicomData: DicomData,
    img: IImageTransformer,
    transformation: Transformation = {},
    voi: VOI,
    transferSyntax?: EncodedTransferSyntaxes,
    sourceAnnotationsElement?: HTMLElement
  ): Promise<DicomData> {
    let { windowWidth, windowCenter } = voi;
    const { invert, rotation, hflip, vflip, crop } = transformation;
    const { width: initialWidth, height: initialHeight } = img.info;
    // We do not apply VOI and invert since DicomSpecification allow specifying them.
    img = img.transform({ rotation, hflip, vflip, crop });
    if (sourceAnnotationsElement) {
      img = await insertAnnotationsInImage(img, sourceAnnotationsElement, {
        width: initialWidth,
        height: initialHeight,
      });
    }

    const { bitDepth, width, height } = img.info;

    let dicomTransferSyntax = transferSyntax;
    let pixelDataVR = 'OB';
    let pixelData = await this.encodePixelData(img, transferSyntax);

    // In case encoding failed or should not return anything, we default to native format.
    if (!pixelData) {
      dicomTransferSyntax = DicomTransferSyntax.ExplicitVRLittleEndian;
      pixelData = bitDepth > 8 ? img.toBuffer() : img.toBuffer();

      if (bitDepth > 8) pixelDataVR = 'OW';
    }

    const minValue = img.getMin();
    const maxValue = img.getMax();

    if (!(windowWidth && windowCenter)) {
      windowWidth = maxValue - minValue;
      windowCenter = Math.round(windowWidth / 2 + minValue);
    }

    // See DICOM specification part03 sect_C.8.7.1.1.6
    const bitsAllocated = bitDepth > 8 ? 16 : 8;
    const bitsStored = computeBitsStored(bitDepth);
    const highBit = bitsStored - 1;

    const dicomPixelData = {
      TransferSyntaxUID: dicomTransferSyntax,
      SamplesPerPixel: 1,
      PhotometricInterpretation: invert ? 'MONOCHROME' : 'MONOCHROME2',
      Rows: height,
      Columns: width,
      BitsAllocated: bitsAllocated,
      BitsStored: bitsStored,
      HighBit: highBit,
      PixelRepresentation: 0,
      SmallestImagePixelValue: { data: minValue, VR: 'US' },
      LargestImagePixelValue: { data: maxValue, VR: 'US' },
      BurnedInAnnotation: 'NO',
      PixelIntensityRelationship: 'LOG',
      PixelIntensityRelationshipSign: 1,
      WindowCenter: windowCenter.toFixed(0),
      WindowWidth: windowWidth.toFixed(0),
      RescaleIntercept: '0',
      RescaleSlope: '1',
      RescaleType: 'US',
      LossyImageCompression: '00',
      PixelData: { data: pixelData, VR: pixelDataVR },
    };
    return { ...dicomData, ...dicomPixelData };
  }

  injectPixelDataFromDisplayableImage = async (
    dicomData: DicomData,
    image: DisplayableImageData,
    transformation: Transformation,
    voi: VOI,
    transferSyntax?: EncodedTransferSyntaxes,
    sourceAnnotationsElement?: HTMLElement
  ): Promise<DicomData> => {
    const img = this.imageTransformerFactory.fromDisplayableImage(image);

    return this.injectPixelData(
      dicomData,
      img,
      transformation,
      voi,
      transferSyntax,
      sourceAnnotationsElement
    );
  };

  injectProcessedImageData = async (
    dicomData: DicomData,
    processedImage: DataImage,
    transformation: Transformation,
    voi: VOI,
    transferSyntax?: EncodedTransferSyntaxes,
    sourceAnnotationsElement?: HTMLElement
  ): Promise<DicomData> => {
    if (!processedImage) return dicomData;

    const img = this.imageTransformerFactory.fromDataImage(processedImage);

    return this.injectPixelData(
      dicomData,
      img,
      transformation,
      voi,
      transferSyntax,
      sourceAnnotationsElement
    );
  };
}
