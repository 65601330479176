import './styles.scss';

import React from 'react';
import ImagesSelector, { ImageSelectorProps } from 'app/components/ImagesSelector';
import StaticImageRenderer, { ImageRendererProps } from 'app/components/StaticImageRenderer';
import { SyncStatusEnum } from 'app/components/SyncStatus';
import ImageUploadStatus from 'app/containers/Teleradiology/ImageUploadStatus';

type ImageUploadRendererProps = ImageRendererProps & { status?: SyncStatusEnum };

function ImageUploadRenderer({ status, ...renderProps }: ImageUploadRendererProps, index: number) {
  return (
    <div className="teleradiology-image-upload__image">
      <StaticImageRenderer {...renderProps} />
      {status && (
        <div className="teleradiology-image-upload__image__status">
          <ImageUploadStatus status={status} />
        </div>
      )}
    </div>
  );
}

export type ImagesUploadSelectorProps = ImageSelectorProps & {
  images: { status?: SyncStatusEnum }[];
};

export default function ImagesUploadSelector(props: ImagesUploadSelectorProps) {
  const imageRender = (renderProps: ImageRendererProps, index: number) => (
    <ImageUploadRenderer {...renderProps} status={props.images[index]?.status} />
  );
  return <ImagesSelector {...props} imageRender={imageRender} />;
}
