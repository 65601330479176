import path from 'app/native/node/path';
import { FRONT_END_PATH } from 'app/redux/global/constants';
import { generateDocxBlobFromStudy } from 'app/utils/docxUtil';
import _ from 'lodash';
import PmsExporter, {
  ImagesType,
  PMSConfigurationType,
  PMS_EXPORT_DEFAULT_JPEG_PARAMS,
  PatientInfosType,
  StudyType,
} from './PmsExporter';
import ApiCalls from 'app/utils/apiCalls';
import generatePDFFromHtml from 'app/pms/exporter/generatePDFFromHtml';
import { getReportHtml } from 'app/utils/pdfUtil';

export default class GmVetExporter extends PmsExporter {
  exportLink = async (exportDirectory: string, pmsId: string, studyId: string) => {
    this.fileSystemPromises.writeFile(
      path().join(exportDirectory, `${pmsId}_study_link.txt`),
      FRONT_END_PATH + '/' + this.intl.locale + '/online?study_ids[]=' + studyId
    );
  };

  exportReportDocx = async (exportDirectory: string, pmsId: string, currentStudy: StudyType) => {
    const transformBlobToArrayBuffer = async (blob: Blob) => blob.arrayBuffer();
    generateDocxBlobFromStudy(currentStudy, this.intl).then(async (docxBlob) => {
      const arrayBuffer = await transformBlobToArrayBuffer(docxBlob);
      const pathToLocalFolderWithFileName = path().join(exportDirectory, `${pmsId}_report.docx`);
      this.fileSystemPromises.writeFile(pathToLocalFolderWithFileName, Buffer.from(arrayBuffer));
    });
  };

  exportReportPdfWithElectron = async (
    exportDirectory: string,
    pmsId: string,
    currentStudy: StudyType,
    images: ImagesType
  ) => {
    const { reportInfo } = (await ApiCalls.getReportInfo(true))?.data;
    const xRayImagesb64 = _.map(
      _.filter(
        await Promise.allSettled(
          images.map(async ({ imageData, annotations, viewport }) =>
            Buffer.from(
              await this.imageEncoder.toJPEG(
                imageData,
                viewport,
                annotations,
                PMS_EXPORT_DEFAULT_JPEG_PARAMS
              )
            ).toString('base64')
          )
        ),
        { status: 'fulfilled' }
      ),
      'value'
    );
    const reportHtml = getReportHtml(currentStudy, xRayImagesb64, reportInfo, this.intl);

    const pdfBuffer = await generatePDFFromHtml(reportHtml);
    this.fileSystemPromises.writeFile(
      path().join(exportDirectory, `${pmsId}_report.pdf`),
      pdfBuffer
    );
  };

  exportDataToPMS = async (
    { exportDirectory }: PMSConfigurationType = {},
    currentStudy: StudyType,
    _patientInfos: PatientInfosType,
    images: ImagesType = []
  ): Promise<PromiseSettledResult<void>> => {
    // PMS not configured, don't bother user with this
    if (!exportDirectory) return undefined;

    const pmsId: string = currentStudy?.pms_id;
    if (!pmsId) {
      console.warn('Patient pms_id is required to export data to PMS');
      return undefined;
    }

    this.exportImages(exportDirectory, pmsId, images);
    // this.exportLink(exportDirectory, pmsId, currentStudy.ID);
    // this.exportReportDocx(exportDirectory, pmsId, currentStudy);
    // this.exportReportPdf(exportDirectory, pmsId, currentStudy, images);
    this.exportReportPdfWithElectron(exportDirectory, pmsId, currentStudy, images);
  };
}
