/* eslint-disable camelcase */
import { format } from 'date-fns';
import * as _ from 'lodash';
import { toLower } from 'lodash';
import * as csc from 'cornerstone-core';
import { computeAge } from '../../components/AgeFromDate';

import { getInitLanguage } from '../languageUtil';
import { NEUTERED_SEXES } from '../sexesConstants';
import { getAnatomicRegionFromString } from '../xrayRegions';
import DEFAULT_SPECIES_MAPPING from 'app/constants/speciesMappings';

// Retrieved from medicalconnections.co.uk
const PICOXIA_DICOM_UID = '1.2.826.0.1.3680043.10.978';
const MAX_DICOM_UID_LENGTH = 64;
const TIMESTAMP_LENGTH = (+Date.now()).toString().length;
const MAX_UID_PART_LENGTH = MAX_DICOM_UID_LENGTH - PICOXIA_DICOM_UID.length - TIMESTAMP_LENGTH - 2;

const truncateObjectIDToNumber = (id, start = -8, end = -1) =>
  id && parseInt(`${id.slice(start, end)}`, 16);

const generateUIDFromObjectID = (objectID) => {
  if (objectID === undefined) return undefined;
  const idRepresentation = BigInt(`0x${objectID}`).toString().slice(-MAX_UID_PART_LENGTH, -1);
  return `${PICOXIA_DICOM_UID}.${idRepresentation}`;
};

const generateStudyUID = (study) => generateUIDFromObjectID(study._id ?? study.ID);

const generateImageUID = (image) => generateUIDFromObjectID(image.backendId);

// Dead code to have a trace of the method used before.
const oldGenerateStudyUID = (study) =>
  `${PICOXIA_DICOM_UID}.${truncateObjectIDToNumber(study._id ?? study.ID)}`;

const oldGenerateImageUID = (study, image) =>
  `${generateStudyUID(study)}.${truncateObjectIDToNumber(image.backendId)}`;

const PACS_BLACKLISTED_DICOM_FIELDS = [
  'FileMetaInformationGroupLength',
  'FileMetaInformationVersion',
  'ImplementationClassUID',
  'ImplementationVersionName',
  'MediaStorageSOPClassUID',
  'MediaStorageSOPInstanceUID',
  'TransferSyntaxUID',
];

const ageRepresentation = (birthDate) => {
  if (!birthDate) return undefined;
  const animalAge = computeAge(birthDate);
  if (!animalAge) return undefined;
  let ageString;
  if (animalAge.years > 2) ageString = `${animalAge.years}Y`;
  else if (animalAge.months > 4) {
    ageString = `${12 * animalAge.years + animalAge.months}M`;
  } else {
    ageString = `${4 * (12 * animalAge.years + animalAge.months) + animalAge.weeks}W`;
  }
  return ageString.padStart(4, '0');
};

/**
 *
 * @param {*} dicomData
 * @param {*} animal
 * @param {import('react-intl').IntlShape} intl
 * @returns
 */
const updateDicomDataPatient = (dicomData, animal, intl) => {
  if (!animal) return dicomData;
  const {
    name,
    sex,
    birth_date,
    specie,
    race,
    owner_name,
    attending_veterinarian,
    chip_id,
    file_id,
    pedigree_id,
  } = animal;
  const ageRepresentationString = ageRepresentation(birth_date);
  const patientName = _.reject([owner_name, name], _.isUndefined).join('^');
  const patientID = file_id ?? animal._id;
  const otherPatientIDs = _.reject([chip_id, pedigree_id], _.isUndefined);

  const specieKey = `patient_info.specie.${specie}`;
  const raceKey = `${specieKey}.race.${race}`;

  const patientDicomData = _.omitBy(
    {
      PatientName: patientName,
      PatientID: patientID,
      PatientSpeciesDescription:
        specie && intl.formatMessage({ id: specieKey, defaultMessage: specie }),
      ResponsiblePerson: owner_name,
      ResponsiblePersonRole: owner_name && 'OWNER',
      PatientBirthDate: birth_date && format(birth_date, 'yyyyMMdd'),
      PatientSex: sex?.[0]?.toUpperCase(),
      PhysiciansOfRecord: attending_veterinarian,
      OtherPatientIDs: otherPatientIDs,
      PatientAge: ageRepresentationString,
      AdditionalPatientHistory: undefined,
      PatientSexNeutered: sex && NEUTERED_SEXES.includes(sex) ? 'ALTERED' : 'UNALTERED',
      PatientBreedDescription: race && intl.formatMessage({ id: raceKey, defaultMessage: race }),
      PatientComments: undefined,
    },
    _.isUndefined
  );
  return { ...dicomData, ...patientDicomData };
};

const updateDicomDataImageWithRawIRay = (dicomData, image) => {
  const { minPixelValue, maxPixelValue, width, height, data } = image.imageFile;
  const windowCenter = Math.round((maxPixelValue + minPixelValue) / 2);
  const windowWidth = Math.round(maxPixelValue - minPixelValue);

  const pixelDicomData = _.omitBy(
    {
      ImageType: ['ORIGINAL', 'PRIMARY'],
      SamplesPerPixel: 1,
      PhotometricInterpretation: 'MONOCHROME1',
      Rows: height,
      Columns: width,
      BitsAllocated: 16,
      BitsStored: 16,
      HighBit: 15,
      PixelRepresentation: 0,
      SmallestImagePixelValue: { data: minPixelValue, VR: 'US' },
      LargestImagePixelValue: { data: maxPixelValue, VR: 'US' },
      BurnedInAnnotation: 'NO',
      PixelIntensityRelationship: 'LOG',
      PixelIntensityRelationshipSign: 1,
      WindowCenter: windowCenter.toString(),
      WindowWidth: windowWidth.toString(),
      RescaleIntercept: '0',
      RescaleSlope: '1',
      RescaleType: 'US',
      LossyImageCompression: '00',
      PixelData: { data, VR: 'OW' },
    },
    _.isUndefined
  );

  return { ...dicomData, ...pixelDicomData };
};

const updateDicomDataImage = (dicomData, image) => {
  const cornerstoneImage = csc.getImage(image.cornerstoneRef);
  const { minPixelValue, maxPixelValue, slope, intercept, width, height } = cornerstoneImage;
  const { windowWidth, windowCenter } = csc.getViewport(image.cornerstoneRef).voi;
  const pixelData = cornerstoneImage.getPixelData();

  const pixelDicomData = _.omitBy(
    {
      SamplesPerPixel: 1,
      PhotometricInterpretation: 'MONOCHROME2',
      Rows: height,
      Columns: width,
      BitsAllocated: 16,
      BitsStored: 16,
      HighBit: 15,
      PixelRepresentation: 0,
      SmallestImagePixelValue: { data: minPixelValue, VR: 'US' },
      LargestImagePixelValue: { data: maxPixelValue, VR: 'US' },
      BurnedInAnnotation: 'NO',
      PixelIntensityRelationship: 'LOG',
      PixelIntensityRelationshipSign: 1,
      WindowCenter: windowCenter && Math.round(windowCenter).toString(),
      WindowWidth: windowWidth && Math.round(windowWidth).toString(),
      RescaleIntercept: intercept?.toString(),
      RescaleSlope: slope?.toString(),
      RescaleType: 'US',
      LossyImageCompression: '00',
      PixelData: { data: pixelData, VR: 'OW' },
    },
    _.isUndefined
  );

  return { ...dicomData, ...pixelDicomData };
};

const updateDicomDataStudyInfo = (dicomData, intl, study, image) => {
  if (!dicomData) return undefined;
  const { mainRegion, subRegion, view } = getAnatomicRegionFromString(image.anatomicRegion);
  let seriesDescription = '';

  if (subRegion) seriesDescription += intl.formatMessage({ id: `exam.body_part.${subRegion}` });

  if (view) {
    if (seriesDescription) seriesDescription += ' ';
    seriesDescription += intl.formatMessage({ id: `exam.view.${view}` });
  }

  const studyInfoData = {
    StudyDescription: intl.formatMessage({ id: `exam.body_part.${mainRegion}` }).toUpperCase(),
    SeriesDescription: seriesDescription || undefined,
  };

  const { acquisitionConstants } = image;
  if (acquisitionConstants) {
    const { kV, mA, s } = acquisitionConstants;
    studyInfoData.KVP = kV.toFixed(0);
    studyInfoData.ExposureTime = (s * 1000).toFixed(0);
    studyInfoData.ExposureTimeInuS = (s * 1000 * 1000).toFixed(0);
    studyInfoData.XRayTubeCurrent = mA.toFixed(0);
    studyInfoData.XRayTubeCurrentInuA = (mA * 1000).toFixed(0);
    // mAs
    studyInfoData.Exposure = (mA * s).toFixed(0);
    studyInfoData.ExposureInuAs = (mA * s * 1000).toFixed(0);
  }
  return { ...dicomData, ...studyInfoData };
};
const updateDicomDataID = (dicomData, study, image) => {
  if (!study.ID || !image.backendId) return dicomData;
  const SOPInstanceUID = generateImageUID(image);
  const StudyInstanceUID = generateStudyUID(study);

  const imageIndex = study.images.findIndex((studyImage) => studyImage === image);

  return {
    StudyID: study.ID,
    InstanceNumber: (imageIndex + 1).toString(),
    SOPInstanceUID,
    StudyInstanceUID,
    SeriesInstanceUID: SOPInstanceUID,
    ...dicomData,
  };
};

const convertIRayImageToDicomData = (
  flatPanelStateContext,
  study,
  image,
  intl,
  isProcessedImage = true
) => {
  const { imageFile, acquisitionTime } = image;
  const contentTime = Date.now();
  const detectorState = flatPanelStateContext?.detectorsStates[imageFile.detectorID];

  const imageDicomData = {
    // StudyDescription: 'RADIO', // OPTIONAL
    // SeriesDescription: undefined,
    BodyPartExamined: undefined && 'QC TARGET',
    PresentationIntentType: 'FOR PRESENTATION',
    ImageType: [isProcessedImage ? 'DERIVED' : 'ORIGINAL', 'PRIMARY'],
    ContentDate: format(contentTime, 'yyyyMMdd'),
    ContentTime: format(contentTime, 'kkmmss.SSS00'),
    StudyDate: acquisitionTime && format(acquisitionTime, 'yyyyMMdd'),
    StudyTime: acquisitionTime && format(acquisitionTime, 'kkmmss.SSS00'),
    AcquisitionDate: acquisitionTime && format(acquisitionTime, 'yyyyMMdd'),
    AcquisitionTime: acquisitionTime && format(acquisitionTime, 'kkmmss.SSS00'),
    SeriesDate: acquisitionTime && format(acquisitionTime, 'yyyyMMdd'),
    SeriesTime: acquisitionTime && format(acquisitionTime, 'kkmmss.SSS00'),
    SpecificCharacterSet: 'ISO_IR 100',
    ImageLaterality: undefined && 'U',
    // PatientOrientation: undefined,
    // PatientPosition: undefined,
    // SeriesNumber: undefined && 4,
    NumberOfFrames: 1,
  };

  const pixelSpacing = detectorState?.allAttributes.pixelSpacing.map((spacing) =>
    spacing?.toFixed(3)
  );

  const { manufacturer, productModel } = detectorState?.allAttributes ?? {};
  // const { serialNumber } = detectorState;
  const deviceDicomData = {
    Modality: 'DX',
    Manufacturer: 'GER INTERNATIONAL',
    // AccessionNumber: undefined, // OPTIONAL
    PixelAspectRatio: ['1', '1'],
    PixelSpacing: pixelSpacing,
    ImagerPixelSpacing: pixelSpacing,
    DetectorElementPhysicalSize: pixelSpacing,
    DetectorID: imageFile.detectorID,
    ViewPosition: undefined && 'AP',
    FieldOfViewShape: 'RECTANGLE',
    // FieldOfViewDimensions: undefined,
    // RelativeXRayExposure: undefined && 2156,
    // TargetExposureIndex: undefined && 0,
    // DetectorActiveTime: undefined && 250,
    DetectorManufacturerName: manufacturer,
    DetectorManufacturerModelName: productModel,
    ManufacturerModelName: 'GER INTERNATIONAL',
    DateOfLastDetectorCalibration: undefined,
    TimeOfLastDetectorCalibration: undefined,
    DetectorTemperature: undefined,
    DetectorType: 'DIRECT',
  };

  const userDicomData = {
    ReferringPhysicianName: undefined,
    InstitutionName: undefined,
    InstitutionAddress: undefined,
    InstitutionalDepartmentName: undefined,
    PhysiciansOfRecord: undefined,
    OperatorsName: undefined,
  };

  let dicomData = _.omitBy(
    {
      SOPClassUID: '1.2.840.10008.5.1.4.1.1.1',
      StationName: 'PICOXIA',
      ...imageDicomData,
      ...deviceDicomData,
      ...userDicomData,
    },
    _.isUndefined
  );
  dicomData = updateDicomDataPatient(dicomData, study.animal, intl);
  dicomData = updateDicomDataStudyInfo(dicomData, intl, study, image);
  if (isProcessedImage) {
    dicomData = updateDicomDataImage(dicomData, image);
  } else {
    dicomData = updateDicomDataImageWithRawIRay(dicomData, image);
  }
  dicomData = updateDicomDataID(dicomData, study, image);

  return dicomData;
};

const dicomDateToDate = (dicomDate) => {
  if (dicomDate === undefined) return undefined;
  const [fullMatch, years, months, days] = dicomDate.match(/(\d{4})(\d{2})(\d{2})/);
  return new Date(years, months - 1, days);
};

const dicomDateTimeToDate = (dicomDate, dicomTime) => {
  if (dicomDate === undefined) return undefined;

  const [_d, years, months, days] = dicomDate.match(/(\d{4})(\d{2})(\d{2})/);
  const [_t, hours, minutes, seconds] = dicomTime?.match(/(\d{2})(\d{2})(\d{2})/) || [];
  return new Date(years, months - 1, days, hours, minutes, seconds);
};

const dicomDateToDateTimeString = (dicomDate, dicomTime) => {
  const date = dicomDateTimeToDate(dicomDate, dicomTime);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return date.toLocaleString(getInitLanguage(), options);
};

const dicomSexToSexKey = (patientSex, patientSexNeutered) => {
  if (!patientSex) return undefined;
  let sexKey;
  if (patientSex === 'M') sexKey = 'male';
  if (patientSex === 'F') sexKey = 'female';
  if (patientSex === 'O') return 'other';

  if (patientSexNeutered) {
    sexKey += patientSexNeutered === 'ALTERED' ? '_castrated' : '';
  }
  return sexKey;
};

const getDicomDataValue = (dicomData, key) => {
  const value = dicomData?.[key];
  if (!value) return undefined;
  if (typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'data')) {
    return value.data;
  }
  return value;
};

const getXRayDetectorID = (dicomData) =>
  getDicomDataValue(dicomData, 'DetectorID') ??
  getDicomDataValue(dicomData, 'XRayDetectorID') ??
  getDicomDataValue(dicomData, 'DeviceID') ??
  getDicomDataValue(dicomData, 'PlateID') ??
  getDicomDataValue(dicomData, 'CassetteID');

export const getPixelSpacing = (dicomData) =>
  (
    getDicomDataValue(dicomData, 'PixelSpacing') ??
    getDicomDataValue(dicomData, 'ImagerPixelSpacing') ??
    getDicomDataValue(dicomData, 'DetectorElementPhysicalSize')
  )?.map(parseFloat);

// Small optimization to avoid lowercasing mapping each time
const lowerCaseSpeciesMappingMemoized = _.memoize((mapping) =>
  _.mapValues(mapping, ({ aliases, breeds }) => ({
    aliases: aliases?.map(toLower),
    breeds: _.mapValues(breeds, (breed) => breed.map(toLower)),
  }))
);

const getSpecieAndBreedFromDicomData = (dicomData, speciesMapping = {}) => {
  const memoizedMapping = lowerCaseSpeciesMappingMemoized(speciesMapping);
  const PatientSpeciesDescription = getDicomDataValue(dicomData, 'PatientSpeciesDescription');
  const PatientBreedDescription = getDicomDataValue(dicomData, 'PatientBreedDescription');

  if (!PatientSpeciesDescription) return {};

  let inputSpecie = PatientSpeciesDescription;
  let inputBreed = PatientBreedDescription;
  const lowerCaseSpecie = PatientSpeciesDescription?.toLowerCase();
  const lowerCaseBreed = PatientBreedDescription?.toLowerCase();

  _.forEach(memoizedMapping, ({ aliases, breeds = {} } = {}, specie) => {
    if (!aliases.includes(lowerCaseSpecie)) return undefined;

    inputSpecie = specie;

    if (lowerCaseBreed) {
      _.forEach(breeds, (breedAliases = [], breed) => {
        if (!breedAliases.includes(lowerCaseBreed)) return undefined;
        inputBreed = breed;
        return false;
      });
    }

    return false;
  });

  return { specie: inputSpecie, race: inputBreed };
};

const getPatientInfoFromDicomData = (dicomData, speciesMapping = DEFAULT_SPECIES_MAPPING) => {
  let name;
  let owner_name;
  const PatientName = getDicomDataValue(dicomData, 'PatientName');
  const ResponsiblePerson = getDicomDataValue(dicomData, 'ResponsiblePerson');
  const PatientSex = getDicomDataValue(dicomData, 'PatientSex');
  const PatientSexNeutered = getDicomDataValue(dicomData, 'PatientSexNeutered');
  const PatientBirthDate = getDicomDataValue(dicomData, 'PatientBirthDate');
  const { specie, race } = getSpecieAndBreedFromDicomData(dicomData, speciesMapping);
  [name, owner_name] = _.filter(PatientName?.split('^')).slice(0, 2).reverse();
  if (ResponsiblePerson) {
    owner_name = ResponsiblePerson?.split('^')[0];
  }
  const sex = dicomSexToSexKey(PatientSex, PatientSexNeutered);
  const birth_date = dicomDateToDate(PatientBirthDate);
  return {
    name,
    owner_name,
    sex,
    birth_date,
    specie,
    race,
  };
};

const prepareDicomForPACS = (image, studyID, imageIndex) => {
  // Avoid copying whole PixelData since its a big Raw array
  let pacsDicomData = _.cloneDeepWith(image.dicomData, (value, key) =>
    key === 'PixelData' ? value : undefined
  );
  // Only SOPClass compatible with PACSCommunication for now
  pacsDicomData.SOPClassUID = '1.2.840.10008.5.1.4.1.1.1';

  pacsDicomData.StudyInstanceUID = generateUIDFromObjectID(studyID);
  const imageSOPInstanceUID = generateImageUID(image);
  pacsDicomData.InstanceNumber = (imageIndex + 1).toString();
  // SOPInstanceUID must be unique in the world.
  // A PACS receiving an already existing ID will ignore the image
  const uniqueSOPInstanceUID = `${imageSOPInstanceUID}.${+Date.now()}`;
  pacsDicomData.SeriesInstanceUID = uniqueSOPInstanceUID;
  pacsDicomData.SOPInstanceUID = uniqueSOPInstanceUID;

  const contentTime = Date.now();
  pacsDicomData.ContentDate = format(contentTime, 'yyyyMMdd');
  pacsDicomData.ContentTime = format(contentTime, 'kkmmss.SSS00');

  pacsDicomData = _.omit(pacsDicomData, PACS_BLACKLISTED_DICOM_FIELDS);
  return pacsDicomData;
};

export {
  generateStudyUID,
  convertIRayImageToDicomData,
  updateDicomDataImageWithRawIRay,
  updateDicomDataPatient,
  updateDicomDataID,
  updateDicomDataStudyInfo,
  getDicomDataValue,
  dicomDateToDate,
  dicomDateTimeToDate,
  dicomDateToDateTimeString,
  dicomSexToSexKey,
  getPatientInfoFromDicomData,
  getXRayDetectorID,
  prepareDicomForPACS,
};
export default convertIRayImageToDicomData;
