import './style.scss';

import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { DisplayableImageData, Size } from 'app/interfaces/Image';
import { Viewport } from 'app/interfaces/Image';
import { getCropRect } from 'app/CornerstoneTools/CropTool';
import { Dimmer, Loader } from 'semantic-ui-react';
import { prepareImageForCanvasDisplay } from 'app/utils/imageManipulation/ImageTransformation';
import { Transformation } from 'app/interfaces/Image/Transformation';

const getCropRectIfPossible = (renderer: { element: HTMLElement }) => {
  try {
    return getCropRect(renderer.element, 'Crop');
  } catch {}
  return undefined;
};

export type ImageRendererProps = {
  image: DisplayableImageData;
  viewport?: Viewport;
  mainRenderer?: { element: HTMLElement };
};

function getTransformationsChangeArray(transformations: Transformation) {
  const { invert, rotation, hflip, vflip, voi, crop } = transformations;
  return [
    invert,
    rotation,
    hflip,
    vflip,
    voi?.windowCenter,
    voi?.windowWidth,
    crop?.x,
    crop?.y,
    crop?.width,
    crop?.height,
  ];
}

export default function ({ image, viewport, mainRenderer }: ImageRendererProps) {
  // const [imageObject, setImageObject] = useState<Image>();
  const [isImageDisplayed, setIsImageDisplayed] = useState<boolean>(false);
  const [{ width = undefined, height = undefined } = {}, setDimensions] = React.useState<Size>();

  const resizeObserver = useRef<ResizeObserver>();
  const canvasRef = useRef<HTMLCanvasElement>();

  const onRef = (element: HTMLElement | null) => {
    if (!element || resizeObserver.current) return;

    const { width: elementWidth, height: elementHeight } = element.getBoundingClientRect();
    if (elementWidth !== width || height !== elementHeight) {
      setDimensions({ width: elementWidth, height: elementHeight });
    }

    const handleResize: ResizeObserverCallback = ([resizeData]) => {
      const { width: elementWidth, height: elementHeight } = element.getBoundingClientRect();
      setDimensions({ width: elementWidth, height: elementHeight });
    };

    resizeObserver.current = new ResizeObserver(handleResize);
    resizeObserver.current.observe(element);
  };

  const crop = getCropRectIfPossible(mainRenderer);
  const transformations = { ...viewport, crop, resize: { width, height } };

  useEffect(() => {
    setIsImageDisplayed(false);
    if (!(image && height && width)) return;

    const dataForCanvas = prepareImageForCanvasDisplay(image, transformations);

    if (!dataForCanvas) return;

    canvasRef.current.width = dataForCanvas.size.width;
    canvasRef.current.height = dataForCanvas.size.height;

    canvasRef.current
      .getContext('2d')
      .putImageData(
        new ImageData(dataForCanvas.data, dataForCanvas.size.width, dataForCanvas.size.height),
        0,
        0
      );
    setIsImageDisplayed(true);
  }, [width, height, image, ...getTransformationsChangeArray(transformations)]);

  useEffect(() => () => resizeObserver.current?.disconnect());

  return (
    <div className="static-image-renderer" ref={onRef}>
      <Dimmer.Dimmable dimmed={!isImageDisplayed} className="static-image-renderer__dimmer">
        <Dimmer active={!isImageDisplayed}>
          <Loader />
        </Dimmer>
        <canvas ref={canvasRef} />
      </Dimmer.Dimmable>
    </div>
  );
}
