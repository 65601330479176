/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import Header from 'components/Header/index';
import Footer from 'components/Footer/index';
import HideHeaderFnProvider from 'app/providers/HideHeaderFnProvider/context';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderHidden: false,
    };
  }

  hideHeader = (isHeaderHidden) => this.setState({ isHeaderHidden });

  render() {
    const { isHeaderHidden } = this.state;
    const { children } = this.props;
    const locationSplitted = global.location.pathname.split('/');
    const locationTail = locationSplitted[locationSplitted.length - 1];

    const isMonitoringPage = locationTail.startsWith('monitoring');
    const isOnlinePage = locationTail.startsWith('online');
    const isAcquisitionPage = locationTail.startsWith('acquisition');
    const isFooterDisabled = isMonitoringPage || isOnlinePage || isAcquisitionPage;

    return (
      <>
        <Header style={{ display: isHeaderHidden ? 'none' : 'inherit' }} />
        <main className="body">
          <HideHeaderFnProvider.Provider value={this.hideHeader}>
            {children}
          </HideHeaderFnProvider.Provider>
        </main>
        {isFooterDisabled ? null : <Footer />}
      </>
    );
  }
}

export default App;
