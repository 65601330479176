/* eslint-disable camelcase */
/* eslint-disable react/no-unused-state */
import React from 'react';
import produce from 'immer';
import * as _ from 'lodash';
import { connect } from 'react-redux';

import { setDetectorKind } from 'app/redux/flatPanelConfiguration/actions';
import { selectFlatPanelConfiguration } from 'app/redux/flatPanelConfiguration/reducer';
import {
  DetectorManager,
  DETECTOR_EVENTS,
  DETECTOR_STATE,
  DETECTOR_IMPLEMENTATION,
  getKeyByValue,
  IRAY_EVENTS,
  detectorKindToImplementation,
  detectorImplementationToKind,
} from '../../types/xray';
import withContext from '../../utils/withContext';
import { LicenseManagerContext } from '../../providers/LicenseManagerProvider';

const FlatPanelStateContext = React.createContext({
  xrayLibEnabled: false,
  detectorManager: undefined,
  detectors: undefined,
  detectorsStates: undefined,
  selectedDetectorIndex: undefined,
  selectDetector: () => {},
  sleepDetector: () => {},
  wakeUpDetector: () => {},
});

const getCurrentDetector = (context) => context?.detectors?.[context?.selectedDetectorIndex];

class FlatPanelStateProvider_ extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      xrayLibEnabled: true,
      detectorManager: undefined,
      detectors: {},
      detectorsStates: {},
      selectedDetectorIndex: undefined,
      selectDetector: (selectedDetectorIndex) => this.setState({ selectedDetectorIndex }),
      sleepDetector: this.sleepDetector,
      wakeUpDetector: this.wakeUpDetector,
    };
    this.detectorsWatchers = {};
  }

  componentDidMount = () => {
    this.setupDetectorManager();
    if (this.state.detectorManager) {
      this.state.detectorManager.setDetectorImplementation(
        detectorKindToImplementation(this.props.flatPanelConfiguration.detectorKind)
      );
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, licenseManagerContext, flatPanelConfiguration } = this.props;
    const { detectorsStates, selectedDetectorIndex, detectorManager } = this.state;
    if (!prevProps.licenseManagerContext.isVerified && licenseManagerContext.isVerified) {
      this.setupDetectorManager();
    }

    if (detectorManager) {
      if (flatPanelConfiguration.detectorKind !== prevProps.flatPanelConfiguration.detectorKind) {
        detectorManager.setDetectorImplementation(
          detectorKindToImplementation(flatPanelConfiguration.detectorKind)
        );
      }
    }

    if (
      detectorsStates[selectedDetectorIndex]?.implementation !== undefined &&
      detectorsStates[selectedDetectorIndex]?.implementation !==
        prevState.detectorsStates[prevState.selectedDetectorIndex]?.implementation
    ) {
      dispatch(
        setDetectorKind(
          detectorImplementationToKind(detectorsStates[selectedDetectorIndex].implementation)
        )
      );
    }
  }

  componentWillUnmount = () => {
    const { detectorManager, detectors } = this.state;
    detectorManager?.removeListener(DetectorManager.EVENTS.NEW_DETECTOR, this.onNewDetector);
    detectorManager?.removeListener(
      DetectorManager.EVENTS.DETECTOR_REMOVED,
      this.onDetectorRemoved
    );
    _.forEach(detectors, (_detector, detectorId) => this.cleanupDetector(detectorId));
  };

  setupDetectorManager = (timeout = 100) => {
    const { flatPanelDetectorManagerInitializer, flatPanelConfiguration } = this.props;
    const isAlreadySetup = this.state.detectorManager !== undefined;
    if (isAlreadySetup) return;

    let detectorManager = flatPanelDetectorManagerInitializer.get();
    try {
      if (!detectorManager)
        detectorManager = flatPanelDetectorManagerInitializer.init(flatPanelConfiguration);
    } catch (e) {
      console.log('setupDetectorManager error', e);
      if (e.license_error) {
        setTimeout(() => this.setupDetectorManager(60 * 60 * 1000), 60 * 60 * 1000);
      } else {
        setTimeout(() => this.setupDetectorManager(2 * timeout), timeout);
      }
    }
    if (!detectorManager) return;

    if (!detectorManager) {
      setTimeout(() => this.setupDetectorManager(2 * timeout), timeout);
      return;
    }
    detectorManager.on(DetectorManager.EVENTS.NEW_DETECTOR, this.onNewDetector);
    detectorManager.on(DetectorManager.EVENTS.DETECTOR_REMOVED, this.onDetectorRemoved);
    this.state.detectorManager = detectorManager;
    this.updateDetectors();
    detectorManager.startScan();
  };

  // eslint-disable-next-line react/destructuring-assignment
  getDetector = (id) => this.state.detectors[id];

  initDetectorState = (detectorId, detector) => {
    console.log('initDetectorState');
    const detectorState = {
      state: detector.getState(),
      acquisitionState: detector.getAcquisitionState(),
      connectionState: detector.getConnectionState(),
      serialNumber: undefined,
    };

    const watchers = {};
    // watchers.onRawEvent = this.onRawEvent.bind(this, detectorId);
    watchers.onStatusChange = this.onStatusChange.bind(this, detectorId);
    watchers.onConnect = this.onConnect.bind(this, detectorId);
    watchers.onWakeUp = this.onWakeUp.bind(this, detectorId);
    watchers.onConnectionStatusChange = this.onConnectionStatusChange.bind(this, detectorId);
    watchers.onConnectionFailure = this.onConnectionFailure.bind(this, detectorId);
    watchers.onAcquisitionStatusChange = this.onAcquisitionStatusChange.bind(this, detectorId);
    // detector.on(DETECTOR_EVENTS.RAW_EVENT, watchers.onRawEvent);
    detector.on(DETECTOR_EVENTS.STATUS_CHANGE, watchers.onStatusChange);
    detector.on(DETECTOR_EVENTS.ACQUISITION_STATUS_CHANGE, watchers.onAcquisitionStatusChange);
    detector.on(DETECTOR_EVENTS.CONNECTION_STATUS_CHANGE, watchers.onConnectionStatusChange);
    detector.on(DETECTOR_EVENTS.CONNECTION_FAILED, watchers.onConnectionFailure);
    this.detectorsWatchers[detectorId] = watchers;
    if (detectorState.state !== DETECTOR_STATE.Unknown) {
      setTimeout(() => watchers.onConnect(), 0);
    } else {
      detector.connect();
    }
    return detectorState;
  };

  cleanupDetector = (detectorId) => {
    const detector = this.getDetector(detectorId);
    const {
      onStatusChange,
      onAcquisitionStatusChange,
      onConnectionStatusChange,
      onConnectionFailure,
    } = this.detectorsWatchers[detectorId];
    detector?.removeListener(DETECTOR_EVENTS.STATUS_CHANGE, onStatusChange);
    detector?.removeListener(DETECTOR_EVENTS.ACQUISITION_STATUS_CHANGE, onAcquisitionStatusChange);
    detector?.removeListener(DETECTOR_EVENTS.CONNECTION_STATUS_CHANGE, onConnectionStatusChange);
    detector?.removeListener(DETECTOR_EVENTS.CONNECTION_FAILED, onConnectionFailure);
  };

  updateDetectors = () => {
    const { detectorsStates, detectorManager, selectedDetectorIndex } = this.state;
    const detectors = { ...detectorManager.getDetectors() };
    let newDetectorsStates = { ...detectorsStates };
    const deletedIds = _.difference(Object.keys(newDetectorsStates), Object.keys(detectors));
    console.log('deletedIds', deletedIds);
    newDetectorsStates = _.omit(newDetectorsStates, deletedIds);

    _.forEach(detectors, (detector, id) => {
      if (selectedDetectorIndex === undefined) {
        this.setState({ selectedDetectorIndex: id });
      }
      if (newDetectorsStates[id] === undefined) {
        this.setState(
          produce((draftState) => {
            draftState.detectorsStates[id] = this.initDetectorState(id, detector);
            console.log('initDetectorState done');
          })
        );
      }
    });
    deletedIds.forEach((detectorId) => {
      this.setState(
        produce((draftState) => {
          delete draftState.detectorsStates[detectorId];
        })
      );
    });
    this.setState({ detectors });
  };

  wakeUpDetector = (detectorId) => this.getDetector(detectorId)?.wakeUp();

  sleepDetector = (detectorId) => this.getDetector(detectorId)?.sleep();

  onNewDetector = () => {
    console.log('new_detector');
    this.updateDetectors();
  };

  onDetectorRemoved = () => {
    console.log('detector_removed');
    this.updateDetectors();
  };

  onRawEvent = (detectorId, evt) => {
    console.log(`raw_event ${detectorId}`, {
      ...evt,
      event_id: getKeyByValue(IRAY_EVENTS, evt.event_id),
    });
  };

  onStatusChange = (detectorId, { previous_status, new_status }) => {
    console.log(`onStatusChange - previous_status: ${previous_status}, new_status: ${new_status}`);
    this.setState(
      produce((draftState) => {
        draftState.detectorsStates[detectorId].state = new_status;
      })
    );
    this.updateConnectionState(detectorId);
    if (previous_status === DETECTOR_STATE.Unknown && new_status !== DETECTOR_STATE.Unknown) {
      this.onConnect(detectorId);
    }
    if (previous_status === DETECTOR_STATE.Sleeping && new_status !== DETECTOR_STATE.Unknown) {
      this.onWakeUp(detectorId);
    }
  };

  onAcquisitionStatusChange = (detectorId, { previous_status, new_status }) => {
    console.log(
      `onAcquisitionStatusChange - previous_status: ${previous_status}, new_status: ${new_status}`
    );
    this.setState(
      produce((draftState) => {
        draftState.detectorsStates[detectorId].acquisitionState = new_status;
      })
    );
  };

  startAutoAcquisition = (detectorId) => {
    const detector = this.getDetector(detectorId);
    detector.startAutoAcq();
  };

  onConnect = (detectorId) => {
    console.log(`onConnect ${detectorId}`);
    const detector = this.getDetector(detectorId);

    this.setState(
      produce((draftState) => {
        draftState.detectorsStates[detectorId].allAttributes = {
          manufacturer: DETECTOR_IMPLEMENTATION.toString(detector.getImplementation()),
          serialNumber: detector.getSerialNumber(),
          productModel: detector.getProductModel(),
          pixelSpacing: detector.getPixelSpacing(),
        };
        draftState.detectorsStates[detectorId].serialNumber =
          draftState.detectorsStates[detectorId].allAttributes.serialNumber;
        draftState.detectorsStates[detectorId].implementation = detector.getImplementation();
        console.log(
          'detector all attributes',
          draftState.detectorsStates[detectorId].allAttributes
        );
      })
    );

    this.startAutoAcquisition(detectorId);
  };

  onConnectionFailure = (detectorId) => this.getDetector(detectorId)?.connect();

  onWakeUp = (detectorId) => {
    console.log(`onWakeUp ${detectorId}`);
    this.startAutoAcquisition(detectorId);
  };

  onConnectionStatusChange = (detectorId, { previous_status, new_status }) => {
    console.log(
      `onConnectionStatusChange - previous_status: ${previous_status}, new_status: ${new_status}`
    );
    this.setState(
      produce((draftState) => {
        draftState.detectorsStates[detectorId].connectionState = new_status;
      })
    );
  };

  updateConnectionState = (detectorId) => {
    const { detectorsStates } = this.state;
    const detector = this.getDetector(detectorId);

    const connectionState = detector.getConnectionState();
    if (connectionState !== detectorsStates[detectorId].connectionState) {
      this.setState(
        produce((draftState) => {
          draftState.detectorsStates[detectorId].connectionState = connectionState;
        })
      );
    }
  };

  render = () => {
    const { children } = this.props;
    return (
      <FlatPanelStateContext.Provider value={this.state}>{children}</FlatPanelStateContext.Provider>
    );
  };
}

const mapStateToProps = (state) => ({
  flatPanelConfiguration: selectFlatPanelConfiguration(state),
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

const FlatPanelStateProviderWithInjections = connect(
  mapStateToProps,
  mapDispatchToProps
)(withContext(FlatPanelStateProvider_, LicenseManagerContext, 'licenseManagerContext'));

const FlatPanelStateProvider = ({ children, flatPanelDetectorManagerInitializer }) => {
  if (!flatPanelDetectorManagerInitializer) return children;

  return (
    <FlatPanelStateProviderWithInjections
      flatPanelDetectorManagerInitializer={flatPanelDetectorManagerInitializer}
    >
      {children}
    </FlatPanelStateProviderWithInjections>
  );
};

export { FlatPanelStateProvider, FlatPanelStateContext, getCurrentDetector };
