import './style.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Label } from 'semantic-ui-react';
import { selectFlatPanelConfiguration } from 'app/redux/flatPanelConfiguration/reducer';
import { setDetectorKind } from 'app/redux/flatPanelConfiguration/actions';
import DetectorKindDropdown, {
  DetectorKindDropdownProps,
} from 'app/components/FlatPanelKindDropdown';
import _ from 'lodash';
import FlatPanelIPConfiguration from 'app/components/FlatPanelConfiguration/FlatPanelIPConfiguration';

const FlatPanelConfiguration: React.FC = () => {
  const dispatch = useDispatch();
  const { detectorKind } = useSelector(selectFlatPanelConfiguration);

  const handleFlatPanelKindChange: DetectorKindDropdownProps['onChange'] = (e, { value }) => {
    dispatch(setDetectorKind(value));
  };

  return (
    <div className="flat-panel-configuration">
      <div className="ui labeled input flat-panel-configuration__detector-kind">
        <Label>
          <FormattedMessage id="account.configuration.flat_panel.detector_kind" />
        </Label>
        <DetectorKindDropdown
          className="selection flat-panel-configuration__detector-kind__dropdown"
          value={detectorKind}
          onChange={handleFlatPanelKindChange}
        />
      </div>

      {detectorKind === 'THALES' && <FlatPanelIPConfiguration />}
    </div>
  );
};

export default FlatPanelConfiguration;
