// @ts-nocheck
import * as _ from 'lodash';
import axios, { AxiosPromise } from 'axios';
import { getAppName } from 'app/utils/envUtil';
import { getCurrentPlatform, PLATFORMS } from './platformConstants';
import { DEFAULT_SPECIE } from './speciesConstants';
import { Patient } from 'app/interfaces/Patient';
import { StoredImageInfo } from 'app/interfaces/Image';

export namespace API {
  export namespace Teleradiology {
    export type AffiliateId = string | number;

    export type StudyId = string | number;

    export type ImageId = string | number;

    export enum Provider {
      VEDIM = 'VEDIM',
    }

    export enum AffiliateTitle {
      Dr = 'Dr Vet',
      Pr = 'Pr',
    }

    export enum VetflowExamType {
      Scanner = 'Scanner',
      Radiologie = 'Radiologie',
    }

    export type Affiliate = {
      company_name: string;
      first_name?: string;
      last_name: string;
      title: AffiliateTitle;
      email: string;
      phone_number: string;
      workplace_name: string;
      address: string;
      address_complement?: string;
      city: string;
      post_code: string;
      country: string;
      country_code: string;
      num_tva?: string;
      website?: string;
      diploma?: string;
      language?: string;
      timezone?: string;
    };

    export type AffiliateIdentifier = {
      _id: string;
      user_id: string;
      type: Provider;
      teleradiology_affiliate_id: AffiliateId;
    };

    export enum AnalysisStatus {
      INCOMPLETE = 'INCOMPLETE', // Denote pending image upload
      PENDING = 'PENDING', // Pending radiologist analysis
      DONE = 'DONE', // Analysis done and result ready
    }

    export enum ImageUploadStatus {
      PENDING = 'PENDING',
      UPLOADED = 'UPLOADED',
    }

    export type StudyImage = {
      image_id: string;
      teleradiology_image_id: ImageId;
      upload_status: ImageUploadStatus;
    };

    // ISODateString are string representations of ISO 8601 date strings.
    export type ISODateString = string;

    export type VetflowAddress = {
      adresse1: string;
      adresse2?: string;
      codePostal: string;
      ville: string;
      telephone: string;
      pays?: string;
      email?: string;
    };

    export type VetflowOwner = {
      nom: string;
      prenom?: string;
      adresse: VetflowAddress;
    };

    export enum VetflowSpecie {
      CHIEN = 'chien',
      CHAT = 'chat',
      CHEVAL = 'cheval',
      RONGEUR = 'rongeur',
      NAC = 'nac',
      AUTRE = 'autre',
    }

    export enum VetflowSex {
      MALE = 'Femelle',
      FEMALE = 'Male',
      MALE_CASTRATED = 'Femelle sterilisee',
      FEMALE_CASTRATED = 'Male castre',
      OTHER = 'Inconnu',
    }

    export type VetflowPatient = {
      nom: string;
      especeNom: VetflowSpecie;
      especePerso?: string;
      numero?: string;
      race: string;
      age: string;
      dateNaissance?: ISODateString;
      sexe?: string;
      poids?: string;
      proprietaire: VetflowOwner;
    };

    export type Study = {
      id?: number;
      affiliateId?: number;
      companyNom: string;
      dateExam: ISODateString;
      dateSoumission?: ISODateString;
      typeExamNom?: VetflowExamType;
      nbImagesRadio?: number;
      vetoExaminant?: string;
      emailExaminant?: string;
      vetoTraitant?: string;
      cliniqueTraitante?: string;
      emailTraitant?: string;
      motif: string;
      signescliniques: string;
      moninterpretation: string;
      dateExamRadio?: ISODateString;
      enUrgence: boolean;
      patient?: VetflowPatient;
    };

    export type StudyIdentifier = {
      _id: string;
      user_id: string;
      study_id: string;
      teleradiology_affiliate_id: AffiliateId;
      type: Provider;
      teleradiology_study_id: StudyId;
      images: [StudyImage];
      analysis_status: AnalysisStatus;
      date_submitted: number | Date;
      reason: string;
      clinical_signs: string;
      interpretation: string;
      referent_veterinarian_name?: string;
      referent_veterinarian_email?: string;
      identification?: string;
      status?: string;
      response_date?: ISODateString;
      // Vetflow field: Analyse du radiologue. Présente que si la date de réponse est renseignée
      radiologist_analysis?: string;
      radiologist_conclusion?: string;
      // Vetflow field: Conclusion du radiologue. Présente que si la date de réponse est renseignée
      radiologist_recommendation?: string;
      // Vetflow field: Recommandation du radiologue. Présente que si la date de réponse est renseignée
      radiologist_comment?: string;
      // Vetflow field: Commentaire privé du radiologue. Présente que si la date de réponse est renseignée
    };
  }

  export type StudiesFilter = {
    page_number: number;
    ownerId?: string;
    animalId?: string;
    fileId?: string;
    startDate?: Date;
    endDate?: Date;
    regions?: string[];
  };

  export type Study = {
    _id: string;
    animal: Patient;
    creationDate: Date;
    images: StoredImageInfo[];
    teleradiology_study?: {
      user_id: string;
      study_id: string;
      teleradiology_affiliate_id: string;
      type: Teleradiology.Provider;
      teleradiology_study_id: string;
      images: {
        image_id: string;
        upload_status: Teleradiology.ImageUploadStatus;
      }[];
      analysis_status: Teleradiology.AnalysisStatus;
    };
  };
}

const formatAnimalData = (animal) =>
  animal && {
    ...animal,
    specie: animal.specie ?? DEFAULT_SPECIE,
    ...(animal.birth_date && { birth_date: new Date(Date.parse(animal.birth_date)) }),
  };

const formatAnimalsData = (data, animalsKey) => ({
  ...data,
  [animalsKey]: data[animalsKey] ? _.map(data[animalsKey], formatAnimalData) : undefined,
});

const ADMIN_PERMISSION = 'admin';
const ACQUISITION_PERMISSION = 'acquisition';
const TELERADIOLOGY_PERMISSION = 'teleradiology';

/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('app/utils/apiCalls').Teleradiology.Affiliate} Affiliate */
/** @typedef {import('app/utils/apiCalls').Teleradiology.AffiliateIdentifier} AffiliateIdentifier */

class ApiCalls {
  static version = process.env.VERSION;

  static appName = getAppName();

  static releaseDate = process.env.RELEASE_DATE;

  static getBackendRoute = () => {
    if (process.env.MODE === 'staging') {
      return 'https://api.staging.picoxia.com';
    }
    if (process.env.NODE_ENV === 'development') {
      if (process.env.PLATFORM === 'electron' && getCurrentPlatform() === PLATFORMS.MAC) {
        return 'https://api.picoxia.com';
      }
      return 'http://localhost:5000';
    }
    // return "https://34.76.73.237:80";
    return 'https://api.picoxia.com';
  };
  // static backendRoute = "http://api.picoxia.com"

  static localStorage = global.window.localStorage;

  static emptyBodyParameters = { key: 'value' };

  static getHttpClient = (token?: string) =>
    axios.create({
      baseURL: ApiCalls.getBackendRoute(),
      headers: ApiCalls.getTokenConfig(token),
    });

  static getTokenConfig = (token?: string) => {
    token = token || localStorage.loggedInToken;
    if (token) {
      return { Authorization: `bearer ${token}` };
    }
    return {};
  };

  static sendResetPasswordMail = (data) => {
    const formData = new FormData();
    formData.append('mail', data.username);
    return ApiCalls.getHttpClient().post('/send_reset_password_mail', formData);
  };

  static logoutFromAllDevices = () => ApiCalls.getHttpClient().post('/logout_from_all_devices');

  static changePassword = (token, data) => {
    const formData = new FormData();
    formData.append('password', data.password);
    return ApiCalls.getHttpClient(token).post('/change_password', formData);
  };

  static unsubscribeMail = (unsubscribe_token) => {
    return ApiCalls.getHttpClient(unsubscribe_token).post('/unsubscribe_mail');
  };

  static confirmMail = (data) => ApiCalls.getHttpClient().post('/confirm_mail', data);

  static getMail = (token) =>
    // token is optional, if not provided it will take the logged in token from local storage
    ApiCalls.getHttpClient(token).post('/get_mail');

  static setLanguage = (data) => ApiCalls.getHttpClient().post('/set_language', { language: data });

  static checkUpdateInfo = (appName = ApiCalls.appName, version = ApiCalls.version) =>
    ApiCalls.getHttpClient().get(`/check_update_info?version=${version}&app_name=${appName}`);

  static getHistory = (data) => ApiCalls.getHttpClient().post('/history', data);

  static getImage = (data) => ApiCalls.getHttpClient().post('/image', data);

  static putImage = (imageId, imageBlob) => {
    const data = new FormData();
    data.append('image_id', imageId);
    data.append('image', imageBlob);
    return ApiCalls.getHttpClient().put('/image', data);
  };

  static saveImageAnnotations = (imageId, annotations, viewport, imageMetadata) =>
    ApiCalls.getHttpClient().post('/image/annotations', {
      imageId,
      annotations,
      viewport,
      image_metadata: imageMetadata,
    });

  static updateImageMetadata = (imageId, metadata) =>
    ApiCalls.getHttpClient().post('/image/metadata', {
      imageId,
      image_metadata: metadata,
    });

  static editAnatomicRegion = (imageId, anatomicRegion) =>
    ApiCalls.getHttpClient().post('/image/anatomic_region', {
      imageId,
      anatomicRegion,
    });

  static editAcquisitionConstants = (imageId, acquisitionConstants) =>
    ApiCalls.getHttpClient().post(`/image/acquisition_constants`, {
      imageId,
      acquisitionConstants,
    });

  static getAnimalDetails = (data) => ApiCalls.getHttpClient().post('/animalDetails', data);

  static getOwnerNameCompletion = (data) =>
    ApiCalls.getHttpClient().post('/owner_completion', data);

  static getAnimalNameCompletion = (data) =>
    ApiCalls.getHttpClient()
      .post('/animal_completion', data)
      .then((response) => ({
        ...response,
        data: formatAnimalsData(response.data, 'animals'),
      }));

  static sendComplementaryInfo = (data) =>
    ApiCalls.getHttpClient().post('/add_complementary_info', data);

  static sendMultipleComplementaryInfo = (data) =>
    ApiCalls.getHttpClient().post('/add_multiple_complementary_info', data);

  static saveClinicInfo = (data) => ApiCalls.getHttpClient().post('/save_clinic_info', data);

  static infer = (data) => ApiCalls.getHttpClient().post('/inference_base_64', data);

  static refreshInference = (imageId, imageBlob, imageSize = undefined, cropRect = undefined) => {
    const data = new FormData();
    data.append('image_id', imageId);
    data.append('image', imageBlob);
    if (imageSize && cropRect) {
      data.append('crop_rect', JSON.stringify(cropRect));
      const { width, height } = imageSize;
      data.append('image_size', JSON.stringify([width, height]));
    }

    return ApiCalls.getHttpClient().post('/refresh_inference', data);
  };

  static getReportInfo = (return_logo) =>
    ApiCalls.getHttpClient().post('/get_user_info', { return_logo });

  static getUsersNumber = () => ApiCalls.getHttpClient().post('/get_users_number');

  static getImagesNumber = () => ApiCalls.getHttpClient().post('/get_images_number');

  static setUserDetectorKind = (detectorKind) =>
    ApiCalls.getHttpClient().post('/user/detector_kind', { detectorKind });

  static checkCouponCode = (data) => ApiCalls.getHttpClient().post('/coupon', data);

  static sendFeedback = (feedback, imageId) => {
    /* let formData = new FormData();
    formData.set('feedback', feedback);
    formData.set('image_id', imageId); */
    const data = {
      feedback,
      image_id: imageId,
    };
    return ApiCalls.getHttpClient().post('/feedback', data);
  };

  static login = (data) => {
    const formData = new FormData();
    formData.append('mail', data.username);
    formData.append('password', data.password);
    return ApiCalls.getHttpClient().post('/login', formData);
  };

  static logout = () => ApiCalls.getHttpClient().post('/logout', ApiCalls.emptyBodyParameters);

  static register = (data) => ApiCalls.getHttpClient().post('/register', data);

  static getPermissions = () => ApiCalls.getHttpClient().post('/get_permissions');

  static createCreditsCheckoutSession = (pack) => {
    const data = { packName: pack.backendName };
    return ApiCalls.getHttpClient().post('/create-credits-checkout-session', data);
  };

  static createSubscriptionCheckoutSession = (plan, couponCode) => {
    const data = { planName: plan.backendName, couponCode };
    return ApiCalls.getHttpClient().post('/create-subscription-checkout-session', data);
  };

  static getPurchaseHistory = () =>
    ApiCalls.getHttpClient().post('/purchase_history', ApiCalls.emptyBodyParameters);

  static getUsersFilteredAndCounted = (
    pageNumber,
    mailSearch,
    addressSearch,
    labels,
    sortMethods,
    filterSubscription,
    filterAcquisition,
    users_per_page = 10
  ) =>
    ApiCalls.getHttpClient().post('/get_users_filtered_and_counted', {
      page_number: pageNumber,
      mail_search: mailSearch,
      address_search: addressSearch,
      labels,
      sort_methods: sortMethods,
      filter_subscription: filterSubscription,
      filter_acquisition: filterAcquisition,
      users_per_page,
    });

  static getAttendingVeterinarians = () => ApiCalls.getHttpClient().get('/attending_veterinarians');

  static addUser = (
    mail,
    managedBy,
    workplaceName,
    address,
    postCode,
    city,
    countryCode,
    country,
    language,
    phoneNumber
  ) => {
    const fullAddress = {
      workplaceName,
      address,
      postCode,
      city,
      countryCode,
      country,
      phoneNumber,
    };
    return ApiCalls.getHttpClient().post('/add_user', {
      subscriber_mail: mail,
      address: fullAddress,
      managed_by: managedBy,
      language,
    });
  };

  static getLabels = () => ApiCalls.getHttpClient().post('/get_labels', {});

  static addLabel = (name) =>
    ApiCalls.getHttpClient().post('/add_label', {
      label_name: name,
    });

  static addLabelToUser = (mail, idLabel) =>
    ApiCalls.getHttpClient().post('/add_label_to_user', {
      id_label: idLabel,
      mail,
    });

  static deleteLabelUser = (mail, idLabel) =>
    ApiCalls.getHttpClient().post('/delete_label_user', {
      id_label: idLabel,
      mail,
    });

  static deleteLabel = (id) =>
    ApiCalls.getHttpClient().post('/delete_label', {
      id_label: id,
    });

  static getCountUsersWithLabel = (idLabel) =>
    ApiCalls.getHttpClient().post('/get_count_users_with_label_id', {
      id_label: idLabel,
    });

  static addSubscription = ({
    mail,
    subscriptionName,
    price = 0,
    start,
    end = undefined,
    sendMail,
    isDemo,
  }) =>
    ApiCalls.getHttpClient().post('/add_subscription', {
      subscriber_mail: mail,
      name: subscriptionName,
      price,
      start: start.toISOString(),
      end: end?.toISOString(),
      send_mail: sendMail,
      is_demo: isDemo,
    });

  /**
   *
   * @param string mail
   * @param int nbDays
   * @returns
   */
  static addFreeTrialSubscription = ({ mail }) =>
    ApiCalls.getHttpClient().post('/add-free-trial-subscription', {
      subscriber_mail: mail,
    });

  static endSubscription = (user_mail, subscription_id, endTime) =>
    // Doesn't handle Stripe stuff
    ApiCalls.getHttpClient().post('/end_subscription', {
      user_mail,
      subscription_id,
      end_time: endTime,
    });

  static unsubscribe = (mongo_id_to_delete) =>
    ApiCalls.getHttpClient().post('/unsubscribe', {
      mongo_id_to_delete,
    });

  static getInvoiceAddress = () => ApiCalls.getHttpClient().post('/get_invoice_address');

  static setInvoiceAddress = (data) =>
    // dictionnary with workplaceName, address, postCode, city, country, phoneNumber
    // console.log(data)
    ApiCalls.getHttpClient().post('/set_invoice_address', data);

  static setInvoiceAddressSingleField = (fieldName, fieldValue) =>
    // dictionnary with workplaceName, address, postCode, city, country, phoneNumber
    ApiCalls.getHttpClient().post('/set_invoice_address_single_field', {
      field_name: fieldName,
      field_value: fieldValue,
    });

  static getPreloadImages = () => ApiCalls.getHttpClient().post('/preload_image/get');

  static getSessionDetails = (session_id) =>
    ApiCalls.getHttpClient().post('/external_client/get_session_details', {
      session_id,
    });

  static confirmSession = (session_id) =>
    ApiCalls.getHttpClient().post('/external_client/confirm_session', {
      session_id,
    });

  static rejectSession = (session_id) =>
    ApiCalls.getHttpClient().post('/external_client/reject_session', {
      session_id,
    });

  static setClinicLogo = (base64Logo) =>
    ApiCalls.getHttpClient().post('/set_clinic_logo', { logo: base64Logo });

  static savePatientInfo = (patientInfo) =>
    ApiCalls.getHttpClient()
      .post('/patient_info/save', patientInfo)
      .then((response) => ({
        ...response,
        data: {
          ...response.data,
          animal: formatAnimalData(response.data.animal),
        },
      }));

  static checkPatientInfoConflicts = (patientInfo) =>
    ApiCalls.getHttpClient()
      .post('/patient_info/check_input_conflicts', patientInfo)
      .then((response) => ({
        ...response,
        data: formatAnimalsData(response.data, 'conflicting_animals'),
      }));

  static changeStudy = (newStudyID, oldStudyID, imagesIDs) =>
    ApiCalls.getHttpClient().post('/change_study', {
      new_study_id: newStudyID,
      old_study_id: oldStudyID,
      images_ids: imagesIDs,
    });

  static removeImagesFromStudy = (studyID, imagesIDs) =>
    ApiCalls.getHttpClient().post('/study/remove_images', {
      study_id: studyID,
      images_ids: imagesIDs,
    });

  static getStudiesCount = (data) => ApiCalls.getHttpClient().post(`/study/count`, data);

  static linkStudyToAnimal = (studyId, animalId, animalName, ownerName) =>
    ApiCalls.getHttpClient().post(`/link_to_animal`, {
      study_id: studyId,
      animal_id: animalId,
      animal_name: animalName,
      owner_name: ownerName,
    });

  static getStudies = (studyIDs) =>
    ApiCalls.getHttpClient()
      .post(`/study`, {
        study_ids: studyIDs,
      })
      .then(({ data }) =>
        data.studies.map((study) => ({
          ...study,
          animal: formatAnimalData(study.animal),
          creationDate: study.creationDate ? Date.parse(study.creationDate) : undefined,
          ownerName: _.get(study, 'animal.owner_name', undefined),
          animalName: _.get(study, 'animal.name', undefined),
        }))
      );

  static filterStudies = (studyFilter: StudiesFilter): AxiosPromise<{ studies: Study[] }> =>
    ApiCalls.getHttpClient()
      .post(`/study/filter`, studyFilter)
      .then((response) => ({
        ...response,
        data: {
          ...response.data,
          studies: response.data.studies.map((study) => ({
            ...study,
            animal: formatAnimalData(study.animal),
            creationDate: study.creationDate ? Date.parse(study.creationDate) : undefined,
            ownerName: _.get(study, 'animal.owner_name', undefined),
            animalName: _.get(study, 'animal.name', undefined),
          })),
        },
      }));

  static deleteStudies = (studyIDs) =>
    ApiCalls.getHttpClient().post('/study/remove', {
      study_ids: studyIDs,
    });

  static removeClinicLogo = () => ApiCalls.getHttpClient().post('/remove_clinic_logo');

  static hasOngoingSubscription = () => ApiCalls.getHttpClient().post('/has_ongoing_subscription');

  static getWorkList = () =>
    ApiCalls.getHttpClient()
      .post('/worklist')
      .then((response) => {
        _.forEach(response.data.workList, (study) => {
          if (study.planningDate) study.planningDate = new Date(study.planningDate);
        });
        return response;
      });

  static planExam = (images, date) =>
    ApiCalls.getHttpClient().post('/study/plan', {
      images,
      date,
    });

  static editExam = (images, date, id) =>
    ApiCalls.getHttpClient().post('/study/edit', {
      study_id: id,
      images: _.map(images, (image) => _.pick(image, ['_id', 'anatomicRegion'])),
      date,
    });

  static addProtocol = (exams, name) =>
    ApiCalls.getHttpClient().post('/protocol/add', {
      exams,
      name,
    });

  // eslint-disable-next-line camelcase
  static setAcquisitionPermission = (subscriber_mail, acquisition_identifiers_limit) =>
    ApiCalls.getHttpClient().post('/set_acquisition_permission', {
      subscriber_mail,
      acquisition_identifiers_limit,
    });

  static getProtocols = () => ApiCalls.getHttpClient().post('/protocols');

  static removeProtocol = (id) =>
    ApiCalls.getHttpClient().post('/protocol/remove', {
      id,
    });

  static editProtocol = (id, name, exams) =>
    ApiCalls.getHttpClient().post('/protocol/edit', {
      id,
      name,
      exams,
    });

  static refreshLicenseIdentifiers = (identifiers) =>
    ApiCalls.getHttpClient().post('/license/refresh_identifiers', {
      identifiers,
    });

  static importPMSExam = (patientInfo, exam) =>
    ApiCalls.getHttpClient().post('/pms/import_exam', {
      patient_info: patientInfo,
      exam,
    });

  static updateManagedBy = (userMail, newManagerName) =>
    ApiCalls.getHttpClient().post('/change_manager', {
      user_mail: userMail,
      new_manager: newManagerName,
    });

  static getManagerPermissions = () => ApiCalls.getHttpClient().post('/manager_permissions');

  static sendRequest = (
    request,
    name,
    email,
    phone,
    address,
    postCode,
    city,
    countryCode,
    country,
    products,
    freeText,
    whatTime = '',
    clientType = '',
    captcha
  ) =>
    ApiCalls.getHttpClient().post('/contact/request', {
      request_type: request,
      client_type: clientType,
      requester_name: name,
      requester_mail: email,
      requester_phone: phone,
      requester_address: address,
      requester_post_code: postCode,
      requester_city: city,
      requester_country_code: countryCode,
      requester_country: country,
      products,
      free_text: freeText,
      what_time_to_contact: whatTime,
      token: captcha,
    });

  static contactUs = (data) => {
    const dataSnakeCase = _.mapKeys(data, (value, key) => _.snakeCase(key));
    return ApiCalls.getHttpClient().post('/contact/us', dataSnakeCase);
  };

  /**
   * Update current storage usage for a specific device and directory
   *
   * @param {{
   * usage : {
   *   totalSize: number; // in bytes
   *   availableSize: number; // in bytes
   * };
   * directory: string;
   * deviceName: string;
   * deviceIdentifier: string;
   * }} param0
   */
  static updateStorageUsageForDirectory = ({ usage, directory, deviceName, deviceIdentifier }) =>
    ApiCalls.getHttpClient().post('/user/storage_usage/directory', {
      usage,
      directory,
      deviceName,
      deviceIdentifier,
    });

  static getUserDetails = () => ApiCalls.getHttpClient().get('/user/detail');

  static updateUserDetails = (data) => ApiCalls.getHttpClient().post('/user/edit', data);

  static setUserTeleradiologyPermission = (userMail: string, teleradiologyPermission: boolean) =>
    ApiCalls.getHttpClient().post('/user/edit/teleradiology-permission', {
      userMail,
      teleradiologyPermission,
    });

  static getTeleradiologyAffiliate = (): AxiosPromise<API.Teleradiology.AffiliateIdentifier> =>
    ApiCalls.getHttpClient().get('/teleradiology/affiliate');

  static getTeleradiologyStudyStatus = (
    studyId: string
  ): AxiosPromise<API.Teleradiology.StudyIdentifier> =>
    ApiCalls.getHttpClient().get(`/teleradiology/study/${studyId}/status`);

  static createTeleradiologyAffiliate = (
    affiliate: API.Teleradiology.Affiliate
  ): AxiosPromise<API.Teleradiology.AffiliateIdentifier> =>
    ApiCalls.getHttpClient().post(`/teleradiology/affiliate`, affiliate);

  static createTeleradiologyStudy = (
    studyId: string,
    studyDetails: API.Teleradiology.Study,
    imageIds: string[]
  ): AxiosPromise<API.Teleradiology.StudyIdentifier> =>
    ApiCalls.getHttpClient().post(`/teleradiology/study`, {
      study_id: studyId,
      teleradiology_study: studyDetails,
      images: imageIds,
    });

  static getTeleradiologyReport = (studyId: string): AxiosPromise<Blob> =>
    ApiCalls.getHttpClient().get(`/teleradiology/study/${studyId}/report`, {
      responseType: 'blob',
    });

  static updateTeleradiologyImages = async (
    studyId: string,
    imageIds: string[]
  ): AxiosPromise<API.Teleradiology.StudyIdentifier> =>
    ApiCalls.getHttpClient().post(`/teleradiology/study/${studyId}/images`, {
      images: imageIds,
    });

  static sendTeleradiologyImage = async (
    studyId: string,
    imageId: string,
    imageContent: Blob,
    onUploadProgress?: (progressEvent: { total: number; progress: number }) => void
  ): AxiosPromise<API.Teleradiology.StudyIdentifier> =>
    ApiCalls.getHttpClient().post(
      `/teleradiology/study/${studyId}/image?image_id=${imageId}`,
      await imageContent.arrayBuffer(),
      { onUploadProgress }
    );
}

export default ApiCalls;

export { formatAnimalData, ADMIN_PERMISSION, ACQUISITION_PERMISSION, TELERADIOLOGY_PERMISSION };
