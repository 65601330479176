import 'semantic-ui-css/semantic.min.css';

import 'app/styles/style.scss';
import './style.css';

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import * as pt from 'prop-types';

import ToolType from '../../types/ToolType';
import ToolsBar from '../ToolsBar';

const MEASURING_TOOLS_BUTTON_MAP = ['Length', 'Angle', 'CircleRadius'];

const ADVANCED_TOOLS_BUTTON_MAP = ['NorbergOlsson', 'VHS', 'TPLO', 'DistractionIndex'];

const DISPLAYED_TOOLS_LISTS = [MEASURING_TOOLS_BUTTON_MAP, ADVANCED_TOOLS_BUTTON_MAP];

class MeasurementTools extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapseToolsBar: window.innerHeight < 700,
    };
  }

  collapseTools = () => this.setState((state) => ({ collapseToolsBar: !state.collapseToolsBar }));

  render() {
    const {
      className,
      onSwitchAnnotationState,
      showAnnotations,
      toolsList,
      produceCommonToolsList,
      produceImageToolsList,
      focusedElement,
      predictions,
      anatomicRegion,
      disabled,
    } = this.props;
    const { collapseToolsBar } = this.state;
    const toolsBar = (
      <ToolsBar
        {...{
          toolsList,
          produceCommonToolsList,
          produceImageToolsList,
          focusedElement,
          predictions,
          anatomicRegion,
        }}
        displayedToolsLists={DISPLAYED_TOOLS_LISTS}
        disabled={disabled}
      />
    );
    if (!toolsBar) return null;

    return (
      <div className={`measurement-tools ${className}`}>
        <div className="tools-header">
          <FormattedMessage id="tools.advanced.description" />
          <button className="collapse picoxia" type="button" onClick={this.collapseTools}>
            <Icon name={collapseToolsBar ? 'plus' : 'minus'} />
          </button>
          <button
            type="button"
            className="show-annotation picoxia"
            onClick={onSwitchAnnotationState}
          >
            <Icon name={showAnnotations ? 'eye' : 'eye slash'} />
          </button>
        </div>
        <div className={collapseToolsBar ? 'hidden' : ''}>{toolsBar}</div>
      </div>
    );
  }
}

MeasurementTools.propTypes = {
  toolsList: pt.objectOf(ToolType).isRequired,
  produceCommonToolsList: pt.func.isRequired,
  produceImageToolsList: pt.func.isRequired,
  showAnnotations: pt.bool.isRequired,
  onSwitchAnnotationState: pt.func.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
  predictions: pt.shape(),
  anatomicRegion: pt.string,
  className: pt.string,
  disabled: pt.bool,
};

MeasurementTools.defaultProps = {
  focusedElement: null,
  predictions: {},
  anatomicRegion: undefined,
  className: '',
  disabled: false,
};

export default injectIntl(MeasurementTools);
