import { SET_IMAGE_ID, SET_STUDY_ID } from './constants';

export const setStudyId = (studyId) => ({
  type: SET_STUDY_ID,
  payload: { studyId },
});
export const setImageId = (imageId) => ({
  type: SET_IMAGE_ID,
  payload: { imageId },
});
