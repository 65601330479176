import { createSelector } from 'reselect';
import { selectPMSReducer } from 'app/redux/reducers';

const makeSelectWatchedDirectory = (subStateSelector = selectPMSReducer) =>
  createSelector(subStateSelector, (pms) => pms.get('watchedDirectory'));

const makeSelectIsInvalidWatchedDirectory = (subStateSelector = selectPMSReducer) =>
  createSelector(subStateSelector, (pms) => pms.get('isInvalidWatchedDirectory'));

export { makeSelectWatchedDirectory, makeSelectIsInvalidWatchedDirectory };
