import './style.scss';
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import getElectron from 'app/native/node/electron';
import { Dimmer, Icon, Loader, Message, Modal, Popup } from 'semantic-ui-react';
import exportStudy from 'app/components/Dropzone/ExportStudyModal/exportStudy';
import ImagesSelector from 'app/components/ImagesSelector';
import { injectProcessedDicomData } from 'app/utils/RadioImageData';
import DicomBuilderContext from 'app/providers/DicomBuilderContext';

export const LOCALSTORAGE_EXPORT_STUDY_MODAL = 'component.ExportStudyModal';

export const defaultInitValues = {
  isReportIncluded: true,
  jpgFormat: {
    isSelected: true,
    quality: 95,
    maxDimension: 1920,
  },
  dicomFormat: {
    isSelected: true,
  },
  thumbnailFormat: {
    isSelected: true,
    quality: 95,
    maxDimension: 1080,
  },
  directoryPath: '',
};

const ExportStudyModal = ({ currentStudy }) => {
  const intl = useIntl();
  const dialog = getElectron()?.remote?.dialog;
  const dicomBuilder = useContext(DicomBuilderContext);
  const { images } = currentStudy;

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExportOngoing, setIsExportOngoing] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const onImagesSelect = (imageIds) => {
    setSelectedImages(imageIds);
  };

  const getExportValuesStoredIntoLocalStorage = () =>
    JSON.parse(localStorage.getItem(LOCALSTORAGE_EXPORT_STUDY_MODAL));

  const initIsReportIncludedValue = () =>
    getExportValuesStoredIntoLocalStorage()?.isReportIncluded ?? defaultInitValues.isReportIncluded;
  const [isReportIncluded, setIsReportIncluded] = useState(initIsReportIncludedValue);

  const initJPGFormatValues = () => {
    const jpgFormatFromLocalStorage = getExportValuesStoredIntoLocalStorage()?.jpgFormat;
    return {
      isSelected: jpgFormatFromLocalStorage?.isSelected ?? defaultInitValues.jpgFormat.isSelected,
      quality: jpgFormatFromLocalStorage?.quality ?? defaultInitValues.jpgFormat.quality,
      maxDimension:
        jpgFormatFromLocalStorage?.maxDimension ?? defaultInitValues.jpgFormat.maxDimension,
    };
  };
  const [jpgFormat, setJpgFormat] = useState(initJPGFormatValues);

  const initDicomFormatValues = () => {
    const dicomFormatFromLocalStorage = getExportValuesStoredIntoLocalStorage()?.dicomFormat;
    return {
      isSelected:
        dicomFormatFromLocalStorage?.isSelected ?? defaultInitValues.dicomFormat.isSelected,
    };
  };
  const [dicomFormat, setDicomFormat] = useState(initDicomFormatValues);

  const initThumbnailFormatValues = () => {
    const thumbnailFormatFromLocalStorage =
      getExportValuesStoredIntoLocalStorage()?.thumbnailFormat;
    return {
      isSelected:
        thumbnailFormatFromLocalStorage?.isSelected ?? defaultInitValues.thumbnailFormat.isSelected,
      quality: defaultInitValues.thumbnailFormat.quality,
      maxDimension: defaultInitValues.thumbnailFormat.maxDimension,
    };
  };
  const [thumbnailFormat, setThumbnailFormat] = useState(initThumbnailFormatValues);

  const initDirectoryPathValue = () =>
    getExportValuesStoredIntoLocalStorage()?.directoryPath ?? defaultInitValues.directoryPath;
  const [directoryPath, setDirectoryPath] = useState(initDirectoryPathValue);

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const exportDataToStore = {
      isReportIncluded,
      jpgFormat,
      dicomFormat,
      thumbnailFormat,
      directoryPath,
    };
    localStorage.setItem(LOCALSTORAGE_EXPORT_STUDY_MODAL, JSON.stringify(exportDataToStore));
  }, [isReportIncluded, jpgFormat, dicomFormat, thumbnailFormat, directoryPath]);

  const toggleIsReportIncluded = () => {
    const newValue = !isReportIncluded;
    setIsReportIncluded(newValue);
  };

  const toggleIsJPGFormatIncluded = () => {
    const newJPGFormat = { ...jpgFormat };
    newJPGFormat.isSelected = !jpgFormat.isSelected;
    setJpgFormat(newJPGFormat, setErrorMessage(null));
  };

  const updateJPGFormatParameter = (parameter, value) => {
    const newJPGFormat = { ...jpgFormat };
    newJPGFormat[parameter] = value;
    setJpgFormat(newJPGFormat);
  };

  const toggleIsDicomFormatIncluded = () => {
    const newDicomFormat = { ...dicomFormat };
    newDicomFormat.isSelected = !dicomFormat.isSelected;
    setDicomFormat(newDicomFormat, setErrorMessage(null));
  };

  const toggleIsThumbnailFormatIncluded = () => {
    const newThumbnailFormat = { ...thumbnailFormat };
    newThumbnailFormat.isSelected = !thumbnailFormat.isSelected;
    setThumbnailFormat(newThumbnailFormat, setErrorMessage(null));
  };

  const chooseDirectory = (setDirectoryPath) => {
    dialog.showOpenDialog({ properties: ['openDirectory'] }).then((selectedDirectories) => {
      if (selectedDirectories.canceled) return;
      setDirectoryPath(selectedDirectories.filePaths[0], setErrorMessage(null));
    });
  };

  const checkIfErrors = () => {
    if (selectedImages.length === 0) {
      setErrorMessage('noImagesSelected');
      return true;
    }
    if (process.env.PLATFORM === 'electron' && directoryPath === '') {
      setErrorMessage('noDirectoryPath');
      return true;
    }
    if (!jpgFormat.isSelected && !dicomFormat.isSelected && !thumbnailFormat.isSelected) {
      setErrorMessage('noFormatsSelected');
      return true;
    }
    return false;
  };

  const handleExportStudy = async () => {
    if (checkIfErrors()) return;

    setErrorMessage(null);

    const exportParameters = {
      selectedImages,
      isReportIncluded,
      jpgFormat,
      dicomFormat,
      thumbnailFormat,
      directoryPath,
    };

    setIsExportOngoing(true);
    try {
      // We have to injectProcessedData before export.
      let study = currentStudy;
      if (dicomFormat.isSelected) {
        const imagesToExport = currentStudy.images
          .filter((image) => selectedImages.includes(image.id))
          .map((image) => ({ ...image }));
        await Promise.all(
          imagesToExport.map((image) => injectProcessedDicomData(dicomBuilder, image, true))
        );
        study = { ...currentStudy, images: imagesToExport };
      }

      await exportStudy(intl, study, exportParameters);
      setIsExportModalOpen(false);
    } catch (error) {
      setErrorMessage('exportError');
      console.error(error);
    }
    setIsExportOngoing(false);
  };

  const ExportButton = () => (
    <Popup
      trigger={
        <button
          className="picoxia"
          type="button"
          data-testid="modal-trigger"
          onClick={() => setIsExportModalOpen(true)}
        >
          <Icon name="download" size="big" />
        </button>
      }
      content={<FormattedMessage id="dropzone.export.tooltip" />}
    />
  );

  return (
    <>
      <ExportButton />
      <Modal
        className="exportModal"
        onClose={() => setIsExportModalOpen(false)}
        onOpen={() => setIsExportModalOpen(true)}
        open={isExportModalOpen}
      >
        <Modal.Header className="exportModal__header">
          <FormattedMessage id="exportStudyModal.title" />
        </Modal.Header>
        <Modal.Content className="exportModal__content" data-testid="modal-content">
          <form className="exportForm">
            <ImagesSelector
              title={<FormattedMessage id="exportStudyModal.selectImages.label" />}
              onSelect={onImagesSelect}
              images={images}
            />

            <div className="includeReport">
              <p className="label">
                <FormattedMessage id="exportStudyModal.report.label" />
              </p>
              <div>
                <div className="checkbox">
                  <input
                    data-testid="report-checkbox"
                    type="checkbox"
                    id="include-report"
                    name="include-report"
                    checked={isReportIncluded}
                    onChange={toggleIsReportIncluded}
                  />
                  <label htmlFor="include-report">
                    <FormattedMessage id="exportStudyModal.report.option.include" />
                  </label>
                </div>
              </div>
            </div>

            <div className="section chooseFormats">
              <p className="label">
                <FormattedMessage id="exportStudyModal.formats.label" />
              </p>
              <div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="dicom"
                    name="dicom"
                    data-testid="format-dicom"
                    checked={dicomFormat.isSelected}
                    onChange={toggleIsDicomFormatIncluded}
                  />
                  <label htmlFor="dicom">
                    <FormattedMessage id="exportStudyModal.formats.option.dicom" />
                  </label>
                </div>
              </div>
              <div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="thumbnail"
                    name="thumbnail"
                    data-testid="format-thumbnail"
                    checked={thumbnailFormat.isSelected}
                    onChange={toggleIsThumbnailFormatIncluded}
                  />
                  <label htmlFor="thumbnail">
                    <FormattedMessage id="exportStudyModal.formats.option.thumbnail" />
                  </label>
                </div>
              </div>

              <div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="jpg"
                    name="jpg"
                    data-testid="format-jpg"
                    checked={jpgFormat.isSelected}
                    onChange={toggleIsJPGFormatIncluded}
                  />
                  <label htmlFor="jpg">
                    <FormattedMessage id="exportStudyModal.formats.option.jpg" />
                  </label>
                </div>
                {jpgFormat.isSelected && (
                  <div className="chooseFormats__jpgParameters">
                    <svg
                      className="chooseFormats__jpgParameters-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                    >
                      <polygon points="50 15, 100 100, 0 100" />
                    </svg>
                    <div className="chooseFormats__parameter">
                      <label className="chooseFormats__parameterLabel" htmlFor="quality">
                        <FormattedMessage id="exportStudyModal.formats.jpg.quality" />
                      </label>
                      <input
                        data-testid="format-jpg-quality"
                        style={{ backgroundSize: `${jpgFormat.quality}% 100%` }}
                        type="range"
                        step="1"
                        value={jpgFormat.quality}
                        min="0"
                        max="100"
                        id="quality"
                        name="quality"
                        onChange={(event) => {
                          updateJPGFormatParameter('quality', event.target.value);
                        }}
                      />
                      <p>{jpgFormat.quality} %</p>
                    </div>
                    <div className="chooseFormats__parameter">
                      <label className="chooseFormats__parameterLabel" htmlFor="quality">
                        <FormattedMessage id="exportStudyModal.formats.jpg.maxDimension" />
                      </label>
                      <input
                        data-testid="format-jpg-maxdim"
                        className="chooseFormats__parameterInput"
                        type="number"
                        id="quality"
                        name="quality"
                        step="10"
                        value={jpgFormat.maxDimension}
                        onChange={(event) => {
                          updateJPGFormatParameter('maxDimension', event.target.value);
                        }}
                      />
                      <span style={{ marginLeft: '-20px' }}>px</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {process.env.PLATFORM === 'electron' && (
              <div className="section directoryPath">
                <label className="label">
                  <FormattedMessage id="exportStudyModal.directoryPath.label" />
                </label>
                <input
                  data-testid="directory-path-input"
                  type="text"
                  className="directoryPath__input"
                  value={directoryPath}
                  readOnly
                />
                <button
                  data-testid="directory-path-button"
                  type="button"
                  className="directoryPath__chooseButton"
                  onClick={() => chooseDirectory(setDirectoryPath)}
                >
                  <FormattedMessage id="exportStudyModal.directoryPath.button.choose" />
                </button>
              </div>
            )}

            {errorMessage && (
              <Message
                data-testid="error-message"
                error
                header={intl.formatMessage({ id: 'exportStudyModal.errorMessage.header' })}
                content={intl.formatMessage({
                  id: `exportStudyModal.errorMessage.${errorMessage}`,
                })}
              />
            )}
          </form>
        </Modal.Content>
        <Modal.Actions className="exportModal__footer">
          <button
            data-testid="button-export"
            type="button"
            className="footerModal__button footerModal__button--green"
            onClick={handleExportStudy}
          >
            <Dimmer.Dimmable>
              <Dimmer data-testid="export-ongoing" active={isExportOngoing}>
                <Loader />
              </Dimmer>
            </Dimmer.Dimmable>
            <FormattedMessage id="exportStudyModal.action.button.export" />
          </button>

          <button
            type="button"
            className="footerModal__button"
            color="black"
            onClick={() => setIsExportModalOpen(false)}
          >
            <FormattedMessage id="exportStudyModal.action.button.cancel" />
          </button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ExportStudyModal;
