import observeStore from 'app/utils/redux/observeStore';
import { fromJS } from 'immutable';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { SET_AI_ONLY_ENABLED } from './constants';

export const AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY = 'redux.aiOnlyConfiguration';

/** @var {boolean} aiOnlyConfigurationInitialState */
const aiOnlyConfigurationInitialState = fromJS(
  restoreFromLocalStorage(AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY, { enabled: false })
);

/**
 *
 * @param {boolean} state
 * @param {object} action
 * @returns
 */
function aiOnlyConfigurationReducer(state = aiOnlyConfigurationInitialState, action) {
  switch (action.type) {
    case SET_AI_ONLY_ENABLED:
      return state.set('enabled', action.payload.enabled);
    default:
      return state;
  }
}

export const selectAiOnlyConfiguration = (state) => state.get('aiOnlyConfiguration');
export const selectAiOnlyEnabledConfiguration = (state) =>
  selectAiOnlyConfiguration(state).get('enabled');

export const attachAiOnlyConfigurationWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectAiOnlyConfiguration,
    makeSyncReduxWithLocalStorageObserver(AI_ONLY_CONFIGURATION_LOCAL_STORAGE_KEY)
  );
};

export default aiOnlyConfigurationReducer;
