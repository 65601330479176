import CornerstoneImageEncoder from 'app/adapters/CornerstoneImageEncoder';
import path from 'app/native/node/path';
import _ from 'lodash';
import { IntlShape } from 'react-intl';

export const PMS_EXPORT_DEFAULT_JPEG_PARAMS = {
  maxWidth: 720,
  maxHeight: 720,
  quality: 0.95,
};

type FileSystemPromisesType = typeof import('fs').promises;

export type ImageType = {
  imageData: cornerstone.Image;
  acquisitionDate: Date;
  annotations: {};
  viewport: cornerstone.Viewport;
};
export type ImagesType = ImageType[];

export type PatientInfosType = {
  _id: string;
  file_id: string;
  name: string;
  attending_veterinarian: string;
  birth_date: Date;
  owner: string;
  owner_name: string;
  sex: string;
  specie: string;
};

export type StudyType = {
  _id: string;
  ID: string;
  animal: PatientInfosType;
  animalName: string;
  clearOngoing: boolean;
  comment: string;
  commentSaved: boolean;
  creationDate: Date;
  images: ImagesType;
  isCommentDirty: boolean;
  ownerName: string;
  planningDate: Date;
  pms_id: string;
  pms_software: string;
  saveOngoing: boolean;
  studyInstanceUID: any;
};

export type PMSConfigurationType = {
  exportDirectory?: string;
};

export type PMSExportFn = (
  currentStudy: StudyType,
  patientInfos: PatientInfosType,
  images: ImagesType
) => Promise<PromiseSettledResult<void>>;

export default class PmsExporter {
  imageEncoder: CornerstoneImageEncoder;
  fileSystemPromises: FileSystemPromisesType;
  intl: IntlShape;

  constructor(imageEncoder: CornerstoneImageEncoder, fileSystemPromises: FileSystemPromisesType) {
    this.imageEncoder = imageEncoder;
    this.fileSystemPromises = fileSystemPromises;
  }

  setIntl(intl: IntlShape) {
    this.intl = intl;
  }

  exportImages = async (exportDirectory: string, pmsId: string, images: ImagesType) => {
    const imagesWithFilenames = images.map((image, index) => {
      const paddedIndex = index.toString().padStart(2, '0');
      const filename = `${pmsId}_radio${paddedIndex}.jpg`;
      return { ...image, filename };
    });

    return Promise.allSettled(
      imagesWithFilenames.map(({ imageData, filename, annotations, viewport }) =>
        this.imageEncoder
          .toJPEG(imageData, viewport, annotations, PMS_EXPORT_DEFAULT_JPEG_PARAMS)
          .then((jpegBuffer) =>
            this.fileSystemPromises.writeFile(path().join(exportDirectory, filename), jpegBuffer)
          )
          .catch((error: any) => {
            console.warn(error);
            throw error;
          })
      )
    );
  };

  exportDataToPMS = async (
    { exportDirectory }: PMSConfigurationType = {},
    currentStudy: StudyType,
    _patientInfos: PatientInfosType,
    images: ImagesType = []
  ): Promise<PromiseSettledResult<void>> => {
    // PMS not configured, don't bother user with this
    if (!exportDirectory) {
      return undefined;
    }

    const pmsId: string = currentStudy?.pms_id;
    if (!pmsId) {
      console.warn('Patient pms_id is required to export images to PMS');
      return undefined;
    }

    this.exportImages(exportDirectory, pmsId, images);
  };
}
