const VALID_PREDICTION_TYPES = [
  'entirebody_profil',
  'entirebody_face',
  'thorax_profil',
  'thorax_face',
  'pelvis_face',
  'abdomen_profil',
  'abdomen_face',
];
const THORAX_PREDICTION_TYPES = [
  'thorax_profil',
  'thorax_face',
  'entirebody_profil',
  'entirebody_face',
];

const ABDOMEN_PREDICTION_TYPES = [
  'abdomen_profil',
  'abdomen_face',
  'entirebody_profil',
  'entirebody_face',
];

const PELVIS_PREDICTION_TYPES = ['pelvis_face'];

const FRONTAL_VIEW_TYPES = ['pelvis_face', 'thorax_face', 'abdomen_face', 'entirebody_face'];
const SIDE_VIEW_TYPES = ['thorax_profil', 'entirebody_profil', 'abdomen_profil'];

export default VALID_PREDICTION_TYPES;

export {
  VALID_PREDICTION_TYPES,
  THORAX_PREDICTION_TYPES,
  ABDOMEN_PREDICTION_TYPES,
  PELVIS_PREDICTION_TYPES,
  FRONTAL_VIEW_TYPES,
  SIDE_VIEW_TYPES,
};
