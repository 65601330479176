import React from 'react';
import ActivatableToolButton from 'app/components/ToolsBar/ActivatableToolButton';
import { getDefaultAnatomicRegion } from 'app/utils/xrayRegions';

export default (props) => {
  const { anatomicRegion } = props;
  if (
    ![
      getDefaultAnatomicRegion(),
      'dog membre_pelvien_gauche Grasset profil',
      'dog membre_pelvien_gauche Grasset face',
      'dog membre_pelvien_droit Grasset profil',
      'dog membre_pelvien_droit Grasset face',
      'dog membre_thoracique_gauche Coude profil',
      'dog membre_thoracique_gauche Coude face',
      'dog membre_thoracique_droit Coude profil',
      'dog membre_thoracique_droit Coude face',
      undefined,
    ].includes(anatomicRegion)
  ) {
    return null;
  }
  return (
    <ActivatableToolButton
      icon="tplo"
      tooltipId="tools.TPLO.tooltip"
      disabledState="disabled"
      {...props}
    />
  );
};
