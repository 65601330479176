import React from 'react';
import * as pt from 'prop-types';
import * as cst from 'cornerstone-tools';
import * as csc from 'cornerstone-core';
import { injectIntl } from 'react-intl';
import { map } from 'lodash';

import ToolType from '../../types/ToolType';
import PreComputedToolButton from './PreComputedToolButton';
import { mapFloatCoordsToPixels } from '../../CornerstoneTools/pixelToCanvasUtils';

const VHS_REGIONS = ['thorax_profil', 'entirebody_profil'];
Object.freeze(VHS_REGIONS);

const predictionToVHSPoints = (prediction, el) => {
  const { heart, vertebrae } = prediction;
  const [t4, t5, t6, t7, t8, ...rest] = vertebrae;

  return {
    handles: {
      heartBottom: mapFloatCoordsToPixels(heart.bottom, el),
      heartLeft: mapFloatCoordsToPixels(heart.left, el),
      heartRight: mapFloatCoordsToPixels(heart.right, el),
      heartTop: mapFloatCoordsToPixels(heart.top, el),
      t4: mapFloatCoordsToPixels(t4, el),
      t8: mapFloatCoordsToPixels(t8, el),
    },
    additionalVertebraPoints: map([t5, t6, t7, ...rest], (v) => mapFloatCoordsToPixels(v, el)),
  };
};

const drawVHS = (name, element, predictions, force = false) => {
  const enabledElem = csc.getEnabledElement(element);
  let vhsPoints = {};
  if ('vhs' in predictions) {
    vhsPoints = predictionToVHSPoints(predictions.vhs, enabledElem);
  } else if (!force) {
    return false;
  }

  return cst.getToolForElement(element, name).addToElement(element, vhsPoints);
};

const VHSButton = (props) => {
  const { toolsList, name, produceImageToolsList, focusedElement, predictions } = props;
  if (!VHS_REGIONS.includes(predictions.type)) return null;

  return (
    <PreComputedToolButton
      toolsList={toolsList}
      name={name}
      content={<i className="svg-icon vhs" />}
      tooltipId="tools.VHS.tooltip"
      produceImageToolsList={produceImageToolsList}
      drawToolDataFn={drawVHS}
      focusedElement={focusedElement}
      predictions={predictions}
    />
  );
};

VHSButton.propTypes = {
  toolsList: pt.objectOf(ToolType).isRequired,
  name: pt.string.isRequired,
  produceImageToolsList: pt.func.isRequired,
  focusedElement: pt.instanceOf(HTMLElement),
  predictions: pt.shape({
    type: pt.string,
    norberg_olsson: pt.shape(),
  }),
};

VHSButton.defaultProps = {
  focusedElement: null,
  predictions: {},
};

export default injectIntl(VHSButton);
