import * as cornerstone from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import _ from 'lodash';
import { AngleTool, LengthTool, ArrowAnnotateTool, ScaleOverlayTool } from 'cornerstone-tools';

import loadImageInCanvas, { computeCanvasDimension } from 'app/CornerstoneTools/loadImageInCanvas';
import { forceImageUpdateWithPromise } from 'app/CornerstoneTools/forceImageUpdate';
import { INTL_MARKERS } from 'app/components/Dropzone/utils';
import TextMarkerTool from 'app/CornerstoneTools/TextMarkerTool';
import CircleRadiusTool from 'app/CornerstoneTools/CircleRadiusTool';
import VHSTool from 'app/CornerstoneTools/VHSTool';
import TPLOTool from 'app/CornerstoneTools/TPLOTool';
import DistractionIndexTool from 'app/CornerstoneTools/DistractionIndexTool';
import NorbergOlssonTool from 'app/CornerstoneTools/NorbergOlssonTool';
import ImageMarkerTool from 'app/CornerstoneTools/ImageMarkerTool';
import CropTool, { focusHandles } from 'app/CornerstoneTools/CropTool';

export const createDefaultToolsProvider = (getIntl) => {
  const intlMarkers = getIntl().locale === 'fr' ? INTL_MARKERS.fr : INTL_MARKERS.en;

  return {
    ArrowAnnotate: {
      toolApi: ArrowAnnotateTool,
      options: {
        deleteIfHandleOutsideImage: false,
        preventHandleOutsideImage: false,
        configuration: {
          allowEmptyLabel: true,
          drawHandlesOnHover: true,
        },
      },
    },
    TextMarker: {
      toolApi: TextMarkerTool,
    },
    Length: {
      toolApi: LengthTool,
    },
    CircleRadius: {
      toolApi: CircleRadiusTool,
    },
    Angle: {
      toolApi: AngleTool,
    },
    VHS: {
      toolApi: VHSTool,
    },
    TPLO: {
      toolApi: TPLOTool,
      options: {
        configuration: { getIntl },
      },
    },
    DistractionIndex: {
      toolApi: DistractionIndexTool,
      options: {
        configuration: { getIntl },
      },
    },
    NorbergOlsson: {
      toolApi: NorbergOlssonTool,
      options: {
        configuration: { getIntl },
      },
    },
    Crop: {
      toolApi: CropTool,
    },
    LeftMarker: {
      toolApi: ImageMarkerTool,
      options: {
        configuration: {
          markerSource: intlMarkers.leftMarker.source,
        },
        svgCursor: intlMarkers.leftMarker.cursor,
      },
    },
    RightMarker: {
      toolApi: ImageMarkerTool,
      options: {
        configuration: {
          markerSource: intlMarkers.rightMarker.source,
        },
        svgCursor: intlMarkers.rightMarker.cursor,
      },
    },
  };
};

const setupCornerstoneToolsForConverter = (element, tools) => {
  _.forEach(tools, ({ toolApi, options }, name) => {
    const converterOptions = _.cloneDeep({ ...options, name, mouseButtonMask: 0 });
    cst.addToolForElement(element, toolApi, converterOptions);
    cst.setToolEnabledForElement(element, name, converterOptions);
  });
};

export default class CornerstoneImageEncoder {
  /** @param {() => Object<string, ToolApi>} toolsProvider */
  constructor(toolsProvider) {
    this.toolsProvider = toolsProvider;
  }

  /**
   *
   * @param {cornerstone.Image} imageData
   * @param {cornerstone.Viewport} viewport
   * @param {Object<string, Map>} annotations
   * @param {import("app/interfaces/ImageEncoder").JpegParams} jpegParams
   * @returns {Promise<Uint8Array>}
   */
  toJPEG = async (imageData, viewport, annotations, { quality, maxWidth, maxHeight } = {}) => {
    /** @type {(cornerstoneImage:cornerstone.EnabledElement) => Blob} */
    const printAnnotationsAndConvertToBlob = async ({
      element,
      viewport: encoderViewport,
      canvas,
    }) => {
      const newImageViewport = _.cloneDeep(encoderViewport);
      _.merge(newImageViewport, viewport);

      cornerstone.setViewport(element, newImageViewport);

      // Add Tools Instance to element to allow for tool drawing
      const tools = this.toolsProvider();
      setupCornerstoneToolsForConverter(element, tools);

      const cropAnnotation = annotations.Crop;
      if (cropAnnotation) {
        const [_uuid, cropData] = [...cropAnnotation]?.[0] ?? [];
        const handles = cropData?.handles;

        if (handles) {
          const cropRect = {
            ..._.pick(imageData, ['columnPixelSpacing', 'rowPixelSpacing']),
            width: Math.abs(handles.topLeft.x - handles.end.x),
            height: Math.abs(handles.topLeft.y - handles.end.y),
          };
          const [canvasWidth, canvasHeight] = computeCanvasDimension(cropRect, maxWidth, maxHeight);
          canvas.height = canvasHeight;
          canvas.width = canvasWidth;

          focusHandles(element, handles);
        }
      }

      // Add back toolData to element
      _.forEach(annotations, (toolAnnotations, toolName) => {
        if (tools[toolName] === undefined) {
          console.warn(`Trying to render missing tool ${toolName}`);
          return;
        }
        [...toolAnnotations].forEach(([_uuid, measurementData]) => {
          cst.addToolState(element, toolName, _.cloneDeep(measurementData));
        });
      });

      await forceImageUpdateWithPromise(element);

      const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg', quality));
      return blob;
    };

    const imageBlob = await loadImageInCanvas(
      imageData,
      printAnnotationsAndConvertToBlob,
      maxWidth,
      maxHeight
    );

    return new Uint8Array(await imageBlob.arrayBuffer());
  };
}
