import { import as cornerstoneToolsImport } from 'cornerstone-tools';

const MouseCursor = cornerstoneToolsImport('tools/cursors/MouseCursor');

const norbergOlssonCursor = new MouseCursor(
  `<g fill="none" stroke="ACTIVE_COLOR" stroke-width="3">
    <path
      d="M33.4746 32H31.7705L29.251 27.5762V32H27.5371V24.8906H29.251L31.7656 29.3145V24.8906H33.4746V32ZM40.5791 28.5918C40.5791 29.2852 40.4505 29.8988 40.1934 30.4326C39.9362 30.9665 39.57 31.3783 39.0947 31.668C38.6227 31.9544 38.084 32.0977 37.4785 32.0977C36.873 32.0977 36.3359 31.9593 35.8672 31.6826C35.3984 31.4027 35.0322 31.0039 34.7686 30.4863C34.5081 29.9688 34.3714 29.3747 34.3584 28.7041V28.3037C34.3584 27.6071 34.4854 26.9935 34.7393 26.4629C34.9964 25.929 35.3626 25.5173 35.8379 25.2275C36.3164 24.9378 36.86 24.793 37.4688 24.793C38.071 24.793 38.6081 24.9362 39.0801 25.2227C39.5521 25.5091 39.9183 25.9176 40.1787 26.4482C40.4424 26.9756 40.5758 27.5811 40.5791 28.2646V28.5918ZM38.8359 28.2939C38.8359 27.5876 38.7171 27.0521 38.4795 26.6875C38.2451 26.3197 37.9082 26.1357 37.4688 26.1357C36.6094 26.1357 36.1553 26.7803 36.1064 28.0693L36.1016 28.5918C36.1016 29.2884 36.2171 29.8239 36.4482 30.1982C36.6794 30.5726 37.0228 30.7598 37.4785 30.7598C37.9115 30.7598 38.2451 30.5758 38.4795 30.208C38.7139 29.8402 38.8327 29.3112 38.8359 28.6211V28.2939Z"
      fill="#E1E1E1"
    />
    <path d="M1.50015 1L9 15H33.5L40.5002 1" stroke="#E1E1E1" stroke-width="3" />
    <circle cx="9.00002" cy="14.9999" r="8.00002" stroke="#E1E1E1" stroke-width="3" />
    <circle cx="34.0002" cy="14.9999" r="8.00002" stroke="#E1E1E1" stroke-width="3" />
  </g>`,
  {
    viewBox: {
      x: 43,
      y: 43,
    },
  }
);

const vhsCursor = new MouseCursor(
  `<g fill="ACTIVE_COLOR" stroke="ACTIVE_COLOR" stroke-width="2">
    <path d="M4.2959 34.0664L5.71191 28.8906H7.62598L5.2334 36H3.3584L0.985352 28.8906H2.88477L4.2959 34.0664ZM14.0908 36H12.3867V33.0312H9.8623V36H8.14844V28.8906H9.8623V31.7129H12.3867V28.8906H14.0908V36ZM18.8467 34.1104C18.8467 33.8597 18.7572 33.6644 18.5781 33.5244C18.4023 33.3844 18.0915 33.2396 17.6455 33.0898C17.1995 32.9401 16.835 32.7952 16.5518 32.6553C15.6305 32.2028 15.1699 31.5811 15.1699 30.79C15.1699 30.3962 15.2839 30.0495 15.5117 29.75C15.7428 29.4473 16.0684 29.2129 16.4883 29.0469C16.9082 28.8776 17.3802 28.793 17.9043 28.793C18.4154 28.793 18.8727 28.8841 19.2764 29.0664C19.6833 29.2487 19.999 29.5091 20.2236 29.8477C20.4482 30.1829 20.5605 30.5671 20.5605 31H18.8516C18.8516 30.7103 18.762 30.4857 18.583 30.3262C18.4072 30.1667 18.168 30.0869 17.8652 30.0869C17.5592 30.0869 17.3167 30.1553 17.1377 30.292C16.9619 30.4255 16.874 30.5964 16.874 30.8047C16.874 30.987 16.9717 31.153 17.167 31.3027C17.3623 31.4492 17.7057 31.6022 18.1973 31.7617C18.6888 31.918 19.0924 32.0872 19.4082 32.2695C20.1764 32.7122 20.5605 33.3226 20.5605 34.1006C20.5605 34.7223 20.3262 35.2106 19.8574 35.5654C19.3887 35.9202 18.7458 36.0977 17.9287 36.0977C17.3525 36.0977 16.8301 35.9951 16.3613 35.79C15.8958 35.5817 15.5443 35.2985 15.3066 34.9404C15.0723 34.5791 14.9551 34.1641 14.9551 33.6953H16.6738C16.6738 34.0762 16.7715 34.3577 16.9668 34.54C17.1654 34.7191 17.486 34.8086 17.9287 34.8086C18.2119 34.8086 18.4349 34.7484 18.5977 34.6279C18.7637 34.5042 18.8467 34.3317 18.8467 34.1104Z" fill="#E1E1E1"/>
    <line x1="13.2324" y1="1.60386" x2="29.7324" y2="33.5726" stroke="#E1E1E1" stroke-width="2"/>
    <line x1="8.78743" y1="22.8322" x2="32.5062" y2="10.4572" stroke="#E1E1E1" stroke-width="2"/>
    <circle cx="13.375" cy="2.0625" r="2.0625" fill="#E1E1E1"/>
    <circle cx="30.0625" cy="34.0625" r="2.0625" fill="#E1E1E1"/>
    <circle cx="9.25" cy="22.6875" r="2.0625" fill="#E1E1E1"/>
    <circle cx="33.0625" cy="10.0625" r="2.0625" fill="#E1E1E1"/>
  </g>
  `,
  {
    viewBox: {
      x: 36,
      y: 37,
    },
  }
);

const leftMarkerENCursor = new MouseCursor(
  `<g fill="ACTIVE_COLOR" stroke="ACTIVE_COLOR" stroke-width="2">
    <path d="M15 11.6666H18.3333V25H25V28.3333H15V11.6666ZM20 3.33331C22.1887 3.33331 24.356 3.76441 26.3781 4.60199C28.4002 5.43957 30.2375 6.66722 31.7851 8.21487C33.3328 9.76251 34.5604 11.5998 35.398 13.6219C36.2356 15.644 36.6667 17.8113 36.6667 20C36.6667 24.4203 34.9107 28.6595 31.7851 31.7851C28.6595 34.9107 24.4203 36.6666 20 36.6666C17.8113 36.6666 15.644 36.2356 13.6219 35.398C11.5999 34.5604 9.76253 33.3327 8.21489 31.7851C5.08928 28.6595 3.33334 24.4203 3.33334 20C3.33334 15.5797 5.08928 11.3405 8.21489 8.21487C11.3405 5.08926 15.5797 3.33331 20 3.33331V3.33331ZM20 6.66665C16.4638 6.66665 13.0724 8.0714 10.5719 10.5719C8.07143 13.0724 6.66667 16.4638 6.66667 20C6.66667 23.5362 8.07143 26.9276 10.5719 29.4281C13.0724 31.9286 16.4638 33.3333 20 33.3333C23.5362 33.3333 26.9276 31.9286 29.4281 29.4281C31.9286 26.9276 33.3333 23.5362 33.3333 20C33.3333 16.4638 31.9286 13.0724 29.4281 10.5719C26.9276 8.0714 23.5362 6.66665 20 6.66665V6.66665Z" fill="#DBDBDB"/>
  </g>
  `,
  {
    viewBox: {
      x: 40,
      y: 40,
    },
  }
);

const leftMarkerFRCursor = new MouseCursor(
  `<g fill="ACTIVE_COLOR" stroke="ACTIVE_COLOR" stroke-width="2">
    <path d="M18.3333 11.6666H25V15H18.3333V25H21.6666V18.3333H25V25C25 25.884 24.6488 26.7319 24.0237 27.357C23.3985 27.9821 22.5507 28.3333 21.6666 28.3333H18.3333C17.4492 28.3333 16.6014 27.9821 15.9763 27.357C15.3512 26.7319 15 25.884 15 25V15C15 14.1159 15.3512 13.2681 15.9763 12.643C16.6014 12.0178 17.4492 11.6666 18.3333 11.6666ZM20 3.33331C22.1887 3.33331 24.3559 3.76441 26.378 4.60199C28.4001 5.43957 30.2374 6.66722 31.7851 8.21487C33.3327 9.76251 34.5604 11.5998 35.398 13.6219C36.2355 15.644 36.6666 17.8113 36.6666 20C36.6666 24.4203 34.9107 28.6595 31.7851 31.7851C28.6595 34.9107 24.4202 36.6666 20 36.6666C17.8113 36.6666 15.644 36.2356 13.6219 35.398C11.5998 34.5604 9.76249 33.3327 8.21485 31.7851C5.08925 28.6595 3.3333 24.4203 3.3333 20C3.3333 15.5797 5.08925 11.3405 8.21485 8.21487C11.3405 5.08926 15.5797 3.33331 20 3.33331ZM20 6.66665C16.4637 6.66665 13.0724 8.0714 10.5719 10.5719C8.07139 13.0724 6.66663 16.4638 6.66663 20C6.66663 23.5362 8.07139 26.9276 10.5719 29.4281C13.0724 31.9286 16.4637 33.3333 20 33.3333C23.5362 33.3333 26.9276 31.9286 29.4281 29.4281C31.9285 26.9276 33.3333 23.5362 33.3333 20C33.3333 16.4638 31.9285 13.0724 29.4281 10.5719C26.9276 8.0714 23.5362 6.66665 20 6.66665Z" fill="#DBDBDB"/>
  </g>
  `,
  {
    viewBox: {
      x: 40,
      y: 40,
    },
  }
);

const rightMarkerENCursor = new MouseCursor(
  `<g fill="ACTIVE_COLOR" stroke="ACTIVE_COLOR" stroke-width="2">
    <path d="M15 11.6666H21.6667C22.5507 11.6666 23.3986 12.0178 24.0237 12.643C24.6488 13.2681 25 14.1159 25 15V18.3333C25 19.7333 24.1667 20.9166 22.9333 21.4166L25 28.3333H21.6667L19.6667 21.6666H18.3333V28.3333H15V11.6666ZM18.3333 15V18.3333H21.6667V15H18.3333ZM20 3.33331C22.1887 3.33331 24.356 3.76441 26.3781 4.60199C28.4002 5.43957 30.2375 6.66722 31.7851 8.21487C33.3328 9.76251 34.5604 11.5998 35.398 13.6219C36.2356 15.644 36.6667 17.8113 36.6667 20C36.6667 24.4203 34.9107 28.6595 31.7851 31.7851C28.6595 34.9107 24.4203 36.6666 20 36.6666C17.8113 36.6666 15.644 36.2356 13.6219 35.398C11.5999 34.5604 9.76253 33.3327 8.21489 31.7851C5.08928 28.6595 3.33333 24.4203 3.33333 20C3.33333 15.5797 5.08928 11.3405 8.21489 8.21487C11.3405 5.08926 15.5797 3.33331 20 3.33331V3.33331ZM20 6.66665C16.4638 6.66665 13.0724 8.0714 10.5719 10.5719C8.07143 13.0724 6.66667 16.4638 6.66667 20C6.66667 27.35 12.6333 33.3333 20 33.3333C23.5362 33.3333 26.9276 31.9286 29.4281 29.4281C31.9286 26.9276 33.3333 23.5362 33.3333 20C33.3333 16.4638 31.9286 13.0724 29.4281 10.5719C26.9276 8.0714 23.5362 6.66665 20 6.66665V6.66665Z" fill="#DBDBDB"/>
  </g>
  `,
  {
    viewBox: {
      x: 40,
      y: 40,
    },
  }
);

const rightMarkerFRCursor = new MouseCursor(
  `<g fill="ACTIVE_COLOR" stroke="ACTIVE_COLOR" stroke-width="2">
    <path d="M15 11.6666H21.6666C22.5507 11.6666 23.3985 12.0178 24.0237 12.643C24.6488 13.2681 25 14.1159 25 15V25C25 25.884 24.6488 26.7319 24.0237 27.357C23.3985 27.9821 22.5507 28.3333 21.6666 28.3333H15V11.6666ZM18.3333 15V25H21.6666V15H18.3333ZM20 3.33331C22.1887 3.33331 24.3559 3.76441 26.378 4.60199C28.4001 5.43957 30.2374 6.66722 31.7851 8.21487C33.3327 9.76251 34.5604 11.5998 35.398 13.6219C36.2355 15.644 36.6666 17.8113 36.6666 20C36.6666 24.4203 34.9107 28.6595 31.7851 31.7851C28.6595 34.9107 24.4202 36.6666 20 36.6666C17.8113 36.6666 15.644 36.2356 13.6219 35.398C11.5998 34.5604 9.76249 33.3327 8.21485 31.7851C5.08925 28.6595 3.3333 24.4203 3.3333 20C3.3333 15.5797 5.08925 11.3405 8.21485 8.21487C11.3405 5.08926 15.5797 3.33331 20 3.33331ZM20 6.66665C16.4637 6.66665 13.0724 8.0714 10.5719 10.5719C8.07139 13.0724 6.66663 16.4638 6.66663 20C6.66663 23.5362 8.07139 26.9276 10.5719 29.4281C13.0724 31.9286 16.4637 33.3333 20 33.3333C23.5362 33.3333 26.9276 31.9286 29.4281 29.4281C31.9285 26.9276 33.3333 23.5362 33.3333 20C33.3333 16.4638 31.9285 13.0724 29.4281 10.5719C26.9276 8.0714 23.5362 6.66665 20 6.66665Z" fill="#DBDBDB"/>
  </g>
  `,
  {
    viewBox: {
      x: 40,
      y: 40,
    },
  }
);

const cropCursor = new MouseCursor(
  `<g fill="ACTIVE_COLOR" stroke="ACTIVE_COLOR" stroke-width="2">
    <path d="M34 10C34 7.794 32.206 6 30 6H10V0H6V6H0V10H30V40H34V34H40V30H34V10Z" fill="FILL_COLOR"/>
    <path d="M6 14V30C6 32.206 7.794 34 10 34H26V30H10V14H6Z" fill="FILL_COLOR"/>
  </g>
  `,
  {
    viewBox: {
      x: 40,
      y: 40,
    },
  }
);

const CircleRadiusCursor = new MouseCursor(
  `
  <svg width="44" height="41" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M20.8 3.21691C30.4979 3.21691 38.3831 11.1021 38.3831 20.8C38.3831 30.4979 30.4979 38.3831 20.8 38.3831C11.1021 38.3831 3.21692 30.4979 3.21692 20.8C3.21692 11.1021 11.1021 3.21691 20.8 3.21691ZM20.8 0.799988C9.7426 0.799988 0.800003 9.74259 0.800003 20.8C0.800003 31.8574 9.7426 40.8 20.8 40.8C31.8574 40.8 40.8 31.8574 40.8 20.8C40.8 9.74259 31.8574 0.799988 20.8 0.799988Z"
		fill="ACTIVE_COLOR" />
	<path d="M20.8 20.8L39 21" stroke="ACTIVE_COLOR" stroke-miterlimit="10.2751" stroke-linecap="round"
		stroke-dasharray="2 2" />
	<path
		d="M20.8 22.8846C21.9513 22.8846 22.8846 21.9513 22.8846 20.8C22.8846 19.6487 21.9513 18.7154 20.8 18.7154C19.6487 18.7154 18.7154 19.6487 18.7154 20.8C18.7154 21.9513 19.6487 22.8846 20.8 22.8846Z"
		fill="ACTIVE_COLOR" />
	<path
		d="M23.3378 11.3438C23.2471 11.3136 23.1565 11.3136 23.0659 11.3136C22.9752 11.3136 22.8846 11.3136 22.794 11.3136C22.4012 11.3136 22.0991 11.4042 21.8272 11.5553C21.5855 11.7366 21.4042 11.948 21.2834 12.2502V16.9329H20.1656V10.3468H21.2532L21.2834 11.1021C21.4647 10.8302 21.6761 10.6187 21.9178 10.4677C22.1595 10.3166 22.4918 10.226 22.8544 10.226C22.945 10.226 23.0356 10.226 23.1263 10.2562C23.2169 10.2864 23.3076 10.2864 23.3378 10.3166V11.3438V11.3438Z"
		fill="ACTIVE_COLOR" />
</svg>
  `,
  {
    viewBox: {
      x: 40,
      y: 40,
    },
  }
);

export {
  norbergOlssonCursor,
  vhsCursor,
  leftMarkerENCursor,
  leftMarkerFRCursor,
  rightMarkerENCursor,
  rightMarkerFRCursor,
  cropCursor,
  CircleRadiusCursor,
};
