import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import * as _ from 'lodash';
import { mergeViewports } from '../utils/cornerstone/imageUtils';
import { focusHandles } from './CropTool';
import { forceImageUpdateWithPromise } from './forceImageUpdate';

import loadImageInCanvas, { computeCanvasDimension } from './loadImageInCanvas';

const MAX_WIDTH = 1920;
const MAX_HEIGHT = 1920;

export const VISIBLE_TOOLS = ['active', 'passive', 'enabled'];
const DISPLAYED_NON_DATA_TOOLS = ['DicomDataPrinter'];
/**
 * Copy all the tools with annotation for a given element into another element.
 * This is done by modifying cornerstone internal tools store.
 * @param {HTMLElement} srcElement  Source element from which tools must be copied.
 * @param {HTMLElement} dstElement  Destination element for which annotations tools will be added.
 * @param {Array<String>} toolsFilter List of tools to enable or null if no filter is to be done
 */
const copyToolsToElement = (srcElement, dstElement, toolsFilter) =>
  cst.store.state.tools
    // Select visible tools of source element
    .filter((tool) => tool.element === srcElement && VISIBLE_TOOLS.includes(tool.mode))
    // Filter out selected tools
    .filter((tool) => !Array.isArray(toolsFilter) || toolsFilter.includes(tool.name))
    // Select tools with annotations
    .filter(
      (tool) =>
        DISPLAYED_NON_DATA_TOOLS.includes(tool.name) ||
        cst.getToolState(srcElement, tool.name)?.data?.length > 0
    )
    // Clone tools by changing their source element to dstElement.
    .forEach((tool) => {
      // We use a deep clone to avoid affecting the original tool data because of rendering.
      const injectedTool = _.cloneDeep(tool);
      injectedTool.element = dstElement;
      const useGlobalToolState =
        cst.getElementToolStateManager(srcElement) === cst.globalImageIdSpecificToolStateManager;
      // globalImageIdSpecificToolStateManager is a global state manager that identify data by imageId,
      // since it is global and we use the same imageId, there is no need to copy tools data.
      if (!useGlobalToolState) {
        cst
          .getToolState(srcElement, tool.name)
          ?.data?.forEach((measurementData) =>
            cst.addToolState(dstElement, tool.name, measurementData)
          );
      }
      cst.store.state.tools.push(injectedTool);
    });

const syncViewport = (srcElement, dstElement) => {
  const { viewport: srcViewport } = csc.getEnabledElement(srcElement);
  const { viewport: dstViewport } = csc.getEnabledElement(dstElement);
  const viewport = mergeViewports(srcViewport, dstViewport);
  csc.setViewport(dstElement, viewport);
};

const syncWWWC = (srcElement, dstElement) => {
  const srcViewport = csc.getViewport(srcElement);
  const dstViewport = csc.getViewport(dstElement);
  const viewport = { ...dstViewport, voi: srcViewport.voi };
  csc.setViewport(dstElement, viewport);
};

/**
 * Retrieve a blob representing the image with the element viewport and tools. Image tools can also
 * be drawn on resulting blob.
 * @param {HTMLElement} element
 * @param {Object} image
 * @param {(canvas:HTMLCanvasElement) => Promise<T>} canvasManipulation used to manipulate the canvas, the canvas becomes invalid once this promise returns.
 * @param {boolean or Object} [drawTools] true or false to enable disable or config to enable
 * specific tools.
 * @param {Array<String>} [drawTools.toolsFiltered] Array of filtered tools
 * @param {boolean} [drawTools.syncViewport] Enable viewport sync for generated image
 * @param {boolean} [drawTools.syncWWWC] Enable viewport sync for generated image
 * @param {number} [maxWidth] Use null to make blob the same size as the full image
 * @param {number} [maxHeight] Use null to make blob the same size as the full image
 * @return {Promise<T>} Result of `canvasManipulation` function
 * @template T
 */
const convertImageAndElementToCanvas = async (
  element,
  image,
  canvasManipulation,
  drawTools = false,
  maxWidth = MAX_WIDTH,
  maxHeight = MAX_HEIGHT
) => {
  const cropToolData = cst.getToolState(element, 'Crop')?.data?.[0];
  let rotation = 0;
  if (drawTools === true || drawTools?.syncViewport) {
    rotation = csc.getViewport(element).rotation;
  }

  const drawToolAndGetCanvas = async ({ canvas, element: tmpElement }) => {
    if (drawTools === true) {
      copyToolsToElement(element, tmpElement, true);
      syncViewport(element, tmpElement);
      if (cropToolData) {
        const cropRect = {
          ..._.pick(image, ['columnPixelSpacing', 'rowPixelSpacing']),
          width: Math.abs(cropToolData.handles.topLeft.x - cropToolData.handles.end.x),
          height: Math.abs(cropToolData.handles.topLeft.y - cropToolData.handles.end.y),
        };
        const [canvasWidth, canvasHeight] = computeCanvasDimension(
          cropRect,
          rotation,
          maxWidth,
          maxHeight
        );
        canvas.height = canvasHeight;
        canvas.width = canvasWidth;
        focusHandles(tmpElement, cropToolData.handles);
      }
      await forceImageUpdateWithPromise(tmpElement);
    } else if (_.isObject(drawTools)) {
      copyToolsToElement(element, tmpElement, drawTools.toolsFiltered);
      if (drawTools.syncViewport) {
        syncViewport(element, tmpElement);
      } else if (drawTools.syncWWWC) {
        syncWWWC(element, tmpElement);
      }
      if (
        cropToolData &&
        (drawTools.toolsFiltered === undefined || drawTools.toolsFiltered.includes('Crop'))
      ) {
        const cropRect = {
          ..._.pick(image, ['columnPixelSpacing', 'rowPixelSpacing']),
          width: Math.abs(cropToolData.handles.topLeft.x - cropToolData.handles.end.x),
          height: Math.abs(cropToolData.handles.topLeft.y - cropToolData.handles.end.y),
        };
        const [canvasWidth, canvasHeight] = computeCanvasDimension(
          cropRect,
          rotation,
          maxWidth,
          maxHeight
        );
        canvas.height = canvasHeight;
        canvas.width = canvasWidth;
        focusHandles(tmpElement, cropToolData.handles);
      }
      await forceImageUpdateWithPromise(tmpElement);
    }

    return canvasManipulation(canvas);
  };

  return loadImageInCanvas(image, drawToolAndGetCanvas, rotation, maxWidth, maxHeight);
};
/**
 * Retrieve a blob representing the image with the element viewport and tools. Image tools can also
 * be drawn on resulting blob.
 * @param {HTMLElement} element
 * @param {Object} image
 * @param {boolean or Object} [drawTools] true or false to enable disable or config to enable
 * specific tools.
 * @param {Array<String>} [drawTools.toolsFiltered] Array of filtered tools
 * @param {boolean} [drawTools.syncViewport] Enable viewport sync for generated image
 * @param {boolean} [drawTools.syncWWWC] Enable viewport sync for generated image
 * @param {number} [maxWidth] Use null to make blob the same size as the full image
 * @param {number} [maxHeight] Use null to make blob the same size as the full image
 * @param {string} [mimeType]
 * @param {number} [qualityArgument]
 * @return {Promise} Promise with with data : { blob, width, height }.
 */
const convertImageAndElementToBlob = async (
  element,
  image,
  drawTools = false,
  maxWidth = MAX_WIDTH,
  maxHeight = MAX_HEIGHT,
  mimeType = 'image/jpeg',
  qualityArgument = 0.92
) => {
  const canvasToBlob = async (canvas) => {
    const blob = await new Promise((resolve) => canvas.toBlob(resolve, mimeType, qualityArgument));

    return { blob, width: canvas.width, height: canvas.height };
  };

  return convertImageAndElementToCanvas(
    element,
    image,
    canvasToBlob,
    drawTools,
    maxWidth,
    maxHeight
  );
};

/**
 * Retrieve a blob representing the `element.image`. Image tools can also be drawn on resulting
 * blob.
 * @param {HTMLElement} element
 * @param {boolean or Object} [drawTools] true or false to enable disable or config to enable
 * specific tools.
 * @param {Array<String>} [drawTools.toolsFiltered] Array of filtered tools
 * @param {boolean} [drawTools.syncViewport] Enable viewport sync for generated image
 * @param {boolean} [drawTools.syncWWWC] Enable viewport sync for generated image
 * @param {number} [maxWidth] Use null to make blob the same size as the full image
 * @param {number} [maxHeight] Use null to make blob the same size as the full image
 * @param {string} [mimeType]
 * @param {number} [qualityArgument]
 * @return {Promise} Promise with with data : { blob, width, height }.
 */
const cornerstoneElementToBlob = async (
  element,
  drawTools = false,
  maxWidth = MAX_WIDTH,
  maxHeight = MAX_HEIGHT,
  mimeType = 'image/jpeg',
  qualityArgument = 0.92
) => {
  const { image } = csc.getEnabledElement(element);
  if (!image) return Promise.reject(Error('No image'));
  return convertImageAndElementToBlob(
    element,
    image,
    drawTools,
    maxWidth,
    maxHeight,
    mimeType,
    qualityArgument
  );
};

export default cornerstoneElementToBlob;

export { convertImageAndElementToCanvas, convertImageAndElementToBlob, cornerstoneElementToBlob };
