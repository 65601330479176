import React from 'react';
import * as pt from 'prop-types';
import * as csc from 'cornerstone-core';
import * as cst from 'cornerstone-tools';
import { forceImageUpdate } from '../../CornerstoneTools/forceImageUpdate';
import OnClickToolButton from './OnClickToolButton';
import { focusHandles } from '../../CornerstoneTools/CropTool';

class VerticalFlipButton extends React.PureComponent {
  doVerticalFlip = () => {
    const { focusedElement } = this.props;
    if (!focusedElement) return;
    try {
      const { viewport } = csc.getEnabledElement(focusedElement);
      const isRotated = viewport.rotation % 180 === 90;
      if (!isRotated) {
        viewport.vflip = !viewport.vflip;
      } else {
        viewport.hflip = !viewport.hflip;
      }

      const cropToolData = cst.getToolState(focusedElement, 'Crop');
      if (cropToolData && cropToolData.data.length > 0) {
        focusHandles(focusedElement, cropToolData.data[0].handles);
      }
      forceImageUpdate(focusedElement);
    } catch (error) {
      console.log('Error in VerticalFlipButton', error);
    }
  };

  render = () => (
    <OnClickToolButton
      onClick={this.doVerticalFlip}
      tooltipId="tools.vertical_flip.tooltip"
      content={<i className="svg-icon vertical-flip" />}
    />
  );
}

VerticalFlipButton.propTypes = {
  focusedElement: pt.instanceOf(HTMLElement),
};

VerticalFlipButton.defaultProps = {
  focusedElement: null,
};

export default VerticalFlipButton;
