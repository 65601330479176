/* eslint-disable react/prop-types */
/* eslint-disable no-empty */
/* eslint-disable react/no-unused-state */
import produce from 'immer';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import React from 'react';
import path from 'app/native/node/path';
import fs from 'app/native/node/fs';
import withContext from 'app/utils/withContext';
import StorageUsageMonitorContext from 'app/providers/StorageUsageMonitorProvider/context';
import JPEGLSModuleContext from 'app/providers/JPEGLSModuleProvider/context';
import { getDicomDataValue } from 'app/utils/dicom/DicomData';
import picoxiaDicom from 'app/native/node-addons/picoxia-dicom';

const UNCOMPRESSED_DICOM_TRANSFER_SYNTAX = [
  '1.2.840.10008.1.2', //  Implicit VR Little Endian Transfer Syntax
  '1.2.840.10008.1.2.1', //  Explicit VR Little Endian Transfer Syntax
];

const SAVED_DICOM_FIELDS = [
  'AcquisitionDate',
  'AcquisitionTime',
  'AdditionalPatientHistory',
  'BitsAllocated',
  'BitsStored',
  'BodyPartExamined',
  'BurnedInAnnotation',
  'Columns',
  'ContentDate',
  'ContentTime',
  'DateOfLastDetectorCalibration',
  'DetectorElementPhysicalSize',
  'DetectorID',
  'DetectorManufacturerModelName',
  'DetectorManufacturerName',
  'DetectorTemperature',
  'DetectorType',
  'FieldOfViewShape',
  'HighBit',
  'ImageLaterality',
  'ImagerPixelSpacing',
  'ImageType',
  'InstanceNumber',
  'InstitutionAddress',
  'InstitutionalDepartmentName',
  'InstitutionName',
  'LargestImagePixelValue',
  'LossyImageCompression',
  'Manufacturer',
  'ManufacturerModelName',
  'Modality',
  'NumberOfFrames',
  'OperatorsName',
  'OtherPatientIDs',
  'PatientAge',
  'PatientBirthDate',
  'PatientBreedDescription',
  'PatientComments',
  'PatientID',
  'PatientName',
  'PatientSex',
  'PatientSexNeutered',
  'PatientSpeciesDescription',
  'PhotometricInterpretation',
  'PhysiciansOfRecord',
  'PixelAspectRatio',
  'PixelData',
  'PixelIntensityRelationship',
  'PixelIntensityRelationshipSign',
  'PixelRepresentation',
  'PixelSpacing',
  'PresentationIntentType',
  'ReferringPhysicianName',
  'RescaleIntercept',
  'RescaleSlope',
  'RescaleType',
  'ResponsiblePerson',
  'ResponsiblePersonRole',
  'Rows',
  'SamplesPerPixel',
  'SeriesDate',
  'SeriesDescription',
  'SeriesInstanceUID',
  'SeriesTime',
  'SmallestImagePixelValue',
  'SOPClassUID',
  'SpecificCharacterSet',
  'StationName',
  'StudyDate',
  'StudyDescription',
  'StudyID',
  'StudyInstanceUID',
  'StudyTime',
  'TimeOfLastDetectorCalibration',
  'ViewPosition',
  'WindowCenter',
  'WindowWidth',
  'KVP',
  'ExposureTime',
  'ExposureTimeInuS',
  'XRayTubeCurrent',
  'XRayTubeCurrentInuA',
  'Exposure',
  'ExposureInuAs',
];

// Those files seems to cause problems when they are kept from one dicom to another.
const DICOM_FILES_TO_REMOVE = [
  'FileMetaInformationGroupLength',
  'FileMetaInformationVersion',
  'MediaStorageSOPClassUID',
  'MediaStorageSOPInstanceUID',
  'TransferSyntaxUID',
  'ImplementationClassUID',
  'ImplementationVersionName',
];

const DEFAULT_LOCAL_DICOM_STORE_CONTEXT = {
  storageDirectories: [],
  produceStorageDirectories: (producer) => {},
  getDicomImagePath: async (studyId, imageId) => {},
  storeDicomImage: (studyId, imageId, dicomData) => {},
};

const SAVE_BETWEEN_STORAGE_USAGE_UPDATE = 10;

const LocalDicomStoreContext = React.createContext(DEFAULT_LOCAL_DICOM_STORE_CONTEXT);

const LocalDicomStoreContextShape = pt.shape({
  storageDirectories: pt.arrayOf(pt.string),
  produceStorageDirectories: pt.func,
  getDicomImagePath: pt.func,
  storeDicomImage: pt.func,
});

class LocalDicomStoreProviderImpl extends React.PureComponent {
  constructor(props) {
    super(props);
    if (process.env.PLATFORM === 'electron') {
      let storageDirectories = localStorage.getItem('AcquisitionSaveDirectories');
      if (storageDirectories) {
        storageDirectories = JSON.parse(storageDirectories);
      } else {
        const { remote } = window.nodeModules?.electron;
        const defaultStorageDirectory = path().join(remote.app.getPath('userData'), 'images');
        storageDirectories = [defaultStorageDirectory];
        localStorage.setItem('AcquisitionSaveDirectories', JSON.stringify(storageDirectories));
      }
      this.state = {
        storageDirectories,
        produceStorageDirectories: this.produceStorageDirectories,
        getDicomImagePath: this.getDicomImagePath,
        storeDicomImage: this.storeDicomImage,
      };
    } else {
      this.state = DEFAULT_LOCAL_DICOM_STORE_CONTEXT;
      Object.freeze(this.state);
    }

    this.imageWritePromises = {};
    this.remainingSaveBeforeStorageUsageUpdate = SAVE_BETWEEN_STORAGE_USAGE_UPDATE;

    // We debounce this function to avoid running non essential task during processing.
    this.triggerStorageUsageUpdateDebouncedIfLimitReached = _.debounce(() => {
      if (this.remainingSaveBeforeStorageUsageUpdate <= 0) {
        this.remainingSaveBeforeStorageUsageUpdate = SAVE_BETWEEN_STORAGE_USAGE_UPDATE;
        this.updateStorageUsage();
      }
    }, 1000);
  }

  componentDidMount() {
    this.updateStorageUsage();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { storageDirectories } = this.state;
    if (prevState.storageDirectories !== storageDirectories) {
      localStorage.setItem('AcquisitionSaveDirectories', JSON.stringify(storageDirectories));
    }
  };

  produceStorageDirectories = (producer) =>
    this.setState(produce(({ storageDirectories }) => producer(storageDirectories)));

  getDicomImagePath = async (studyId, imageId) => {
    const { storageDirectories } = this.state;
    try {
      return await Promise.any(
        storageDirectories.map(async (storageDirectory) => {
          const testedDicomImagePath = path().join(storageDirectory, studyId, `${imageId}.dcm`);
          // Promise will reject if file cannot be accessed.
          await fs().promises.access(testedDicomImagePath, fs().constants.R_OK);
          return testedDicomImagePath;
        })
      );
    } catch {
      return undefined;
    }
  };

  countSaveUsageAndUpdateStorageUsage = () => {
    this.remainingSaveBeforeStorageUsageUpdate -= 1;
    this.triggerStorageUsageUpdateDebouncedIfLimitReached();
  };

  updateStorageUsage = () => {
    const { storageUsageMonitor } = this.props;
    const { storageDirectories } = this.state;
    storageDirectories.forEach(storageUsageMonitor.updateStorageUsageForDirectory);
  };

  storeDicomImage = async (studyId, imageId, dicomData) => {
    const { storageDirectories } = this.state;

    if (!dicomData) return;
    if (storageDirectories.length === 0) return;

    this.countSaveUsageAndUpdateStorageUsage();

    let transferSyntax = getDicomDataValue(dicomData, 'TransferSyntaxUID') ?? '1.2.840.10008.1.2.1';
    const dicomDataToSave = _.pick(dicomData, SAVED_DICOM_FIELDS);

    // We convert pixelData to jpegls for compression if module is available and data can be compressed.
    const encapsulationResult = await this.encapsulatePixelDataIfPossible(dicomData);
    if (encapsulationResult) {
      transferSyntax = encapsulationResult.transferSyntax;
      dicomDataToSave.TransferSyntaxUID = encapsulationResult.transferSyntax;
      dicomDataToSave.PixelData = encapsulationResult.PixelData;
    }

    console.time(`writeDicom-${imageId}`);
    let dicomImageBuffer;
    try {
      dicomImageBuffer = await picoxiaDicom()?.writeDicomAsync(
        dicomDataToSave,
        undefined,
        transferSyntax
      );
    } catch (e) {
      console.error('Failed to write dicom', e);
    }
    console.timeEnd(`writeDicom-${imageId}`);
    if (!dicomImageBuffer) return;

    storageDirectories.forEach(async (storageDirectory) => {
      const studyPath = path().join(storageDirectory, studyId);
      try {
        await fs().promises.mkdir(studyPath, { recursive: true });
      } catch {
        return;
      }
      const imageFilePath = path().join(studyPath, `${imageId}.dcm`);

      if (this.imageWritePromises[imageFilePath]) {
        try {
          await this.imageWritePromises[imageFilePath];
        } catch {}
      }

      const writePromise = fs().promises.writeFile(imageFilePath, new Uint8Array(dicomImageBuffer));
      this.imageWritePromises[imageFilePath] = writePromise;
      writePromise.finally(() => delete this.imageWritePromises[imageFilePath]);
    });
  };

  async encapsulatePixelDataIfPossible(dicomData) {
    const { jpegLSModule } = this.props;
    const originalTransferSyntax = getDicomDataValue(dicomData, 'TransferSyntaxUID');

    if (!jpegLSModule) return undefined;

    const alreadyEncapsulatedPixelData =
      originalTransferSyntax &&
      !UNCOMPRESSED_DICOM_TRANSFER_SYNTAX.includes(originalTransferSyntax);

    if (alreadyEncapsulatedPixelData) return undefined;

    /** @type {FrameInfo} */
    const frameInfo = {
      width: getDicomDataValue(dicomData, 'Columns'),
      height: getDicomDataValue(dicomData, 'Rows'),
      bitsPerSample: getDicomDataValue(dicomData, 'BitsStored'),
      componentCount: 1,
    };

    const pixelData = getDicomDataValue(dicomData, 'PixelData');
    let jpegLSPixelData;
    try {
      jpegLSPixelData = await jpegLSModule.encode(pixelData, frameInfo);
    } catch (e) {
      console.error('Failed to compress pixel data', e);
      return undefined;
    }

    if (!jpegLSPixelData) return undefined;

    // This preceding Uint32Array represent the offset table that denote multi-frame image
    // decomposition.
    // For single-frame image an empty offset table is still needed.
    const PixelData = { data: [new Uint32Array(0), jpegLSPixelData], VR: 'OB' };

    // JPEGLS lossless transfer syntax.
    return { transferSyntax: '1.2.840.10008.1.2.4.80', PixelData };
  }

  render = () => {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return (
      <LocalDicomStoreContext.Provider value={this.state}>
        {children}
      </LocalDicomStoreContext.Provider>
    );
  };
}

const LocalDicomStoreProvider = withContext(
  withContext(LocalDicomStoreProviderImpl, StorageUsageMonitorContext, 'storageUsageMonitor'),
  JPEGLSModuleContext,
  'jpegLSModule'
);

export {
  LocalDicomStoreContext,
  LocalDicomStoreContextShape,
  LocalDicomStoreProvider,
  SAVED_DICOM_FIELDS,
};
