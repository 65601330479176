import * as csc from 'cornerstone-core';
import * as csm from 'cornerstone-math';
import {
  import as cornerstoneToolsImport,
  toolStyle,
  getToolState,
  addToolState,
  toolColors,
  getModule,
  store,
} from 'cornerstone-tools';
import * as _ from 'lodash';
import { vhsCursor } from './Cursors';
import drawLineMarker from './drawLineMarker';

import forceImageUpdate from './forceImageUpdate';
import {
  getCanvasDistance,
  getDistanceForElement,
  projectPerpendicularToAxis,
  projectAlongAxis,
  offsetPixelPointInCanvas,
} from './pixelToCanvasUtils';

const BaseAnnotationTool = cornerstoneToolsImport('base/BaseAnnotationTool');
const throttle = cornerstoneToolsImport('util/throttle');
const getNewContext = cornerstoneToolsImport('drawing/getNewContext');
const draw = cornerstoneToolsImport('drawing/draw');
const setShadow = cornerstoneToolsImport('drawing/setShadow');
const drawHandles = cornerstoneToolsImport('drawing/drawHandles');
const drawLine = cornerstoneToolsImport('drawing/drawLine');
const drawCircle = cornerstoneToolsImport('drawing/drawCircle');
const drawTextBox = cornerstoneToolsImport('drawing/drawTextBox');
const drawLinkedTextBox = cornerstoneToolsImport('drawing/drawLinkedTextBox');
const drawJoinedLines = cornerstoneToolsImport('drawing/drawJoinedLines');
const roundToDecimal = cornerstoneToolsImport('util/roundToDecimal');
const getHandleNearImagePoint = cornerstoneToolsImport('manipulators/getHandleNearImagePoint');

const AXIS_VALUE_OFFSET = { x: 20, y: 0 };
Object.freeze(AXIS_VALUE_OFFSET);

const getVHSValue = (vhsData, precision) => {
  const vhsValue = vhsData.longAxisVertebralLength + vhsData.shortAxisVertebralLength;
  const vhsValueWithPrecision = vhsValue.toFixed(precision);
  const vhsValueWithPrecisionNoTrailingZeros = +vhsValueWithPrecision;
  return vhsValueWithPrecisionNoTrailingZeros;
};

/**
 * Subtract vertebra length until the whole axis as been reported onto the broken line.
 * @param {*} axisLength      Canvas length of the axis to report on the vertebras
 * @param {*} vertebrasLength List of vertebras length starting from t4
 */
const computeVertebralLength = (axisLength, vertebrasLength) => {
  let vertebralLength = 0;
  let vertebraIndex = 0;
  let remainingAxisLength = axisLength;

  while (remainingAxisLength > 0) {
    if (remainingAxisLength > vertebrasLength[vertebraIndex]) {
      vertebralLength += 1;
      remainingAxisLength -= vertebrasLength[vertebraIndex];
    } else {
      vertebralLength += remainingAxisLength / vertebrasLength[vertebraIndex];
      break;
    }
    if (vertebraIndex < vertebrasLength.length - 1) {
      vertebraIndex += 1;
    }
  }
  return vertebralLength;
};

/**
 * @public
 * @class VHS calculation Tool
 * @memberof Tools.Annotation
 * @classdesc Add a VHS calculation tool in the canvas.
 * @extends Tools.Base.BaseAnnotationTool
 * @hideconstructor
 *
 * @param {ToolConfiguration} [props={}]
 */
export default class VHSTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'VHS',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        drawHandles: true,
        drawHandlesOnHover: false,
        hideHandlesIfMoving: false,
        renderDashed: false,
      },
      svgCursor: vhsCursor,
    };

    super(props, defaultProps);

    this.preventNewMeasurement = false;

    this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
  }

  // Additional method usable to display tool with precomputed data.
  addToElement = (element, VHSPoints = {}) => {
    const { image } = csc.getEnabledElement(element);

    const midWidth = image.width / 2;
    const quarterHeight = (3 * image.height) / 4;

    const measurementData = {
      visible: true,
      active: false,
      color: undefined,
      invalidated: true,
      // This field is filled with all the movable handles that cornerstoneTools
      // control to allow drag and drop.
      handles: {
        heartBottom: {
          x: midWidth - midWidth / 3,
          y: quarterHeight,
          allowedOutsideImage: true,
          highlight: true,
          active: false,
        },
        heartLeft: {
          x: midWidth - midWidth / 3 - midWidth / 8,
          y: quarterHeight,
          allowedOutsideImage: true,
          highlight: true,
          active: false,
        },
        heartRight: {
          x: midWidth - midWidth / 3 - midWidth / 7,
          y: quarterHeight - image.height / 6,
          allowedOutsideImage: true,
          highlight: true,
          active: false,
        },
        heartTop: {
          x: midWidth + midWidth / 3,
          y: quarterHeight,
          allowedOutsideImage: true,
          highlight: true,
          active: false,
        },
        t4: {
          x: midWidth + midWidth / 3 + midWidth / 8,
          y: quarterHeight,
          allowedOutsideImage: true,
          highlight: true,
          active: false,
        },
        t8: {
          x: midWidth + midWidth / 3 + midWidth / 7,
          y: quarterHeight - image.height / 6,
          allowedOutsideImage: true,
          highlight: true,
          active: false,
        },
      },
      additionalVertebraPoints: [],
    };

    _.merge(measurementData, VHSPoints);
    this.updateCachedStats(image, element, measurementData);
    addToolState(element, this.name, measurementData);
    forceImageUpdate(element);
    return measurementData;
  };

  /**
   * @brief Disable since we do not want click to create a new tool data.
   */
  // eslint-disable-next-line class-methods-use-this
  createNewMeasurement = () => {
    console.log(this.name, 'createNewMeasurement doing nothing');
  };

  // eslint-disable-next-line class-methods-use-this
  pointNearTool = (element, data, coords, interactionType) => {
    if (data.visible === false) {
      return false;
    }
    const nearDistance =
      interactionType === 'mouse' ? store.state.clickProximity : store.state.touchProximity;

    const nearHandleInRange = getHandleNearImagePoint(element, data.handles, coords, nearDistance);
    return nearHandleInRange !== undefined;
  };

  /* eslint-disable no-param-reassign */
  // eslint-disable-next-line class-methods-use-this
  updateCachedStats = (_image, element, data) => {
    const { t4, t8, heartBottom, heartTop, heartLeft, heartRight } = data.handles;
    const longAxisLength = getDistanceForElement(element, heartBottom, heartTop);
    const shortAxisLength = getDistanceForElement(element, heartLeft, heartRight);

    if (t4.hasMoved || t8.hasMoved || data.additionalVertebraPoints.length < 4) {
      // A vertebral handle has been moved or we do not have enough additionalVertebraPoints.
      // we compute VHS using averaged value.
      const averageVertebralLength = getDistanceForElement(element, t4, t8) / 4;
      data.longAxisVertebralLength = longAxisLength / averageVertebralLength;
      data.shortAxisVertebralLength = shortAxisLength / averageVertebralLength;
    } else {
      // If nothing was moved, we report each axis on the broken line composed of each vertebra
      const [t5, t6, t7, ...rest] = data.additionalVertebraPoints;
      const successivePoints = [t4, t5, t6, t7, t8, ...rest];
      const vertebrasLength = [];

      // Create the list of each vertebra length
      for (let i = 0; i < successivePoints.length; i += 1) {
        if (i < successivePoints.length - 1) {
          vertebrasLength.push(
            getDistanceForElement(element, successivePoints[i], successivePoints[i + 1])
          );
        }
      }

      data.longAxisVertebralLength = computeVertebralLength(longAxisLength, vertebrasLength);
      data.shortAxisVertebralLength = computeVertebralLength(shortAxisLength, vertebrasLength);
    }

    data.longAxisVertebralLength = roundToDecimal(data.longAxisVertebralLength, 1);
    data.shortAxisVertebralLength = roundToDecimal(data.shortAxisVertebralLength, 1);
    data.invalidated = false;
    /* eslint-enable no-param-reassign */
  };

  // Draw projected heart lines along t4->t8 axis
  drawHeartAxisProjection = (context, elem, color, vLength, t4, t8, heartPoints, canvasOffset) => {
    const axisProjStart = projectPerpendicularToAxis(elem, t4, t4, t8, canvasOffset);
    const axisDistance = getCanvasDistance(elem, heartPoints[0], heartPoints[1]);
    const axisProjEnd = projectAlongAxis(elem, axisProjStart, t4, t8, axisDistance);

    drawLine(context, elem, axisProjStart, axisProjEnd, { color });
    drawLineMarker(context, elem, color, [axisProjStart, axisProjEnd], 10);

    const axisTextBox = csc.pixelToCanvas(elem, axisProjEnd);

    drawTextBox(context, `${vLength} V`, axisTextBox.x + 10, axisTextBox.y - 20, color);
  };

  drawTool = (eventData, toolData, context) => {
    const { element: elem } = eventData;
    const { handles, longAxisVertebralLength, shortAxisVertebralLength } = toolData;
    const { heartBottom, heartLeft, heartRight, heartTop, t4, t8 } = handles;
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving } = this.configuration;
    const { lineDash } = getModule('globalConfiguration').configuration;

    setShadow(context, this.configuration);

    // Differentiate the color of activation tool
    const color = toolColors.getColorIfActive(toolData);
    const lineWidth = toolStyle.getToolWidth();

    // Draw vertebral lines.
    // If no point was modified, we display a broken line with all vertebral points found
    // Else we draw a solid between t4 and t8.
    // This is to minimize user efforts when points are not properly set by algorithm.
    const vertebralLineOptions = { color, lineDash };
    let linePoints = [t8];

    if (!t4.hasMoved && !t8.hasMoved) {
      // Only t4 and t8 are movable handles, the rest are just basic points
      const [t5, t6, t7, ...rest] = toolData.additionalVertebraPoints;
      linePoints = [t5, t6, t7, t8, ...rest];
      toolData.additionalVertebraPoints.forEach((p) => drawCircle(context, elem, p, 4, { color }));
    }
    drawJoinedLines(context, elem, t4, linePoints, vertebralLineOptions);

    // Draw vertebra name near them to help identification.
    const drawVertebraTextBox = (vertebra, vertebraName) => {
      const vertebraTextBoxCoords = offsetPixelPointInCanvas(elem, vertebra, { x: 0, y: 20 });
      drawLinkedTextBox(
        context,
        elem,
        vertebraTextBoxCoords,
        vertebraName,
        null,
        () => [vertebra],
        color,
        lineWidth,
        0,
        true
      );
    };
    drawVertebraTextBox(t4, 'T4');
    drawVertebraTextBox(t8, 'T8');

    const heartLineOptions = { color };

    // Draw heart lines
    drawLine(context, elem, heartTop, heartBottom, heartLineOptions);
    drawLine(context, elem, heartLeft, heartRight, heartLineOptions);

    // Draw projected heart lines along t4, t8 axis
    this.drawHeartAxisProjection(
      context,
      elem,
      color,
      longAxisVertebralLength,
      t4,
      t8,
      [heartTop, heartBottom],
      -30
    );

    this.drawHeartAxisProjection(
      context,
      elem,
      color,
      shortAxisVertebralLength,
      t4,
      t8,
      [heartLeft, heartRight],
      -60
    );

    // Draw the handles
    const handleOptions = {
      color,
      handleRadius,
      drawHandlesIfActive: drawHandlesOnHover,
      hideHandlesIfMoving,
    };
    if (this.configuration.drawHandles) {
      drawHandles(context, eventData, toolData.handles, handleOptions);
    }
  };

  renderToolData = (evt) => {
    const eventData = evt.detail;
    const { image, element } = eventData;

    // If we have no toolData for this element, return immediately as there is nothing to do
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    draw(context, (ctx) => {
      for (let i = 0; i < toolData.data.length; i += 1) {
        const data = toolData.data[i];
        // Stats must be updated before calling drawTool since values will be used
        // for display.
        if (data.invalidated === true) {
          if (data.leftAngle && data.rightAngle) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }
        this.drawTool(evt.detail, data, ctx);
      }
    });
  };
}

export { getVHSValue };
