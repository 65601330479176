import React from 'react';

const SEXES = ['male', 'female', 'male_castrated', 'female_castrated'];
const SEXES_WITH_OTHER = [...SEXES, 'other'];
const NEUTERED_SEXES = ['male_castrated', 'female_castrated'];

const makeSexesOptions = () =>
  SEXES.map((sex) => ({
    label: <i className={`svg-icon ${sex}`} />,
    value: sex,
  }));

export { NEUTERED_SEXES, SEXES, SEXES_WITH_OTHER, makeSexesOptions };
