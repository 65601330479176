import './style.css';

import React from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getVHSValue } from '../../CornerstoneTools/VHSTool';
import { CAT_VHS_REFERENCES, DOG_VHS_REFERENCES } from '../../utils/speciesConstants';

const VHS_WARNING_THRESHOLD = 0.5;

const SEVERITY_TO_COLOR = {
  unknown: 'blue',
  severe: 'red',
  warning: 'orange',
  ok: 'green',
};
Object.freeze(SEVERITY_TO_COLOR);

const computeRacesList = (predictions, specie) => {
  if (predictions.chat === undefined && specie === undefined) return null;

  if (specie === 'cat') return CAT_VHS_REFERENCES;
  if (specie === 'dog') return DOG_VHS_REFERENCES;
  if (specie !== undefined) return null;

  const catConfidence = parseFloat(predictions.chat, 10);

  if (catConfidence < 0.4) return DOG_VHS_REFERENCES;
  if (catConfidence > 0.6) return CAT_VHS_REFERENCES;
  return null;
};

class VHSReferencesDropdown extends React.PureComponent {
  componentDidMount() {
    const { predictions, onRaceSelected, selectedRaceKey, patientInfo } = this.props;
    const racesList = computeRacesList(predictions, _.get(patientInfo, 'specie'));
    if (!selectedRaceKey && racesList) {
      onRaceSelected(racesList[0].key);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { predictions, onRaceSelected, selectedRaceKey, patientInfo } = nextProps;

    const racesList = computeRacesList(predictions, _.get(patientInfo, 'specie'));
    if (!racesList) return;

    if (!selectedRaceKey) {
      onRaceSelected(racesList[0].key);
    } else if (_.find(racesList, { key: selectedRaceKey }) === undefined) {
      onRaceSelected(racesList[0].key);
    }
  }

  onRaceSelection = (_evt, data) => {
    const { onRaceSelected } = this.props;
    onRaceSelected(data.value);
  };

  getSelectedRace = () => {
    const { selectedRaceKey, patientInfo } = this.props;
    const { specie, race } = patientInfo || {};

    if (specie && race) {
      if (specie === 'cat') return CAT_VHS_REFERENCES[0];
      if (specie === 'dog') {
        return _.find(DOG_VHS_REFERENCES, { key: `dog.${race}` }) || DOG_VHS_REFERENCES[0];
      }
      return undefined;
    }

    return (
      _.find(CAT_VHS_REFERENCES, { key: selectedRaceKey }) ||
      _.find(DOG_VHS_REFERENCES, { key: selectedRaceKey })
    );
  };

  getSeverity = (vhsValue) => {
    const selectedRace = this.getSelectedRace();

    if (!selectedRace) return 'unknown';
    const { lower, upper } = selectedRace;

    const vhsMaxDisparity = Math.max(lower - vhsValue, vhsValue - upper);
    if (vhsMaxDisparity > VHS_WARNING_THRESHOLD) {
      return 'severe';
    }
    if (vhsMaxDisparity > 0) {
      return 'warning';
    }
    return 'ok';
  };

  formatRaceOption = (race) => {
    const { intl } = this.props;
    const { formatMessage, formatNumber } = intl;

    const formattedLower = formatNumber(race.lower, { style: 'decimal' });
    const formattedUpper = formatNumber(race.upper, { style: 'decimal' });
    return {
      key: race.key,
      value: race.key,
      text: `${formatMessage({
        id: `VHSReferenceDropdown.reference.${race.key}`,
      })} : ${formattedLower} - ${formattedUpper} V`,
    };
  };

  renderRacesDropdown(racesList) {
    const { intl, hideDropdown, selectedRaceKey, patientInfo } = this.props;
    const { specie, race } = patientInfo || {};
    const { formatMessage } = intl;

    if (hideDropdown) return null;
    if (!racesList || racesList.length <= 1) return null;

    const raceAlreadySelected = specie !== undefined && race !== undefined;
    if (raceAlreadySelected) return null;

    const racesOptions = racesList.map(this.formatRaceOption);

    const selectedRace = this.getSelectedRace();

    return (
      <span>
        <span className="ui label grey basic large">
          <Dropdown
            placeholder={formatMessage({ id: 'VHSReferenceDropdown.placeholder' })}
            direction="right"
            upward={false}
            value={selectedRaceKey}
            onChange={this.onRaceSelection}
            options={racesOptions}
          />
        </span>
        {selectedRace?.bibli && (
          <Popup
            content={selectedRace.bibli}
            on="click"
            trigger={
              <button type="button" className="dark-button circular">
                ?
              </button>
            }
          />
        )}
      </span>
    );
  }

  renderVHSValue = (vhsValue) => {
    const { intl, onVHSClick, valueSize } = this.props;
    const { formatNumber } = intl;

    const severity = this.getSeverity(vhsValue);
    const selectedRace = this.getSelectedRace();

    const VHSValue = (
      <span
        className={`ui ${SEVERITY_TO_COLOR[severity]} basic image label bold ${valueSize} vhs_reference`}
        onClick={onVHSClick}
        role="presentation"
      >
        <i className="heart icon" />
        <FormattedMessage id="VHSReferenceDropdown.vhs" />
        <span className="detail">{formatNumber(vhsValue, { style: 'decimal' })} V</span>
      </span>
    );

    if (!selectedRace) {
      return VHSValue;
    }
    return (
      <Popup
        content={
          <>
            <div>{this.formatRaceOption(selectedRace).text}</div>
            <div>{selectedRace.bibli}</div>
          </>
        }
        trigger={VHSValue}
      />
    );
  };

  render = () => {
    const { annotations, predictions, patientInfo } = this.props;
    const { specie } = patientInfo || {};

    if (!annotations.VHS || !annotations.VHS.size) return null;

    const vhsValue = getVHSValue(annotations.VHS.values().next().value, 1);

    const racesList = computeRacesList(predictions, specie);

    return (
      <div>
        {this.renderVHSValue(vhsValue)}
        {this.renderRacesDropdown(racesList)}
      </div>
    );
  };
}

VHSReferencesDropdown.propTypes = {
  intl: pt.shape().isRequired,
  onRaceSelected: pt.func.isRequired,
  predictions: pt.shape({
    type: pt.oneOfType([pt.string, pt.number]),
    chat: pt.oneOfType([pt.string, pt.number]),
  }),
  patientInfo: pt.shape({
    specie: pt.string,
    race: pt.string,
  }),
  annotations: pt.shape(),
  selectedRaceKey: pt.string,
  onVHSClick: pt.func,
  hideDropdown: pt.bool,
  valueSize: pt.string,
};

VHSReferencesDropdown.defaultProps = {
  predictions: {},
  annotations: {},
  patientInfo: {},
  selectedRaceKey: null,
  onVHSClick: () => {},
  hideDropdown: false,
  valueSize: 'large',
};

export default injectIntl(VHSReferencesDropdown);
