/* eslint-disable no-underscore-dangle */
import picoxiaDicom from 'app/native/node-addons/picoxia-dicom';

export default class PACSCommunication {
  constructor(pacsConfiguration) {
    this.pacsConfiguration_ = pacsConfiguration;
  }

  associate = (associationParameters) => {
    const pacsCommunication = new (picoxiaDicom().PACSCommunication)();

    const association = pacsCommunication.associate(associationParameters);

    association
      .then(() => {
        this.pacsConfiguration_ = associationParameters;
      })
      .finally(() => pacsCommunication.release());

    return association;
  };

  echo = () => {
    const pacsCommunication = new (picoxiaDicom().PACSCommunication)(this.pacsConfiguration_);

    return pacsCommunication.echo().finally(() => pacsCommunication.release());
  };

  store = (dataSet) => {
    const pacsCommunication = new (picoxiaDicom().PACSCommunication)(this.pacsConfiguration_);

    return pacsCommunication.store(dataSet).finally(() => pacsCommunication.release());
  };

  storeMany = (dataSets) => {
    const pacsCommunication = new (picoxiaDicom().PACSCommunication)(this.pacsConfiguration_);

    const storeRequests = dataSets.map((dataSet) => pacsCommunication.store(dataSet));
    Promise.allSettled(storeRequests).finally(() => pacsCommunication.release());

    return storeRequests;
  };

  checkAssociation = () => {
    const pacsCommunication = new (picoxiaDicom().PACSCommunication)();

    return pacsCommunication
      .associate(this.pacsConfiguration_)
      .finally(() => pacsCommunication.release());
  };
}
