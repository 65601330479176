import React, { useState, useEffect, PropsWithChildren } from 'react';
import { AggregatedParser } from 'app/pms/parser/AggregatedParser';
import { PmsDirectoryWatcher } from 'app/pms/directoryWatcher';
import handleFileInput from 'app/pms/handleFileInput';
import { useSelector, useStore } from 'react-redux';
import { selectPMSReducer } from 'app/redux/reducers';
import { makeSelectWatchedDirectory } from 'app/pms/selector';
import PmsParser from 'app/pms/parser/PmsParser';
import { PmsFileHandler } from 'app/pms/types';

export const PMSParserContext = React.createContext<PmsDirectoryWatcher>(undefined);

type PMSParserProviderProps = {
  externalParser: PmsParser;
  fileHandler: PmsFileHandler;
};

const PMSParserProvider = ({
  children,
  externalParser,
  fileHandler,
}: PropsWithChildren<PMSParserProviderProps>) => {
  const store = useStore();
  const pmsConfiguration = useSelector(selectPMSReducer);
  const pmsWatchedDirectory = useSelector(makeSelectWatchedDirectory());
  const [parser] = useState(() => externalParser ?? new AggregatedParser());
  const [pmsDirectoryWatcher] = useState(
    () => new PmsDirectoryWatcher(fileHandler ?? handleFileInput, parser.parse)
  );

  useEffect(() => {
    // console.log('pmsWatchedDirectory', pmsWatchedDirectory);
    pmsDirectoryWatcher.onDirectoryChange({ store, state: pmsWatchedDirectory });
  }, [pmsWatchedDirectory]);

  useEffect(() => {
    // console.log('pmsConfiguration', pmsConfiguration?.toJS());
    parser.updateConfiguration(pmsConfiguration?.toJS());
  }, [pmsConfiguration]);

  return (
    <PMSParserContext.Provider value={pmsDirectoryWatcher}>{children}</PMSParserContext.Provider>
  );
};

export default PMSParserProvider;
