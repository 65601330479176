import React from 'react';
import * as PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import 'app/styles/style.scss';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ApiCalls from '../../utils/apiCalls';
import FormattedServerError from '../FormattedServerError';

class InferenceErrorMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: props.errorMessage,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.state = {
      errorMessage: nextProps.errorMessage,
    };
  }

  render() {
    if (this.state.errorMessage === null) {
      return null;
    }
    if (
      this.state.errorMessage.message === 'Network Error' ||
      !('response' in this.state.errorMessage)
    ) {
      return (
        <div className="errorMessage">
          <b>
            <FormattedMessage id="inferenceErrorMessage.noInternet" />
          </b>
        </div>
      );
    }
    if (this.state.errorMessage.response.data.cause === 'unsubscribed') {
      return null;
    }
    if (this.state.errorMessage.response.data.cause === 'unbillable') {
      return (
        <div className="errorMessage">
          <b>
            <div style={{ marginTop: '2vh', textAlign: 'center' }}>
              <h3>
                Vous avez utilisé vos{' '}
                {this.state.errorMessage.response.data.NUMBER_OF_FREE_REQUESTS_FOR_REGISTERED_USER}{' '}
                essais gratuits
              </h3>
            </div>
            <div style={{ marginTop: '4vh' }}>
              <FormattedMessage id="inferenceErrorMessage.activateBilling" />
            </div>
          </b>
        </div>
      );
    }
    return (
      <div className="errorMessage">
        <b>
          <FormattedServerError error={this.state.errorMessage} />
        </b>
      </div>
    );
  }
}

InferenceErrorMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InferenceErrorMessage);
