/**
 * Allow usage of multiple workers as if they were a single one by calling them in
 * a round robin way.
 * Worker must be exposed through comlink and be callable with a single argument.
 * Single arg is due to (https://github.com/GoogleChromeLabs/comlink/issues/579), using a dict is an easy fix.
 */
export default function makeBasicFunctionalWorkerPool(workers) {
  const workerPool = [...workers];
  let counter = 0;

  return (param) => {
    const workerIndex = counter % workerPool.length;
    counter = (counter + 1) % workerPool.length;

    return workerPool[workerIndex](param);
  };
}
