import _ from 'lodash';
import { getImage, getViewport } from 'cornerstone-core';

import { DropzoneImage } from 'app/interfaces/Study';
import isWorkListImage from 'app/utils/isWorkListImage';
import { Viewport } from 'app/interfaces/Image';
import { ImageForTeleradiologySelection } from 'app/containers/Teleradiology/VetFlow/Modal/ImageForTeleradiologySelection';
import { getCropRect } from 'app/CornerstoneTools/CropTool';

const getCropRectIfPossible = (element: HTMLElement) => {
  try {
    return getCropRect(element, 'Crop');
  } catch {}
  return undefined;
};

export default function formatStudyImagesForTeleradiology(
  studyImages: DropzoneImage[]
): ImageForTeleradiologySelection[] {
  return _(studyImages)
    .reject(isWorkListImage)
    .map((image) => {
      try {
        const cscImage = getImage(image.cornerstoneRef);
        if (!cscImage) return undefined;
        const crop = getCropRectIfPossible(image.cornerstoneRef);
        return {
          ...image,
          id: image.id,
          image: cscImage,
          viewport: getViewport(image.cornerstoneRef) as unknown as Viewport,
          mainRenderer: { element: image.cornerstoneRef },
          transformation: { ...getViewport(image.cornerstoneRef), crop },
        };
      } catch {
        return undefined;
      }
    })
    .filter()
    .value();
}
