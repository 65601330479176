import { isDicomImage, isWebImage, loadWebImage } from '../../utils/imageFsUtil';

const addImage = (imageFilePath) => {
  let promise;
  if (isDicomImage(imageFilePath)) {
    promise = Promise.resolve({ path: imageFilePath, isFromFileSystem: true });
  } else if (isWebImage(imageFilePath)) {
    promise = loadWebImage(imageFilePath);
  }
  return promise;
};

const loadImagesFromCommandLine = (args) => {
  const imagesPaths = args.images;
  if (!imagesPaths) {
    return {};
  }

  const imageMetadata = { animalName: args.animalName, ownerName: args.ownerName };

  const registerImageArgsPromises = imagesPaths.map(addImage);
  return { registerImageArgsPromises, imageMetadata };
};

export default loadImagesFromCommandLine;
