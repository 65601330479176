import 'app/styles/style.scss';
import './style.css';

import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import * as pt from 'prop-types';
import * as _ from 'lodash';
import { SPECIES } from '../../utils/speciesConstants';
import { SEXES, SEXES_WITH_OTHER } from '../../utils/sexesConstants';

class PatientInfoBar extends React.PureComponent {
  render = () => {
    const { fileID, animalName, ownerName, birthDate, specie, sex, className, showId } = this.props;
    let animalDescriptionValues = [
      animalName,
      ownerName,
      specie ? (
        <FormattedMessage id={`patient_info.specie.${specie}`} defaultMessage={specie} />
      ) : null,
      SEXES.includes(sex) ? <i className={`svg-icon ${sex}`} /> : null,
    ];
    animalDescriptionValues = _.reject(animalDescriptionValues, _.isNull);
    return (
      <div className={`patient-info-bar ${className}`}>
        {showId ? (
          <div className="patient-id">
            <FormattedMessage id="patient_info.file_id.short" />:{' '}
            {fileID || <FormattedMessage id="patient_info.file_id.unknown" />}
          </div>
        ) : null}

        <div>
          {animalDescriptionValues.map((value, index) => (
            <span key={`${value}_${index}`}>
              {index > 0 ? <span>,&nbsp;</span> : null} {value}
            </span>
          ))}
        </div>
        <div>{birthDate ? <FormattedDate value={birthDate} /> : null}</div>
      </div>
    );
  };
}

PatientInfoBar.propTypes = {
  fileID: pt.string,
  animalName: pt.string,
  ownerName: pt.string,
  birthDate: pt.instanceOf(Date),
  specie: pt.oneOf(SPECIES),
  sex: pt.oneOf(SEXES_WITH_OTHER),
  className: pt.string,
  showId: pt.bool,
};

PatientInfoBar.defaultProps = {
  fileID: null,
  animalName: null,
  ownerName: null,
  birthDate: null,
  specie: null,
  sex: null,
  className: '',
  showId: true,
};

export default PatientInfoBar;
