/* eslint-disable no-underscore-dangle */
import * as _ from 'lodash';
import { memoizeDebounce } from 'app/utils/lodashMixins';
import { setInvalidWatchedDirectory } from 'app/pms/actions';
import { selectPMSReducer } from 'app/redux/reducers';
import handleFileInput from 'app/pms/handleFileInput';
import observeStore from 'app/utils/redux/observeStore';
import { makeSelectWatchedDirectory } from 'app/pms/selector';
import fs from 'app/native/node/fs';
import path from 'app/native/node/path';

async function* getFiles(dir) {
  const directoryEntries = await fs().promises.readdir(dir, { withFileTypes: true });
  for (let i = 0; i < directoryEntries.length; i += 1) {
    const directoryEntry = directoryEntries[i];
    const res = path().resolve(dir, directoryEntry.name);
    if (directoryEntry.isDirectory()) {
      yield* getFiles(res);
    } else {
      yield res;
    }
  }
}

class PmsDirectoryWatcher extends EventTarget {
  constructor(fileHandler, parser) {
    super();
    this.watcher = undefined;
    this.fileHandler = fileHandler;
    this.parser = parser;
    this.debouncedFileChange = memoizeDebounce(this.onFileChange, 100, {
      resolver: (watchedDirectory, _eventType, filename) => path().join(watchedDirectory, filename),
    });
  }

  onDirectoryChange = async ({ store, state: watchedDirectory }) => {
    if (!fs() || !path()) return;

    this.watcher?.close();
    if (!watchedDirectory) {
      this.watcher = undefined;
      return;
    }
    try {
      this.watcher = fs().watch(
        watchedDirectory,
        { persistent: false, recursive: true, encoding: 'utf8' },
        this.debouncedFileChange.bind(this, watchedDirectory)
      );
      store.dispatch(setInvalidWatchedDirectory(false));
    } catch (e) {
      store.dispatch(setInvalidWatchedDirectory(true));
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for await (const filename of getFiles(watchedDirectory)) {
      try {
        await this._readAndParseFile(filename);
      } catch (e) {
        console.warn(e);
      }
    }
  };

  _readAndParseFile = async (filepath) => {
    await this.fileHandler(filepath, this.parser);
    const event = new Event('file_parsed');
    this.dispatchEvent(event);
  };

  onFileChange = async (watchedDirectory, eventType, filename) => {
    if (eventType !== 'change') return;
    try {
      const filePath = path().join(watchedDirectory, filename);
      const fileStat = await fs().promises.stat(filePath);
      if (fileStat.isFile()) {
        await this._readAndParseFile(path().join(watchedDirectory, filename));
      }
    } catch (e) {
      console.warn(e);
    }
  };
}

const attachPMSDirectoryWatcher = (
  store,
  { fileHandler = handleFileInput, pmsSelector = makeSelectWatchedDirectory(), parser } = {}
) => {
  const pmsDirectoryWatcher = new PmsDirectoryWatcher(fileHandler, parser);
  const unsubscribe = observeStore(store, pmsSelector, pmsDirectoryWatcher.onDirectoryChange);
  return {
    pmsDirectoryWatcher,
    unsubscribe,
  };
};

export { PmsDirectoryWatcher, attachPMSDirectoryWatcher };
