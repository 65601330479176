const ORDERED_STADE_LIST = [
  { name: 'A', color: 'green' },
  { name: 'A-B', color: 'green' },
  { name: 'B', color: 'green' },
  { name: 'B-C', color: 'yellow' },
  { name: 'C', color: 'yellow' },
  { name: 'C-D', color: 'orange' },
  { name: 'D', color: 'orange' },
  { name: 'D-E', color: 'red' },
  { name: 'E', color: 'red' },
];
Object.freeze(ORDERED_STADE_LIST);

const DYSPLASIA_STADE_THRESHOLDS = [
  { stade: { name: 'A', color: 'green' }, threshold: 0.5 },
  { stade: { name: 'A-B', color: 'green' }, threshold: 1 },
  { stade: { name: 'B', color: 'green' }, threshold: 1.5 },
  { stade: { name: 'B-C', color: 'yellow' }, threshold: 2 },
  { stade: { name: 'C', color: 'yellow' }, threshold: 2.5 },
  { stade: { name: 'C-D', color: 'orange' }, threshold: 3 },
  { stade: { name: 'D', color: 'orange' }, threshold: 3.5 },
  { stade: { name: 'D-E', color: 'red' }, threshold: 4 },
  { stade: { name: 'E', color: 'red' }, threshold: 4.5 },
];
Object.freeze(DYSPLASIA_STADE_THRESHOLDS);

// Instead of returning A, B, D or E we return a stade 1 stade less severe because norberg olsson
// criteria is a bit too strict.
const NORBERG_OLSSON_STADE_THRESHOLDS = [
  { stade: { name: 'D-E', color: 'red' }, threshold: 90 },
  // We use 96 instead of 100 because the NO criteria in this angle range can downgrade a dog from A-B to C-D
  // and vets usually don't apply the criteria strictly.
  { stade: { name: 'C-D', color: 'orange' }, threshold: 95 },
  { stade: { name: 'B', color: 'green' }, threshold: 100 },
  { stade: { name: 'A-B', color: 'green' }, threshold: 105 },
  { stade: { name: 'A', color: 'green' }, threshold: 106 },
];

export { ORDERED_STADE_LIST, DYSPLASIA_STADE_THRESHOLDS, NORBERG_OLSSON_STADE_THRESHOLDS};
