import { fromJS } from 'immutable';
import {
  makeSyncReduxWithLocalStorageObserver,
  restoreFromLocalStorage,
} from 'app/dataManagement/syncReduxStateWithLocalStorage';
import { INPUT_MAPPING_SET_DICOM_MAPPING } from 'app/redux/userInputMapping/actions';
import observeStore from 'app/utils/redux/observeStore';
import { createSelector } from 'reselect';

export const USER_INPUT_MAPPING_LOCAL_STORAGE_KEY = 'redux.userInputMapping';

const pacsConfigurationInitialState = fromJS(
  restoreFromLocalStorage(USER_INPUT_MAPPING_LOCAL_STORAGE_KEY, { dicomMapping: undefined })
);

const userInputMappingReducer = (state = pacsConfigurationInitialState, action) => {
  switch (action.type) {
    case INPUT_MAPPING_SET_DICOM_MAPPING:
      return state.set('dicomMapping', action.dicomMapping);
    default:
      return state;
  }
};

export const selectUserInputMapping = (state) => state.get('userInputMapping');
export const selectDicomMapping = createSelector(
  selectUserInputMapping,
  (state) => state.toJS().dicomMapping
);

export const attachUserInputMappingWithLocalStorageSynchronizationObserver = (store) => {
  observeStore(
    store,
    selectUserInputMapping,
    makeSyncReduxWithLocalStorageObserver(USER_INPUT_MAPPING_LOCAL_STORAGE_KEY)
  );
};

export default userInputMappingReducer;
