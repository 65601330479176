import * as _ from 'lodash';
import { isString } from 'lodash';
import { isNumberConvertible } from '../mathUtils';
import { predictionsMerger } from '../predictions/utils';

import BaseReportGenerator from './BaseReportGenerator';
import { auxiliaryPredictions, newline, PATTERNS_AFFIXES_BY_KEYS } from './constants';
import {
  addToMaxSeverity,
  addTrailingDot,
  capitalizeFirstLetter,
  lowerCaseFirstLetter,
  removeTrailingDot,
} from './utils';

const getDifferentialDiagnosisGroups = (patternsDescription) =>
  _(patternsDescription).map('differentialDiagnosis').uniq().compact();

const getDifferentialDiagnosisKeys = (patternsDescription) =>
  getDifferentialDiagnosisGroups(patternsDescription).flatten().value();

const filterPatternsOfInterest = (patternsDescription, ...predictionsListToFilter) => {
  const patternsOfInterestKeys = [
    ..._.keys(patternsDescription),
    ...getDifferentialDiagnosisKeys(patternsDescription),
  ];
  return predictionsListToFilter.map((predictions) => _.pick(predictions, patternsOfInterestKeys));
};

const getPatternName = (pattern) => (isString(pattern) ? pattern : pattern[0]);

const findPatternIndexByName = (patterns, patternName) =>
  _.findIndex(patterns, (pattern) => patternName === getPatternName(pattern));

const intersectWithPatternsSeverities = (patternsBySeverity, ...patternsToIntersect) =>
  _.mapValues(patternsBySeverity, (severityGroupPatterns) =>
    _.intersectionBy(severityGroupPatterns, ...patternsToIntersect, getPatternName)
  );

const toSearchArray = (value) => (Array.isArray(value) ? value : [value]);

class GenericPatternsReportGenerator extends BaseReportGenerator {
  getMaxPredictions = (images) => {
    const predictionsNumbersList = _.map(images, ({ predictions }) =>
      _.pickBy(predictions, isNumberConvertible)
    );
    const maxPredictions = _.mergeWith({}, ...predictionsNumbersList, predictionsMerger);

    const feedbackNumbersList = _.map(images, ({ feedback }) =>
      _.pickBy(feedback, isNumberConvertible)
    );
    const maxPredictionsFeedback = _.mergeWith({}, ...feedbackNumbersList, predictionsMerger);

    return { maxPredictions, maxPredictionsFeedback };
  };

  isAuxiliaryPrediction = (patternName) => auxiliaryPredictions.indexOf(patternName) > -1;

  removePatternsSingleList = (patternsToRemove, list) =>
    list?.filter((pattern) => patternsToRemove.indexOf(pattern) === -1);

  removePatterns = (
    patternsToRemove,
    markedPatterns,
    presentPatterns,
    likelyPatterns,
    unlikelyPatterns
  ) => ({
    markedPatterns: this.removePatternsSingleList(patternsToRemove, markedPatterns),
    presentPatterns: this.removePatternsSingleList(patternsToRemove, presentPatterns),
    likelyPatterns: this.removePatternsSingleList(patternsToRemove, likelyPatterns),
    unlikelyPatterns: this.removePatternsSingleList(patternsToRemove, unlikelyPatterns),
  });

  removePatternsInSeverities = (
    patternsToRemove,
    { markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns }
  ) =>
    this.removePatterns(
      patternsToRemove,
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns
    );

  getAbsentPatterns = (predictions, feedback) =>
    Object.keys(predictions).filter(
      (patternName) =>
        predictions[patternName] <= 0.4 &&
        !this.isAuxiliaryPrediction(patternName) &&
        !feedback[patternName]
    );

  getMarkedPatterns = (predictions, feedback) =>
    Object.keys(predictions).filter(
      (patternName) =>
        predictions[patternName] > 0.75 &&
        !feedback[patternName] &&
        !this.isAuxiliaryPrediction(patternName)
    );

  getPresentPatterns = (predictions, feedback) =>
    Object.keys(predictions).filter(
      (patternName) =>
        ((predictions[patternName] <= 0.75 && predictions[patternName] > 0.5) ||
          feedback[patternName]) &&
        !this.isAuxiliaryPrediction(patternName)
    );

  getLikelyPatterns = (predictions, feedback) =>
    Object.keys(predictions).filter(
      (patternName) =>
        predictions[patternName] <= 0.5 &&
        predictions[patternName] > 0.45 &&
        !this.isAuxiliaryPrediction(patternName) &&
        !feedback[patternName]
    );

  getUnlikelyPatterns = () => [];

  getNumberOfPatternsToDisplay = (predictions, feedback, patternsDescription) => {
    const [predictionsOfInterest, feedbackOfInterest] = filterPatternsOfInterest(
      patternsDescription,
      predictions,
      feedback
    );
    return (
      Object.keys(predictionsOfInterest).length -
      this.getAbsentPatterns(predictionsOfInterest, feedbackOfInterest).length
    );
  };

  getSentences = (patterns, patternsDescription) =>
    _.filter(patterns, (pattern) => patternsDescription[getPatternName(pattern)]?.isSentence);

  applyCompositionRule = (
    patternsBySeverity,
    compositionList,
    compositionType,
    composedPatternName
  ) => {
    const compositionListSearchResult = _(compositionList)
      .mapValues(toSearchArray)
      .mapValues((searchedPatterns) =>
        intersectWithPatternsSeverities(patternsBySeverity, searchedPatterns)
      )
      .mapValues((searchResultBySeverity) => _.omitBy(searchResultBySeverity, _.isEmpty))
      .value();

    const isAllCompositionListFound = _.every(
      compositionListSearchResult,
      (compositionSearchResult) => _.flatMap(compositionSearchResult).length > 0
    );
    if (!isAllCompositionListFound) return {};

    const allPatternsFound = _.flatMap(compositionListSearchResult, _.unary(_.flatMap));

    const compositionTranslationValues = _(compositionListSearchResult)
      .mapValues(_.toPairs)
      .mapValues(([[severity, [patternName]]]) =>
        lowerCaseFirstLetter(removeTrailingDot(this.formatPattern(patternName, severity)))
      )
      .value();

    let composedPattern;
    if (compositionType === 'combinationOf') {
      composedPattern = [composedPatternName, composedPatternName, compositionTranslationValues];
    } else if (compositionType === 'associationOf') {
      composedPattern = [composedPatternName, 'associated_with', compositionTranslationValues];
    }
    return { patternsToRemove: allPatternsFound, composedPattern };
  };

  /**
   * Method to override by sub class to add specific patterns compositions: see
   * thoraxReportGenerator for an example.
   * @param {*} markedPatterns
   * @param {*} presentPatterns
   * @param {*} likelyPatterns
   * @param {*} unlikelyPatterns
   * @returns Object composed of { markedPatterns, presentPatterns, likelyPatterns, unlikelyPatterns }
   * with composition results and unmodified patterns
   */
  composePatterns = (
    initialMarkedPatterns,
    initialPresentPatterns,
    likelyPatterns,
    unlikelyPatterns,
    patternsDescription
  ) => {
    let markedPatterns = [...initialMarkedPatterns];
    let presentPatterns = [...initialPresentPatterns];

    const compositionPatternsDescription = _.pickBy(
      patternsDescription,
      (patternDescription) => patternDescription.combinationOf || patternDescription.associationOf
    );
    _.forEach(compositionPatternsDescription, (description, composedPatternName) => {
      const compositionType = description.associationOf ? 'associationOf' : 'combinationOf';
      const compositionList = description[compositionType];
      const { patternsToRemove, composedPattern } = this.applyCompositionRule(
        { markedPatterns, presentPatterns },
        compositionList,
        compositionType,
        composedPatternName
      );

      if (!patternsToRemove || !composedPattern) return;

      addToMaxSeverity(composedPattern, patternsToRemove, [markedPatterns, presentPatterns]);

      if (patternsToRemove) {
        ({ markedPatterns, presentPatterns } = this.removePatterns(
          patternsToRemove,
          markedPatterns,
          presentPatterns
        ));
      }
    });

    return {
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns,
    };
  };

  getComposedPatterns = (predictions, maxPredictionsFeedback, patternsDescription) => {
    let markedPatterns = this.getMarkedPatterns(predictions, maxPredictionsFeedback);
    const presentPatterns = this.getPresentPatterns(predictions, maxPredictionsFeedback);
    const markedPatternsWithMaxSeverityPresent = markedPatterns.filter(
      (pattern) => patternsDescription[pattern]?.maxSeverityPresent
    );
    markedPatterns = _.difference(markedPatterns, markedPatternsWithMaxSeverityPresent);
    presentPatterns.push(...markedPatternsWithMaxSeverityPresent);

    const likelyPatterns = this.getLikelyPatterns(predictions, maxPredictionsFeedback);
    const unlikelyPatterns = this.getUnlikelyPatterns(predictions, maxPredictionsFeedback);
    return this.composePatterns(
      markedPatterns,
      presentPatterns,
      likelyPatterns,
      unlikelyPatterns,
      patternsDescription
    );
  };

  getAfter = (after, patterns) =>
    this.formatMessageIfPlural(
      after,
      patterns
        .slice()
        .reverse()
        .findIndex((pattern) => getPatternName(pattern).endsWith('.')) > 0
    );

  getBefore = (before, patterns) =>
    this.formatMessageIfPlural(
      before,
      patterns.findIndex((pattern) => getPatternName(pattern).endsWith('.')) > 0
    );

  getPatternListAsText = (
    patterns,
    before,
    after,
    beforePattern = 'report.beforePattern',
    union = 'report.and',
    severity = undefined
  ) => {
    let report = this.getBefore(before, patterns);
    let separator;
    patterns.forEach((pattern, index) => {
      let displayPatternName = this.formatPattern(pattern, severity);
      if (displayPatternName.endsWith('.')) {
        displayPatternName = displayPatternName.slice(0, displayPatternName.length - 1);
        separator = '';
        if (index < patterns.length - 1) {
          separator += this.getAfter(after, patterns.slice(index));
          separator += ` ${this.getBefore(before, patterns.slice(index))}`;
        }
      } else if (index === patterns.length - 1) {
        separator = '';
      } else if (index === patterns.length - 2) {
        separator = ` ${this.formatMessage(union)} ${this.formatMessage(beforePattern)} `;
      } else {
        separator = `, ${this.formatMessage(beforePattern)} `;
      }
      report += displayPatternName + separator;
    });
    return report + this.getAfter(after, patterns);
  };

  splitPatternsIntoSentences = (
    patterns,
    beforePatterns,
    afterPatterns,
    patternsDescription,
    severity
  ) => {
    const orderedPatterns = [...patterns];
    const patternsDescriptionWithOrdering = _.pickBy(patternsDescription, 'shouldFollow');
    _.forEach(patternsDescriptionWithOrdering, ({ shouldFollow }, patternWithShouldFollow) => {
      const patternWithShouldFollowIndex = findPatternIndexByName(
        orderedPatterns,
        patternWithShouldFollow
      );
      if (patternWithShouldFollowIndex === -1) return;
      const extractedPattern = orderedPatterns.splice(patternWithShouldFollowIndex, 1);
      const followedPatternIndex = findPatternIndexByName(orderedPatterns, shouldFollow);
      orderedPatterns.splice(followedPatternIndex, 0, ...extractedPattern);
    });
    const sentences = this.getSentences(patterns, patternsDescription);
    const enumeratedPatterns = _.difference(patterns, sentences);
    const textList = [];
    if (enumeratedPatterns?.length) {
      textList.push(
        capitalizeFirstLetter(
          this.getPatternListAsText(
            enumeratedPatterns,
            beforePatterns,
            afterPatterns,
            'report.beforePattern',
            'report.and',
            severity
          )
        )
      );
    }
    if (sentences?.length) {
      textList.push(...sentences.map((pattern) => this.formatPattern(pattern, severity)));
    }
    return textList;
  };

  getShortCompteRendu = (predictions, maxPredictionsFeedback, patternsDescription) => {
    let report = '';
    const patternsOfInterestKeys = [
      ..._.keys(patternsDescription),
      ...getDifferentialDiagnosisKeys(patternsDescription),
    ];
    const predictionsOfInterest = _.pick(predictions, patternsOfInterestKeys);
    const feedbacksOfInterest = _.pick(maxPredictionsFeedback, patternsOfInterestKeys);
    const composedPatterns = this.getComposedPatterns(
      predictionsOfInterest,
      feedbacksOfInterest,
      patternsDescription
    );
    const { patterns, differentialDiagnosis } = this.mergePatternsAndDiagnosis(
      composedPatterns,
      patternsDescription
    );
    const patternsText = _(patterns)
      .map((patternsBySeverity, severity) =>
        this.splitPatternsIntoSentences(
          patternsBySeverity,
          ...PATTERNS_AFFIXES_BY_KEYS[severity],
          patternsDescription,
          severity
        )
      )
      .flatten()
      .map(capitalizeFirstLetter)
      .map(addTrailingDot)
      .join(newline);
    report += patternsText + (patternsText && newline);

    if (differentialDiagnosis) {
      const anyPresentDiagnosis =
        differentialDiagnosis.markedPatterns.length + differentialDiagnosis.presentPatterns.length >
        0;
      if (anyPresentDiagnosis) {
        report +=
          capitalizeFirstLetter(
            this.getPatternListAsText(
              differentialDiagnosis.markedPatterns.concat(differentialDiagnosis.presentPatterns),
              'report.beforeDifferentialDiagnosis',
              'report.afterDifferentialDiagnosis',
              'report.nothingBeforePattern',
              'report.andOr'
            )
          ) + newline;
      }
    }
    return report;
  };

  matchPatternsWithDiagnosis = (
    analyzedDiagnosis,
    analyzedPatternsBySeverity,
    diagnosisGroup,
    patternsDescription
  ) => {
    const foundDiagnosis = _.intersection(analyzedDiagnosis, diagnosisGroup);
    if (foundDiagnosis.length === 0) return undefined;

    const patternsForDiagnosisGroup = _(patternsDescription)
      .pickBy({ differentialDiagnosis: diagnosisGroup })
      .keys()
      .value();

    const foundPatternsByGroup = intersectWithPatternsSeverities(
      analyzedPatternsBySeverity,
      patternsForDiagnosisGroup
    );
    const maxSeverity = _.findKey(foundPatternsByGroup, _.negate(_.isEmpty));
    const foundPatterns = _(foundPatternsByGroup).values().flatten().value();
    if (foundPatterns.length === 0) return undefined;

    const allSeveritiesPatternSentences = _(foundPatternsByGroup)
      .map((patternsSeverity, severityKey) =>
        this.splitPatternsIntoSentences(
          patternsSeverity,
          ...PATTERNS_AFFIXES_BY_KEYS[severityKey],
          patternsDescription,
          severityKey
        )
      )
      .flatten();
    const patternSentencesAsText = this.enumeratePatterns(
      allSeveritiesPatternSentences,
      'report.and',
      maxSeverity
    );
    const diagnosisSentence = lowerCaseFirstLetter(
      this.enumeratePatterns(foundDiagnosis, 'report.andOr', maxSeverity)
    );
    const diagnosisPattern = [
      'differential_diagnosis',
      'differential_diagnosis',
      { patterns: patternSentencesAsText, diagnosis: diagnosisSentence },
    ];
    return { diagnosisPattern, patternsToRemove: foundPatterns, diagnosisToRemove: foundDiagnosis };
  };

  mergePatternsAndDiagnosis = (patternsSeverityGroups, patternsDescription) => {
    let { patterns, differentialDiagnosis } = this.splitPatternsAndDifferentialDiagnosis(
      patternsSeverityGroups,
      patternsDescription
    );
    const analyzedDiagnosis = [
      ...differentialDiagnosis.markedPatterns,
      ...differentialDiagnosis.presentPatterns,
    ];
    const analyzedPatternsBySeverity = {
      markedPatterns: patterns.markedPatterns,
      presentPatterns: patterns.presentPatterns,
      likelyPatterns: patterns.likelyPatterns,
    };
    const diagnosisGroups = getDifferentialDiagnosisGroups(patternsDescription);
    diagnosisGroups.forEach((diagnosisGroup) => {
      const matchResult = this.matchPatternsWithDiagnosis(
        analyzedDiagnosis,
        analyzedPatternsBySeverity,
        diagnosisGroup,
        patternsDescription
      );
      if (!matchResult) return;
      const { diagnosisPattern, patternsToRemove, diagnosisToRemove } = matchResult;
      addToMaxSeverity(diagnosisPattern, patternsToRemove, [
        patterns.markedPatterns,
        patterns.presentPatterns,
        patterns.likelyPatterns,
      ]);
      patterns = this.removePatternsInSeverities(patternsToRemove, patterns);
      differentialDiagnosis = this.removePatternsInSeverities(
        diagnosisToRemove,
        differentialDiagnosis
      );
    });

    return { patterns, differentialDiagnosis };
  };

  splitPatternsAndDifferentialDiagnosis = (composedPatterns, patternsDescription) => {
    const patterns = intersectWithPatternsSeverities(composedPatterns, _.keys(patternsDescription));
    const differentialDiagnosisKeys = getDifferentialDiagnosisKeys(patternsDescription);
    const differentialDiagnosis = intersectWithPatternsSeverities(
      composedPatterns,
      differentialDiagnosisKeys
    );

    return { patterns, differentialDiagnosis };
  };
}

export default GenericPatternsReportGenerator;

export { filterPatternsOfInterest };
